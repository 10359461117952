import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';

export type TimeTransactionsState = {
    timeTransactions: Array<ITimeTransactionDetailResult>;
    isLoading: boolean;
    shouldRefreshSummary: boolean;
};

export enum TimeTransactionsDispatchType {
    Create,
    CreateDefaultIn,
    CreateDefaultOut,
    SetTransactions,
    UpdatingTransactions,
    UpdatingFailed,
    RefreshSummary
}

export type TimeTransactionsAction =
    | { type: TimeTransactionsDispatchType.UpdatingTransactions }
    | { type: TimeTransactionsDispatchType.RefreshSummary; shouldRefreshSummary: boolean }
    | { type: TimeTransactionsDispatchType.UpdatingFailed; errorMessage: string }
    | {
          type: TimeTransactionsDispatchType.Create;
          newTrans: ITimeTransactionDetailResult;
      }
    | {
          type: TimeTransactionsDispatchType.SetTransactions;
          transactions: Array<ITimeTransactionDetailResult>;
      };
