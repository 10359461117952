import dateI18n, { DateI18n } from 'i18n/dateI18n';
import { EmployeeRegistrationAccessInformation } from 'models/TimeRegistrationModels';

function canEditForwardsInTime(
    dateRequested: DateI18n,
    timeOfRequest: DateI18n,
    settings: EmployeeRegistrationAccessInformation
) {
    if (!settings.canRegisterAtAll) {
        return false;
    }

    const daysAheadRequested = dateRequested.diff(timeOfRequest.startOf('day'), 'day');
    const isSameDay = daysAheadRequested === 0;
    if (isSameDay) {
        return true;
    }

    const isDaysBackDiff = daysAheadRequested < 0;
    if (isDaysBackDiff) {
        return false;
    }

    // Grace period for next day if past 22:00, with a 5 second margin present in MC1
    const isWithinGracePeriod =
        daysAheadRequested === 1 &&
        (timeOfRequest.hour() >= 22 ||
            (timeOfRequest.hour() === 21 && timeOfRequest.second() >= 55));
    if (settings.canRegisterForwardInTime && isWithinGracePeriod) {
        return true;
    }

    if (
        settings.canRegisterForwardInTime &&
        daysAheadRequested <= settings.numberOfDaysForwardInTimeRegisteringIsAllowed
    ) {
        return true;
    }

    return false;
}

function canEditBackInTime(
    dateRequested: DateI18n,
    timeOfRequest: DateI18n,
    settings: EmployeeRegistrationAccessInformation
) {
    if (!settings.canRegisterAtAll) {
        return false;
    }

    const daysBackRequested = timeOfRequest.startOf('day').diff(dateRequested, 'day');
    const isSameDay = daysBackRequested === 0;
    if (isSameDay) {
        return true;
    }

    const isDaysAheadDiff = daysBackRequested < 0;
    if (isDaysAheadDiff) {
        return false;
    }

    // Grace period for previous day until 02:00, with a 5 second margin present in MC1
    const isWithinGracePeriod =
        daysBackRequested === 1 &&
        (timeOfRequest.hour() < 2 || (timeOfRequest.hour() === 2 && timeOfRequest.second() < 5));
    if (settings.canRegisterBackInTime && isWithinGracePeriod) {
        return true;
    }

    const isWithinAllowedSetting =
        settings.canRegisterBackInTime &&
        daysBackRequested <= settings.numberOfDaysBackInTimeRegisteringIsAllowed;
    if (isWithinAllowedSetting) {
        return true;
    }

    return false;
}

export default function canAccessTimeRegistration(
    dateRequestedAsIso: string,
    timeOfRequest: DateI18n,
    employeeAccessSettings: EmployeeRegistrationAccessInformation
) {
    const dateRequested = dateI18n(dateRequestedAsIso);

    return (
        canEditBackInTime(dateRequested, timeOfRequest, employeeAccessSettings) ||
        canEditForwardsInTime(dateRequested, timeOfRequest, employeeAccessSettings)
    );
}
