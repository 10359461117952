import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from '@mui/material';
import HFTextField from 'components/hookForm/HFTextField';
import HFTimeInput from 'components/hookForm/HFTimeInput';
import HFFormProvider from 'ctx/HFFormProvider';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/button/ActionButton';
import { upperFirst } from 'lodash';
import { useCallback } from 'react';
import {
    DimensionValuesByDimension,
    getDimensionNameAsInputNameForHourRegistration
} from 'utils/dimension';
import { DimensionName } from 'models/TimeRegistrationModels';
import ErrorMessage from 'components/error/ErrorMessage';
import { getTranslatedError } from 'utils/translation';
import useReadableDate from 'hooks/useReadableDate';
import DialogSpinner from 'components/dialog/DialogSpinner';
import {
    DimensionOptionsByDimension,
    DimensionInputList,
    DimensionInputRules
} from 'features/misc/dimensionInput';
import { PostHourRegistrationPayload } from '../hooks/useHourRegistrationForm';

export type HourRegistrationViewProps = {
    date: string;
    formProps: UseFormWithApiIntegrationReturn<PostHourRegistrationPayload>;
    dimensionInputRules: DimensionInputRules;
    dimensionOptionsSet: DimensionOptionsByDimension;
    dimensionNamesLoading: Array<DimensionName>;
    isEditing: boolean;
    initDimensionValuesByDimensionName: DimensionValuesByDimension;
    isLoading: boolean;
    isTimeInEditable: boolean;
    isTimeOutEditable: boolean;
    onCancel: VoidFunction;
};

function getBasicInputName(fieldName: string, purpose: 'create' | 'update') {
    return purpose === 'update' ? `new${upperFirst(fieldName)}` : `${fieldName}`;
}

export default function HourRegistrationView({
    date,
    formProps,
    dimensionInputRules,
    dimensionOptionsSet,
    dimensionNamesLoading,
    isEditing,
    initDimensionValuesByDimensionName,
    isLoading,
    isTimeInEditable,
    isTimeOutEditable,
    onCancel
}: HourRegistrationViewProps) {
    const { t } = useTranslation();

    const inputMode = isEditing ? 'update' : 'create';

    const getDimensionInputName = useCallback(
        (dimensionName: DimensionName) =>
            getDimensionNameAsInputNameForHourRegistration(dimensionName, inputMode),
        [inputMode]
    );

    const readableDate = useReadableDate(date);

    if (isLoading) {
        return <DialogSpinner />;
    }

    return (
        <Box>
            <HFFormProvider formProps={formProps}>
                <DialogTitle>{`${t('actions.registerTime')}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText marginBottom={2}>
                        {t('dateTime.selectedDateText', { readableDate })}
                    </DialogContentText>

                    <Stack spacing={2}>
                        <Stack direction="row" spacing={2}>
                            <HFTimeInput
                                name={getBasicInputName('timeIn', inputMode)}
                                label={t('dimension.timeIn')}
                                required
                                disabled={!isTimeInEditable}
                            />
                            <HFTimeInput
                                name={getBasicInputName('timeOut', inputMode)}
                                label={t('dimension.timeOut')}
                                disabled={!isTimeOutEditable}
                            />
                        </Stack>

                        <DimensionInputList
                            inputRules={dimensionInputRules}
                            dimensionOptionsSet={dimensionOptionsSet}
                            dimensionNamesLoading={dimensionNamesLoading}
                            transformInputName={getDimensionInputName}
                            initValuesByDimensionName={initDimensionValuesByDimensionName}
                        />

                        <HFTextField
                            label={t('dimension.comment')}
                            enterKeyHint="send"
                            name={getBasicInputName('text', inputMode)}
                            fullWidth
                            multiline
                            rows={5}
                        />

                        {formProps.displayErrorMessage && (
                            <ErrorMessage
                                message={getTranslatedError(
                                    formProps.displayErrorMessage,
                                    'hourRegistration.errors.generic'
                                )}
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ActionButton type="button" variant="outlined" onClick={onCancel}>
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formProps.formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}
