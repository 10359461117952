import {
    Info,
    SvgIconComponent,
    WarningAmber,
    ErrorOutline,
    CheckCircle
} from '@mui/icons-material';
import { Typography } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import { useTranslation } from 'react-i18next';

type Severity = 'success' | 'info' | 'warning' | 'error';

export interface AlertDialogProps extends DialogProps {
    title: string;
    description?: string;
    confirmText?: string;
    isRunningConfirmOperation: boolean;
    severity: Severity;
    errorMessage?: string;
    onConfirm: VoidFunction;
}

export default function AlertDialog({
    title,
    description,
    severity,
    open,
    sx,
    confirmText,
    isRunningConfirmOperation,
    onConfirm,
    errorMessage
}: AlertDialogProps) {
    const { t } = useTranslation();

    const icons: Record<Severity, SvgIconComponent> = {
        warning: WarningAmber,
        info: Info,
        error: ErrorOutline,
        success: CheckCircle
    };

    const DialogIcon = icons[severity];

    const contentColor = severity !== 'warning' ? severity : 'error';

    return (
        <Dialog role="alertdialog" open={open} sx={{ textAlign: 'center', ...sx }}>
            <Typography
                variant="h1"
                component="div"
                aria-hidden="true"
                sx={{ marginTop: '30px', display: 'flex', justifyContent: 'center' }}
            >
                <DialogIcon color={contentColor} fontSize="inherit" />
            </Typography>

            <DialogTitle>{title}</DialogTitle>
            {(description || errorMessage) && (
                <DialogContent>
                    {description && <DialogContentText>{description}</DialogContentText>}
                    {errorMessage && <ErrorMessage message={errorMessage} />}
                </DialogContent>
            )}
            <DialogActions sx={{ display: 'flex', justifyContent: 'center' }}>
                <ActionButton
                    autoFocus
                    onClick={onConfirm}
                    color={contentColor}
                    isloading={isRunningConfirmOperation}
                >
                    {confirmText || t('actions.ok')}
                </ActionButton>
            </DialogActions>
        </Dialog>
    );
}

AlertDialog.defaultProps = {
    confirmText: undefined,
    description: undefined,
    errorMessage: undefined
};
