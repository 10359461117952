import dayjs, { Dayjs } from 'dayjs';
import globalLocaleData from 'dayjs/plugin/localeData';
import updateLocale from 'dayjs/plugin/updateLocale';
import isToday from 'dayjs/plugin/isToday';
import isYesterday from 'dayjs/plugin/isYesterday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import relativeTime from 'dayjs/plugin/relativeTime';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import 'dayjs/locale/nb';
import 'dayjs/locale/en';
import { DEFAULT_LANGUAGE, SupportedLanguage, supportedLanguages } from './support';

dayjs.extend(globalLocaleData);
dayjs.extend(isToday);
dayjs.extend(isYesterday);
dayjs.extend(weekOfYear);
dayjs.extend(relativeTime);

type AllSupportedLanguagesAndMoreMap = Record<SupportedLanguage, string> & Record<string, string>;
const dayJsLocaleMap: AllSupportedLanguagesAndMoreMap = {
    no: 'nb',
    en: 'en',
    nb: 'nb',
    nn: 'nb',
    'nb-NO': 'nb',
    'nn-NO': 'nb',
    'en-US': 'en',
    'en-UK': 'en'
};

/**
 * Ideally we'd like to set FIXED_LOCALIZED_DATE_FORMAT on the date locale by doing...
 *   dayjs.updateLocale(languageRef, {
 *       weekStart: 1,
 *       formats: nbFormatWithExceptions
 *   });
 * ... but the nbFormatWithExceptions part is where things get tricky. nbFormatWithExceptions
 * should be a mix of formats obtained from dayjs/locale variants.
 *
 * By doing this we could refer to format L/LL when formatting dates (as opposed to what
 * dateToReadableString is doing right now), and we could also rid ourselves of passing
 * `format={FIXED_LOCALIZED_DATE_FORMAT}` to the DatePicker component.
 */
export const FIXED_LOCALIZED_DATE_FORMAT = 'DD.MM.YYYY';

// Set weekStart to monday for all supported languages
dayjs.extend(updateLocale);
supportedLanguages.forEach((supportedLanugage) => {
    const languageRef = dayJsLocaleMap[supportedLanugage];
    dayjs.updateLocale(languageRef, {
        weekStart: 1
    });
});

type DateLocaleLookup = keyof typeof dayJsLocaleMap;

export function isDateLocaleAvailable(
    languageOrLocale: string
): languageOrLocale is DateLocaleLookup {
    return Object.keys(dayJsLocaleMap).includes(languageOrLocale);
}

export function getDateLocaleAvailableOrDefault(languageOrLocale: string) {
    return isDateLocaleAvailable(languageOrLocale)
        ? dayJsLocaleMap[languageOrLocale]
        : dayJsLocaleMap[DEFAULT_LANGUAGE];
}

export function setDateLanguage(language: SupportedLanguage) {
    const locale = getDateLocaleAvailableOrDefault(language);
    dayjs.locale(locale);
}

export function getFullWeekdayName(minName: string) {
    dayjs.extend(globalLocaleData);

    const localeData = dayjs.localeData();
    const weekDaysMin = localeData.weekdaysMin();
    const weekDaysFull = localeData.weekdays();

    const dayNumber = weekDaysMin.indexOf(minName);

    return weekDaysFull[dayNumber];
}

export function getShortWeekdayName(minName: string) {
    return getFullWeekdayName(minName).substring(0, 3);
}

export { AdapterDayjs as I18nDateAdapter };
export { Dayjs as DateI18n };

export default dayjs;
