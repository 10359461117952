import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { i18n } from 'i18n';
import { DutyAvailable } from 'models/Duty';
import customValidators from 'utils/customValidators';
import { number, object, string } from 'zod';

export type PostDutyRequestPayload = undefined; // Server responds with empty content array upon success

export default function useRequestDutyForm(duty: DutyAvailable, onSuccess: VoidFunction) {
    const schema = object({
        departmentId: number(),
        dutyPlanId: number(),
        dutyId: number(),
        originalEmployeeId: number().nullable(),
        originalFromDate: customValidators.date(),
        commentFromEmployee: string()
            .max(
                4000,
                i18n.t('genericValidation.errors.string.maxLength', { maxLength: 4000 }) || ''
            )
            .optional()
    });
    const formProps = useFormWithApiIntegration<PostDutyRequestPayload>(
        'requestAvaliableDuty',
        schema,
        {
            departmentId: duty.departmentId,
            dutyPlanId: duty.dutyPlanId,
            dutyId: duty.dutyId,
            originalEmployeeId: duty.employeeId,
            originalFromDate: duty.startDate,
            commentFromEmployee: ''
        },
        {
            onSuccess
        }
    );

    return formProps;
}
