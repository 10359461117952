import { useContext } from 'react';
import { MyCapitechSettingsContext } from 'ctx/MyCapitechSettingsProvider/MyCapitechSettingsProvider';
import useEmployeeSettings from './useEmployeeSettings';
import { AllKnownEmployeeSettings } from './useEmployeeSettings.types';

/**
 * This hook provides a function which ensures an employee setting is up to date with an expected value (typically returned from a post request)
 *
 * @returns {function(string, string): void}
 */
export default function useEmployeeSettingSync() {
    const {
        refreshMyCapitechSettings,
        isLoading: isSyncingSettings,
        errorMessage
    } = useContext(MyCapitechSettingsContext);

    // Ensure settings are up to date
    const { employeeSettings: currentlySyncedEmployeeSettings } = useEmployeeSettings();

    // Function which syncs if needed. Sync status status is returned by hook, as it's shared across all components using the hook.
    const syncEmployeeSetting = async (key: AllKnownEmployeeSettings, updatedValue: string) => {
        if (
            !isSyncingSettings && // If we could cancel existing sync instead, that would be better
            updatedValue &&
            currentlySyncedEmployeeSettings[key] !== updatedValue &&
            !errorMessage
        ) {
            refreshMyCapitechSettings();
        }
    };

    return {
        isSyncingSettings,
        syncEmployeeSetting
    };
}
