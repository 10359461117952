import { AppSettingsContext } from 'ctx/AppSettingsProvider';
import { useContext } from 'react';

export default function useAppSettings() {
    const ctx = useContext(AppSettingsContext);

    if (!ctx) {
        throw new Error('useAppSettings only available inside AppSettingsContext');
    }

    return ctx;
}
