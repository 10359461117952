import { Grid, Stack } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import SuccessMessage from 'components/form/SuccessMessage';
import HFTextField from 'components/hookForm/HFTextField';
import HFFormProvider from 'ctx/HFFormProvider';
import { useTranslation } from 'react-i18next';
import { getTranslatedOrFallback } from 'utils/translation';
import { useCallback, useEffect, useState } from 'react';
import useThrottleStartStopFn from 'hooks/useThrottleStartStopFns';
import usePasswordChangeForm from './hooks/usePasswordChangeForm';

export default function PasswordChangeForm() {
    const { t } = useTranslation();
    const [displaySuccess, setDisplaySuccess] = useState(false);

    const formProps = usePasswordChangeForm({
        onBeforeHandleSubmit: () => {
            setDisplaySuccess(false);
            return true;
        }
    });

    const { isSubmitSuccessful } = formProps.formState;

    // Reset form and init display success message
    const { reset: resetForm } = formProps;
    useEffect(() => {
        if (isSubmitSuccessful) {
            resetForm();
            setDisplaySuccess(true);
        }
    }, [isSubmitSuccessful, resetForm]);

    // Clear success after 4 seconds, or if user submits again
    const { run, flush } = useThrottleStartStopFn({
        runAtStart: useCallback(() => setDisplaySuccess(true), []),
        runAtStop: useCallback(() => setDisplaySuccess(false), []),
        runTime: 4000
    });
    useEffect(() => {
        if (displaySuccess) run();
        else if (!isSubmitSuccessful) flush(); // The !isSubmitSuccessful avoids flush when displaySuccess is about to be set to true, which would revert it to false in the same tick.
    }, [isSubmitSuccessful, displaySuccess, run, flush]);

    let processedDisplayErrorMessage = formProps.displayErrorMessage;
    if (processedDisplayErrorMessage === 'Passordet er ikke korrekt') {
        processedDisplayErrorMessage = t('profile.passwordChange.oldPasswordIsIncorrect');
    }

    return (
        <HFFormProvider formProps={formProps}>
            <Grid container mt={2}>
                <Grid item xs={12} md={6}>
                    <Stack spacing={2} mb={2}>
                        <HFTextField
                            type="password"
                            name="oldPassword"
                            autoComplete="oldPassword"
                            label={t('profile.passwordChange.oldPassword')}
                            required
                            fullWidth
                        />
                        <HFTextField
                            type="password"
                            name="newPassword"
                            autoComplete="newPassword"
                            label={t('profile.passwordChange.newPassword')}
                            required
                            fullWidth
                        />

                        <HFTextField
                            type="password"
                            name="newPasswordRepeated"
                            autoComplete="newPasswordRepeated"
                            label={t('profile.passwordChange.newPasswordRepeated')}
                            required
                            fullWidth
                        />

                        {processedDisplayErrorMessage && (
                            <ErrorMessage
                                message={getTranslatedOrFallback(processedDisplayErrorMessage)}
                            />
                        )}
                        {displaySuccess && (
                            <SuccessMessage>
                                {t('profile.passwordChange.successMessage') || ''}
                            </SuccessMessage>
                        )}
                    </Stack>

                    <ActionButton type="submit" isloading={formProps.formState.isSubmitting}>
                        {t('profile.passwordChange.passwordChange')}
                    </ActionButton>
                </Grid>
            </Grid>
        </HFFormProvider>
    );
}
