import { ExpandMore } from '@mui/icons-material';
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    CardActions,
    Collapse,
    useTheme,
    IconButtonProps,
    CardActionArea
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { TransactionCardProps } from './TransactionCard.types';

type ExpandMoreButtonProps = {
    isExpanded: boolean;
} & IconButtonProps;

function ExpandMoreButton({ isExpanded }: ExpandMoreButtonProps) {
    const theme = useTheme();

    return (
        <ExpandMore
            sx={{
                transform: !isExpanded ? 'rotate(0deg)' : 'rotate(180deg)',
                transition: theme.transitions.create('transform', {
                    duration: theme.transitions.duration.shortest
                })
            }}
        />
    );
}

function ActionAreaWrapper({
    hasExpand,
    children,
    clickHandler
}: {
    hasExpand: boolean;
    clickHandler: VoidFunction;
    children: JSX.Element;
}) {
    if (hasExpand) {
        return <CardActionArea onClick={clickHandler}>{children}</CardActionArea>;
    }

    return children;
}

export default function TransactionCard({
    title,
    description,
    expandedInfo,
    onExpandClick,
    shouldForceExpanded,
    actions,
    icon,
    sx
}: TransactionCardProps) {
    const [isExpanded, setIsExpanded] = useState(false);

    const handleExpandClick = useCallback(() => {
        setIsExpanded(!isExpanded);
        if (onExpandClick) {
            onExpandClick(isExpanded);
        }
    }, [onExpandClick, isExpanded]);

    useEffect(() => {
        if (shouldForceExpanded && onExpandClick) {
            setIsExpanded(true);
            onExpandClick(false);
        }
    }, [handleExpandClick, isExpanded, onExpandClick, shouldForceExpanded]);

    return (
        <Card
            sx={{
                mb: 1,
                pb: 0,
                borderWidth: '5px',
                borderStyle: 'solid',
                borderColor: 'transparent',
                ...sx
            }}
        >
            <ActionAreaWrapper hasExpand={expandedInfo !== null} clickHandler={handleExpandClick}>
                <>
                    <CardHeader
                        avatar={icon}
                        action={expandedInfo ? <ExpandMoreButton isExpanded={isExpanded} /> : null}
                        titleTypographyProps={{ fontSize: 16, fontFamily: 'GT-Walsheim-Light' }}
                        title={title}
                        sx={{ py: 1, px: 1 }}
                    />
                    <CardContent sx={{ py: 0, px: 1, '&:last-child': { paddingBottom: 1 } }}>
                        <Typography
                            fontSize={14}
                            variant="body2"
                            component="div"
                            color="text.secondary"
                        >
                            {description}
                            {expandedInfo && (
                                <Collapse in={isExpanded} timeout="auto" unmountOnExit>
                                    {expandedInfo}
                                </Collapse>
                            )}
                        </Typography>
                    </CardContent>
                </>
            </ActionAreaWrapper>
            {actions && <CardActions sx={{ px: 1 }}>{actions}</CardActions>}
        </Card>
    );
}

TransactionCard.defaultProps = {
    sx: {},
    expandedInfo: null,
    shouldForceExpanded: false,
    icon: null,
    onExpandClick: null,
    actions: null
};
