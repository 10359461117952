import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

/**
 * A custom hook that returns the URL query parameters as a URLSearchParams object.
 * Uses the `useLocation` hook from `react-router-dom` to get the current search string.
 * Memoizes the `URLSearchParams` object to avoid unnecessary re-renders.
 * @returns The URL query parameters as a `URLSearchParams` object.
 */
export default function useQuery() {
    const { search } = useLocation();
    return useMemo(() => new URLSearchParams(search), [search]);
}
