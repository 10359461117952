import { difference, keys, isNull, omit, findKey, isEmpty, uniq } from 'lodash';
import { DimensionName } from 'models/TimeRegistrationModels';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { keyByAndFill, keysFromRecord } from 'utils/object';
import usePrevious from 'utils/usePrevious';
import { DimensionValuesByDimension } from 'utils/dimension';
import useErrorScreen from 'hooks/useErrorScreen';
import { useTranslation } from 'react-i18next';
import { AuthError } from 'utils/auth';
import useAuth from 'hooks/useAuth';
import { DimensionInputRules } from '../types/DimensionInput';
import {
    DescriptionStore,
    fetchDimensionDescriptionSet,
    hasMissingDependencyValues,
    getImmediateUpdatesToDescriptionStore as getImmediateDimensionNameUpdatesToDescriptionStore
} from './useDimensionDescriptionStore.helpers';

export default function useDimensionDescriptionStore(
    dimensionInputRules: DimensionInputRules | null,
    currentDimensionValues: DimensionValuesByDimension
) {
    const [descriptionStore, setDescriptionStore] = useState<DescriptionStore | null>(null);
    const prevDimensionValues = usePrevious(currentDimensionValues);

    const { setError } = useErrorScreen();
    const { t } = useTranslation();
    const { onAuthError } = useAuth();

    const onFetchOptionsError = useCallback(
        (dimensionName: DimensionName) => {
            const errorMessage = t('dimensionRegistration.errors.loadDescriptionsFailed', {
                dimension: dimensionName
            });
            setError({ message: errorMessage });
        },
        [t, setError]
    );

    useEffect(() => {
        if (!dimensionInputRules || isEmpty(currentDimensionValues)) return;

        const immediateUpdates = getImmediateDimensionNameUpdatesToDescriptionStore(
            descriptionStore,
            currentDimensionValues,
            prevDimensionValues || {}
        );

        const updatedDescriptionStore = immediateUpdates
            ? {
                  ...omit(descriptionStore, immediateUpdates.refetch),
                  ...keyByAndFill(immediateUpdates.setToEmpty, [])
              }
            : descriptionStore;

        // Obtain descriptions that needs fetching. Break if there ain't any.
        const activeDimensions = dimensionInputRules.map((rule) => rule.name);

        const dimensionsReady = updatedDescriptionStore
            ? (keys(updatedDescriptionStore) as Array<DimensionName>)
            : [];

        const missingDimensionNames = difference(activeDimensions, dimensionsReady);
        const dimensionNamesToFetch = missingDimensionNames.filter(
            (dimensionName) =>
                !hasMissingDependencyValues(
                    dimensionName,
                    dimensionInputRules,
                    currentDimensionValues,
                    true
                )
        );
        if (!dimensionNamesToFetch.length) {
            if (updatedDescriptionStore !== descriptionStore) {
                setDescriptionStore({
                    ...updatedDescriptionStore
                });
            }
            return;
        }

        // Set download state as downloading. Prevents descriptions to be downloaded multiple times.
        const dimensionNamesAsDownloadingEntries = dimensionNamesToFetch.reduce(
            (result: DescriptionStore, value) => {
                const newResult = { ...result, [value]: null };
                return newResult;
            },
            {}
        );
        setDescriptionStore({
            ...updatedDescriptionStore,
            ...dimensionNamesAsDownloadingEntries
        });

        // Download queued descriptions
        // TODO:: Cancel potentially ongoing requests that matches dimensionsToFetch
        fetchDimensionDescriptionSet(
            dimensionNamesToFetch,
            dimensionInputRules,
            currentDimensionValues,
            onFetchOptionsError
        )
            .then((dimensionDescriptionSet) => {
                const descriptionStoreUpdate = {
                    ...(updatedDescriptionStore || {}),
                    ...(dimensionDescriptionSet || {})
                };
                setDescriptionStore(descriptionStoreUpdate);
            })
            .catch((e: Error) => {
                if (e instanceof AuthError) {
                    onAuthError(e);
                }
                throw e;
            });
    }, [
        dimensionInputRules,
        descriptionStore,
        currentDimensionValues,
        prevDimensionValues,
        onFetchOptionsError,
        onAuthError
    ]);

    const isInitialized =
        dimensionInputRules &&
        (!dimensionInputRules.length || // There was nothing to load
            Boolean(
                findKey(
                    descriptionStore,
                    (descriptionSetToDimension) => !isNull(descriptionSetToDimension) // Something has been loaded
                )
            ));

    // Get loading state
    const dimensionNamesDownloading = useMemo(
        () =>
            descriptionStore !== null
                ? keysFromRecord(descriptionStore).filter(
                      (dimensionName) => descriptionStore[dimensionName] === null
                  )
                : [],
        [descriptionStore]
    );
    const dimensionNamesPending = useMemo(() => {
        // These will be set to downloading as soon as useEffect has done its thing
        const immediateUpdates = getImmediateDimensionNameUpdatesToDescriptionStore(
            descriptionStore,
            currentDimensionValues,
            prevDimensionValues || {}
        );
        return immediateUpdates?.refetch || [];
    }, [descriptionStore, currentDimensionValues, prevDimensionValues]);
    const dimensionNamesLoading = useMemo(
        () => uniq([...dimensionNamesDownloading, ...dimensionNamesPending]),
        [dimensionNamesDownloading, dimensionNamesPending]
    );

    return {
        descriptionStore,
        isLoading: dimensionNamesLoading.length > 0,
        isDescriptionsInitialized: isInitialized,
        dimensionNamesLoading
    };
}
