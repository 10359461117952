import { Alert, AlertProps } from '@mui/material';
import { PropsWithChildren } from 'react';

export default function SuccessMessage({
    children,
    ...restOfProps
}: PropsWithChildren & AlertProps) {
    return (
        <Alert {...restOfProps} severity="success">
            {children}
        </Alert>
    );
}
