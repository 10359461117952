import { getCookie, setCookie } from './cookie';
import { LEGACY_CLIENT_ID_COOKIE_NAME, PREVIOUS_CLIENT_ID_COOKIE_NAME } from './cookie.constants';

export function setPreviouslySelectedClientIdCookie(clientId: string) {
    setCookie(
        PREVIOUS_CLIENT_ID_COOKIE_NAME,
        clientId,
        new Date(Date.now() + 1000 * 60 * 60 * 24 * 400) // 400 days is the max cookie expiry time as per rfc6265bis (https://httpwg.org/http-extensions/draft-ietf-httpbis-rfc6265bis.html#attribute-expires)
    );
}

export function getPreviouslySelectedClientIdCookie() {
    return getCookie(PREVIOUS_CLIENT_ID_COOKIE_NAME);
}

/**
 * Reads the content of the client id cookie set by MyCapitech1
 */
export function getLegacyPreviouslySelectedClientIdCookie() {
    return getCookie(LEGACY_CLIENT_ID_COOKIE_NAME);
}
