import { Circle, TripOrigin } from '@mui/icons-material';
import { Box, BoxProps } from '@mui/material';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { DateEventType, SubsetMetaData } from './DateEvent';

export interface DateEventGroupType<
    TypeVariants extends string = string,
    ItemSourceData extends object = object
> {
    itemType: TypeVariants;
    items: Array<DateEventType<TypeVariants, ItemSourceData>>;
    metaDataCollectionFromSubsets: Array<SubsetMetaData>;
}

export type DateEventSet<
    TypeVariants extends string = string,
    ItemSourceData extends object = object
> = Array<
    DateEventGroupType<TypeVariants, ItemSourceData> | DateEventType<TypeVariants, ItemSourceData>
>;

export interface DateEventSetsByDate<
    TypeVariants extends string = string,
    ItemSourceData extends object = object
> {
    [key: string]: DateEventSet<TypeVariants, ItemSourceData>;
}

export default function DateEventGroup({
    children,
    groupData,
    ...boxProps
}: BoxProps & PropsWithChildren<{ groupData: DateEventGroupType }>) {
    const { t } = useTranslation();
    return (
        <Box {...boxProps} className="date-event-group">
            {groupData.metaDataCollectionFromSubsets.map((metaDataToOneSubset) => {
                const fillVariant =
                    metaDataToOneSubset.fillVariant ||
                    groupData.items.find((item) => item.fillVariant === 'hollow')?.fillVariant ||
                    'full';
                const DotComponent = fillVariant === 'full' ? Circle : TripOrigin;

                const contentDescription = t(metaDataToOneSubset.titleFromTranslationKey, {
                    count: metaDataToOneSubset.count
                });

                // We wrap the dot in a span as we'd like an informal tooltip to go with this icon.
                //
                // We also apply the aria-label for VoiceOver pleasure. It'll be the only event-related element displayed on smaller screens. Role set
                // to image image is first and foremost to have the VoiceOver read the label when snooping around for reading out content description of day.
                // Strictly speaking the label ain't a description of what the (SVG) image looks like, but it's very much a description of what the image is
                // supposed to convey.
                return (
                    <span
                        key={`event-group-${groupData.itemType}-${fillVariant}`}
                        title={contentDescription}
                        className="date-event-group-dot"
                    >
                        <DotComponent
                            role="img"
                            aria-label={`${contentDescription}.`}
                            color={metaDataToOneSubset.color}
                            fontSize="inherit"
                        />
                    </span>
                );
            })}
            {children}
        </Box>
    );
}
