import { Navigate, useLocation } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import useAuth from 'hooks/useAuth';
import { useDialog } from 'ctx/DialogCtx';
import { useEffect } from 'react';
import useAppSettings from 'hooks/useAppSettings';

export default function RequireAuth({ children }: { children: JSX.Element }) {
    const { isAuthenticated } = useAuth();
    const location = useLocation();
    const { clearDialogs } = useDialog();
    const { loginUserNameAndPassword } = useAppSettings();

    useEffect(() => {
        if (!isAuthenticated) {
            clearDialogs();
        }
    }, [isAuthenticated, clearDialogs]);

    if (!isAuthenticated) {
        return loginUserNameAndPassword ? (
            <Navigate to={PageRoutes.Login} state={{ from: location }} replace />
        ) : null;
    }
    return children;
}
