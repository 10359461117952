import { zodResolver } from '@hookform/resolvers/zod';
import { useEffect, useMemo } from 'react';
import {
    DefaultValues,
    SubmitHandler,
    useForm,
    UseFormReturn,
    SubmitErrorHandler,
    ValidationMode
} from 'react-hook-form';
import useAutoFocusErrors from 'hooks/useAutoFocusErrors';
import { Schema as ZodSchema, TypeOf } from 'zod';
import { isEqual } from 'lodash';
import useApiPost, { GenericApiPayload, GenericPostInput } from './useApiPost';
import useMemoWithIsEqual from './useMemoWithIsEqual';

type InputFromSchema<T extends ZodSchema> = TypeOf<T>;

export type UseFormWithApiIntegrationReturn<
    SuccessPayload extends GenericApiPayload,
    InputFields extends GenericPostInput = GenericPostInput,
    TContext = any
> = UseFormReturn<InputFields, TContext> & {
    onSubmitValidatedInputHandler: SubmitHandler<InputFields>;
    onBeforeHandleSubmit?: () => boolean;
    onErrorHandler: SubmitErrorHandler<InputFields>;
    successPayload: SuccessPayload | null;
    displayErrorMessage: string | null;
    clearApiResponse: () => void;
};

export type UseFormWithApiIntegrationOptions<
    SuccessPayload extends GenericApiPayload,
    PostInput extends GenericPostInput = GenericPostInput
> = {
    onSuccess?: (data: SuccessPayload, formInput?: PostInput | null) => void;
    transformValidatedDataBeforeSend?: (data: PostInput) => PostInput;
    onBeforeHandleSubmit?: () => boolean;
    isAnonymous?: boolean;
    formValidationMode?: keyof ValidationMode;
    clearFormOnSuccess?: boolean; // Feel free to change this to resetOnSuccess later on, but be sure to consider if memoizedDefaultValues should be passed into reset as well
};

/**
 * react-hook-form states "It is encouraged that you set defaultValues for all inputs to non-undefined values such as the empty string or null.".
 *
 * @param endpoint
 * @param zodSchema
 * @param defaultValues
 * @param options
 * @returns
 */
export default function useFormWithApiIntegration<
    SuccessPayload,
    Schema extends ZodSchema = ZodSchema,
    PostInput extends GenericPostInput = InputFromSchema<Schema>
>(
    endpoint: string,
    zodSchema: Schema,
    defaultValues: DefaultValues<PostInput>,
    options?: UseFormWithApiIntegrationOptions<SuccessPayload, PostInput>
): UseFormWithApiIntegrationReturn<SuccessPayload, PostInput> {
    const [
        { successPayload, errorMessage: displayErrorMessage, clearResponse: clearApiResponse },
        request
    ] = useApiPost<SuccessPayload, PostInput>(endpoint, {
        onSuccess: options?.onSuccess,
        popContentArray: true,
        isAnonymous: options?.isAnonymous
    });

    /**
     * A note about the next chunk of code.
     *
     * We started out by just doing useForm, providing resolver and defaultValues without any memoization at all.
     *
     * Every now and then there's data arriving late to a form component. Whenever this data is meant to affect
     * init values to the form, the default values need to be updated. The react hook form documentation states
     * that `defaultValues are cached. If you want to reset the defaultValues, you should use the reset api.`
     * (https://react-hook-form.com/api/useform/). So, we need to use the reset API to cover for such cases.
     * If we ignore this rule the default values will be out of sync. When we're using the reset API, we make
     * sure that updating the default values doesn't have any effect on the inputs which the user has already
     * changed. We achieve this by passing options requesting desired effect to the reset function.
     *
     * To keep this as fluent as possible for us devs, there's the useEffect tucked in after useForm to reset the
     * form whenever the default values passed to this function changes.
     *
     * The default values are only regarded as changed when there's a value which differs from the old, so cases
     * of "different object with same properties" are ignored.
     *
     * We still proivde memoizedDefaultValues when calling useForm. This ensures default values are there at the
     * init run, and it doesn't seem to cause any extra re-renders afterwards.
     */
    const memoizedDefaultValues = useMemoWithIsEqual(defaultValues);
    const useFormProps = useForm<PostInput>({
        resolver: useMemo(() => zodResolver(zodSchema), [zodSchema]),
        defaultValues: memoizedDefaultValues,
        mode: options?.formValidationMode ?? undefined,
        shouldFocusError: false // useAutoFocusErrors does this. Also keeps watches error messages not tied to a specific input element.
    });
    const { reset, trigger: revalidateForm } = useFormProps;
    // By now you should have read the elaborative comment just before useForm is called, which explains the need for the upcoming chunk of code.
    const { formState } = useFormProps;
    const defaultValuesInForm = useFormProps.formState.defaultValues;
    useEffect(() => {
        // https://react-hook-form.com/docs/useform/reset states 'formState dirtyFields will need to be subscribed' for keepDirtyValues to work, which also seems to require that we access the dirtyFields property
        const hasDirtyValues = Object.keys(formState.dirtyFields).length > 0; // We ensure dirtyFields are subscribed to by accessing it early on in the useEffect

        // Doing this without useEffect will cause 'Cannot update a component (`Controller`) while rendering a different component'-error if render-with-new-default-values is caused by an input field change
        if (!isEqual(defaultValuesInForm, memoizedDefaultValues)) {
            reset(memoizedDefaultValues, {
                keepDirty: formState.isDirty,
                keepDirtyValues: hasDirtyValues,
                keepIsSubmitted: true,
                keepIsValid: true,
                keepTouched: true,
                keepSubmitCount: true,
                keepErrors: true
            });
        }
    }, [defaultValuesInForm, memoizedDefaultValues, reset, formState, revalidateForm]); // We deliberately subscribe to the whole formState, which is according to rules defined here https://react-hook-form.com/docs/useform/formstate

    const { watchForErrors } = useAutoFocusErrors(useFormProps.formState.errors);

    const onSubmitHandler: SubmitHandler<PostInput> = async (data) => {
        const possiblyTranformedData = options?.transformValidatedDataBeforeSend
            ? options.transformValidatedDataBeforeSend(data)
            : data;
        const isSuccess = await request(possiblyTranformedData);

        // START ensuring isSubmitSuccessful is set as expected. Hack-ish, so elaborative comments follow to give an insight as to why.
        //
        // From the docs of isSubmitSuccessful:
        // "Indicate the form was successfully submitted without any Promise rejection or Error been thrown within the handleSubmit callback."
        //
        // Promise rejection bubbles up to the console like an unhandled exception. We'd like neither rejection or exception to keep things clean.
        // Errors in console should rather be errors not accounted for. Going by suggestion over at github https://github.com/react-hook-form/react-hook-form/issues/2859#issuecomment-800142131
        // we set an error value instead. Also kind of awkward and not ideal, but at least we indicate that errors are under control.
        // Actual error message meant to be shown for user is made available through displayErrorMessage defined outside this callback.
        // Makes sense as it doesn't connect to a field name. It's what we'd like in case of uncaught exceptions as well. And it plays well with
        // useApiPost hook.
        if (!isSuccess) {
            useFormProps.setError('_serverError' as any, {}); // 'any' because error name is supposed to tie with an input element, and there's no input relating to _serverError. Defining an input field for this is uglier than "forcing in" the error.
            // Reverse our error field as soon as we're as sure as we can be that isSubmitSuccessful synced. This is quite hacky, but as there's no event to related to it seems to be the most isolated way of doing this.
            setTimeout(() => {
                useFormProps.clearErrors('_serverError' as any);
            }, 200); // 200 is just a best guess as to when react-hook-form has (re-)set isSubmitSuccessful to false. Not tightly tied to something else.
        }
        // STOP ensuring isSubmitSuccessful is set as expected

        // Separated from above to keep things tidy. Scroll only triggers once.
        if (!isSuccess) {
            watchForErrors();
        }

        if (isSuccess && options?.clearFormOnSuccess) {
            reset();
        }
    };

    const onErrorHandler: SubmitErrorHandler<PostInput> = () => {
        watchForErrors();
    };

    return {
        ...useFormProps,
        onSubmitValidatedInputHandler: onSubmitHandler,
        onBeforeHandleSubmit: options?.onBeforeHandleSubmit, // Yeah, this is just along for the ride so far. The thing is, right now the only way to run this is when we're latching onto form element's onSubmit. And that's built in within HFFormProvider, to which we're non-chalantly passing all of the properties returned by this function. In short; it seems more convenient doing it this way when using this hook.
        onErrorHandler,
        successPayload,
        displayErrorMessage,
        clearApiResponse
    };
}
