import { useCallback, useEffect, useRef } from 'react';

type UseThrottleStartStopFnProps = {
    runAtStart: () => void;
    runAtStop: () => void;
    runTime: number;
};

type UseThrottleStartStopFnReturn = {
    run: () => void;
    cancel: () => void;
    flush: () => void;
};

export default function useThrottleStartStopFns(
    props: UseThrottleStartStopFnProps
): UseThrottleStartStopFnReturn {
    const { runAtStart, runAtStop, runTime } = props;
    const timerRef = useRef<NodeJS.Timeout | null>(null);
    const isRunningRef = useRef<boolean | null>(null);

    isRunningRef.current = false;

    const run = useCallback(() => {
        if (isRunningRef.current) {
            return;
        }
        runAtStart();
        isRunningRef.current = true;
        timerRef.current = setTimeout(() => {
            isRunningRef.current = false;
            runAtStop();
        }, runTime);
    }, [runAtStart, runAtStop, runTime]);

    const cancel = useCallback(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
            timerRef.current = null;
        }
    }, []);

    const flush = useCallback(() => {
        cancel();
        runAtStop();
    }, [cancel, runAtStop]);

    useEffect(() => flush, [flush]);

    return {
        run,
        cancel,
        flush
    };
}
