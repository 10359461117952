import { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

type DialogComponent = (onClosed: VoidFunction) => JSX.Element;

type DialogState = {
    showDialog: (dialog: DialogComponent) => void;
    clearDialogs: VoidFunction;
};

const DialogContext = createContext<DialogState>({
    showDialog: () => {},
    clearDialogs: () => {}
});

export function useDialog() {
    return useContext(DialogContext);
}

/**
 * For use when the dialog component has dynamic content and cannot be prerendered
 */
export default function DialogProvider({ children }: PropsWithChildren<{}>) {
    const [dialogs, setDialogs] = useState<Array<DialogComponent>>([]);

    const showDialog = (dialog: DialogComponent) =>
        setDialogs((prevDialogs) => prevDialogs.concat(dialog));

    const closeDialog = (dialog: DialogComponent) =>
        setDialogs((prevDialogs) => prevDialogs.filter((d) => d !== dialog));

    const value = useMemo(
        () => ({
            showDialog,
            clearDialogs: () => setDialogs([])
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return (
        <DialogContext.Provider value={value}>
            {dialogs.map((dialog, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <div key={`dialogid${i}`}>{dialog(() => closeDialog(dialog))}</div>
            ))}
            {children}
        </DialogContext.Provider>
    );
}
