import { useFormContext, Controller } from 'react-hook-form';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import Dropdown, { DropdownProps } from 'components/Input/Dropdown';
import { isUndefined } from 'lodash';
import { useEffect } from 'react';

export type HFDropdownProps = {
    lockIfSingle?: boolean;
} & Omit<
    DropdownProps,
    'errorMessage' | 'onChange' | 'value' // onChange support deliberately left out. Think you need it? You _should_ be better off using watch functionality offered by react-hook-form instead. If you're not convinced, be sure field.onChange gets called as well when adding support for onChange again.
>;

export default function HFDropdown({ lockIfSingle, readOnly, ...selectProps }: HFDropdownProps) {
    const context = useFormContext();
    const {
        control,
        formState: { errors }
    } = context;

    const { options, name } = selectProps;

    useFieldNameGuard(name, context);

    // lockIfSingle; we need to explicitly set value by using setValue. And we should only be doing it once.
    const { setValue, getValues } = context;
    useEffect(() => {
        // TODO:: Unset value __before__ options are changed instead. MUI (correctly) still throws an out-of-range warning.
        const newValue = getValues(name);
        const isMissingOptionToNewValue =
            newValue &&
            Array.isArray(options) &&
            !options.find((option) => option.value === newValue);
        if (isMissingOptionToNewValue) {
            setValue(name, '');
            return;
        }

        if (lockIfSingle && Array.isArray(options) && options.length === 1) {
            setValue(name, options[0].value);
        }
    }, [options, getValues, setValue, name, lockIfSingle]);

    const currentValue = context.getValues()[name];
    const isReadOnlyField = !isUndefined(readOnly)
        ? readOnly
        : Boolean(currentValue && lockIfSingle && Array.isArray(options) && options.length === 1);

    const errorMessage = (errors[name]?.message as unknown as string) || '';

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, ...nonOverriddenFieldProps } }) => (
                <Dropdown
                    readOnly={isReadOnlyField}
                    errorMessage={errorMessage}
                    {...selectProps}
                    {...nonOverriddenFieldProps}
                    inputProps={{
                        /* We want hook-form to provide error message and MUI to show off its "this field has errors" styling. Also, if selectProps.required is set to true we want the "required styling" MUI applies to labels. */
                        required: false,
                        ...selectProps.inputProps
                    }}
                    onChange={(newValue) => {
                        onChange(newValue);
                    }}
                />
            )}
        />
    );
}
HFDropdown.defaultProps = {
    lockIfSingle: false
};
