import { useFormContext, Controller } from 'react-hook-form';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import TimeInput from 'components/TimeInput';
import { TimeInputProps } from 'components/TimeInput/TimeInput.types';

export type HFTimeInputProps = {
    name: string;
} & TimeInputProps;

function HFTimeInput(props: HFTimeInputProps) {
    const context = useFormContext();
    const {
        control,
        formState: { errors }
    } = context;

    const { name, onChange } = props;

    useFieldNameGuard(name, context);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange: onFieldChange } }) => (
                <TimeInput
                    {...props}
                    value={value || ''}
                    error={!!errors[name]}
                    onChange={(newValue) => {
                        onFieldChange(newValue);
                        onChange?.(newValue);
                    }}
                    helperText={errors[name] ? (errors[name]?.message as unknown as string) : ''}
                />
            )}
        />
    );
}

export default HFTimeInput;
