import { dateToIsoString } from 'utils/datetime';
import { useMemo } from 'react';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';

export default function useAbsenceRequestRegistrationAccess(dateInIsoFormat?: string) {
    const { employeeRegistrationInformation, isLoading } = useEmployeeRegistrationInformation('');

    const dateToday = useMemo(() => dateToIsoString(new Date()), []);
    const isDateOk = dateInIsoFormat ? dateToday <= dateInIsoFormat : true;

    let isAbsenceRequestRegistrationAccessible: boolean | null;
    if (isDateOk) {
        isAbsenceRequestRegistrationAccessible = employeeRegistrationInformation
            ? employeeRegistrationInformation.hasAbsenceRegistration
            : null;
    } else {
        isAbsenceRequestRegistrationAccessible = false;
    }

    return {
        isAbsenceRequestRegistrationAccessible,
        isLoading
    };
}
