import { useFetch } from 'hooks/useFetch';
import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useErrorScreen from 'hooks/useErrorScreen';
import useLoadingScreen from 'hooks/useLoadingScreen';
import {
    MyCapitechClientSettings,
    MyCapitechClientSettingsContextType
} from './MyCapitechClientSettingsProvider.types';

export const MyCapitechClientSettingsContext = createContext<MyCapitechClientSettingsContextType>(
    null!
);

type MyCapitechClientSettingsProviderProps = PropsWithChildren;

export default function MyCapitechClientSettingsProvider({
    children
}: MyCapitechClientSettingsProviderProps) {
    const { t } = useTranslation();

    const [myCapitechClientSettings, { isLoading, errorMessage: genericErrorMessage }] =
        useFetch<MyCapitechClientSettings>('getMyCapitechClientSettings');

    const errorMessageLoadFailed = t('myCapitechSettings.errors.loadFailed');
    const isLoadingSettingsFirstTime = isLoading && !myCapitechClientSettings;

    const value: MyCapitechClientSettingsContextType = useMemo<MyCapitechClientSettingsContextType>(
        () => ({
            myCapitechClientSettings,
            isLoading,
            errorMessage: genericErrorMessage ? errorMessageLoadFailed : null
        }),
        [myCapitechClientSettings, isLoading, genericErrorMessage, errorMessageLoadFailed]
    );

    // These settings are critical for the app to function, so we show an error screen if they fail to load
    const displayableErrorMessage = value.errorMessage;
    const { setError } = useErrorScreen();
    useEffect(() => {
        if (displayableErrorMessage) {
            setError({ message: displayableErrorMessage });
        }
    }, [displayableErrorMessage, setError]);

    const { setSourceIsLoading } = useLoadingScreen();

    useEffect(() => {
        setSourceIsLoading('MyCapitechClientSettings', isLoadingSettingsFirstTime);
    }, [isLoadingSettingsFirstTime, setSourceIsLoading]);

    return (
        <MyCapitechClientSettingsContext.Provider value={value}>
            {children}
        </MyCapitechClientSettingsContext.Provider>
    );
}
