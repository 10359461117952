import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import customValidators from 'utils/customValidators';
import { number, object, string } from 'zod';
import { dateToIsoString, getFirstDateOfMonth } from 'utils/datetime';
import { GetReportPayload } from './Reports.types';

function getReportsDateSelectionFormSchema() {
    return object({
        reportId: number().min(1),
        fromDate: customValidators.date(),
        cultureCode: string().min(1),
        toDate: customValidators.date()
    });
}

export default function useReportsDateSelectionForm(onSuccess: (data: GetReportPayload) => void) {
    const defaultValues = {
        fromDate: dateToIsoString(getFirstDateOfMonth(new Date())),
        toDate: dateToIsoString(new Date()),
        reportId: 0,
        cultureCode: ''
    };
    const schema = getReportsDateSelectionFormSchema();

    const formSetup = useFormWithApiIntegration<GetReportPayload>(
        'getReport',
        schema,
        defaultValues,
        { onSuccess }
    );

    return { ...formSetup, defaultValues };
}
