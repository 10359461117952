import { SvgIcon, SvgIconProps } from '@mui/material';

export default function GroupIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path
                    d="M23.5,18.9c-0.3-3-2.6-4.7-6.4-4.7c-0.4,0-0.8,0-1.2,0c0.5,0.7,0.8,1.6,1,2.5H17c3.7,0,3.9,1.8,4.1,2.5l0.1,2.1h2.4
	L23.5,18.9z"
                />
                <path d="M3,17.7C3.1,15.9,5,15,8,15s4.7,0.9,5,2.7l0.3,3.4h2.4l-0.3-3.7c-0.3-3.1-3-5-7.3-5s-6.9,1.8-7.3,5l-0.3,3.7h2.4L3,17.7z" />
                <path
                    d="M17.1,13.1c2.4,0,4.1-2,4.1-4.6c0-2.2-1.7-4.1-4.1-4.1S13,6.1,13,8.5C13,11.1,14.7,13.1,17.1,13.1z M17.1,6.9
	c0.8,0,1.6,0.4,1.6,1.6c0,1.3-0.7,2.2-1.6,2.2c-0.9,0-1.6-0.9-1.6-2.2C15.5,7.3,16.3,6.9,17.1,6.9z"
                />
                <path
                    d="M7.7,11.6c2.5,0,4.3-2.1,4.3-5c0-2.4-1.8-4.2-4.3-4.2c-2.4,0-4.3,1.8-4.3,4.2C3.4,9.5,5.2,11.6,7.7,11.6z M7.7,4.9
	c1,0,1.8,0.8,1.8,1.8c0,1.4-0.8,2.5-1.8,2.5s-1.8-1-1.8-2.5C5.8,5.6,6.6,4.9,7.7,4.9z"
                />
            </svg>
        </SvgIcon>
    );
}
