import { Box, IconButton, IconButtonProps } from '@mui/material';
import { MuiColorType, VisualFillVariant } from 'components/Calendar/DateEvent';
import { MyCapitechIcon } from 'features/dashboard/util/capitechData';

interface CircledIconButtonPropsBase extends Pick<IconButtonProps, 'onClick' | 'sx'> {
    color: MuiColorType;
    fillVariant: VisualFillVariant;
    title?: string;
    icon: MyCapitechIcon;
    badgeIcon?: MyCapitechIcon;
    badgeIconColor?: MuiColorType;
    staticDisplayMode?: boolean;
}

interface CircledIconButtonPropsStaticDisplayMode extends CircledIconButtonPropsBase {
    staticDisplayMode: true;
    onClick?: never;
}

interface CircledIconButtonPropsRegularButtonMode extends CircledIconButtonPropsBase {
    staticDisplayMode?: false;
    onClick: IconButtonProps['onClick'];
}

type CircledIconButtonProps =
    | CircledIconButtonPropsStaticDisplayMode
    | CircledIconButtonPropsRegularButtonMode;

export default function CircledIconButton({
    color,
    fillVariant,
    title,
    icon: IconComponent,
    badgeIcon: BadgeIconComponent,
    badgeIconColor,
    onClick,
    sx,
    staticDisplayMode
}: CircledIconButtonProps) {
    let fillProps: IconButtonProps['sx'];
    if (fillVariant === 'full') {
        fillProps = {
            backgroundColor: `${color}.main`,
            '&:hover': staticDisplayMode
                ? {}
                : {
                      backgroundColor: `${color}.dark`
                  }
        };
    } else if (fillVariant === 'hollow') {
        fillProps = {
            borderWidth: 4,
            borderColor: `${color}.main`,
            borderStyle: 'solid',
            '&:hover': staticDisplayMode
                ? {}
                : {
                      borderColor: `${color}.dark`
                  }
        };
    } else if (fillVariant === 'none') {
        fillProps = {};
    }

    const combinedSxFromProps = { ...sx, ...fillProps };

    const ComponentAccordingToDisplayMode: React.ElementType = staticDisplayMode ? Box : IconButton;

    return (
        <Box sx={{ position: 'relative' }}>
            <ComponentAccordingToDisplayMode
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '40px',
                    height: '40px',
                    borderRadius: '50%',
                    ...combinedSxFromProps
                }}
                onClick={onClick}
                title={title}
                aria-label={title}
            >
                <IconComponent color="primary" />
            </ComponentAccordingToDisplayMode>
            {BadgeIconComponent && (
                <BadgeIconComponent
                    color={badgeIconColor}
                    sx={{
                        position: 'absolute',
                        bottom: '-6px',
                        right: '-6px',
                        pointerEvents: 'none'
                    }}
                    fontSize="small"
                />
            )}
        </Box>
    );
}

CircledIconButton.defaultProps = {
    badgeIcon: undefined,
    badgeIconColor: 'inherit',
    staticDisplayMode: false
};
