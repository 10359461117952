import { Email, Menu as MenuIcon } from '@mui/icons-material';
import {
    AppBar,
    Box,
    List,
    ListItem,
    Toolbar,
    ListItemButton,
    ListItemText,
    Divider,
    Badge,
    useMediaQuery,
    useTheme,
    Button
} from '@mui/material';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useMessagesUnreadCount } from 'features/messages';
import PageRoutes from 'router/pageRoutes';
import SimployerLogoMin from 'components/icon/SimployerLogoMin';
import { useAuth0ClientList } from 'hooks/useAuth0ClientList';
import useExternalLoginProvider from 'hooks/useExternalLoginProvider';
import useContentKillSwitch from 'hooks/useContentKillSwitch/useContentKillSwitch';
import { useNavBarRouteItems } from './hooks';
import { invalidateAccess } from './util';
import MenuDrawer from './components/MenuDrawer';
import ToggleAuth0MenuButton from './components/ToggleAuth0MenuButton';
import LogOutButton from './components/LogOutButton';
import Auth0ClientSelectionMenu from './components/Auth0ClientSelectionMenu';

export default function NavBar() {
    const { t } = useTranslation();
    const { pathname, hash } = useLocation();

    const allowedRouteItems = useNavBarRouteItems();
    const { messagesUnreadCount } = useMessagesUnreadCount();
    const unreadMessageTitle = t('navbar.unreadMessages', { count: messagesUnreadCount });

    const isMobileWidth = useMediaQuery(useTheme().breakpoints.down('md'), {
        noSsr: true
    });

    const { provider } = useExternalLoginProvider();
    const isAuth0Active = provider === 'auth0';
    const { isSingleClient } = useAuth0ClientList();

    const [isMobileMenuDrawerOpen, setMobileMenuDrawerOpen] = useState(false);

    function toggleMobileMenuDrawer() {
        setMobileMenuDrawerOpen((state) => !state);
    }
    function closeMobileMenuDrawer() {
        setMobileMenuDrawerOpen(false);
    }

    const messagesTitle = t('navbar.messages');

    const contentKillSwitch = useContentKillSwitch();

    const handleRouteClick = useCallback(
        (targetPath: string) => {
            closeMobileMenuDrawer();
            if (targetPath === pathname) {
                contentKillSwitch.turnOn();
            }
        },
        [pathname, contentKillSwitch]
    );

    const sharedMenuItems = (
        <>
            {allowedRouteItems.map((routeItem) => (
                <ListItem key={routeItem.route} disableGutters disablePadding>
                    {routeItem.isCustom || routeItem.openInNewWindow ? (
                        <ListItemButton
                            href={routeItem.route}
                            onClick={() => handleRouteClick(routeItem.route)}
                            {...(routeItem.openInNewWindow ? { target: '_blank' } : null)}
                        >
                            <ListItemText primary={routeItem.title} />
                        </ListItemButton>
                    ) : (
                        <ListItemButton
                            component={Link}
                            to={routeItem.route}
                            onClick={() => handleRouteClick(routeItem.route)}
                            selected={pathname === routeItem.route}
                        >
                            {!isMobileWidth &&
                            routeItem.title === messagesTitle &&
                            messagesUnreadCount > 0 ? (
                                <ListItemText
                                    primary={
                                        <Badge badgeContent={messagesUnreadCount} color="error">
                                            {messagesTitle}
                                        </Badge>
                                    }
                                />
                            ) : (
                                <ListItemText primary={routeItem.title} />
                            )}
                        </ListItemButton>
                    )}
                </ListItem>
            ))}
            <Divider />
            {hash === '#debug' && (
                <>
                    <ListItem disableGutters>
                        <ListItemButton onClick={() => invalidateAccess()}>
                            <ListItemText primary={t('dev.invalidateAccess')} />
                        </ListItemButton>
                    </ListItem>
                    <Divider />
                </>
            )}
        </>
    );

    const desktopMenuItems = (
        <>
            {sharedMenuItems}
            {isAuth0Active ? <ToggleAuth0MenuButton /> : <LogOutButton />}
        </>
    );
    const mobileMenuItems = (
        <>
            {messagesUnreadCount > 0 && (
                <ListItem disableGutters disablePadding>
                    <ListItemButton
                        component={Link}
                        to={PageRoutes.Messages}
                        selected={pathname === PageRoutes.Messages}
                    >
                        <Badge
                            title={unreadMessageTitle}
                            badgeContent={messagesUnreadCount}
                            color="error"
                        >
                            <Email />
                        </Badge>
                    </ListItemButton>
                </ListItem>
            )}
            <ListItem disableGutters disablePadding>
                <ListItemButton onClick={() => toggleMobileMenuDrawer()}>
                    <MenuIcon />
                </ListItemButton>
            </ListItem>
        </>
    );

    const mobileMenuDrawer = (
        <MenuDrawer isOpen={isMobileMenuDrawerOpen} onClose={() => closeMobileMenuDrawer()}>
            <List>{sharedMenuItems}</List>
            {isAuth0Active && !isSingleClient && (
                <>
                    <Auth0ClientSelectionMenu />
                    <Divider />
                </>
            )}
            <LogOutButton />
        </MenuDrawer>
    );

    return (
        <AppBar
            position="sticky"
            component="nav"
            role="navigation"
            aria-label={t('navbar.ariaLabel')}
        >
            <Toolbar disableGutters sx={{ whiteSpace: 'nowrap' }}>
                <Box sx={{ flexGrow: 1 }}>
                    {isAuth0Active && (
                        <Button
                            aria-label={t('navbar.mySimployer')}
                            href="https://my.simployer.com"
                            sx={{
                                textTransform: 'unset',
                                fontWeight: 'unset',
                                fontSize: 'unset',
                                focus: 'unset',
                                '&:hover': { backgroundColor: 'unset' }
                            }}
                        >
                            <SimployerLogoMin size={40} />
                        </Button>
                    )}
                </Box>

                <List
                    disablePadding
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}
                >
                    {isMobileWidth ? mobileMenuItems : desktopMenuItems}
                </List>
            </Toolbar>

            {isMobileWidth && mobileMenuDrawer}
        </AppBar>
    );
}
