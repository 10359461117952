import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';
import { useFetchArray } from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import useErrorScreen from 'hooks/useErrorScreen';
import useLoadingScreen from 'hooks/useLoadingScreen';
import {
    MyCapitechSetting,
    MyCapitechSettingsContextType
} from './MyCapitechSettingsProvider.types';

export const MyCapitechSettingsContext = createContext<MyCapitechSettingsContextType>(null!);

type MyCapitechSettingsProviderProps = PropsWithChildren;

export type GetMyCapitechSettingsInput = {};

export default function MyCapitechSettingsProvider({ children }: MyCapitechSettingsProviderProps) {
    const { t } = useTranslation();
    const [hasAppliedStartupSettings, setHasAppliedStartupSettings] = useState(false);

    const [myCapitechSettings, { isLoading, errorMessage: genericErrorMessage, sendRequest }] =
        useFetchArray<MyCapitechSetting, GetMyCapitechSettingsInput>('getMyCapitechSettings');
    const isLoadingSettingsFirstTime = isLoading && !myCapitechSettings.length;

    const errorMessageLoadFailed = t('myCapitechSettings.errors.loadFailed');

    useEffect(() => {
        if (!isLoading) {
            setHasAppliedStartupSettings(true);
        }
    }, [isLoading]);

    const value: MyCapitechSettingsContextType = useMemo<MyCapitechSettingsContextType>(
        () => ({
            myCapitechSettings,
            isLoading,
            hasAppliedStartupSettings,
            errorMessage: genericErrorMessage ? errorMessageLoadFailed : null,
            refreshMyCapitechSettings: sendRequest
        }),
        [
            myCapitechSettings,
            isLoading,
            hasAppliedStartupSettings,
            genericErrorMessage,
            errorMessageLoadFailed,
            sendRequest
        ]
    );

    // These settings are critical for the app to function, so we show an error screen if they fail to load
    const displayableErrorMessage = value.errorMessage;
    const { setError } = useErrorScreen();
    useEffect(() => {
        if (displayableErrorMessage) {
            setError({ message: displayableErrorMessage });
        }
    }, [displayableErrorMessage, setError]);

    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('MyCapitechSettings', isLoadingSettingsFirstTime);
    }, [isLoadingSettingsFirstTime, setSourceIsLoading]);

    // Rest of component tree is on hold while settings are loading
    if ((isLoading && !myCapitechSettings.length) || displayableErrorMessage) {
        return null;
    }

    return (
        <MyCapitechSettingsContext.Provider value={value}>
            {children}
        </MyCapitechSettingsContext.Provider>
    );
}
