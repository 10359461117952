import {
    AutocompleteRenderOptionState,
    ListSubheader,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import { createContext, forwardRef, HTMLAttributes, useContext, useEffect, useRef } from 'react';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { InputOption } from '../../InputOption';

type GroupSeparatorRowData = { group: string; key: string };
export type OptionRowData = {
    liElementAttributes: HTMLAttributes<HTMLLIElement>;
    option: InputOption;
    renderOptionState: AutocompleteRenderOptionState;
    isSelected: boolean;
};
type RowData = GroupSeparatorRowData | OptionRowData;
type OptionListData = Array<RowData>;

const ITEMS_COUNT_VISIBLE = 8;
const LISTBOX_PADDING = 0; // px

function useResetCache(data: any) {
    const ref = useRef<VariableSizeList>(null);
    useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

const OuterElementContext = createContext({});

const OuterElementType = forwardRef<HTMLDivElement>((props, ref) => {
    const outerProps = useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function renderRow(props: ListChildComponentProps<OptionListData>) {
    const { data, index, style } = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
        top: (style.top as number) + LISTBOX_PADDING
    };

    if ('group' in dataSet) {
        return (
            <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
                {dataSet.group}
            </ListSubheader>
        );
    }

    const { liElementAttributes, option, isSelected } = dataSet; // optionIndex as number three

    const ariaProps = { 'aria-selected': isSelected };

    return (
        <Typography
            component="li"
            {...liElementAttributes}
            {...ariaProps}
            /* Assumes none of the rendered opitons share the same value. Also resumes change to another option set where only the labels are different won't be an issue. */
            key={option.value}
            noWrap
            style={inlineStyle}
        >
            {option.value === '' ? <em>{option.label}</em> : option.label}
        </Typography>
    );
}

// Adapter for react-window
const SelectWithSearchAutocompleteListbox = forwardRef<HTMLDivElement, HTMLAttributes<HTMLElement>>(
    (props, ref) => {
        const { children, ...other } = props;
        const itemData: OptionListData = [];
        (children as OptionListData).forEach((item: RowData & { children?: RowData[] }) => {
            // Possible to provide a group of items
            itemData.push(item);
            itemData.push(...(item.children || []));
        });

        const theme = useTheme();
        const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
            noSsr: true
        });
        const itemCount = itemData.length;
        const itemSize = smUp ? 40 : 48; // TODO:: Show logic for how these come together

        const getChildSize = (child: RowData) => {
            if ('group' in child) {
                return 48;
            }

            return itemSize;
        };

        const getHeight = () => {
            if (itemCount > ITEMS_COUNT_VISIBLE) {
                return ITEMS_COUNT_VISIBLE * itemSize;
            }
            return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
        };

        const gridRef = useResetCache(itemCount);

        return (
            <div ref={ref}>
                <OuterElementContext.Provider value={other}>
                    <VariableSizeList
                        itemData={itemData}
                        height={getHeight() + 2 * LISTBOX_PADDING}
                        width="100%"
                        ref={gridRef}
                        outerElementType={OuterElementType}
                        innerElementType="ul"
                        itemSize={(index) => getChildSize(itemData[index])}
                        overscanCount={5}
                        itemCount={itemCount}
                    >
                        {renderRow}
                    </VariableSizeList>
                </OuterElementContext.Provider>
            </div>
        );
    }
);

export default SelectWithSearchAutocompleteListbox;
