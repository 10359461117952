import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useFetch } from 'hooks/useFetch';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { useTranslation } from 'react-i18next';

type PostDeleteTimeTransactionPayload = [];
type PostDeleteTimeTransactionInput = {
    existingDateIn: string;
    existingTimeIn: string;
};

type TimeTransactionDialogDeleteProps = {
    transaction: ITimeTransactionDetailResult;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};
export default function TimeTransactionDeleteDialog({
    transaction,
    onSuccess,
    onCancel
}: TimeTransactionDialogDeleteProps) {
    const [, { errorMessage, isLoading: isDeleting, sendRequest: deleteTimeTransaction }] =
        useFetch<PostDeleteTimeTransactionPayload, PostDeleteTimeTransactionInput>(
            'deleteTimeTransaction',
            {
                reqData: {
                    existingDateIn: transaction.dateIn,
                    existingTimeIn: transaction.timeIn
                },
                manuallyTriggerRequest: true,
                onSuccess: () => onSuccess()
            }
        );

    const { t } = useTranslation();

    return (
        <ConfirmDialog
            open
            severity="error"
            title={t('actions.confirmDeletion')}
            confirmText={t('actions.delete')}
            isRunningConfirmOperation={isDeleting}
            onCancel={onCancel}
            onConfirm={deleteTimeTransaction}
            errorMessage={errorMessage}
        />
    );
}
