import { Delete } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { capitechDataVisuals } from 'features/dashboard/util';
import TransactionCard from './TransactionCard';
import { TransactionCardProps } from './TransactionCard.types';
import { DetailCardButton } from './buttons';

export default function RequestedDutyDetailCard({
    title,
    description,
    onDeleteClick
}: TransactionCardProps & {
    onDeleteClick: VoidFunction;
}) {
    const { t } = useTranslation();
    const { icon: Icon } = capitechDataVisuals.dutyRequested;
    return (
        <TransactionCard
            sx={{
                bgcolor: '#fff',
                borderWidth: '5px',
                borderColor: 'shift.main',
                borderStyle: 'solid'
            }}
            icon={<Icon />}
            title={title}
            description={description}
            actions={
                <DetailCardButton
                    onClick={onDeleteClick}
                    label={t('actions.delete')}
                    icon={<Delete />}
                />
            }
        />
    );
}
