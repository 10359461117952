/*
 * This is more or less a copy of useFetch, but with the notable difference
 * that it does not handle AuthErrors internally. onAuthError is a required
 * option when isAnonymous !== false.
 *
 * At first attempted to have useFetch/useFetchArray built on top of these,
 * but types turned out too heavy.
 */

import { postToWebtidApi } from 'utils/http';
import { useEffect, useRef, useState } from 'react';
import { noop } from 'lodash';
import { AuthError } from 'utils/auth';

function areObjectsEqual(obj1: any, obj2: any) {
    return JSON.stringify(obj1) === JSON.stringify(obj2);
}

interface UseFetchOptions<Input = StringMap> {
    reqData?: Input;
    isAnonymous?: boolean;
    isGet?: boolean;
    manuallyTriggerRequest?: boolean;
}

interface UseFetchOptionsAnonymous<Input = StringMap> extends UseFetchOptions<Input> {
    isAnonymous: true;
}

interface UseFetchOptionsLoggedIn<Input = StringMap> extends UseFetchOptions<Input> {
    isAnonymous?: false;
    onAuthError: (error: AuthError) => void;
}

/**
 * Fetch data from the WebTid API
 * @param url The API URI
 * @param optionsParam
 * @returns The data as an array, a loading state, any errors and a function to repeat the request
 */
export function useFetchArrayOutsideAuthContext<T = unknown, Input = StringMap>(
    url: string,
    optionsParam?: UseFetchOptionsAnonymous<Input> | UseFetchOptionsLoggedIn<Input>
): [Array<T>, { isLoading: boolean; errorMessage: string; sendRequest: () => Promise<void> }] {
    const automaticallyTriggerRequest = !optionsParam?.manuallyTriggerRequest;

    const [data, setData] = useState<Array<T>>([]);
    const [isLoading, setIsLoading] = useState(automaticallyTriggerRequest);
    const [errorMessage, setError] = useState<string>('');
    const opt = {
        reqData: {},
        isAnonymous: false,
        isGet: false,
        manuallyTriggerRequest: false,
        onAuthError: noop,
        ...optionsParam
    };
    const reqDataRef = useRef(opt.reqData);

    if (!areObjectsEqual(reqDataRef.current, opt.reqData)) {
        reqDataRef.current = opt.reqData;
    }

    const sendRequest = async () => {
        try {
            setError(''); // Wipe previous error - if any

            const response = await postToWebtidApi<Array<T>>(url, opt.reqData, {
                isAnonymous: opt.isAnonymous,
                method: opt.isGet ? 'GET' : 'POST',
                onAuthError: opt.onAuthError
            });

            if (response.success) {
                setData(response.content);
            } else {
                setError(response.displayErrorMessage);
            }
        } catch (err) {
            setError(`${err}`);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (automaticallyTriggerRequest) {
            setIsLoading(true);

            sendRequest();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url, reqDataRef.current, automaticallyTriggerRequest]);

    return [data as Array<T>, { isLoading, errorMessage, sendRequest }];
}

/**
 * Performs an authenticated API request
 * @param url The API URI
 * @param options
 * @returns The data as an object, a loading state, any errors and a function to repeat the request
 */
export function useFetchOutsideAuthContext<T = unknown, Input extends StringMap = StringMap>(
    url: string,
    options?: UseFetchOptionsAnonymous<Input> | UseFetchOptionsLoggedIn<Input>
): [T, { isLoading: boolean; errorMessage: string; sendRequest: () => Promise<void> }] {
    const [data, rest] = useFetchArrayOutsideAuthContext<T>(url, options);

    return [data[0] as T, rest];
}

interface StringMap {
    [key: string]: string;
}
