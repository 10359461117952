import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AbsenceIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="-253 326.5 24 24"
            >
                <path style={{ fill: 'none' }} d="M-253,326.5h24v24h-24V326.5z" />
                <path
                    d="M-239.9,335.924h-2.1v2.418h-2.2v2.308h2.2v2.308h2.1v-2.308h2.1v-2.308h-2.1V335.924z M-236.5,329.111
	c-1.7,0-3.4,0.879-4.5,2.308c-1.1-1.429-2.8-2.308-4.5-2.308c-3.1,0-5.5,2.637-5.5,6.044c0,4.176,3.4,7.582,8.5,12.527l1.5,1.429
	l1.5-1.429c5.1-5.165,8.5-8.571,8.5-12.527C-231,331.638-233.4,329.111-236.5,329.111z M-240.9,346.144L-240.9,346.144h-0.2
	c-4.8-4.725-7.9-7.912-7.9-10.989c0-2.198,1.5-3.846,3.5-3.846c1.5,0,3,1.099,3.6,2.637h1.9c0.5-1.538,2-2.637,3.6-2.637
	c2,0,3.5,1.648,3.5,3.846C-233,338.231-236.1,341.418-240.9,346.144z"
                />
            </svg>
        </SvgIcon>
    );
}
