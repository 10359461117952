import { ListItem, List, listItemClasses, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccessTime, Add } from '@mui/icons-material';
import { useCallback, useEffect, useState } from 'react';
import { useSelectedDate } from 'features/dashboard/ctx/SelectedTimePeriodCtx';
import { AccumulatedTimeForSingleTransactionResult } from 'models/AccumulatedTime';
import { useFetchArray } from 'hooks/useFetch';
import { dateToIsoString } from 'utils/datetime';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import { useTimeTransactionsState } from 'features/dashboard/ctx/TimeTransactionsProvider';
import usePrevious from 'utils/usePrevious';
import TransactionCard from './TransactionCard';
import {
    EditableTransactionCardProps,
    DeletableTransactionCardProps,
    TransactionCardProps
} from './TransactionCard.types';
import { EditButton, DeleteButton, DetailCardButton } from './buttons';

export type ExpandedHourInfoProps = {
    hoursAndExtraRates: Array<AccumulatedTimeForSingleTransactionResult>;
    comment?: string | null;
    dutyPlanId?: number | null;
    isLoading: boolean;
};

export function ExpandedHourInfo({
    hoursAndExtraRates,
    comment,
    dutyPlanId,
    isLoading
}: ExpandedHourInfoProps) {
    const { t } = useTranslation();

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    if (hoursAndExtraRates.length === 0 && !comment && !dutyPlanId) {
        return <Typography>{t('detailsCard.expanded.noInfo')}</Typography>;
    }

    return (
        <List
            dense
            sx={{
                paddingTop: '4px',
                [`& .${listItemClasses.root}`]: { display: 'inline-block' }
            }}
        >
            {dutyPlanId && (
                <ListItem disableGutters>
                    <strong>{t('detailsCard.expanded.planId').concat(': ')}</strong>
                    {dutyPlanId}
                </ListItem>
            )}
            {hoursAndExtraRates.map((item) => (
                <ListItem disableGutters key={item.timeCategoryId}>
                    <strong>{item.timeCategoryDescription.concat(': ')}</strong>
                    {item.amount}
                </ListItem>
            ))}
            {comment && (
                <ListItem disableGutters>
                    <strong>{t('detailsCard.expanded.comment').concat(': ')}</strong>
                    {comment}
                </ListItem>
            )}
        </List>
    );
}

ExpandedHourInfo.defaultProps = { comment: null, dutyPlanId: null };

export type TimeTransactionDetailCardProps = TransactionCardProps &
    Partial<ExpandedHourInfoProps> & {
        timeIn: string;
    };

export default function TimeTransactionDetailCard({
    title,
    description,
    onManualAdditionsClick,
    hasManualAdditionButton,
    onEditClick,
    onDeleteClick,
    isEditable,
    isDeletable,
    dutyPlanId,
    comment,
    shouldForceExpanded,
    timeIn
}: TimeTransactionDetailCardProps &
    EditableTransactionCardProps &
    DeletableTransactionCardProps & {
        hasManualAdditionButton: boolean;
        onManualAdditionsClick: VoidFunction;
    }) {
    const inDate = useSelectedDate();
    const { t } = useTranslation();

    const [shouldRefreshOnTransactionUpdate, setShouldRefreshOnTransactionUpdate] = useState(false);
    const { isLoading: isLoadingTimeTransactions } = useTimeTransactionsState();

    const [hoursAndExtraRates, { sendRequest: fetchAccumTime, isLoading: isLoadingAccumTime }] =
        useFetchArray<AccumulatedTimeForSingleTransactionResult>(
            'getAccumulatedTimeForSingleTransaction',
            {
                manuallyTriggerRequest: true,
                reqData: { inDate: dateToIsoString(inDate), inTime: timeIn }
            }
        );

    const handleExpandClick = useCallback(
        async (isExpanded: boolean) => {
            if (isExpanded) {
                setShouldRefreshOnTransactionUpdate(false);
            } else {
                await fetchAccumTime();
                setShouldRefreshOnTransactionUpdate(true);
            }
        },
        [fetchAccumTime]
    );

    const wasLoadingTimeTransactions = usePrevious(isLoadingTimeTransactions);
    const justUpdatedTimeTransactions = wasLoadingTimeTransactions && !isLoadingTimeTransactions;
    useEffect(() => {
        if (
            justUpdatedTimeTransactions &&
            shouldRefreshOnTransactionUpdate &&
            !isLoadingAccumTime
        ) {
            fetchAccumTime();
        }
    }, [
        fetchAccumTime,
        isLoadingAccumTime,
        justUpdatedTimeTransactions,
        shouldRefreshOnTransactionUpdate
    ]);

    return (
        <TransactionCard
            shouldForceExpanded={shouldForceExpanded}
            icon={<AccessTime />}
            title={title}
            description={description}
            onExpandClick={handleExpandClick}
            actions={
                (isDeletable || isEditable || hasManualAdditionButton) && (
                    <>
                        {isEditable && <EditButton onClick={onEditClick} />}
                        {hasManualAdditionButton && (
                            <DetailCardButton
                                onClick={onManualAdditionsClick}
                                icon={<Add />}
                                label={t('actions.manualAddition')}
                            />
                        )}
                        {isDeletable && <DeleteButton onClick={onDeleteClick} />}
                    </>
                )
            }
            expandedInfo={
                <ExpandedHourInfo
                    isLoading={isLoadingAccumTime}
                    hoursAndExtraRates={hoursAndExtraRates}
                    comment={comment}
                    dutyPlanId={dutyPlanId}
                />
            }
            sx={{ bgcolor: 'hours.main' }}
        />
    );
}
