import { ErrorScreenContext } from 'ctx/ErrorScreenProvider';
import { useContext } from 'react';

export default function useErrorScreen() {
    const ctx = useContext(ErrorScreenContext);

    if (!ctx) {
        throw new Error(
            "useErrorScreen only available inside ErrorScreenContext. You've probably forgot to set up a ErrorScreenProvider."
        );
    }

    return ctx;
}
