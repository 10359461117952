import { isNull, isUndefined, keys, lowerFirst, pickBy, sortBy, upperFirst } from 'lodash';
import {
    DimensionName,
    allDimensionNamesSorted,
    RegistrationVisibility,
    IClientAbsenceRegistrationRules,
    DimensionNameInAbsenceRegistrationRules
} from 'models/TimeRegistrationModels';
import { useMemo } from 'react';
import {
    DimensionInputRule,
    UseDimensionInputRulesResult,
    useCustomDimensionLabels
} from 'features/misc/dimensionInput';
import { dimensionDependenciesByDimensionName } from 'utils/dimension';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';

type DimensionNameSupportedByAbsenceRegistrationForm =
    | Exclude<DimensionNameInAbsenceRegistrationRules, 'competence'>
    | 'department';

const dimensionsHardcodedAsRequiredBecauseOfQuirks: Array<DimensionName> = ['department'];

function getEnabledAbsenceDimensionNamesFromAbsenceRegistrationRules(
    absenceRegistrationRules: IClientAbsenceRegistrationRules
) {
    const enabledDimensionRegistrations = pickBy(
        absenceRegistrationRules,
        (isEnabled, prefixedDimensionName) =>
            isEnabled &&
            prefixedDimensionName.startsWith('register') &&
            /**
             * The following meanse support for competence is left out for now, even though the rule set includes
             * it as a property. Flow doesn't offer adjusting the rule, and adding support for gets complicated.
             * Here are a couple of notes from a quick attempt at supporting it. Keep'em in mind if implementing
             * support.
             *
             * competenceRole and competence are the same thing.
             * - Rules for absence request registration use the term competence
             * - Rules for time registration prefer competenceRole
             * - defaultValues, which is shared across both forms, provide value for competenceRole
             * - api end point providing options is only set up for competenceRole
             * - createAbsenceRequest doesn't support it either, so we don't know which of the terms is preferred
             */
            prefixedDimensionName !== 'registerCompetence'
    ) as Omit<IClientAbsenceRegistrationRules, 'registerCompetence' | 'requiredCompetence'>;

    const enabledDimensionNames = keys(enabledDimensionRegistrations).map(
        (prefixedDimensionName) =>
            lowerFirst(
                prefixedDimensionName.substring('register'.length)
            ) as DimensionNameSupportedByAbsenceRegistrationForm
    );

    const dimensionNamesNotPresentInRegistrationRules: Array<DimensionNameSupportedByAbsenceRegistrationForm> =
        ['department'];

    const dimensionNamesToInclude =
        dimensionNamesNotPresentInRegistrationRules.concat(enabledDimensionNames);

    return sortBy(dimensionNamesToInclude, (dimensionName) =>
        allDimensionNamesSorted.indexOf(dimensionName)
    );
}

export default function useAbsenceDimensionInputRules(date: string): UseDimensionInputRulesResult {
    const {
        employeeRegistrationInformation: registrationInformation,
        isLoading: isLoadingRegistrationInformation
    } = useEmployeeRegistrationInformation(date);

    const {
        customDimensionLabels: customDimensionNames,
        isLoading: isLoadingCustomDimensionNames
    } = useCustomDimensionLabels();

    const enabledDimensionNames = useMemo(
        () =>
            registrationInformation
                ? getEnabledAbsenceDimensionNamesFromAbsenceRegistrationRules(
                      registrationInformation.absenceRegistrationRules
                  )
                : null,
        [registrationInformation]
    );

    const dimensionInputRules = useMemo(
        () =>
            enabledDimensionNames
                ? enabledDimensionNames.map<DimensionInputRule>((dimensionName) => {
                      const dimensionNameAsRequiredKey = `requires${upperFirst(
                          dimensionName
                      )}` as keyof IClientAbsenceRegistrationRules;

                      const rawDefaultValue =
                          registrationInformation?.registrationDefaults[`${dimensionName}Id`];

                      return {
                          name: dimensionName,
                          isRequired: dimensionsHardcodedAsRequiredBecauseOfQuirks.includes(
                              dimensionName
                          )
                              ? true
                              : registrationInformation?.absenceRegistrationRules[
                                    dimensionNameAsRequiredKey
                                ] || false,
                          defaultValue:
                              !isUndefined(rawDefaultValue) && !isNull(rawDefaultValue)
                                  ? String(rawDefaultValue)
                                  : null, // We parse it to string, as that's the type which'll be used in form
                          visibility:
                              // Visibility rules do not apply to absence registration
                              RegistrationVisibility.Show,
                          dependencies: dimensionDependenciesByDimensionName[dimensionName],
                          customLabel: customDimensionNames?.[`${dimensionName}Description`]
                      };
                  })
                : null,
        [enabledDimensionNames, customDimensionNames, registrationInformation]
    );

    return {
        isLoading: isLoadingRegistrationInformation || isLoadingCustomDimensionNames,
        dimensionInputRules
    };
}
