import { useTimeTransactionsState } from 'features/dashboard/ctx/TimeTransactionsProvider';
import { dateToIsoString, getTimeString, getSortValueTimeStringsAsc } from 'utils/datetime';

export default function useSuggestedTimeIn(isClockingIn: boolean, dateInIsoFormat: string): string {
    const { timeTransactions } = useTimeTransactionsState();

    const timeTransactionForSelectedDay = dateInIsoFormat
        ? timeTransactions.filter((t) => t.dateIn === dateInIsoFormat)
        : [];

    const currentDate = new Date();
    const currentIsoDate = dateToIsoString(currentDate);
    const isTodayOrEarlier = dateInIsoFormat <= currentIsoDate;
    const currentTime = () => getTimeString(currentDate);

    if (timeTransactionForSelectedDay.length === 0 || isClockingIn) {
        return isTodayOrEarlier ? currentTime() : '00:00';
    }

    const sortedByTimeIn = timeTransactionForSelectedDay.sort((a, b) =>
        getSortValueTimeStringsAsc(a.timeIn, b.timeIn)
    );

    const latestTimeInTransaction = sortedByTimeIn[sortedByTimeIn.length - 1];

    return latestTimeInTransaction.timeOut || currentTime();
}
