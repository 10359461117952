import { useFeatureAccess } from 'features/misc/employeeSettings';
import { useFetch } from 'hooks/useFetch';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { DutyWishFilterResult } from 'models/DutyWishFilter';
import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { DepartmentAndDutyFilterContextType } from './DepartmentAndDutyFilterProvider.types';

export const DepartmentAndDutyFilterContext = createContext<DepartmentAndDutyFilterContextType>(
    null!
);

export default function DepartmentAndDutyFilterProvider({ children }: PropsWithChildren) {
    const { access: featureAccess, isLoading: isLoadingFeatureAccess } = useFeatureAccess();

    // Sort out data required to determine if which data names are accessible
    const holdOffOnDutyFilterFetch = !featureAccess || !featureAccess.plan;
    const [
        savedDutyFilterValues,
        { isLoading: isLoadingDutyFilter, errorMessage: errorMessageDutyFilter, sendRequest }
    ] = useFetch<DutyWishFilterResult>('getDutyWishFilter', {
        manuallyTriggerRequest: holdOffOnDutyFilterFetch
    });

    // Loading state
    const isLoadingDutyFilterFirstTime = savedDutyFilterValues === undefined && isLoadingDutyFilter;
    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('DepartmentAndDutyFilterProvider', isLoadingDutyFilterFirstTime);
    }, [isLoadingDutyFilterFirstTime, setSourceIsLoading]);

    const hasDutyFilterWithValues =
        featureAccess.plan && savedDutyFilterValues
            ? savedDutyFilterValues.departmentIds.length > 0 &&
              savedDutyFilterValues.dutyTypeIds.length > 0
            : false;

    const value = useMemo(
        (): DepartmentAndDutyFilterContextType => ({
            isBlockingDutyDataDisplay: !hasDutyFilterWithValues,
            isBlockingDutyWishRegistration: !hasDutyFilterWithValues,
            isLoading: isLoadingFeatureAccess || isLoadingDutyFilter,
            values: savedDutyFilterValues,
            refresh: sendRequest,
            errorMessage: errorMessageDutyFilter || undefined
        }),
        [
            hasDutyFilterWithValues,
            isLoadingFeatureAccess,
            isLoadingDutyFilter,
            savedDutyFilterValues,
            sendRequest,
            errorMessageDutyFilter
        ]
    );

    return (
        <DepartmentAndDutyFilterContext.Provider value={value}>
            {children}
        </DepartmentAndDutyFilterContext.Provider>
    );
}
