import { Box, Button, Typography } from '@mui/material';
import ScreenOverlay from 'components/ScreenOverlay';
import ErrorScreenIcon, { ErrorScreenIconName } from './ErrorScreenIcon';

export type ErrorScreenAction = {
    title: string;
    onClick: () => void;
};

export type ErrorScreenOptions = {
    iconName: ErrorScreenIconName;
    title: string;
    message: string;
    action: ErrorScreenAction;
};

export type ErrorScreenProps = {
    options: ErrorScreenOptions;
};

export default function ErrorScreen({ options }: Required<ErrorScreenProps>) {
    return (
        <ScreenOverlay
            style={{ backgroundColor: 'white' }}
            role="alertdialog"
            aria-labelledby="errorDialogTitle"
            aria-describedby="errorDialogMessage"
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2rem'
                }}
                tabIndex={0}
                role="document"
            >
                <Typography variant="h1" component="div" aria-hidden="true">
                    <ErrorScreenIcon iconName={options.iconName} />
                </Typography>
                <Typography variant="h2" mb={2} sx={{ fontSize: '2rem' }} id="errorDialogTitle">
                    {options.title}
                </Typography>
                <Typography
                    variant="body1"
                    mb={3}
                    textAlign="center"
                    role="alert"
                    id="errorDialogMessage"
                >
                    {options.message}
                </Typography>
                <Button autoFocus onClick={options.action.onClick}>
                    {options.action.title}
                </Button>
            </Box>
        </ScreenOverlay>
    );
}
