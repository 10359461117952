import { useContext, useMemo } from 'react';
import { dateToIsoString } from 'utils/datetime';
import {
    DateRange,
    UseAbsenceRequestsAffectingDateRangeResult
} from './useAbsenceRequestsAffectingDateRange.types';
import { AbsenceRequestsContext } from '../../AbsenceRequestsProvider';

export default function useAbsenceRequestsAffectingDateRange(
    dateRange: DateRange
): UseAbsenceRequestsAffectingDateRangeResult {
    const ctx = useContext(AbsenceRequestsContext);
    if (!ctx) {
        throw new Error('useAbsenceRequests must be used within an AbsenceRequestsProvider');
    }

    const { absenceRequests, isLoading, errorMessage, refreshAbsenceRequests } = ctx;

    const fromDateString = dateToIsoString(dateRange.from);
    const toDateString = dateToIsoString(dateRange.to);

    const absenceRequestsAffectingPeriod = useMemo(
        () =>
            absenceRequests.filter(
                (absenceRequest) =>
                    (absenceRequest.fromDate >= fromDateString &&
                        absenceRequest.fromDate <= toDateString) || // absence requests starting within range
                    (absenceRequest.toDate >= fromDateString &&
                        absenceRequest.toDate <= toDateString) || // absence requests ending within range
                    (absenceRequest.fromDate <= fromDateString &&
                        absenceRequest.toDate >= toDateString) // absence requests covering entire range
            ),
        [toDateString, fromDateString, absenceRequests]
    );

    return {
        absenceRequests: absenceRequestsAffectingPeriod,
        isLoading,
        errorMessage,
        refreshAbsenceRequests
    };
}
