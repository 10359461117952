import { Drawer } from '@mui/material';
import { PropsWithChildren } from 'react';

type MenuDrawerProps = {
    isOpen: boolean;
    onClose: () => void;
};

export default function MenuDrawer({
    isOpen,
    onClose,
    children
}: PropsWithChildren<MenuDrawerProps>) {
    return (
        <Drawer
            anchor="right"
            variant="temporary"
            ModalProps={{
                keepMounted: true
            }}
            sx={{
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 }
            }}
            open={isOpen}
            onClose={onClose}
        >
            {children}
        </Drawer>
    );
}
