import { Backdrop, CircularProgress, Fade } from '@mui/material';

export default function LoadingOverlay({ isLoading }: { isLoading: boolean }) {
    return (
        <Backdrop sx={{ color: 'white', zIndex: (theme) => theme.zIndex.drawer }} open={isLoading}>
            <Fade in timeout={2000} style={{ transitionDelay: '300ms' }}>
                <CircularProgress color="inherit" />
            </Fade>
        </Backdrop>
    );
}
