import { createContext, PropsWithChildren, useContext, useMemo } from 'react';
import {
    SelectedTimePeriodCtxType,
    UseSelectedDateResult,
    UseSelectedTimePeriodResult
} from './SelectedTimePeriodCtx.types';

const SelectedTimePeriodContext = createContext<SelectedTimePeriodCtxType>(null!);

type SelectedTimePeriodCtxProps = {
    from: Date;
    to: Date;
    selectedDate: Date | null;
};

export function SelectedTimePeriodProvider({
    from,
    to,
    selectedDate,
    children
}: PropsWithChildren<SelectedTimePeriodCtxProps>) {
    const value = useMemo(
        () => ({
            fromDate: from,
            toDate: to,
            selectedDate
        }),
        [from, to, selectedDate]
    );

    return (
        <SelectedTimePeriodContext.Provider value={value}>
            {children}
        </SelectedTimePeriodContext.Provider>
    );
}

export function useSelectedTimePeriod(): UseSelectedTimePeriodResult {
    const ctx = useContext(SelectedTimePeriodContext);

    if (!ctx) {
        throw new Error('useSelectedTimePeriod only available inside SelectedTimePeriodContext');
    }

    return ctx;
}

export function useSelectedDate(): UseSelectedDateResult {
    const { selectedDate } = useSelectedTimePeriod();

    if (!selectedDate) {
        throw new Error('Attempted to use selectedDate but it was null');
    }

    return selectedDate;
}
