import { useEffect, useRef } from 'react';
import { TextField } from '@mui/material';
import { TimeInputNativeProps } from './TimeInputNative.types';

export default function TimeInputNative({
    label,
    onChange,
    value,
    ...textFieldProps
}: TimeInputNativeProps) {
    /**
     * Input component is uncontrolled. This is due to issues arising on iOS when controlled.
     * See https://github.com/facebook/react/issues/23299. None of the proposed solutions
     * which involves blanking the defaultValue property worked. Even attempted blanking
     * the defaultValue every 100ms through useInterval.
     */
    const inputRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (inputRef.current && typeof value !== 'undefined') {
            inputRef.current.value = value;
        }
    }, [value]);

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value);
        }
    };

    return (
        <TextField
            inputRef={inputRef}
            label={label}
            type="time"
            onChange={handleOnChange}
            sx={{ width: '100%' }}
            inputProps={{
                sx: {
                    textAlign: 'left',
                    '&::-webkit-date-and-time-value': {
                        textAlign: 'left'
                    }
                }
            }}
            InputLabelProps={{
                shrink: true
            }}
            {...textFieldProps}
        />
    );
}
