import { AccessTime, CalendarMonth, SvgIconComponent } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';
import { MuiColorType, VisualFillVariant } from 'components/Calendar/DateEvent';
import AbsenceIcon from 'components/icon/AbsenceIcon';
import AbsenceRequestIcon from 'components/icon/AbsenceRequestIcon';
import DutyRequestIcon from 'components/icon/DutyRequestIcon';

export const capitechDataNames = [
    'holiday',
    'time',
    'timeIncomplete',
    'absence',
    'absenceRequest',
    'dutyPlanned',
    'dutyAvailable',
    // See commit dc60efa if considering joining the following 3 into one "dutyRequest" type
    'dutyRequested',
    'dutyWish',
    'dutyWishPeriod'
] as const;
export type CapitechDataName = typeof capitechDataNames[number];

export const capitechDataSwitchNames = [
    'time',
    'absence',
    'absenceRequest',
    'dutyPlanned',
    'dutyAvailable' // dutyRequest?
] as const;
export type CapitechDataSwitchName = typeof capitechDataSwitchNames[number];

export const capitechDataSwitchMap: Record<CapitechDataSwitchName, readonly CapitechDataName[]> = {
    time: ['time', 'timeIncomplete'],
    absence: ['absence'],
    absenceRequest: ['absenceRequest'],
    dutyPlanned: ['dutyPlanned'],
    dutyAvailable: ['dutyAvailable', 'dutyRequested', 'dutyWish', 'dutyWishPeriod']
};

type SvgCustomIconComponent = (props: SvgIconProps) => JSX.Element;
export type MyCapitechIcon = SvgIconComponent | SvgCustomIconComponent;

interface CapitechDataVisualProps {
    color: MuiColorType;
    fillVariant: VisualFillVariant;
    icon: MyCapitechIcon | null;
}
interface CapitechDataVisualPropsWithIcon extends CapitechDataVisualProps {
    icon: MyCapitechIcon;
}
interface CapitechDataVisualPropsWithoutIcon extends CapitechDataVisualProps {
    icon: null;
}
type CapitechDataNamesWithoutIcons = 'holiday';
type CapitechDataNameWithIcons = Exclude<CapitechDataName, CapitechDataNamesWithoutIcons>;

type CapitechDataVisuals = Record<
    CapitechDataNamesWithoutIcons,
    CapitechDataVisualPropsWithoutIcon
> &
    Record<CapitechDataNameWithIcons, CapitechDataVisualPropsWithIcon>;

export const capitechDataVisuals: CapitechDataVisuals = {
    absence: {
        color: 'absence',
        fillVariant: 'full',
        icon: AbsenceIcon
    },
    absenceRequest: {
        color: 'absence',
        fillVariant: 'hollow',
        icon: AbsenceRequestIcon
    },
    time: {
        color: 'hours',
        fillVariant: 'full',
        icon: AccessTime
    },
    timeIncomplete: {
        color: 'hours',
        fillVariant: 'hollow',
        icon: AccessTime
    },
    holiday: {
        color: 'holiday',
        fillVariant: 'none',
        icon: null
    },
    dutyPlanned: {
        color: 'duty',
        fillVariant: 'full',
        icon: CalendarMonth
    },
    dutyAvailable: {
        color: 'shift',
        fillVariant: 'full',
        icon: DutyRequestIcon
    },
    dutyRequested: {
        color: 'dutyRequest',
        fillVariant: 'hollow',
        icon: DutyRequestIcon
    },
    dutyWish: {
        color: 'dutyRequest',
        fillVariant: 'hollow',
        icon: DutyRequestIcon
    },
    dutyWishPeriod: {
        color: 'dutyRequest',
        fillVariant: 'hollow',
        icon: DutyRequestIcon
    }
};

export const capitechDataSwitchVisuals: Record<
    CapitechDataSwitchName,
    CapitechDataVisualPropsWithIcon
> = {
    time: capitechDataVisuals.time,
    absence: capitechDataVisuals.absence,
    absenceRequest: capitechDataVisuals.absenceRequest,
    dutyPlanned: capitechDataVisuals.dutyPlanned,
    dutyAvailable: capitechDataVisuals.dutyAvailable
};
