import { isNull, isUndefined, keys, lowerFirst, pickBy, sortBy, upperFirst } from 'lodash';
import {
    DimensionName,
    allDimensionNamesSorted,
    IClientRegistrationRequiredFields,
    IClientRegistrationRules
} from 'models/TimeRegistrationModels';
import { useMemo } from 'react';
import {
    DimensionInputRule,
    UseDimensionInputRulesResult,
    useCustomDimensionLabels
} from 'features/misc/dimensionInput';
import { dimensionDependenciesByDimensionName } from 'utils/dimension';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';

const dimensionsHardcodedAsRequiredBecauseOfQuirks: Array<DimensionName> = ['department'];

function getEnabledDimensionNamesFromDimensionRegistrationRules(
    registrationRules: IClientRegistrationRules
) {
    const enabledDimensionRegistrations = pickBy(
        registrationRules,
        (isEnabled, prefixedDimensionName) =>
            isEnabled && prefixedDimensionName.startsWith('register')
    );
    const enabledDimensionNames = keys(enabledDimensionRegistrations).map(
        (prefixedDimensionName) =>
            lowerFirst(prefixedDimensionName.substring('register'.length)) as DimensionName
    );

    const dimensionNamesNotPresentInRegistrationRules: Array<DimensionName> = ['department'];

    const dimensionNamesToInclude = enabledDimensionNames.concat(
        dimensionNamesNotPresentInRegistrationRules
    );
    return sortBy(dimensionNamesToInclude, (dimensionName) =>
        allDimensionNamesSorted.indexOf(dimensionName)
    );
}

export default function useDimensionInputRules(date: string): UseDimensionInputRulesResult {
    const {
        employeeRegistrationInformation: registrationInformation,
        isLoading: isLoadingRegistrationInformation
    } = useEmployeeRegistrationInformation(date);

    const {
        customDimensionLabels: customDimensionNames,
        isLoading: isLoadingCustomDimensionNames
    } = useCustomDimensionLabels();
    // Fetch custom translations as well

    const enabledDimensionNames = useMemo(
        () =>
            registrationInformation
                ? getEnabledDimensionNamesFromDimensionRegistrationRules(
                      registrationInformation.registrationRules
                  )
                : null,
        [registrationInformation]
    );

    const dimensionInputRules = useMemo(
        () =>
            registrationInformation && enabledDimensionNames
                ? enabledDimensionNames.map<DimensionInputRule>((dimensionName) => {
                      const dimensionNameAsRequiredKey = `is${upperFirst(
                          dimensionName
                      )}IdRequired` as keyof IClientRegistrationRequiredFields;

                      const rawDefaultValue =
                          registrationInformation.registrationDefaults[`${dimensionName}Id`];

                      return {
                          name: dimensionName,
                          isRequired: dimensionsHardcodedAsRequiredBecauseOfQuirks.includes(
                              dimensionName
                          )
                              ? true
                              : registrationInformation.registrationRequiredFields[
                                    dimensionNameAsRequiredKey
                                ],
                          defaultValue:
                              !isUndefined(rawDefaultValue) && !isNull(rawDefaultValue)
                                  ? String(rawDefaultValue)
                                  : null, // We parse it to string, as that's the type which'll be used in form
                          visibility:
                              registrationInformation.registrationVisibilityRules[dimensionName],
                          dependencies: dimensionDependenciesByDimensionName[dimensionName],
                          customLabel: customDimensionNames?.[`${dimensionName}Description`]
                      };
                  })
                : null,
        [enabledDimensionNames, customDimensionNames, registrationInformation]
    );

    return {
        isLoading: isLoadingRegistrationInformation || isLoadingCustomDimensionNames,
        dimensionInputRules
    };
}
