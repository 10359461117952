import { useContext } from 'react';
import { DutyWishRegistrationContext } from '../../DutyWishRegistrationProvider';
import { UseDutyWishRegistrationInputOptionsResult } from './useDutyWishRegistrationInputOptions.types';

export default function useDutyWishRegistrationInputOptions(): UseDutyWishRegistrationInputOptionsResult {
    const ctx = useContext(DutyWishRegistrationContext);

    if (!ctx) {
        throw new Error(
            'useDutyWishRegistrationInputOptions must be used within DutyWishRegistrationProvider'
        );
    }

    return ctx.optionsAvailable;
}
