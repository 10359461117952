import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { kebabCase } from 'lodash';

export type KeyValueListItem = { key: string; value: string };

type KeyValueListProps = {
    items: Array<KeyValueListItem>;
};

export default function KeyValueList({ items }: KeyValueListProps) {
    const isTableLike = useMediaQuery(useTheme().breakpoints.up('sm'));

    return (
        <Grid container>
            {items.map(({ key, value }) => {
                const itemId = `${kebabCase(key)}-item`;
                const itemKeyId = `key-for-${itemId}-value`;

                return (
                    <Grid
                        item
                        key={`${itemId}-row`}
                        xs={12}
                        sx={{
                            py: 1, // alternative to rowGap={1} rowSpacing={1} on Grid container. Doing this on the container adds a bothersome minus margin on the top.
                            ...(isTableLike
                                ? {
                                      borderTop: '1px solid',
                                      borderColor: 'divider'
                                  }
                                : {})
                        }}
                    >
                        <Grid container>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    id={itemKeyId}
                                    variant="body1"
                                    component="span"
                                    color="textPrimary"
                                    fontFamily="GT-Walsheim-Bold"
                                    gutterBottom
                                    fontWeight="bold"
                                >
                                    {key}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography
                                    /* labelledby seems to be ok for such cases; https://stackoverflow.com/a/29319374 */
                                    aria-labelledby={itemKeyId}
                                    variant="body1"
                                    component="span"
                                    color="textSecondary"
                                    whiteSpace="pre-line"
                                    gutterBottom
                                >
                                    {value}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
}
