import dateI18n, { FIXED_LOCALIZED_DATE_FORMAT } from 'i18n/dateI18n';
import { SupportedLanguage, i18n } from '../i18n';

export type DateISOString = string; // ISO 8601 format: YYYY-MM-DD

type SingleDigit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
export type MarkerTime = `${0 | 1 | 2}${SingleDigit}:${0 | 1 | 2 | 3 | 4 | 5}${SingleDigit}`; // Doesn't catch 26:34

export function isTimeString(value: string): value is MarkerTime {
    return dateI18n(value, 'HH:mm', true).isValid();
}

export function isIsoDateString(value: string) {
    // Too complex to represent as IsoDateString
    return dateI18n(value, 'YYYY-MM-DD', true).isValid();
}

export function getTimeString(date: Date | string): MarkerTime {
    return dateI18n(date).format('HH:mm') as MarkerTime;
}

/**
 * Converts a date object to an ISO-formatted string without the time data.
 * @param date input date object
 * @returns YYYY-MM-DD
 */
export function dateToIsoString(date: Date) {
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${date.getFullYear()}-${month}-${day}`;
}

/**
 * Compares the actual date, NOT the date object
 * @param oneDate
 * @param anotherDate
 * @returns
 */
export function isSameDate(oneDate: Date, anotherDate: Date) {
    return dateI18n(oneDate).isSame(anotherDate, 'date');
}

export function isToday(date: Date) {
    return isSameDate(date, new Date());
}

export function isYesterday(date: Date) {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return isSameDate(date, yesterday);
}

export function getStartOfWeek(date: Date) {
    return dateI18n(date).startOf('week').toDate();
}

export function getEndOfWeek(date: Date) {
    return dateI18n(date).endOf('week').toDate();
}

export function getFirstDateOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth(), 1);
}

export function getLastDateOfMonth(date: Date) {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0); // the 0th day of a month is actually the last day of the previous month
}

export function isFirstDayOfMonth(date: Date) {
    return date.getDate() === 1;
}

export function isWeekendDay(date: Date) {
    const dayNumber = date.getDay();
    return dayNumber === 0 || dayNumber === 6;
}

export function isLastDayOfMonth(date: Date) {
    const lastDateOfMonth = getLastDateOfMonth(date);
    return date.getDate() === lastDateOfMonth.getDate();
}

export function isBetweenDates(d: Date, start: Date, end: Date) {
    return dateI18n(d).isBetween(start, end, 'day', '[]');
}

export function dateToString(d: Date) {
    return dateI18n(d).format('D. MMMM'); // `${d.getDate()}. ${MonthNames[d.getMonth()]}`;
}

export function dateToStringLong(d: Date) {
    // return `${Weekdays[d.getDay() - 1]} ${d.getDate()}. ${MonthNames[d.getMonth()]}`;
    return dateI18n(d).format('dddd D. MMMM');
}

export function dateToStringRaw(d: Date | string) {
    return dateI18n(d).format(FIXED_LOCALIZED_DATE_FORMAT);
}

export function dateToReadableString(date: Date, language: SupportedLanguage) {
    const formatDictionary: Record<SupportedLanguage, string> = {
        no: 'D. MMMM YYYY',
        en: 'MMMM D, YYYY'
    };

    const format = formatDictionary[language];
    return dateI18n(date).format(format);
}

export function dateToSmartString(d: Date | string) {
    const date = dateI18n(d);

    // Today
    if (date.isToday()) {
        return getTimeString(d);
    }

    // Yesterday
    if (date.isYesterday()) {
        return i18n.t('dateTime.yesterday');
    }

    // Within last week
    if (date.isAfter(dateI18n().subtract(1, 'week')) && date.isBefore(dateI18n())) {
        return date.format('dddd');
    }

    // Date as default value (Older than 1 week or in the future)
    return dateToStringRaw(d);
}

export function getMonthName(d: Date) {
    return dateI18n(d).format('MMMM');
}

export function getUnixTime() {
    return dateI18n().unix();
}

enum TimeUnitShortHands {
    day = 'd',
    week = 'w',
    quarter = 'Q',
    month = 'M',
    year = 'y',
    hour = 'h',
    minute = 'm',
    second = 's',
    millisecond = 'ms'
}
type TimeUnits = keyof typeof TimeUnitShortHands;

export function calculateTimespanLength(
    start: Date,
    end: Date,
    includeUnits: Array<TimeUnits> = ['year', 'month', 'week', 'day']
) {
    const { t } = i18n;
    let dynamicStart = dateI18n(start);

    const timeSpanParts = includeUnits.map((oneUnit, position) => {
        const unitDiff = dateI18n(end).diff(dynamicStart, oneUnit);

        // quarter is not accepted as an addable-unit - for some odd reason
        if (oneUnit === 'quarter') {
            dynamicStart = dynamicStart.add(unitDiff * 3, 'month');
        } else {
            dynamicStart = dynamicStart.add(unitDiff, oneUnit);
        }

        // Bug ALERT. Can end up with 13
        const isLastUnitWithScrapsToRegardAsOne =
            position === includeUnits.length - 1 && dateI18n(end).diff(dynamicStart); // TODO:: Something more elegant, like only true if more than halfway to one more of current unit - and only if additional boolean parameter allowing +1 provided as true
        const unitDiffToPresent = isLastUnitWithScrapsToRegardAsOne ? unitDiff + 1 : unitDiff;

        const translatedUnit = t(`dateTime.${oneUnit}`, { count: unitDiffToPresent });
        return unitDiffToPresent ? `${unitDiffToPresent} ${translatedUnit}` : null;
    });

    return timeSpanParts.filter((timePart) => timePart !== null).join(' ');
}

export function wasteTime(seconds: number, unit: 'second' | 'millisecond' = 'second') {
    const multiplier = unit === 'second' ? 1000 : 1;
    return new Promise<boolean>((resolve) => {
        setTimeout(() => resolve(true), seconds * multiplier);
    });
}

export function getSortValueTimeStringsAsc(timeA: string, timeB: string) {
    // Can be rewritten as `a.timeIn > b.timeIn ? 1 : -1` if we can guarantee that the timeIn is always compatible with lexicographic sorting (i.e. HH:mm)
    const aHHmm = timeA.split(':').map((t: string) => parseInt(t, 10));
    const bHHmm = timeB.split(':').map((t: string) => parseInt(t, 10));
    return aHHmm[0] * 60 + aHHmm[1] - (bHHmm[0] * 60 + bHHmm[1]);
}
