import useAuth from 'hooks/useAuth';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { boolean, object, string, z } from 'zod';
import { IS_DEV } from 'utils/constants';
import { AuthApiResult } from 'utils/auth';
import { TFunction, useTranslation } from 'react-i18next';
import { setPreviouslySelectedClientIdCookie } from 'utils/cookie';

function getLoginSchema(t: TFunction) {
    return object({
        clientId: string().nonempty(t('loginScreen.validationErrors.clientRequired')),
        username: string().nonempty(t('loginScreen.validationErrors.usernameRequired')),
        password: string().nonempty(t('loginScreen.validationErrors.passwordRequired')),
        remember: boolean()
    });
}

export type PostLoginPayload = AuthApiResult;
export type LoginFormData = z.infer<ReturnType<typeof getLoginSchema>>;

export default function useLoginForm() {
    const auth = useAuth();

    const { t } = useTranslation();

    const loginSchema = getLoginSchema(t);

    return useFormWithApiIntegration<PostLoginPayload, typeof loginSchema, LoginFormData>(
        '/authenticate',
        loginSchema,
        {
            clientId: '',
            username: '',
            password: '',
            remember: IS_DEV
        },
        {
            onSuccess: (data, formInput) => {
                if (!formInput) throw new Error('formInput is undefined');

                auth.onSignInSuccess(data, formInput.remember);

                setPreviouslySelectedClientIdCookie(formInput.clientId);
            },
            isAnonymous: true
        }
    );
}
