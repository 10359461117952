import { SvgIcon, SvgIconProps } from '@mui/material';

type SimployerLogoMinProps = SvgIconProps & { size: number };

export default function SimployerLogoMin({ size, ...props }: SimployerLogoMinProps) {
    // Logo from https://brand.simployer.com/logo/ - width (=300) and heigth (=366) props have been removed from the svg tag

    const aspectRatio = 300 / 366;

    const width = size;
    const height = Math.ceil(size / aspectRatio);

    return (
        <SvgIcon sx={{ ...props.sx, width, height }} {...props}>
            <svg viewBox="0 0 300 366" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M129.55 193.21C110.384 185.879 105.215 181.461 105.215 173.388C105.215 164.572 112.586 158.707 124.38 158.707C137.649 158.707 147.968 163.848 151.663 166.038C153.138 166.78 154.612 166.78 156.086 166.78C157.56 166.038 159.035 165.314 159.035 163.848L165.678 144.768C166.425 142.559 165.678 139.627 163.457 138.903C153.138 132.296 138.376 128.621 125.108 128.621C92.656 128.621 69.068 149.167 69.068 176.339C69.068 190.278 76.4393 210.1 111.094 222.572C131.005 229.903 134.682 235.787 134.682 244.585C134.682 254.867 125.836 261.474 111.094 261.474C99.2995 261.474 86.759 256.333 79.3878 251.192C77.9135 250.468 76.4393 250.468 74.965 250.468C73.4908 251.192 72.0165 252.677 71.2701 254.143L64.6639 274.689C63.9175 276.898 64.6639 279.83 66.8846 280.554C78.6787 287.162 94.1676 291.56 111.131 291.56C148.006 291.56 171.594 272.48 171.594 242.376C171.594 219.621 158.326 204.216 129.568 193.191L129.55 193.21Z"
                    fill="white"
                />
                <path
                    d="M213.619 73.5713C201.079 73.5713 191.505 83.1112 191.505 95.5836C191.505 108.056 200.351 116.872 212.891 116.872C219.535 116.872 225.432 114.663 229.108 110.265C232.803 106.59 235.005 100.725 235.005 95.5836C235.005 83.1112 226.16 73.5713 213.619 73.5713Z"
                    fill="white"
                />
                <path
                    d="M213.619 159.449C201.079 159.449 191.505 168.989 191.505 181.462C191.505 193.934 200.351 202.75 212.891 202.75C219.535 202.75 225.432 200.541 229.108 196.143C232.803 192.468 235.005 186.603 235.005 181.462C235.005 168.247 226.16 159.449 213.619 159.449Z"
                    fill="white"
                />
                <path
                    d="M213.619 245.327C201.079 245.327 191.505 254.867 191.505 267.339C191.505 279.812 200.351 288.628 212.891 288.628C219.535 288.628 225.432 286.419 229.108 282.021C232.803 278.346 235.005 272.481 235.005 267.339C235.005 254.125 226.16 245.327 213.619 245.327Z"
                    fill="white"
                />
            </svg>
        </SvgIcon>
    );
}
