import { useContext } from 'react';
import { ExternalLinksContext } from 'ctx/ExternalLinksProvider';
import { UseExternalLinksResult } from './useExternalLinks.types';

export default function useExternalLinks(): UseExternalLinksResult {
    const ctx = useContext(ExternalLinksContext);

    if (!ctx) {
        throw new Error('useExternalLinks must be used within an ExternalLinksProvider');
    }

    return ctx;
}
