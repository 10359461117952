import { getJwtCookie, setJwtCookie } from 'utils/cookie';

// eslint-disable-next-line import/prefer-default-export
export function invalidateAccess() {
    const currentToken = getJwtCookie();
    if (!currentToken) return;

    const firstChar = currentToken?.charAt(0);
    const newChar = firstChar === 'a' ? 'b' : 'a';
    const corruptedToken = currentToken.replace(firstChar, newChar);

    const date = new Date();
    date.setTime(date.getTime() + 60 * 2 * 1000);

    setJwtCookie(corruptedToken, date);
}
