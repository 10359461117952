import { useContext } from 'react';
import { ContentKillSwitchContext } from 'ctx/ContentKillSwitchProvider';
import { UseContentKillSwitchResult } from './useContentKillSwitch.types';

export default function useContentKillSwitch(): UseContentKillSwitchResult {
    const ctx = useContext(ContentKillSwitchContext);

    if (!ctx) {
        throw new Error('useContentKillSwitch only available inside ContentKillSwitchContext');
    }

    return ctx;
}
