import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReportDateSelectionForm from './ReportDateSelectionForm';
import { GetReportPayload, ReportAccordionProps } from './Reports.types';

export default function ReportAccordionWithDateForm({
    report,
    isExpanded,
    onChangeCallback
}: ReportAccordionProps) {
    const [reportHtml, setReportHtml] = useState<string>();

    const handleFormSuccess = (data: GetReportPayload) => {
        setReportHtml(data.resultHtml);
    };

    const { t } = useTranslation();

    return (
        <Accordion
            expanded={isExpanded}
            onChange={onChangeCallback}
            TransitionProps={{ unmountOnExit: true }}
        >
            <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls={`panel${report.id}-content`}
                id={`panel${report.id}-header`}
                sx={(theme) => ({
                    position: 'sticky',
                    top: 0,
                    zIndex: theme.zIndex.appBar,
                    backgroundColor: theme.palette.background.paper
                })}
            >
                <Typography>
                    {report.name === ''
                        ? t('reports.defaultName', { number: report.id })
                        : report.name}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{report.comment}</Typography>
                {report.requiresFromAndToDates && (
                    <ReportDateSelectionForm
                        reportId={report.id}
                        onFormSuccess={handleFormSuccess}
                    />
                )}
                {reportHtml && (
                    <div
                        // eslint-disable-next-line react/no-danger
                        dangerouslySetInnerHTML={{ __html: reportHtml }}
                        style={{ overflowX: 'auto' }}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
}
