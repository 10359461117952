import { Dialog, DialogProps } from '@mui/material';
import ModalCloseButton from 'components/button/ModalCloseButton';
import { PropsWithChildren } from 'react';

type DialogWithCloseBtnProps = {
    onClose: VoidFunction;
    closeOnBackdropClick?: boolean;
} & DialogProps;

export default function DialogWithCloseBtn({
    children,
    onClose,
    closeOnBackdropClick,
    ...rest
}: PropsWithChildren<DialogWithCloseBtnProps>) {
    return (
        <Dialog {...rest} onClose={closeOnBackdropClick ? onClose : undefined} keepMounted>
            <ModalCloseButton onClick={onClose} />
            {children}
        </Dialog>
    );
}
DialogWithCloseBtn.defaultProps = {
    closeOnBackdropClick: false
};
