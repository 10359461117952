import { SvgIcon, SvgIconProps } from '@mui/material';

export default function DepartmentIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="0 0 24 24"
                xmlSpace="preserve"
            >
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path fill="none" d="M0,0h24v24H0V0z" />
                <path d="M1.9,17.5c0-0.5,0.3-1.9,3.1-1.9h0.1c0.1-0.7,0.4-1.3,0.8-1.9c-0.3,0-0.6,0-0.9,0c-2.9,0-4.6,1.3-4.9,3.6L0,19.1h1.8L1.9,17.5z" />
                <path d="M23.9,17.4c-0.2-2.3-2-3.6-4.9-3.6c-0.3,0-0.6,0-0.9,0c0.4,0.5,0.6,1.2,0.8,1.9H19c2.8,0,3,1.4,3.1,1.9l0.1,1.6H24L23.9,17.4z" />
                <path d="M8.2,16.5c0.1-1.4,1.5-2.1,3.8-2.1s3.6,0.7,3.8,2.1l0.2,2.6h1.8l-0.2-2.8c-0.2-2.4-2.3-3.8-5.6-3.8s-5.3,1.4-5.6,3.8l-0.2,2.8H8L8.2,16.5z" />
                <path d="M4.6,13c1.8,0,3.1-1.5,3.1-3.5c0-1.8-1.3-3.1-3.1-3.1S1.5,7.7,1.5,9.5C1.5,11.4,2.9,13,4.6,13z M4.6,8.2c0.6,0,1.2,0.3,1.2,1.2c0,1-0.5,1.7-1.2,1.7s-1.2-0.7-1.2-1.7C3.4,8.5,4,8.2,4.6,8.2z" />
                <path d="M19,13c1.8,0,3.1-1.5,3.1-3.5c0-1.7-1.3-3.1-3.1-3.1s-3.1,1.3-3.1,3.1C15.9,11.4,17.2,13,19,13z M19,8.2c0.6,0,1.2,0.3,1.2,1.2c0,1-0.5,1.7-1.2,1.7s-1.2-0.7-1.2-1.7C17.8,8.5,18.4,8.2,19,8.2z" />
                <path d="M11.8,11.8c1.9,0,3.3-1.6,3.3-3.8c0-1.8-1.4-3.2-3.3-3.2C10,4.8,8.5,6.2,8.5,8C8.5,10.2,9.9,11.8,11.8,11.8z M11.8,6.7c0.8,0,1.4,0.6,1.4,1.4c0,1.1-0.6,1.9-1.4,1.9s-1.4-0.8-1.4-1.9C10.4,7.2,11,6.7,11.8,6.7z" />
            </svg>
        </SvgIcon>
    );
}
