import { useFeatureAccess } from 'features/misc/employeeSettings';
import PageRoutes from 'router/pageRoutes';
import useExternalLinks from 'hooks/useExternalLinks/useExternalLinks';
import { useTranslation } from 'react-i18next';
import { useBalanceNamesAvailable } from 'features/balances';
import {
    NavBarRouteItem,
    NavBarRouteItemToPageRoute,
    UseNavBarRouteItemsResult
} from './useNavBarRouteItems.types';

export default function useNavBarRouteItems(): UseNavBarRouteItemsResult {
    const { t } = useTranslation();

    const allRouteItemsToPageRoutes: Array<NavBarRouteItemToPageRoute> = [
        {
            title: t('navbar.dashboard'),
            route: PageRoutes.Home
        },
        {
            title: t('navbar.messages'),
            route: PageRoutes.Messages
        },
        {
            title: t('navbar.absence'),
            route: PageRoutes.Absence
        },
        {
            title: t('navbar.balance'),
            route: PageRoutes.Balance
        },
        {
            title: t('navbar.reports'),
            route: PageRoutes.Reports
        },
        {
            title: t('navbar.profile'),
            route: PageRoutes.Profile
        }
    ];

    const { access } = useFeatureAccess();
    const { balanceNamesAvailable } = useBalanceNamesAvailable();
    const deniedPageRoutes: Array<PageRoutes> = [
        ...(!access.absence ? [PageRoutes.Absence] : []),
        ...(!balanceNamesAvailable?.length ? [PageRoutes.Balance] : [])
    ];

    const allowedRouteItemsToPageRoutes = allRouteItemsToPageRoutes.filter(
        (routeItem) => !deniedPageRoutes.includes(routeItem.route)
    );

    const { externalLinks: customLinks } = useExternalLinks();
    const customLinksToDisplay = customLinks.filter(
        (link) => link.isActive && link.isVisibleInMyCapitech
    );

    const routeItemsToCustomLinks = customLinksToDisplay.map<NavBarRouteItem>((link) => ({
        title: link.linkText,
        route: link.linkUrl,
        openInNewWindow: link.openInNewWindow,
        isCustom: true
    }));

    const helpRoute: NavBarRouteItem = {
        title: t('navbar.help'),
        route: 'https://simployer.atlassian.net/l/c/agWpFuEj',
        openInNewWindow: true,
        isCustom: false
    };

    return [...allowedRouteItemsToPageRoutes, ...routeItemsToCustomLinks, helpRoute];
}
