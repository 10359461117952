import { getJwtCookie } from 'utils/cookie';
import { Buffer } from 'buffer';
import { isPlainObject, camelCase } from 'lodash';
import {
    ConvertJwtToAuthDataResult,
    DecodeJwtResult,
    GetJWTPayloadStringFromCookieResult,
    JwtPayload
} from './auth.types';

/**
 * @param input
 * @returns Decoded JWT payload from base64 to string, or undefined if invalid
 */
export function decodeJwt(input: string): DecodeJwtResult {
    const data = input.split('.');

    if (data && data.length > 1) {
        return Buffer.from(data[1], 'base64').toString();
    }

    return undefined;
}

/**
 * @returns String representation of the JWT payload as stored in the JWT cookie, or undefined if there is nothing to read.
 */
export function getJWTPayloadStringFromCookie(): GetJWTPayloadStringFromCookieResult {
    const jwt = getJwtCookie();

    if (jwt) {
        return decodeJwt(jwt);
    }

    return undefined;
}

// https://stackoverflow.com/a/73603301/10926702
function convertPascalCaseToCamelCase<
    T extends Record<string, unknown> | Record<string, unknown>[]
>(obj: T): T {
    if (Array.isArray(obj)) {
        return <T>obj.map((v) => convertPascalCaseToCamelCase(v));
    }
    if (isPlainObject(obj)) {
        return <T>Object.keys(obj).reduce(
            (result, key) => ({
                ...result,
                [camelCase(key)]: convertPascalCaseToCamelCase(<T>obj[key])
            }),
            {}
        );
    }
    return obj;
}

/**
 * Parses a payload string to an JwtPayload object
 * Also ensures that all keys are camelCased because some sites use PascalCase in
 * their JWT payload for whatever reason.
 * @param payload
 * @returns The JWTPayload object
 */
export function parseJwtPayload(payload: string): JwtPayload {
    const obj = JSON.parse(payload) as JwtPayload;
    return convertPascalCaseToCamelCase<JwtPayload>(obj);
}

export function convertJwtToAuthData(jwt: string): ConvertJwtToAuthDataResult {
    const jwtPayloadString = decodeJwt(jwt);
    if (!jwtPayloadString) {
        return undefined;
    }

    const jwtPayload = parseJwtPayload(jwtPayloadString);

    return {
        clientId: Number(jwtPayload.clientId),
        employeeId: Number(jwtPayload.employeeId),
        accessToken: jwt,
        accessTokenExpires: new Date(jwtPayload.exp * 1000),
        refreshToken: jwtPayload.refreshTokenId
    };
}

export function accessTokenHasExpired(): boolean {
    const jwtPayloadString = getJWTPayloadStringFromCookie();

    if (!jwtPayloadString) {
        return true;
    }

    const storedAuth = parseJwtPayload(jwtPayloadString);
    const currentTime = Date.now();
    const storedAuthExpiryInMs = storedAuth.exp * 1000;
    const isExpired = storedAuthExpiryInMs <= currentTime;

    return isExpired;
}
