import { lowerFirst, mapKeys, pick } from 'lodash';
import {
    allDimensionNamesSorted,
    DimensionInputName,
    DimensionInputNameUpdate,
    DimensionName
} from 'models/TimeRegistrationModels';
import {
    DimensionValuesByDimension,
    getDimensionNameAsInputNameForHourRegistration
} from 'utils/dimension';

/* eslint-disable import/prefer-default-export */
function parseDimensionNameFromDimensionInputName(
    inputName: DimensionInputName | DimensionInputNameUpdate
): DimensionName | undefined {
    const pascalCasedCandidate = inputName.startsWith('new')
        ? inputName.substring(3, inputName.length - 2)
        : inputName.substring(0, inputName.length - 2);
    const camelCasedCandidate = lowerFirst(pascalCasedCandidate);

    const assumedDimensionName = camelCasedCandidate as DimensionName;
    return allDimensionNamesSorted.includes(assumedDimensionName)
        ? assumedDimensionName
        : undefined;
}

export function pickDimensionValuesFromDefaultValues(defaultValues: Record<string, string>) {
    const isDefaultValuesFromEdit = 'existingDateIn' in defaultValues;
    const contextMode = isDefaultValuesFromEdit ? 'update' : 'create';
    const propsToLookFor = allDimensionNamesSorted.map((dimensionName) =>
        getDimensionNameAsInputNameForHourRegistration(dimensionName, contextMode)
    );

    const dimensionInputProps = pick(defaultValues, propsToLookFor);
    const probableSet = mapKeys(dimensionInputProps, (_value, dimensionInputName) => {
        const name =
            parseDimensionNameFromDimensionInputName(
                dimensionInputName as DimensionInputName | DimensionInputNameUpdate
            ) || dimensionInputName;
        return name;
    });

    return probableSet as DimensionValuesByDimension;
}
