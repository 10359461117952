export function getCookie(name: string): string | undefined {
    const nameLength = name.length + 1;

    return (
        document.cookie
            .split(';')
            .map((c) => c.trim())
            .filter((cookie) => cookie.substring(0, nameLength) === `${name}=`)
            .map((cookie) => decodeURIComponent(cookie.substring(nameLength)))[0] || undefined
    );
}

export function deleteCookie(name: string) {
    const date = new Date();
    date.setTime(date.getTime() + -1 * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=; expires=${date.toUTCString()}; path=/`;
}

export function setCookie(name: string, payload: string, expiry: Date) {
    document.cookie = `${name}=${payload}; SameSite=Lax; expires=${expiry.toUTCString()}; path=/`;
}
