import { Stack, Typography } from '@mui/material';
import { useCapitechDataVisibility } from 'features/dashboard/providers';
import { capitechDataSwitchVisuals } from 'features/dashboard/util/capitechData';
import { useTranslation } from 'react-i18next';
import CircledIconButton from '../CapitechDataVisibilitySwitchesRow/CircledIconButton';

export default function CapitechDataVisibilityExplanations() {
    const { t } = useTranslation();
    const { allDataSwitchNamesAvailable } = useCapitechDataVisibility();

    return (
        <Stack gap={1} direction="column">
            {allDataSwitchNamesAvailable.map((switchName) => {
                const {
                    icon: SwitchIcon,
                    color: switchColor,
                    fillVariant: switchFillVariant
                } = capitechDataSwitchVisuals[switchName];
                return (
                    <Stack key={switchName} gap={1} direction="row" sx={{ alignItems: 'center' }}>
                        <CircledIconButton
                            staticDisplayMode
                            icon={SwitchIcon}
                            color={switchColor}
                            fillVariant={switchFillVariant}
                        />
                        <Typography key={switchName}>
                            {t(`dashboard.dataVisibilitySwitches.${switchName}`)}
                        </Typography>
                    </Stack>
                );
            })}
        </Stack>
    );
}
