import { Card, CardContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PasswordChangeForm from './PasswordChangeForm';

export default function PasswordChangeCard() {
    const { t } = useTranslation();

    return (
        <Card>
            <CardContent sx={{ pb: 0 }}>
                <Typography component="h2" variant="h5" gutterBottom>
                    {t('profile.passwordChange.passwordChange')}
                </Typography>
                <PasswordChangeForm />
            </CardContent>
        </Card>
    );
}
