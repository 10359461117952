import dateI18n from 'i18n/dateI18n';
import { pick } from 'lodash';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';
import canAccessTimeRegistration from './useTimeRegistrationAccces.helpers';
import { UseTimeRegistrationAccessResult } from './useTimeRegistrationAccess.types';

export default function useTimeRegistrationAccess(
    dateInIsoFormat: string,
    requireFreshAccessInformation = false
): UseTimeRegistrationAccessResult {
    const { employeeRegistrationInformation, isLoading } = useEmployeeRegistrationInformation(
        '',
        requireFreshAccessInformation
    );

    const timeNow = dateI18n();
    const isTimeRegistrationAccessible = employeeRegistrationInformation
        ? canAccessTimeRegistration(dateInIsoFormat, timeNow, employeeRegistrationInformation)
        : null;

    const canGenerallyRegisterBackInTime = employeeRegistrationInformation
        ? employeeRegistrationInformation.canRegisterBackInTime
        : null;

    const canGenerallyRegisterForwardInTime = employeeRegistrationInformation
        ? employeeRegistrationInformation.canRegisterForwardInTime
        : null;

    const canRegisterBackInTime = isTimeRegistrationAccessible && canGenerallyRegisterBackInTime;
    const canRegisterForwardInTime =
        isTimeRegistrationAccessible && canGenerallyRegisterForwardInTime;

    const canRegisterMoreThanJustNow = canRegisterBackInTime || canRegisterForwardInTime;

    return {
        isTimeRegistrationAccessible,
        isTimeEditAccessible: canRegisterMoreThanJustNow,
        isTimeInEditable:
            /* This is how MC1 rolls */
            canRegisterMoreThanJustNow,
        isTimeOutEditable:
            /* This is how MC1 rolls */
            canRegisterMoreThanJustNow,

        // Beware, canDoManualAddition is always true if the user has access to manual additions
        // It does not turn false if time registration is closed for that date
        isManualAdditionsAvailable:
            Boolean(employeeRegistrationInformation?.canDoManualAddition) &&
            Boolean(isTimeRegistrationAccessible),
        employeeRegistrationAccessInformation: employeeRegistrationInformation
            ? pick(employeeRegistrationInformation, [
                  'canRegisterAtAll',
                  'canRegisterBackInTime',
                  'canRegisterForwardInTime',
                  'numberOfDaysBackInTimeRegisteringIsAllowed',
                  'numberOfDaysForwardInTimeRegisteringIsAllowed',
                  'canDoManualAddition'
              ])
            : null,
        isLoading
    };
}
