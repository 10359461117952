import { useMemo, useState } from 'react';
import { getFirstDateInMonthDisplay, getLastDateInMonthDisplay } from 'utils/calendar';
import { getFirstDateOfMonth, isSameDate } from 'utils/datetime';
import { useEmployeeSettings } from 'features/profile';
import { AbsenceRequestsProvider } from 'features/absence';
import { DutiesProvider } from 'features/plan';
import { DutyWishRegistrationProvider } from 'features/plan/providers/registration';
import { SelectedTimePeriodProvider } from './ctx/SelectedTimePeriodCtx';
import { TimeTransactionsProvider } from './ctx/TimeTransactionsProvider';
import DashboardView from './DashboardView';
import AbsenceTransactionsProvider from './ctx/AbsenceTransactionsCtx';
import DashboardLoadWrapper from './components/DashboardLoadWrapper';
import HolidaysProvider from './ctx/HolidaysProvider/HolidaysProvider';

export default function Dashboard() {
    const dateOnFirstRender = useMemo(() => new Date(), []);
    const { employeeSettings, hasAppliedStartupSettings } = useEmployeeSettings();
    const startDate =
        employeeSettings.selectCurrentDateOnStartup && !hasAppliedStartupSettings
            ? dateOnFirstRender
            : null;

    const [selectedDay, setSelectedDate] = useState<Date | null>(startDate);
    const [from, setFrom] = useState(getFirstDateInMonthDisplay(new Date()));
    const [to, setTo] = useState(getLastDateInMonthDisplay(new Date()));

    const dateMiddleOfInterval = new Date((from.getTime() + to.getTime()) / 2);
    const currentMonth = getFirstDateOfMonth(dateMiddleOfInterval);

    const syncIntervalByDate = (date: Date | null) => {
        if (!date) return;

        const firstVisibleDate = getFirstDateInMonthDisplay(date);
        const lastVisibleDate = getLastDateInMonthDisplay(date);

        const isNewInterval =
            !isSameDate(firstVisibleDate, from) || !isSameDate(lastVisibleDate, to);

        if (isNewInterval) {
            setFrom(firstVisibleDate);
            setTo(lastVisibleDate);
        }
    };

    return (
        <SelectedTimePeriodProvider from={from} to={to} selectedDate={selectedDay}>
            <TimeTransactionsProvider>
                <AbsenceTransactionsProvider>
                    <AbsenceRequestsProvider>
                        <DutiesProvider fromDate={from} toDate={to}>
                            <HolidaysProvider>
                                <DutyWishRegistrationProvider>
                                    <DashboardLoadWrapper>
                                        <DashboardView
                                            currentMonth={currentMonth}
                                            selectedDay={selectedDay}
                                            handleSelectDay={(date) => {
                                                setSelectedDate(date);
                                                syncIntervalByDate(date);
                                            }}
                                            handleChangeMonth={(date) => {
                                                setSelectedDate(null);
                                                syncIntervalByDate(date);
                                            }}
                                        />
                                    </DashboardLoadWrapper>
                                </DutyWishRegistrationProvider>
                            </HolidaysProvider>
                        </DutiesProvider>
                    </AbsenceRequestsProvider>
                </AbsenceTransactionsProvider>
            </TimeTransactionsProvider>
        </SelectedTimePeriodProvider>
    );
}
