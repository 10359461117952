import {
    FlexiBalanceTableView,
    FlexiTimeBalance,
    VacationBalance,
    VacationBalanceTableView,
    useBalanceNamesAvailable
} from 'features/balances';
import { useTranslation } from 'react-i18next';
import {
    dateToIsoString,
    getFirstDateOfMonth,
    getLastDateOfMonth,
    getMonthName
} from 'utils/datetime';
import { Card, CardContent, Typography } from '@mui/material';
import { useFetch, useFetchArray } from 'hooks/useFetch';
import AccumulatedTimeResult from 'models/AccumulatedTime';
import AccumulatedTimeSummaryTableView from './AccumulatedTimeSummaryTableView';

type MonthlySummaryProps = {
    currentMonth: Date;
};

export default function MonthlySummary({ currentMonth }: MonthlySummaryProps) {
    const { t } = useTranslation();
    const year = `${new Date().getFullYear()}`;
    const firstOfTheMonth = getFirstDateOfMonth(currentMonth);
    const lastOfTheMonth = getLastDateOfMonth(currentMonth);

    // Accumulated time
    const [accumulatedTime, { isLoading: isLoadingAccuTime, errorMessage: errorMessageAccuTime }] =
        useFetchArray<AccumulatedTimeResult>('getAccumulatedTime', {
            reqData: {
                fromDate: dateToIsoString(firstOfTheMonth),
                toDate: dateToIsoString(lastOfTheMonth)
            }
        });

    // Vacation balance
    const [
        vacationBalance,
        { isLoading: isLoadingVacationBalance, errorMessage: errorMessageBalance }
    ] = useFetch<VacationBalance>('getVacationBalance');

    // Flexi balance
    const [
        flexiTimeBalances,
        { isLoading: isLoadingFlexiBalances, errorMessage: errorMessageFlexiBalances }
    ] = useFetchArray<FlexiTimeBalance>('getFlexiTimeBalance', { reqData: { year } });

    const { balanceNamesAvailable } = useBalanceNamesAvailable();
    const hasAnyBalances = balanceNamesAvailable ? balanceNamesAvailable.length > 0 : undefined;

    return (
        <>
            <Card sx={{ mb: 2 }}>
                <CardContent sx={{ px: 1 }}>
                    <Typography gutterBottom variant="h5" component="div" px={1}>
                        {t('dashboard.yourHoursForSomething', {
                            something: getMonthName(currentMonth)
                        })}
                    </Typography>
                    <AccumulatedTimeSummaryTableView
                        errorMessage={errorMessageAccuTime}
                        accumulatedTimeLines={accumulatedTime}
                        isLoading={isLoadingAccuTime}
                        label={t('dashboard.yourHoursForSomething')}
                    />
                </CardContent>
            </Card>
            {hasAnyBalances && (
                <Card sx={{ mb: 2 }}>
                    <CardContent sx={{ px: 1 }}>
                        <Typography gutterBottom variant="h5" component="div" px={1}>
                            {t('dashboard.yourBalanceForSometime', {
                                sometime: year
                            })}
                        </Typography>
                        {balanceNamesAvailable?.includes('flexitime') && (
                            <>
                                <FlexiBalanceTableView
                                    isDense
                                    isLoading={isLoadingFlexiBalances}
                                    errorMessage={errorMessageFlexiBalances}
                                    flexiTimeBalances={flexiTimeBalances}
                                />
                                <br />
                            </>
                        )}
                        {balanceNamesAvailable?.includes('vacation') && (
                            <VacationBalanceTableView
                                isDense
                                isLoading={isLoadingVacationBalance}
                                errorMessage={errorMessageBalance}
                                vacationBalance={vacationBalance || null}
                            />
                        )}
                    </CardContent>
                </Card>
            )}
        </>
    );
}
