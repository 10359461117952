import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { array, number, object, string } from 'zod';
import { AccumulatedTimeForSingleTransactionResult } from 'models/AccumulatedTime';
import customValidators from 'utils/customValidators';
import {
    UpdateManualAdditionInput,
    UpdateManualAdditionPayload
} from './useManualAdditionsForm.types';

export default function useManualAdditionsForm(
    inDate: string,
    inTime: string,
    accumulatedTimeCategories: Array<AccumulatedTimeForSingleTransactionResult>,
    onSuccess?: () => void
) {
    const schema = object({
        inDate: string().nonempty(),
        inTime: string().nonempty(),
        details: array(
            object({
                originalTimeCategoryId: number().nullable(),
                newTimeCategoryId: number(),
                amount: customValidators.numericString({
                    min: -999999.99,
                    max: 999999.99,
                    ignoreNonNumericChars: true
                })
            })
        )
    });

    const defaultValues: UpdateManualAdditionInput = {
        inDate,
        inTime,
        details: accumulatedTimeCategories.map((atc) => ({
            amount: atc.amount.toFixed(2),
            newTimeCategoryId: atc.timeCategoryId,
            originalTimeCategoryId: atc.timeCategoryId
        }))
    };

    const stripUnmutableFields = (
        submitData: UpdateManualAdditionInput
    ): UpdateManualAdditionInput => ({
        ...submitData,
        details: submitData.details.filter((detail) => {
            const accumulatedTimeCategory = accumulatedTimeCategories.find(
                (atc) => atc.timeCategoryId === Number(detail.newTimeCategoryId)
            );

            // only submit the details that are manual adjustments if they exist in accumulatedTimeCategories
            return (
                (accumulatedTimeCategory && accumulatedTimeCategory.isManualAdjustment) ||
                accumulatedTimeCategory === undefined
            );
        })
    });

    const convertAmountToNumber = (
        submitData: UpdateManualAdditionInput
    ): UpdateManualAdditionInput => ({
        ...submitData,
        details: submitData.details.map((detail) => ({
            ...detail,
            amount: detail.amount.replace(',', '.') // the database expects a dot as decimal separator
        }))
    });

    const transformValidatedDataBeforeSend = (data: UpdateManualAdditionInput) => {
        const strippedData = stripUnmutableFields(data);
        const convertedData = convertAmountToNumber(strippedData);

        return convertedData;
    };

    const formSetup = useFormWithApiIntegration<UpdateManualAdditionPayload>(
        'updateManualAddition',
        schema,
        defaultValues,
        { onSuccess, transformValidatedDataBeforeSend }
    );

    return { ...formSetup, defaultValues };
}
