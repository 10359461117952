/**
 * Guarding the name property for a form. Warns if the field name isn't present
 * in the form. Ideally we'd like typescript to catch this, but this'll have to
 * make do until then.
 */
import { useEffect } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IS_DEV } from 'utils/constants';
import { getFieldNames, isFieldNamePresent } from 'utils/form';

export default function useFieldNameGuard(name: string, setup: UseFormReturn) {
    const isOkFieldName = isFieldNamePresent(name, setup);
    const fieldNames = getFieldNames(setup);

    useEffect(() => {
        if (!isOkFieldName && IS_DEV) {
            // eslint-disable-next-line no-console
            console.warn(
                `Input name ${name} does not exist in form with fields`,
                fieldNames,
                `. Most likely you've either forgot to include it in the schema when setting up the form, or you've named the input incorrectly.`,
                fieldNames
            );
        }
    }, [name, isOkFieldName, fieldNames]);
}
