import { SvgIcon, SvgIconProps } from '@mui/material';

export default function PersonIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                viewBox="-253 326.5 24 24"
            >
                <path fill="none" d="M-253,326.5h24v24h-24V326.5z" />
                <path
                    d="M-232.304,348.5v-5.87c0-2.935-5.761-4.348-8.696-4.348s-8.696,1.413-8.696,4.348v5.87H-232.304z M-247.63,346.435v-3.804
	c0-0.652,3.37-2.283,6.63-2.283s6.63,1.63,6.63,2.283v3.804H-247.63z M-241,337.196c2.391,0,4.348-1.957,4.348-4.348
	c0-2.391-1.957-4.348-4.348-4.348s-4.348,1.957-4.348,4.348C-245.348,335.239-243.391,337.196-241,337.196z M-241,330.565
	c1.304,0,2.283,0.978,2.283,2.283c0,1.304-0.978,2.283-2.283,2.283s-2.283-0.978-2.283-2.283
	C-243.283,331.543-242.304,330.565-241,330.565z"
                />
            </svg>
        </SvgIcon>
    );
}
