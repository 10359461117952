import { useTimeTransactionsState } from 'features/dashboard/ctx/TimeTransactionsProvider';
import { useCapitechDataVisibility } from 'features/dashboard/providers';
import { useMemo } from 'react';

/**
 * Filter time transactions for a given date
 * @param date The date to get time transactions for
 * @param ignoreVisibility Ignores the visibility settings from the filter panel
 * @returns Time transactions for the given date
 */
export default function useTimeTransactionsForDate(date: Date, ignoreVisibility = false) {
    const { timeTransactions } = useTimeTransactionsState();
    const { isVisibleData } = useCapitechDataVisibility();

    const visibleTimeTransactionForSelectedDay = useMemo(
        () =>
            timeTransactions.filter((t) => {
                const matchesSelectedDate =
                    new Date(t.dateIn).toDateString() === date.toDateString();

                const isCompleteTransaction = !!t.timeOut;
                const matchesVisibilitySetting = isCompleteTransaction
                    ? isVisibleData('time')
                    : isVisibleData('timeIncomplete');

                return matchesSelectedDate && (matchesVisibilitySetting || ignoreVisibility);
            }),
        [timeTransactions, date, isVisibleData, ignoreVisibility]
    );

    return visibleTimeTransactionForSelectedDay;
}
