import { useContext } from 'react';
import { DutiesContext } from '../../DutiesProvider';
import { UseDutiesInSetDateRangeResult } from './useDutiesInSetDateRange.types';

export default function useDutiesInSetDateRange(): UseDutiesInSetDateRangeResult {
    const ctx = useContext(DutiesContext);
    if (!ctx) {
        throw new Error('useDutiesInSetDateRanges must be used within DutiesProvider');
    }

    return ctx;
}
