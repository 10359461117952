import { Grid, ListItem, ListItemText, TextField } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import { formatStringAsNumber } from 'features/dashboard/util/util';
import { TimeCategory } from 'models/TimeCategory';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type AddableTimeCategoryRowProps = {
    timeCategory: TimeCategory;
    onAdd: (amount: string) => void;
};

export default function AddableTimeCategoryRow({
    timeCategory,
    onAdd
}: AddableTimeCategoryRowProps) {
    const { t } = useTranslation();
    const [amount, setAmount] = useState('0.00');

    return (
        <ListItem divider sx={{ paddingX: 0, paddingY: 1 }}>
            <Grid container spacing={1} flexWrap="wrap" alignItems="center">
                <Grid item>
                    <TextField
                        sx={{ maxWidth: '110px' }} // comfortable width for 999999.99 (6 digits + 2 decimals (max value))
                        size="small"
                        inputProps={{
                            inputMode: 'decimal',
                            pattern: '[0-9.,]*',
                            style: { textAlign: 'right' }
                        }}
                        onChange={(event) => {
                            setAmount(event.target.value);
                        }}
                        onBlur={(event) => {
                            const newValue = formatStringAsNumber(event.target.value);
                            setAmount(newValue);
                        }}
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        value={amount}
                        aria-describedby={`catAddRowInput${timeCategory.timeCategoryId}`}
                    />
                </Grid>
                <Grid item>
                    <ListItemText id={`catAddRowInput${timeCategory.timeCategoryId}`}>
                        {timeCategory.timeCategoryDescription}
                    </ListItemText>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ textAlign: 'right' }}
                    marginLeft="auto"
                    marginRight={3}
                >
                    <ActionButton onClick={() => onAdd(amount)} type="button">
                        {t('actions.add')}
                    </ActionButton>
                </Grid>
            </Grid>
        </ListItem>
    );
}
