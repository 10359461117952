import { Typography } from '@mui/material';

// If we decide on asterisking both adjusted times, we can remove the asterisked prop and just use the isAdjusted prop
function TimePossiblyAdjusted({
    timeString,
    isAdjusted,
    asterisked
}: {
    timeString: string;
    isAdjusted: boolean;
    asterisked: boolean;
}) {
    return (
        <Typography
            component="span"
            sx={{ fontSize: 'inherit', ...(isAdjusted && { color: 'error.main' }) }}
        >
            {timeString}
            {asterisked && '*'}
        </Typography>
    );
}

export default function TimeRangePossiblyAdjusted({
    start,
    stop,
    isStartAdjusted,
    isStopAdjusted,
    trimSpaces
}: {
    start: string;
    stop: string;
    isStartAdjusted: boolean;
    isStopAdjusted: boolean;
    trimSpaces?: boolean;
}) {
    return (
        <>
            <TimePossiblyAdjusted
                timeString={start}
                isAdjusted={isStartAdjusted}
                asterisked={isStartAdjusted && !isStopAdjusted}
            />
            <Typography
                component="span"
                sx={{
                    fontSize: 'inherit',
                    ...(isStartAdjusted && isStopAdjusted && { color: 'error.main' })
                }}
            >
                {trimSpaces ? '-' : ' - '}
            </Typography>
            <TimePossiblyAdjusted
                timeString={stop}
                isAdjusted={isStopAdjusted}
                asterisked={isStopAdjusted}
            />
        </>
    );
}
TimeRangePossiblyAdjusted.defaultProps = {
    trimSpaces: false
};
