import {
    AuthApiResult,
    sendLogOutRequestAndDeleteCookies,
    RefreshTokenUnauthorizedError,
    AccessTokenInvalidError,
    AuthData,
    convertJwtToAuthData,
    getStoredAuthData,
    getStoredAuthentication,
    AuthError
} from 'utils/auth';
import {
    createContext,
    PropsWithChildren,
    useState,
    useMemo,
    useRef,
    useCallback,
    useEffect
} from 'react';
import useErrorScreen from 'hooks/useErrorScreen';
import { useTranslation } from 'react-i18next';
import {
    cleanUpAuthCookies,
    deleteJwtCookie,
    setJwtCookie,
    setRefreshTokenCookie
} from 'utils/cookie';
import useLoadingScreen from 'hooks/useLoadingScreen';
import useExternalLoginProvider from 'hooks/useExternalLoginProvider';
import { NetworkError } from 'utils/http/postToWebtidApi.types';
import { AuthContextType } from './AuthProvider.types';

export const AuthContext = createContext<AuthContextType>(null!);

const initialAuthData = getStoredAuthData();
const isInitialAuthDataExpired =
    initialAuthData && initialAuthData.accessTokenExpires.getTime() < new Date().getTime();

export function AuthProvider({ children }: PropsWithChildren) {
    const [authData, setAuthData] = useState<AuthData | undefined>(
        isInitialAuthDataExpired ? undefined : initialAuthData
    );
    const [isLoadingAuthFirstTime, setIsLoadingAuthFirstTime] = useState(true);

    const { isExternalLoginEnabled, logOut, login } = useExternalLoginProvider();

    useEffect(() => {
        if (initialAuthData && isInitialAuthDataExpired) {
            // Clean up expired auth data that should have been cleared by the browser
            // If there is a refresh token, the useEffect below will handle reauthenticating through getStoredAuthentication()
            deleteJwtCookie();
        }
    }, []);

    useEffect(() => {
        const loadAuthData = async () => {
            const storedAuthData = await getStoredAuthentication();
            setIsLoadingAuthFirstTime(false);

            if (!storedAuthData) return;

            const isAuthDataExpired =
                storedAuthData.accessTokenExpires.getTime() < new Date().getTime();

            if (isAuthDataExpired) {
                deleteJwtCookie();
                return;
            }

            setAuthData(storedAuthData);
        };
        loadAuthData();
    }, []);

    const { setError } = useErrorScreen();
    const { t } = useTranslation();

    // Flag used to prevent multiple auth errors to be handled
    const hasHandledBrokenSession = useRef<boolean>(false);

    const logout = useCallback(async () => {
        // Don't clear the auth data if we're using Auth0 or AzureAD,
        // as this broke the logout flow by cancelling the signOut-request on some browsers (iOS)
        if (!isExternalLoginEnabled) {
            setAuthData(undefined);
        }

        await sendLogOutRequestAndDeleteCookies();

        if (logOut) {
            logOut();
        }
    }, [isExternalLoginEnabled, logOut]);

    const onAuthError = useCallback(
        async (error: Error) => {
            if (error instanceof NetworkError) {
                setError(
                    {
                        iconName: 'error',
                        title: t('requestErrors.networkError'),
                        action: {
                            title: t('authErrors.sessionError.button'),
                            onClick: async () => window.location.reload()
                        }
                    },
                    true
                );
                return;
            }

            if (!(error instanceof AuthError)) {
                return;
            }

            if (hasHandledBrokenSession.current) {
                return;
            }

            hasHandledBrokenSession.current = true;

            if (error instanceof RefreshTokenUnauthorizedError) {
                cleanUpAuthCookies();
            } else {
                deleteJwtCookie();
            }

            setAuthData(undefined);

            if (error instanceof AccessTokenInvalidError) {
                setError(
                    {
                        iconName: 'authError',
                        title: t('authErrors.accessInvalid.title'),
                        message: t('authErrors.accessInvalid.message'),
                        action: {
                            title: t('authErrors.accessInvalid.button'),
                            onClick: async () => logOut()
                        }
                    },
                    true
                );
            } else if (error instanceof RefreshTokenUnauthorizedError) {
                setError(
                    {
                        iconName: 'authError',
                        title: t('authErrors.accessExpired.title'),
                        message: t('authErrors.accessExpired.message'),
                        action: {
                            title: t('authErrors.accessExpired.button'),
                            onClick: async () => login()
                        }
                    },
                    true
                );
            }
        },
        [setError, t, logOut, login]
    );

    const onSignInSuccess = useCallback(
        (data: AuthApiResult, useRememberMe: boolean) => {
            setAuthData(convertJwtToAuthData(data.accessToken));

            if (useRememberMe) {
                setRefreshTokenCookie(data.refreshToken, new Date(data.refreshTokenExpires));
            }

            setJwtCookie(data.accessToken, new Date(data.accessTokenExpires));
        },
        [setAuthData]
    );

    const value: AuthContextType = useMemo(
        () => ({
            logout,
            onSignInSuccess,
            isAuthenticated: authData !== undefined,
            onAuthError,
            authData
        }),
        [logout, onSignInSuccess, onAuthError, authData]
    );

    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('AuthProvider', isLoadingAuthFirstTime);
    }, [isLoadingAuthFirstTime, setSourceIsLoading]);

    if (isLoadingAuthFirstTime) return null;

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
