import {
    upperFirst,
    mapValues,
    keyBy,
    mapKeys,
    isEqual,
    intersection,
    pickBy,
    pick,
    values,
    merge,
    isEmpty,
    keys,
    difference,
    flatten
} from 'lodash';
import {
    DimensionValuesByDimension,
    getAllDependenciesToDimensionName,
    getDimensionNamesAffectedByDimensionValueChanges
} from 'utils/dimension';
import { postToWebtidApi } from 'utils/http';
import {
    IGenericDimensionDescription,
    CompetenceRoleDescription,
    DepartmentDescription,
    DimensionName,
    DutyDefinitionDescription,
    DutyDescription,
    Fridim1Description,
    Fridim2Description,
    OrderDescription,
    PhaseDescription,
    ProjectDescription,
    ShiftDescription,
    SubProjectDescription,
    TaskDescription,
    allDimensionNamesSorted,
    DimensionNamePlural,
    DimensionReferencePropertyName
} from 'models/TimeRegistrationModels';
import { getModifiedProperties } from 'utils/object';
import { chopPrefix, chopSuffix } from 'utils/string';
import { DimensionInputRules } from '../types/DimensionInput';

type DimensionDependencyTreeWithValues = Partial<Record<DimensionName, DimensionValuesByDimension>>;

export type DescriptionsByDimensionName = Partial<{
    department: Array<DepartmentDescription>;
    task: Array<TaskDescription>;
    order: Array<OrderDescription>;
    project: Array<ProjectDescription>;
    subProject: Array<SubProjectDescription>;
    phase: Array<PhaseDescription>;
    shift: Array<ShiftDescription>;
    fridim1: Array<Fridim1Description>;
    fridim2: Array<Fridim2Description>;
    duty: Array<DutyDescription>;
    dutyDefinition: Array<DutyDefinitionDescription>;
    competenceRole: Array<CompetenceRoleDescription>;
}>;

// TODO:: I'd like the type to be defined such that all dimension names are required to be defined. So if a new one gets added, an error occurs
export type DescriptionStore = Partial<{
    department: Array<DepartmentDescription> | null;
    task: Array<TaskDescription> | null;
    order: Array<OrderDescription> | null;
    project: Array<ProjectDescription> | null;
    subProject: Array<SubProjectDescription> | null;
    phase: Array<PhaseDescription> | null;
    shift: Array<ShiftDescription> | null;
    fridim1: Array<Fridim1Description> | null;
    fridim2: Array<Fridim2Description> | null;
    duty: Array<DutyDescription> | null;
    dutyDefinition: Array<DutyDefinitionDescription> | null;
    competenceRole: Array<CompetenceRoleDescription> | null;
}>;

function getDimensionDependencyTreeWithValues(
    dimensionInputRules: DimensionInputRules,
    currentDimensionValues: DimensionValuesByDimension
): DimensionDependencyTreeWithValues {
    const rulesWithDependencies = dimensionInputRules.filter(
        (dimensionInputRule) => dimensionInputRule.dependencies?.length
    );
    if (!rulesWithDependencies.length) return {};

    const rulesByDimensionName = keyBy(rulesWithDependencies, (rule) => rule.name);
    return mapValues(rulesByDimensionName, (rule) =>
        rule.dependencies?.reduce(
            (dependencyValuesByDimensionName: DimensionValuesByDimension, dimensionName) => ({
                ...dependencyValuesByDimensionName,
                [dimensionName]: currentDimensionValues[dimensionName]
            }),
            {}
        )
    );
}

// exported primarily for testin purposes
export function getDimensionDependenciesWithValues(
    dimensionName: DimensionName,
    dimensionInputRules: DimensionInputRules,
    currentDimensionValues: DimensionValuesByDimension,
    recursive: boolean
) {
    const dependencyTree = getDimensionDependencyTreeWithValues(
        dimensionInputRules,
        currentDimensionValues
    );

    const dimensionNamesToObtainFor = recursive
        ? [dimensionName].concat(getAllDependenciesToDimensionName(dimensionName))
        : dimensionName;
    const valuesToEachDimensionByDimension = pick(dependencyTree, dimensionNamesToObtainFor);
    const valuesToEachDimension = values(valuesToEachDimensionByDimension);

    if (isEmpty(valuesToEachDimension)) return {};

    const [first, ...rest] = valuesToEachDimension;

    return merge(first, ...rest);
}

// TODO:: Name me better
export function hasMissingDependencyValues(
    dimensionName: DimensionName,
    dimensionInputRules: DimensionInputRules,
    currentDimensionValues: DimensionValuesByDimension,
    recursive: boolean
) {
    const dependencyValuesByDimensionName = getDimensionDependenciesWithValues(
        dimensionName,
        dimensionInputRules,
        currentDimensionValues,
        recursive
    );

    if (!dependencyValuesByDimensionName) {
        return false; // Dimension has no dependencies
    }

    // Dimension has dependencies. Dependencies are missing if any of its values are undefined
    const dependencyValues = Object.values(
        dependencyValuesByDimensionName as Record<DimensionName, string | undefined> // Need type assertion because Object.values of Partial<Record<DimensionName, string | undefined>> assumes return type to be string[], when in fact it is `string | undefined`. To be clear; same goes for type `{[key in DimensionName]?: string | undefined}`.
    );

    return dependencyValues.includes(undefined) || dependencyValues.includes('');
}

function getDimensionReferencePropertyNameFromDimensionName(
    dimensionName: DimensionName
): DimensionReferencePropertyName {
    return `${dimensionName}Id`;
}

function createFetchDescriptionsToDimensionPromise(
    dimensionName: DimensionName,
    dimensionInputRules: DimensionInputRules,
    currentDimensionValues: DimensionValuesByDimension
) {
    const dimensionDependencies = dimensionInputRules.find(
        (rule) => rule.name === dimensionName
    )?.dependencies;

    // Parent needs to have value selected. Also; dependency knows if there are any records present.
    const dependencyValuesByDimensionName = dimensionDependencies
        ? getDimensionDependenciesWithValues(
              dimensionName,
              dimensionInputRules,
              currentDimensionValues,
              true
          )
        : {};
    const dataToPost = mapKeys(dependencyValuesByDimensionName, (_value, key) =>
        getDimensionReferencePropertyNameFromDimensionName(key as DimensionName)
    );

    return postToWebtidApi<Array<IGenericDimensionDescription>>(
        `get${upperFirst(dimensionName)}Descriptions`,
        dataToPost,
        {
            isAnonymous: false,
            onAuthError: (e) => {
                throw e;
            }
        }
    );
}

type PrefixedWithHas<T extends string> = `has${Capitalize<T>}`;
type SubdimensionDeterminators = Partial<Record<PrefixedWithHas<DimensionNamePlural>, boolean>>;
function getSubdimensionNamesKnownEmpty(description: IGenericDimensionDescription) {
    const propertyNamesToLookFor = allDimensionNamesSorted.map(
        (dimensionName) =>
            `has${upperFirst(dimensionName)}s` as PrefixedWithHas<DimensionNamePlural>
    );

    const propertiesDetermingSubdimensionContent = pick(
        description,
        propertyNamesToLookFor
    ) as SubdimensionDeterminators;

    const propertiesOmittingSubdimensions = pickBy(
        propertiesDetermingSubdimensionContent,
        (value) => value === false
    );

    // TODO:: Use RegEx magic to extract camel cased dimension name from "has<PascalCasedDimensionName>s" instead
    return keys(propertiesOmittingSubdimensions).map((prefixedPluralDimensionName) => {
        const prefixLessName = chopPrefix(prefixedPluralDimensionName, 'has', true);
        const suffixLessName = chopSuffix(prefixLessName, 's');
        return suffixLessName;
    }) as Array<DimensionName>;
}

// TODO:: Name this better. Function returns a data structure fit for descriptionStore
function getEmptySubdimensionRecords(
    descriptionList: Array<IGenericDimensionDescription>,
    selectedDescriptionValue: string
) {
    const selectedDescription =
        selectedDescriptionValue &&
        descriptionList.find((description) => description.value === selectedDescriptionValue);
    const subDimensionsKnownEmpty = selectedDescription
        ? getSubdimensionNamesKnownEmpty(selectedDescription)
        : [];

    const objectReadyToBePopulated = mapKeys(subDimensionsKnownEmpty);
    const emptySubdimensionRecords = mapValues(objectReadyToBePopulated, () => []);

    return emptySubdimensionRecords;
}

export async function fetchDimensionDescriptionSet(
    dimensionNamesToFetch: Array<DimensionName>,
    dimensionInputRules: DimensionInputRules,
    currentDimensionValues: DimensionValuesByDimension,
    onError: (dimensionName: DimensionName, error: Error) => void
) {
    const apiOperationsToFetchDescriptions = dimensionNamesToFetch.map((dimensionName) =>
        createFetchDescriptionsToDimensionPromise(
            dimensionName,
            dimensionInputRules,
            currentDimensionValues
        )
    );

    const apiResults = await Promise.all(apiOperationsToFetchDescriptions);

    const parsedDescriptionsByDimensionName = apiResults.reduce(
        (acc: DescriptionsByDimensionName, resultSet, index) => {
            const fetchedDimensionName: DimensionName = dimensionNamesToFetch[index];

            if (!resultSet.success) {
                onError(fetchedDimensionName, new Error(resultSet.displayErrorMessage));
            }

            // Prevent unnecessary downloads
            // TODO:: We're already doing this when we decide whether to download. Verify it's actually neccessary to do this here.
            const selectedDescriptionValue = currentDimensionValues[fetchedDimensionName];

            const emptySubdimensionRecords =
                resultSet.success && selectedDescriptionValue
                    ? getEmptySubdimensionRecords(resultSet.content, selectedDescriptionValue)
                    : {};

            return {
                ...acc,
                ...emptySubdimensionRecords,
                [fetchedDimensionName]: resultSet.success ? resultSet.content : []
            };
        },
        {}
    );
    return parsedDescriptionsByDimensionName;
}

/*
function getAsHasPropertyNam<T extends string>(string: T): PrefixedWithHas<T> {
    const capitalizedString = upperFirst(string) as Capitalize<T>;
    return `has${capitalizedString}`;
}
*/

function getDimensionNamesWhereDescriptionsNeedsToBeReFetched(
    currentDimensionValues: DimensionValuesByDimension,
    previousDimensionValues: DimensionValuesByDimension,
    dimensionNamesWithFetchStatus: Array<DimensionName>,
    dimensionNamesToIgnore: Array<DimensionName>
) {
    if (isEqual(currentDimensionValues, previousDimensionValues)) {
        return [];
    }

    const dimensionNamesPossiblyMissingDescriptions =
        getDimensionNamesAffectedByDimensionValueChanges(
            currentDimensionValues,
            previousDimensionValues || {}
        );

    const dimensionNamesNowMissingDescriptions = difference(
        dimensionNamesPossiblyMissingDescriptions,
        dimensionNamesToIgnore
    );

    const dimensionNamesPresentInDownloadSetNowMissing = intersection(
        dimensionNamesWithFetchStatus,
        dimensionNamesNowMissingDescriptions
    );

    return dimensionNamesPresentInDownloadSetNowMissing;
}

function getDimensionNamesKnownToHaveNoDescriptions(
    dimensionValues: DimensionValuesByDimension,
    storedDescriptions: DescriptionStore | null
): Array<DimensionName> {
    const dimensionNames = keys(dimensionValues) as Array<DimensionName>;

    const subDimensionNamesKnownEmptyForEachDimensionName = dimensionNames.map((dimensionName) => {
        const dimensionValue = dimensionValues[dimensionName];

        const selectedDescription =
            dimensionValue &&
            storedDescriptions?.[dimensionName]?.find(
                (description) => description.value === dimensionValue
            );
        if (!selectedDescription) return [];

        return getSubdimensionNamesKnownEmpty(selectedDescription);
    });

    const dimensionNamesKnownEmpty = flatten(subDimensionNamesKnownEmptyForEachDimensionName);
    return dimensionNamesKnownEmpty;
}

/**
 *
 * @param descriptionStore
 * @param currentDimensionValues
 * @param prevDimensionValues
 * @returns
 */
export function getImmediateUpdatesToDescriptionStore(
    descriptionStore: DescriptionStore | null,
    currentDimensionValues: DimensionValuesByDimension,
    prevDimensionValues?: DimensionValuesByDimension
) {
    let dimensionNamesKnownToBeEmpty: Array<DimensionName> = [];
    let dimensionNamesToRefetch: Array<DimensionName> = [];

    const changedDimensionValues = getModifiedProperties(
        prevDimensionValues || {},
        currentDimensionValues || {}
    );
    if (changedDimensionValues) {
        dimensionNamesKnownToBeEmpty = getDimensionNamesKnownToHaveNoDescriptions(
            changedDimensionValues,
            descriptionStore
        );

        dimensionNamesToRefetch = getDimensionNamesWhereDescriptionsNeedsToBeReFetched(
            currentDimensionValues,
            prevDimensionValues || {},
            keys(descriptionStore) as Array<DimensionName>,
            dimensionNamesKnownToBeEmpty
        );
    }

    if (isEmpty(dimensionNamesKnownToBeEmpty) && isEmpty(dimensionNamesToRefetch)) {
        return undefined;
    }

    return {
        refetch: dimensionNamesToRefetch,
        setToEmpty: dimensionNamesKnownToBeEmpty
    };
}
