import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string } from 'zod';

type SendMessagePayload = number;

export default function useReplyDialogForm(
    dialogId: string,
    onSuccess: () => void,
    requireAcknowledgeOtherMessageFirst: boolean // This is a condition that can be set by the parent component. If true, the user will be blocked from sending a message until they have acknowledged a previous message.
) {
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false); // Flag used to increase warning severity related to requireAcknowledgeOtherMessageFirst
    const updateStateAndBlockSubmitIfAcknowledgementRequired = useCallback(() => {
        setHasAttemptedSubmit(true);
        if (requireAcknowledgeOtherMessageFirst) {
            return false;
        }
        return true;
    }, [requireAcknowledgeOtherMessageFirst]);
    const updateStateAndRunOnSuccess = useCallback(() => {
        setHasAttemptedSubmit(false);
        onSuccess();
    }, [onSuccess]);

    const { t } = useTranslation();

    const replyDialogSchema = object({
        MessageBody: string().nonempty(t('messages.replyDialog.validationErrors.messageRequired')),
        DialogId: string().nonempty()
    });

    const formSetup = useFormWithApiIntegration<SendMessagePayload>(
        '/sendInDialog',
        replyDialogSchema,
        {
            MessageBody: '',
            DialogId: dialogId
        },
        {
            onBeforeHandleSubmit: updateStateAndBlockSubmitIfAcknowledgementRequired,
            onSuccess: updateStateAndRunOnSuccess,
            clearFormOnSuccess: true
        }
    );

    // Revert form whenever dialog changes. This means the user is browsing between dialogs in DialogList, in which case we're re-using the ReplyDialog component.
    useEffect(() => {
        if (dialogId) {
            setHasAttemptedSubmit(false);
        }
    }, [dialogId]);
    const { reset: resetForm } = formSetup;
    useEffect(() => {
        resetForm();
    }, [dialogId, resetForm]);
    // Clear previous response from api if dialog changes
    const { clearApiResponse } = formSetup;
    useEffect(() => {
        clearApiResponse();
    }, [dialogId, clearApiResponse]);

    const warnAcknowledgeMessage =
        requireAcknowledgeOtherMessageFirst && (formSetup.formState.isDirty || hasAttemptedSubmit);

    return { ...formSetup, hasAttemptedSubmit, warnAcknowledgeMessage };
}
