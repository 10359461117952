import { PropsWithChildren } from 'react';
import { EmployeeRegistrationInformationProvider } from 'features/misc/employeeSettings';
import { CapitechDataVisibilityProvider } from 'features/dashboard/providers';
import { DepartmentAndDutyFilterProvider } from 'features/plan';
import DateLocalizationProvider from './DateLocalizationProvider';
import DialogProvider from './DialogCtx';
import MyCapitechSettingsProvider from './MyCapitechSettingsProvider/MyCapitechSettingsProvider';
import MessagesUnreadProvider from './MessagesUnreadProvider';
import ExternalLinksProvider from './ExternalLinksProvider';
import MyCapitechClientSettingsProvider from './MyCapitechClientSettingsProvider';

export default function LoggedInProviders({ children }: PropsWithChildren) {
    return (
        <DateLocalizationProvider>
            <ExternalLinksProvider>
                <EmployeeRegistrationInformationProvider>
                    <MyCapitechSettingsProvider>
                        <MyCapitechClientSettingsProvider>
                            <DepartmentAndDutyFilterProvider>
                                <CapitechDataVisibilityProvider>
                                    <DialogProvider>
                                        <MessagesUnreadProvider>{children}</MessagesUnreadProvider>
                                    </DialogProvider>
                                </CapitechDataVisibilityProvider>
                            </DepartmentAndDutyFilterProvider>
                        </MyCapitechClientSettingsProvider>
                    </MyCapitechSettingsProvider>
                </EmployeeRegistrationInformationProvider>
            </ExternalLinksProvider>
        </DateLocalizationProvider>
    );
}
