import { Container, Grid, Typography, Box, useMediaQuery, useTheme } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import ActionButton from 'components/button/ActionButton';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import useQuery from 'hooks/useQuery';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import usePrevious from 'utils/usePrevious';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from 'components/error/ErrorMessage';
import DialogsList from './components/DialogsList';
import StartDialog from './StartDialog';
import useDialogs from './hooks/useDialogs';
import DialogView from './components/DialogView';
import { useMessagesUnreadCount } from './hooks';

export default function Messages() {
    const navigate = useNavigate();
    const query = useQuery();
    const dialogId = useMemo(() => query.get('dialogId'), [query]);
    const { t } = useTranslation();
    const { showDialog } = useDialog();
    const { dialogs, isLoading, refresh, errorMessage } = useDialogs();
    const selectedDialog = useMemo(
        () => dialogs?.find((d) => d.id === dialogId) ?? null,
        [dialogs, dialogId]
    );
    const previousSelectedDialog = usePrevious(selectedDialog);
    const containerRef = useRef<any>(null);
    const dialogsListRef = useRef<any>(null);

    const scrollToDialog = (d: string) => {
        if (dialogsListRef.current) {
            dialogsListRef.current.scrollToDialog(d);
        }
    };

    const { current: currentContainerRef } = containerRef;
    const top = useMemo(() => {
        if (!currentContainerRef) {
            return 0;
        }
        return Math.floor(currentContainerRef.offsetTop);
    }, [currentContainerRef]);

    const { refreshCountManually } = useMessagesUnreadCount();
    const handleOnDialogMessagesChanged = useCallback(() => {
        refreshCountManually();
        refresh();
    }, [refreshCountManually, refresh]);

    useEffect(() => {
        if (!previousSelectedDialog && selectedDialog) {
            scrollToDialog(selectedDialog.id);
        } else if (!selectedDialog && previousSelectedDialog) {
            scrollToDialog(previousSelectedDialog.id);
        }
    }, [selectedDialog, previousSelectedDialog]);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));

    const handleStartDialogClick = useCallback(() => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <StartDialog
                    onSuccess={(d) => {
                        refresh();
                        if (!isMobile) {
                            navigate(`/messages?dialogId=${d}`);
                        }
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    }, [showDialog, refresh, navigate, isMobile]);

    return (
        <Container
            maxWidth={selectedDialog ? 'xl' : 'md'}
            sx={(theme) => ({
                mt: theme.spacing(4)
            })}
        >
            {(!isMobile || !selectedDialog) && (
                <Grid container display="flex">
                    <Grid item xs>
                        <Typography
                            component="h1"
                            variant="h5"
                            sx={{
                                textTransform: 'uppercase'
                            }}
                        >
                            {t('messages.title')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <ActionButton onClick={handleStartDialogClick}>
                            {t('messages.actions.newMessage')}
                        </ActionButton>
                    </Grid>
                </Grid>
            )}

            <Box sx={{ mt: 2 }}>{errorMessage && <ErrorMessage message={errorMessage} />}</Box>

            {isMobile && !selectedDialog && (
                <Box sx={{ mt: 2 }}>
                    <DialogsList
                        dialogs={dialogs}
                        selectedDialog={selectedDialog}
                        ref={dialogsListRef}
                    />
                </Box>
            )}

            {isMobile && selectedDialog && (
                <Box sx={{}}>
                    <DialogView
                        dialog={selectedDialog}
                        onDialogMessagesChanged={handleOnDialogMessagesChanged}
                    />
                </Box>
            )}

            {!isMobile && (
                <Box>
                    <Grid
                        ref={containerRef}
                        className="desktopGrid"
                        container
                        display="flex"
                        mt={2}
                        sx={{
                            height: selectedDialog ? `calc(100vh - ${top}px)` : 'auto',
                            boxSizing: 'border-box'
                        }}
                    >
                        <Grid
                            item
                            xs={selectedDialog ? 6 : 12}
                            sx={{
                                height: '100%',
                                overflow: 'auto',
                                display: {
                                    xs: selectedDialog ? 'none' : 'block',
                                    md: 'block'
                                }
                            }}
                        >
                            <DialogsList
                                dialogs={dialogs}
                                selectedDialog={selectedDialog}
                                ref={dialogsListRef}
                            />
                        </Grid>
                        {selectedDialog && (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                sx={{
                                    overflow: 'auto',
                                    height: '100%'
                                }}
                            >
                                <Box sx={{ hm: '8px' }}>
                                    <DialogView
                                        dialog={selectedDialog}
                                        onDialogMessagesChanged={handleOnDialogMessagesChanged}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </Grid>
                </Box>
            )}
            <LoadingOverlay isLoading={isLoading} />
        </Container>
    );
}
