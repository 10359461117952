import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { version } from 'version';

import getBaseUrl from 'router/utils/getBaseUrl';
import { setDateLanguage } from './dateI18n';
import {
    DEFAULT_LANGUAGE,
    getUseableLanguageFromLanguageString,
    supportedLanguages
} from './support';

const baseUrl = getBaseUrl(window.location.href);

i18n.use(Backend)
    .use(initReactI18next)
    .use(LanguageDetector)
    .init(
        {
            load: 'languageOnly',
            debug: process.env.NODE_ENV === 'development',
            fallbackLng: {
                default: [DEFAULT_LANGUAGE],
                nn_NO: ['no'],
                nb_NO: ['no'],
                nb: ['no'],
                nn: ['no']
            },
            supportedLngs: supportedLanguages,
            backend: {
                loadPath: `${baseUrl}locales/{{lng}}/{{ns}}.json?${version}`
            },
            interpolation: {
                escapeValue: false
            }
        },
        () => {
            i18n.on('languageChanged', (lng) => {
                const useableLanguage = getUseableLanguageFromLanguageString(lng);

                document.documentElement.lang = useableLanguage;
                document.title = `${i18n.t('brand.my')}${i18n.t('brand.capitech')}`;
            });
        }
    );

i18n.on('languageChanged', (lng) => {
    const useableLanguage = getUseableLanguageFromLanguageString(lng);

    if (useableLanguage !== lng) {
        // Prevent language variants (like nn or nb) when useTranslation is referenced
        i18n.changeLanguage(useableLanguage);
        return; // Will cause languageChanged to fire again
    }

    setDateLanguage(useableLanguage);
});

export default i18n;
