import ActionButton, { ActionButtonProps } from 'components/button/ActionButton';

type DetailCardButtonProps = {
    icon: React.ReactNode;
    label: string;
};

export default function DetailCardButton({
    icon,
    label,
    isloading,
    onClick,
    ...props
}: DetailCardButtonProps & ActionButtonProps) {
    return (
        <ActionButton {...props} variant="outlined" size="small" startIcon={icon} onClick={onClick}>
            {label}
        </ActionButton>
    );
}
