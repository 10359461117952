import { Key, Person } from '@mui/icons-material';
import { Container, Box, Typography, Grid, Link, Divider, InputAdornment } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import HFCheckbox from 'components/hookForm/HFCheckbox';
import HFTextField from 'components/hookForm/HFTextField';
import HFFormProvider from 'ctx/HFFormProvider';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import { IS_DEV } from 'utils/constants';
import { getTranslatedOrFallback } from 'utils/translation';
import { version } from 'version';
import ClientSelect from './components/ClientSelect';
import MyCapitechLogo from './components/MyCapitechLogo';
import { LoginFormData, PostLoginPayload } from './hooks/useLoginForm';
import AzureADLoginButton from './components/AzureADLoginButton';

export type LoginViewProps = {
    formProps: UseFormWithApiIntegrationReturn<PostLoginPayload, any, LoginFormData>;
    showForgotPasswordLink?: boolean;
    showAzureADLogin?: boolean;
};

function Footer() {
    const { t } = useTranslation();

    return (
        <Typography
            component="footer"
            variant="subtitle2"
            position="absolute"
            bottom={0}
            align="center"
            mb={2}
            zIndex={1}
        >
            {`${t('brand.my')}${t('brand.capitech')}`} {version}
            {IS_DEV && (
                <Typography sx={{ color: 'error.light' }} component="div" variant="subtitle2">
                    {t('devEnv')}
                </Typography>
            )}
        </Typography>
    );
}

export default function LoginView({
    formProps,
    showForgotPasswordLink,
    showAzureADLogin
}: LoginViewProps) {
    const { t } = useTranslation();

    return (
        <Container
            component="main"
            maxWidth="sm"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center'
            }}
        >
            <Box
                sx={(theme) => ({
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    spacing: 0,
                    minHeight: '100vh',
                    position: 'relative',
                    padding: theme.spacing(1),
                    zIndex: 2 // needs to be 1 higher than footer
                })}
            >
                <HFFormProvider formProps={formProps}>
                    <MyCapitechLogo />
                    <ClientSelect enterKeyHint="next" />
                    <HFTextField
                        enterKeyHint="next"
                        margin="normal"
                        required
                        fullWidth
                        id="userid"
                        label={t('loginScreen.username')}
                        name="username"
                        autoComplete="username"
                        autoFocus
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            )
                        }}
                    />
                    <HFTextField
                        enterKeyHint="send"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label={t('loginScreen.password')}
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Key />
                                </InputAdornment>
                            )
                        }}
                    />
                    <HFCheckbox name="remember" label={t('loginScreen.rememberMe')} />
                    {formProps.displayErrorMessage && (
                        <ErrorMessage
                            message={getTranslatedOrFallback(
                                formProps.displayErrorMessage,
                                'loginScreen.errors.generic'
                            )}
                        />
                    )}
                    {showAzureADLogin && <AzureADLoginButton />}
                    <ActionButton
                        isloading={formProps.formState.isSubmitting}
                        fullWidth
                        sx={{ my: 2 }}
                    >
                        {t('loginScreen.signInBtn')}
                    </ActionButton>
                    {showForgotPasswordLink && (
                        <>
                            <Divider variant="middle" sx={{ my: 2 }} />
                            <Grid container textAlign="center">
                                <Grid item xs>
                                    <Link
                                        component={RouterLink}
                                        to={PageRoutes.PasswordForgot}
                                        variant="body2"
                                    >
                                        {t('loginScreen.forgotPasswordLink')}
                                    </Link>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </HFFormProvider>
            </Box>
            <Footer />
        </Container>
    );
}

LoginView.defaultProps = { showForgotPasswordLink: true, showAzureADLogin: false };
