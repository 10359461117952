import {
    createContext,
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useReducer
} from 'react';
import { sortBy } from 'lodash';
import useAuth from 'hooks/useAuth';
import { refreshTimeDataFromApi } from '../../util';
import { useSelectedTimePeriod } from '../SelectedTimePeriodCtx';
import timeTransactionsReducer from '../TimeTransactionReducer';
import { TimeTransactionsState } from '../TimeTransactionReducer.types';
import {
    TimeTransactionsCtxType,
    UseTimeTransactionsResult,
    UseTimeTransactionsStateResult
} from './TimeTransactionsProvider.types';

const initialState: TimeTransactionsState = {
    timeTransactions: [],
    isLoading: false,
    shouldRefreshSummary: false
};

const TimeTransactionsContext = createContext<TimeTransactionsCtxType>({
    state: initialState,
    dispatch: () => {},
    refreshSelectedTimePeriod: () => {}
});

export function TimeTransactionsProvider({ children }: PropsWithChildren<{}>) {
    const [state, dispatch] = useReducer(timeTransactionsReducer, initialState);

    const { fromDate, toDate } = useSelectedTimePeriod();

    const { onAuthError } = useAuth();

    useEffect(() => {
        refreshTimeDataFromApi(fromDate, toDate, dispatch, onAuthError);
    }, [fromDate, toDate, onAuthError]);

    const refreshSelectedTimePeriod = useCallback(() => {
        refreshTimeDataFromApi(fromDate, toDate, dispatch, onAuthError);
    }, [fromDate, toDate, dispatch, onAuthError]);

    const value = useMemo(() => {
        // sort time transactions by date and time in ascending order
        sortBy(state.timeTransactions, ['dateIn', 'timeIn']);
        return { state, dispatch, refreshSelectedTimePeriod };
    }, [state, refreshSelectedTimePeriod]);

    return (
        <TimeTransactionsContext.Provider value={value}>
            {children}
        </TimeTransactionsContext.Provider>
    );
}

export function useTimeTransactions(): UseTimeTransactionsResult {
    const ctx = useContext(TimeTransactionsContext);

    if (!ctx) {
        throw new Error('useTimeTransactions only available inside TimeTransactionsContext');
    }

    return ctx;
}

export function useTimeTransactionsState(): UseTimeTransactionsStateResult {
    const ctx = useContext(TimeTransactionsContext);

    if (!ctx) {
        throw new Error('useTimeTransactions only available inside TimeTransactionsContext');
    }

    return ctx.state;
}
