import { useContext, useMemo } from 'react';
import { isBetweenDates, isSameDate } from 'utils/datetime';
import { dayjs } from 'i18n';
import { UseDutiesOnDateInSetDateRangeResult } from './useDutiesOnDateInSetDateRange.types';
import { DutiesContext } from '../../DutiesProvider';

export default function useDutiesOnDateInSetDateRange(
    date: Date
): UseDutiesOnDateInSetDateRangeResult {
    const ctx = useContext(DutiesContext);
    if (!ctx) {
        throw new Error(
            'useDutiesPlannedOnDateInSetDateRange must be used within DateRangeDutiesPlannedProvider'
        );
    }

    return useMemo<UseDutiesOnDateInSetDateRangeResult>(
        () => ({
            isLoading: ctx.isLoading,
            planned: {
                ...ctx.planned,
                items: ctx.planned.items.filter((item) => {
                    const itemDate = new Date(item.dutyStartDate);
                    return isSameDate(itemDate, date);
                })
            },
            available: {
                ...ctx.available,
                items: ctx.available.items.filter((item) => {
                    const itemDate = new Date(item.startDate);
                    return isSameDate(itemDate, date);
                })
            },
            requested: {
                ...ctx.requested,
                items: ctx.requested.items.filter((item) => {
                    const itemDate = new Date(item.startDate);
                    return isSameDate(itemDate, date);
                })
            },
            wish: {
                ...ctx.wish,
                items: ctx.wish.items.filter((item) => {
                    const itemDate = new Date(item.date);
                    return isSameDate(itemDate, date);
                })
            },
            wishPeriods: {
                ...ctx.wishPeriods,
                items: ctx.wishPeriods.items.filter((item) => {
                    const requestedDayNumber = dayjs(date).day();
                    const wishFromDate = new Date(item.fromDate);
                    const wishToDate = new Date(item.toDate);

                    return (
                        item.requestedDaysOfWeek.includes(requestedDayNumber) &&
                        isBetweenDates(date, wishFromDate, wishToDate)
                    );
                })
            }
        }),
        [ctx, date]
    );
}
