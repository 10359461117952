import {
    difference,
    intersection,
    isNull,
    isUndefined,
    keyBy,
    keys,
    mapValues,
    pickBy,
    uniq,
    upperFirst
} from 'lodash';
import {
    allDimensionNamesSorted,
    DimensionInputName,
    DimensionInputNameUpdate,
    DimensionName,
    DimensionReferencePropertyName
} from 'models/TimeRegistrationModels';

// TODO:: Obtain through some kind of input schema instead
export type DimensionDependenciesByDimensionName = Partial<
    Record<DimensionName, Array<DimensionName>>
>;
export const dimensionDependenciesByDimensionName: DimensionDependenciesByDimensionName = {
    task: ['department'],
    subProject: ['project'],
    phase: ['subProject'],
    duty: ['department']
};

function recursivelyGetAllDimensionDependencies(
    dimensionName: DimensionName,
    gatheredDependencies: Array<DimensionName> = []
) {
    const newDependencies = difference(
        dimensionDependenciesByDimensionName[dimensionName],
        gatheredDependencies
    );
    if (!newDependencies.length) return gatheredDependencies; // We´ve got all we need

    const newGatheredependencies = [...gatheredDependencies, ...newDependencies];

    let furtherUpdatedependencies = newGatheredependencies;

    newDependencies.forEach((newDimensionName) => {
        furtherUpdatedependencies = recursivelyGetAllDimensionDependencies(
            newDimensionName,
            furtherUpdatedependencies
        );
    });
    return furtherUpdatedependencies;
}

export function getAllDependenciesToDimensionName(dimensionName: DimensionName) {
    return recursivelyGetAllDimensionDependencies(dimensionName);
}

function getAllDimensionNamesRequiredByDimensionName(): DimensionDependenciesByDimensionName {
    const allDimensionNamesWithDependencies = keys(
        dimensionDependenciesByDimensionName
    ) as Array<DimensionName>;

    return mapValues(keyBy(allDimensionNamesWithDependencies), (dimensionName: DimensionName) =>
        getAllDependenciesToDimensionName(dimensionName)
    );
}

export type DimensionValuesByDimension = Partial<Record<DimensionName, string | undefined>>;
export function getDimensionNamesAffectedByDimensionValueChanges(
    currentValues: DimensionValuesByDimension,
    previousValues: DimensionValuesByDimension
) {
    // Find changed dimension names
    const prevDimensionNames = keys(previousValues) as Array<DimensionName>;
    const currDimensionNames = keys(currentValues) as Array<DimensionName>;
    const allDimensionsPresent = uniq([...prevDimensionNames, ...currDimensionNames]);
    const changedDimensionNames = allDimensionsPresent.filter(
        (dimensionName) => previousValues[dimensionName] !== currentValues[dimensionName]
    );

    const possibleDimensionNamesRequiredByDimensionName =
        getAllDimensionNamesRequiredByDimensionName();

    const dimensionNamesAffectedByChange = keys(
        pickBy(
            possibleDimensionNamesRequiredByDimensionName,
            (dimensionNamesRequired) =>
                intersection(dimensionNamesRequired, changedDimensionNames).length
        )
    ) as Array<DimensionName>;

    return dimensionNamesAffectedByChange;
}

export function getDimensionNameAsInputNameForAbsenceRegistration(
    dimensionName: DimensionName
): `${DimensionName}Id` {
    return `${dimensionName}Id`;
}

export function getDimensionNameAsInputNameForHourRegistration(
    dimensionName: DimensionName,
    purpose: 'fetch'
): DimensionInputName;
export function getDimensionNameAsInputNameForHourRegistration(
    dimensionName: DimensionName,
    purpose: 'create'
): DimensionInputName;
export function getDimensionNameAsInputNameForHourRegistration(
    dimensionName: DimensionName,
    purpose: 'update'
): DimensionInputNameUpdate;
export function getDimensionNameAsInputNameForHourRegistration(
    dimensionName: DimensionName,
    purpose: 'update' | 'create'
): DimensionInputNameUpdate | DimensionInputName;
export function getDimensionNameAsInputNameForHourRegistration<
    T extends DimensionName = DimensionName
>(
    dimensionName: T,
    purpose: 'create' | 'update' | 'fetch'
): DimensionInputName | DimensionInputNameUpdate {
    return purpose === 'update'
        ? `new${upperFirst(dimensionName) as Capitalize<T>}Id` // Ohh, if only lodash would set return type to Capitalize<inputString> in this case
        : `${dimensionName}Id`;
}

type DimensionReferenceProps = Partial<Record<DimensionReferencePropertyName, string | undefined>>;

// TODO:: Type to something else than object would be nice.
export function pickDimensionPropsForInput(object: object): DimensionReferenceProps {
    const dimensionNamesAsTransactionProperties =
        allDimensionNamesSorted.map<DimensionReferencePropertyName>(
            (dimensionName) => `${dimensionName}Id`
        );
    const dimensionProps = pickBy(
        object,
        (value, key) =>
            !isUndefined(value) &&
            !isNull(value) &&
            dimensionNamesAsTransactionProperties.includes(key as DimensionReferencePropertyName)
    ); // We've ruled out undefined and null records

    return mapValues(dimensionProps, String) as DimensionReferenceProps;
}
