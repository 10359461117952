import { useTranslation } from 'react-i18next';
import HFTextField from 'components/hookForm/HFTextField';
import { Alert, Box, BoxProps, InputAdornment, Stack } from '@mui/material';
import HFFormProvider from 'ctx/HFFormProvider';
import HFHidden from 'components/hookForm/HFHidden';
import ActionButton from 'components/button/ActionButton';
import { getTranslatedError } from 'utils/translation';
import ErrorMessage from 'components/error/ErrorMessage';
import useReplyDialogForm from './hooks/useReplyDialogForm';

type ReplyDialogProps = {
    dialogId: string;
    requireAcknowledgeOtherMessageFirst: boolean; // Can be omitted and dealt with internally (from dialog id) if we add a shared provider for messages within a dialog
    sx: BoxProps['sx'];
    onSuccess: () => void;
};

export default function ReplyDialog({
    dialogId,
    requireAcknowledgeOtherMessageFirst,
    sx,
    onSuccess
}: ReplyDialogProps) {
    const { t } = useTranslation();

    const { hasAttemptedSubmit, warnAcknowledgeMessage, ...formSetup } = useReplyDialogForm(
        dialogId,
        onSuccess,
        requireAcknowledgeOtherMessageFirst
    );

    return (
        <Box sx={sx}>
            <HFFormProvider formProps={formSetup}>
                <HFHidden name="DialogId" />

                <Stack direction="column" spacing={2}>
                    {warnAcknowledgeMessage && (
                        <Alert severity={hasAttemptedSubmit ? 'error' : 'warning'}>
                            {t('messages.replyDialog.errors.mustAcknowledgeOtherMessageFirst')}
                        </Alert>
                    )}

                    {formSetup.displayErrorMessage && (
                        <ErrorMessage
                            message={getTranslatedError(
                                formSetup.displayErrorMessage,
                                'messages.replyDialog.errors.generic'
                            )}
                        />
                    )}
                    <HFTextField
                        label={t('messages.replyDialog.message')}
                        name="MessageBody"
                        sx={{ flex: 1 }}
                        multiline
                        maxRows={5}
                        minRows={1}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <ActionButton isloading={formSetup.formState.isSubmitting}>
                                        {t('messages.replyDialog.actions.reply')}
                                    </ActionButton>
                                </InputAdornment>
                            )
                        }}
                    />
                </Stack>
            </HFFormProvider>
        </Box>
    );
}
