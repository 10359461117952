enum PageRoutes {
    Home = '/',
    Login = '/login',
    PasswordForgot = '/forgotpassword',
    Messages = '/messages',
    Absence = '/absence',
    Balance = '/balance',
    Reports = '/reports',
    Profile = '/profile'
}

export default PageRoutes;
