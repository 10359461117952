import { InputOption } from 'components/Input/Dropdown/InputOption';
import { useFetchArray } from 'hooks/useFetch';
import { useMemo } from 'react';

type AbsenceCode = {
    absenceCode: 'AVS';
    description: 'Avspasering';
    isSelfCertificationAllowed: false;
};

export default function useAbsenceCodeOptions() {
    const [asbenceCodes, { isLoading, errorMessage }] =
        useFetchArray<AbsenceCode>('getAbsenceCodes');

    const absenceCodesAsOptions = useMemo(
        () =>
            asbenceCodes.map<InputOption>((absenceCode) => ({
                label: absenceCode.description,
                value: absenceCode.absenceCode,
                isSelectable: true
            })),
        [asbenceCodes]
    );
    return {
        options: absenceCodesAsOptions,
        isLoading,
        errorMessage
    };
}
