import { createContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
    ContentKillSwitchProviderContextType,
    ContentKillSwitchProviderProps
} from './ContentKillSwitchProvider.types';

export const ContentKillSwitchContext = createContext<ContentKillSwitchProviderContextType>(null!);

export default function ContentKillSwitchProvider({ children }: ContentKillSwitchProviderProps) {
    const [isOn, setIsOn] = useState(false);

    // Always reset when leaving to another page
    const { pathname } = useLocation();
    useEffect(() => {
        setIsOn(false);
    }, [pathname]);

    const value = useMemo(
        (): ContentKillSwitchProviderContextType => ({
            isOn,
            turnOff: () => setIsOn(false),
            turnOn: () => setIsOn(true)
        }),
        [isOn]
    );

    return (
        <ContentKillSwitchContext.Provider value={value}>
            {children}
        </ContentKillSwitchContext.Provider>
    );
}
