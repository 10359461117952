import { MessagesUnreadContext } from 'ctx/MessagesUnreadProvider';
import { useContext } from 'react';
import { UseMessagesUnreadCountResult } from './useMessagesUnreadCount.types';

export default function useMessagesUnreadCount(): UseMessagesUnreadCountResult {
    const { messagesUnreadCount, refreshCountManually } = useContext(MessagesUnreadContext);

    return {
        messagesUnreadCount,
        refreshCountManually
    };
}
