import HFHidden from 'components/hookForm/HFHidden';
import HFDropdown from 'components/hookForm/HFDropdown';
import useClientList from 'hooks/useClientList';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { EnterKeyHint } from 'utils/input';
import { useTranslation } from 'react-i18next';
import { InputOption } from 'components/Input/Dropdown/InputOption';
import {
    getLegacyPreviouslySelectedClientIdCookie,
    getPreviouslySelectedClientIdCookie,
    setPreviouslySelectedClientIdCookie
} from 'utils/cookie';

export default function ClientSelect({ enterKeyHint }: { enterKeyHint: EnterKeyHint }) {
    const { clientList, isLoading, isSingleClient, singleClient } = useClientList();
    const { setValue, watch } = useFormContext();
    const { t } = useTranslation();

    useEffect(() => {
        if (isSingleClient()) {
            // Pre-select value if single. Ensures proper value in hidden field is set.
            setValue('clientId', singleClient ? String(singleClient.clientId) : '');
        } else {
            // Pre-select value if previously selected.
            let prevClientId = getPreviouslySelectedClientIdCookie();

            if (!prevClientId) {
                prevClientId = getLegacyPreviouslySelectedClientIdCookie();
            }

            const prevClient = clientList.find(
                (client) => client.clientId === Number(prevClientId)
            );

            if (prevClientId && prevClient) {
                setValue('clientId', prevClientId);
            }
        }
    }, [singleClient, setValue, isSingleClient, clientList]);

    // TODO:: Consider renaming hook to useClientOptions instead
    const clientOptions = useMemo(
        () =>
            clientList.map<InputOption>((client) => ({
                label: client.clientName,
                value: String(client.clientId),
                isSelectable: true
            })),
        [clientList]
    );

    const clientId = watch('clientId');
    useEffect(() => {
        if (clientId && clientId.length > 0) {
            setPreviouslySelectedClientIdCookie(clientId);
        }
    }, [clientId]);

    if (isLoading) {
        return <div />;
    }

    return isSingleClient() ? (
        /* Need for testid: Element indicates list is done loading. No other indicators present. The only way to access a hidden element is through testid. */
        <HFHidden name="clientId" id="client-select" data-testid="client-select" />
    ) : (
        <HFDropdown
            disableSearch
            name="clientId"
            options={clientOptions}
            isLoadingOptions={isLoading}
            margin="normal"
            required
            fullWidth
            id="client-select"
            label={t('client')}
            /* Needed for testing: client list's label is dirtied with an asterix on display, which makes findByLabelText to not work as expected */
            data-testid="client-select"
            enterKeyHint={enterKeyHint}
        />
    );
}
