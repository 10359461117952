import { Backdrop, BackdropProps } from '@mui/material';
import { SCREEN_OVERLAY_INDEX } from 'utils/layerIndexes';

type ScreenOverlayProps = Omit<BackdropProps, 'open'>;

export default function ScreenOverlay({ children, ...backdropProps }: ScreenOverlayProps) {
    const isOpen = true; // Started out just having open set directly in JSX, but turned out that aria-hidden didn't adjust accordingly

    return (
        <Backdrop
            {...backdropProps}
            open={isOpen}
            aria-hidden={!isOpen}
            sx={{
                zIndex: SCREEN_OVERLAY_INDEX,
                backgroundColor: 'background.default',
                ...backdropProps.sx
            }}
        >
            {children}
        </Backdrop>
    );
}
