export type ApiResponse<T = any> = {
    success: boolean;
    content: T;
    displayErrorMessage: string;
    serverErrorMessage: string;
};

export interface PostOptions {
    isAnonymous?: boolean;
    method?: 'POST' | 'GET';
}

export interface PostOptionsAnonymous extends PostOptions {
    isAnonymous: true;
}

export interface PostOptionsLoggedIn extends PostOptions {
    isAnonymous?: false;
    onAuthError: (error: Error) => void;
}

export type PostToWebtidApiResult<T> = Promise<ApiResponse<T | []>>;

export class NetworkError extends Error {
    constructor(message: string) {
        super(message);
        this.name = 'NetworkIsGone';
        this.message = message;
    }
}
