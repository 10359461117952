import { useContext } from 'react';
import { CapitechDataVisibilityContext } from '../../CapitechDataVisibilityProvider';
import { UseCapitechDataVisibilityResult } from './useCapitechDataVisibility.types';

export default function useCapitechDataVisibility(): UseCapitechDataVisibilityResult {
    const ctx = useContext(CapitechDataVisibilityContext);

    if (!ctx) {
        throw new Error(
            'useCapitechDataVisibility must be used within CapitechDataVisibilityProvider'
        );
    }

    return ctx;
}
