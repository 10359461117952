import { useFetchArray } from 'hooks/useFetch';
import { AccumulatedTimeForSingleTransactionResult } from 'models/AccumulatedTime';
import {
    GetAccumulatedTimeForTransactionInput,
    UseAccumulatedTimeForTransactionResult
} from './useAccumulatedTimeForTransaction.types';

export default function useAccumulatedTimeForTransaction(
    input: GetAccumulatedTimeForTransactionInput
): UseAccumulatedTimeForTransactionResult {
    const [accumulatedTimeCategories, { isLoading }] = useFetchArray<
        AccumulatedTimeForSingleTransactionResult,
        GetAccumulatedTimeForTransactionInput
    >('getAccumulatedTimeForSingleTransaction', { reqData: input });

    return { accumulatedTimeCategories, isLoading };
}
