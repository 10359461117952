import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import { object, string } from 'zod';

export type PostForgotPasswordPayload = undefined;

export default function useForgotPasswordForm() {
    const { t } = useTranslation();
    const { i18n } = useTranslation();

    const forgotPasswordSchema = object({
        clientId: string().min(1, t('loginScreen.forgotPassword.validationErrors.clientRequired')),
        emailAddress: string()
            .min(1, t('loginScreen.forgotPassword.validationErrors.emailRequired'))
            .email(t('loginScreen.forgotPassword.validationErrors.emailInvalid')),
        cultureCode: string()
    });

    return useFormWithApiIntegration<PostForgotPasswordPayload>(
        '/forgotPassword',
        forgotPasswordSchema,
        {
            clientId: '',
            emailAddress: '',
            cultureCode: i18n.language
        },
        { isAnonymous: true }
    );
}
