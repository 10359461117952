import useErrorScreen from 'hooks/useErrorScreen';
import { useFetch } from 'hooks/useFetch';
import { DimensionName } from 'models/TimeRegistrationModels';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type CustomDimensionNameKey = `${DimensionName}Description`;
type CustomDimensionNamePayload = Partial<Record<CustomDimensionNameKey, string>> | null;

export default function useCustomDimensionLabels() {
    // TODO:: Deliver data from an appropriate App provider instead
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    const [customDimensionLabels, { isLoading, errorMessage }] =
        useFetch<CustomDimensionNamePayload>('getDimensionNames', {
            reqData: {
                cultureCode: i18n.language
            }
        });

    const { setError } = useErrorScreen();
    useEffect(() => {
        if (errorMessage) {
            setError({ message: t('dimensionRegistration.errors.loadCustomNamesFailed') });
        }
    }, [errorMessage, setError, t]);

    return { customDimensionLabels, isLoading, errorMessage };
}
