import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';
import { InputAdornment, TextField } from '@mui/material';
import { forwardRef, ReactNode } from 'react';

type SelectWithSEarchTriggerProps = {
    label: ReactNode;
    textToDisplay: string;
    onTrigger: () => void;
    isTriggered: boolean;
    errorMessage?: string;
    disabled?: boolean;
    readOnly?: boolean;
    required?: boolean;
};

const SelectWithSearchTrigger = forwardRef<HTMLDivElement, SelectWithSEarchTriggerProps>(
    (
        {
            label,
            textToDisplay,
            onTrigger,
            isTriggered,
            errorMessage,
            disabled,
            readOnly,
            required
        }: SelectWithSEarchTriggerProps,
        ref
    ) => {
        const isLocked = disabled || readOnly;

        return (
            <TextField
                role="combobox"
                label={label}
                required={required}
                error={!!errorMessage}
                ref={ref}
                onClick={() => {
                    if (isLocked) {
                        return;
                    }
                    onTrigger();
                }}
                value={textToDisplay}
                sx={{
                    '& .MuiInputBase-root': {
                        paddingRight: '7px'
                    },
                    '& .MuiInputBase-root, & .MuiInputBase-root textarea': {
                        cursor: 'pointer'
                    }
                }}
                fullWidth
                disabled={disabled}
                aria-readonly="true"
                multiline // Hacky-hack which prevents text selection on focus
                InputProps={{
                    readOnly: true,
                    endAdornment: (
                        <InputAdornment position="end">
                            {isTriggered ? <ArrowDropUp /> : <ArrowDropDown />}
                        </InputAdornment>
                    )
                }}
                InputLabelProps={{
                    shrink: textToDisplay.length > 0 || isTriggered
                }}
                onKeyDown={(evt) => {
                    if (isLocked) {
                        return;
                    }

                    if (
                        evt.key === ' ' ||
                        evt.key === 'ArrowDown' ||
                        evt.key === 'ArrowUp' ||
                        evt.key === 'Enter'
                    ) {
                        evt.preventDefault();
                        onTrigger();
                    }
                }}
            />
        );
    }
);
SelectWithSearchTrigger.defaultProps = {
    disabled: false,
    readOnly: false,
    errorMessage: '',
    required: false
};

export default SelectWithSearchTrigger;
