import { useCallback, useMemo } from 'react';
import { Box, IconButton, LinearProgress } from '@mui/material';
import dayjs from 'dayjs';
import { Close } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import ErrorMessage from 'components/error/ErrorMessage';
import DialogMessage from '../models/DialogMessage';
import useDialogMessages from '../hooks/useDialogMessages';
import Dialog from '../models/Dialog';
import DialogHeader from './DialogHeader';
import DialogMessages from './DialogMessages';
import { ReplyDialog } from '.';

function hasMessageToManuallyConfirmRead(messages: DialogMessage[]) {
    return messages.some((m) => m.isIncoming && m.isUnAcknowledged && m.acknowledgeRequired);
}

type DialogMessagesProps = {
    dialog: Dialog | undefined | null;
    onDialogMessagesChanged: () => void;
};

export default function DialogView({ dialog, onDialogMessagesChanged }: DialogMessagesProps) {
    const handleGetDialogMessagesSuccess = useCallback(
        (messages: DialogMessage[]) => {
            // Any unread messages not needing acknowledgement are marked and delivered as read when user requests them.
            const hasMessagesAutmaticallyConfirmedRead =
                !!dialog?.hasUnReadMessages &&
                messages.length > 0 &&
                !hasMessageToManuallyConfirmRead(messages);

            if (hasMessagesAutmaticallyConfirmedRead) {
                onDialogMessagesChanged();
            }
        },
        [dialog?.hasUnReadMessages, onDialogMessagesChanged]
    );

    const { messages, isLoading, getDialogMessages, errorMessage } = useDialogMessages(
        dialog?.id!,
        handleGetDialogMessagesSuccess
    );

    const sortedMessages = useMemo(
        () => [...messages].sort((a, b) => dayjs(a.createdOn).diff(dayjs(b.createdOn))),
        [messages]
    );

    const onReplySuccess = useCallback(() => {
        getDialogMessages();
    }, [getDialogMessages]);

    const onMessageChanged = useCallback(() => {
        getDialogMessages();
        onDialogMessagesChanged();
    }, [getDialogMessages, onDialogMessagesChanged]);

    return (
        <Box sx={{ position: 'relative' }}>
            <IconButton
                aria-label="close"
                component={Link}
                to="/messages"
                sx={(theme) => ({
                    position: 'absolute',
                    top: theme.spacing(1),
                    right: { xs: theme.spacing(1), md: theme.spacing(2) }
                })}
            >
                <Close />
            </IconButton>
            <DialogHeader dialog={dialog} />
            {isLoading && (
                <Box sx={{ mx: 1, opacity: 0.25 }}>
                    <LinearProgress />
                </Box>
            )}
            {!isLoading && errorMessage && (
                <Box margin={1}>{errorMessage && <ErrorMessage message={errorMessage} />}</Box>
            )}
            <DialogMessages messages={sortedMessages} onMessageChanged={onMessageChanged} />
            {sortedMessages.length > 0 && dialog && (
                <ReplyDialog
                    dialogId={dialog.id}
                    requireAcknowledgeOtherMessageFirst={hasMessageToManuallyConfirmRead(messages)}
                    onSuccess={onReplySuccess}
                    sx={{
                        px: { xs: 0, md: 1 },
                        pb: { xs: 3, md: 2 }
                    }}
                />
            )}
        </Box>
    );
}
