import ErrorScreen, { ErrorScreenOptions } from 'components/error/ErrorScreen';
import { createContext, PropsWithChildren, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ErrorScreenContextType = {
    setError: (options: Partial<ErrorScreenOptions>, lock?: boolean) => void;
};

export const ErrorScreenContext = createContext<ErrorScreenContextType>(null!);

export default function ErrorScreenProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation();

    const [options, setOptions] = useState<ErrorScreenOptions | undefined>();

    const isLockedRef = useRef<boolean>(false);

    const value = useMemo<ErrorScreenContextType>(
        () => ({
            setError: (optionalOptions: Partial<ErrorScreenOptions>, lock = false) => {
                if (isLockedRef.current) return;
                if (lock) isLockedRef.current = true;

                setOptions({
                    iconName: 'sad',
                    title: t('errorScreen.sigh'),
                    message: t('errorScreen.somethingWentWrong'),
                    action: {
                        title: t('errorScreen.tryAgain'),
                        onClick: () => {
                            window.location.reload();
                        }
                    },
                    ...optionalOptions
                });
            }
        }),
        [t, isLockedRef]
    );

    return (
        <ErrorScreenContext.Provider value={value}>
            {options && <ErrorScreen options={options} />}
            {!isLockedRef.current ? children : null}
        </ErrorScreenContext.Provider>
    );
}
