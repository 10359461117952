import { TextField } from '@mui/material';
import React, { useRef } from 'react';
import { TimeInputCustomProps } from './TimeInputCustom.types';

function stripInvalidCharacters(userInput: string) {
    return userInput.replace(/[^0-9:.,]/g, '');
}

function replaceDelimiterCharacters(str: string): string {
    return str.replace(/[.,]/g, ':');
}

function addDelimiterCharacters(input: string): string {
    if (input.length !== 4 || input.indexOf(':') !== -1) {
        return input;
    }

    const parts = input.split('');
    parts.splice(2, 0, ':');
    return parts.join('');
}

function formatTimeInput(userInput: string): string {
    let timeInput = replaceDelimiterCharacters(userInput);

    if (timeInput.indexOf(':') === -1) {
        if (timeInput.length === 1) {
            timeInput = `0${timeInput}00`;
        } else if (timeInput.length === 2) {
            timeInput = `${timeInput}00`;
        } else if (timeInput.length === 3) {
            timeInput = `0${timeInput}`;
        }
        const parts = timeInput.split('');
        parts.splice(2, 0, ':');
        timeInput = parts.join('');
    }

    if (timeInput === '24:00') {
        return '00:00';
    }

    if (timeInput === ':') {
        return '';
    }

    const timeParts = timeInput.split(':');

    const hours = Number.isNaN(parseInt(timeParts[0], 10)) ? 0 : parseInt(timeParts[0], 10);
    const minutes = Number.isNaN(parseInt(timeParts[1], 10)) ? 0 : parseInt(timeParts[1], 10);

    if (Number.isNaN(timeParts[0]) || Number.isNaN(timeParts[1])) {
        return '';
    }

    if (hours < 0 || hours > 23) {
        return '';
    }

    if (minutes < 0 || minutes > 59) {
        return '';
    }

    const paddedHours = String(hours).padStart(2, '0');
    const paddedMinutes = String(minutes).padStart(2, '0');
    const formattedResult = `${paddedHours}:${paddedMinutes}`;

    return formattedResult;
}

export default function TimeInputCustom({
    label,
    onChange,
    ...textFieldProps
}: TimeInputCustomProps) {
    const inputRef = useRef<HTMLInputElement | null>();

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const strippedInput = stripInvalidCharacters(event.target.value);
        const inputWithStandardDelimiter = replaceDelimiterCharacters(strippedInput);
        const sanitizedInputWithAddedDelimiter = addDelimiterCharacters(inputWithStandardDelimiter);

        if (inputRef.current) {
            inputRef.current.value = sanitizedInputWithAddedDelimiter;
        }

        if (onChange) {
            onChange(sanitizedInputWithAddedDelimiter);
        }
    };

    const handleOnBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        const userInput = event.target.value;
        const formattedInput = formatTimeInput(userInput);

        if (inputRef.current) {
            inputRef.current.value = formattedInput;
        }

        if (onChange) {
            onChange(formattedInput);
        }
    };

    return (
        <TextField
            label={label}
            type="text"
            placeholder="XX:XX"
            value={textFieldProps.value}
            onChange={handleOnChange}
            onBlur={handleOnBlur}
            inputProps={{
                maxLength: 5
            }}
            inputRef={inputRef}
            {...textFieldProps}
        />
    );
}

TimeInputCustom.defaultProps = {
    onChange: undefined
};
