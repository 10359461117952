import { useFetchArray } from 'hooks/useFetch';
import Dialog from '../models/Dialog';

type GetDialogsInput = {
    Take: number;
    Skip: number;
};

export default function useDialogs() {
    const [dialogs, { isLoading, errorMessage, sendRequest: refresh }] = useFetchArray<
        Dialog,
        GetDialogsInput
    >('/getDialogs', {
        reqData: {
            Take: 50, // Same hard coded value as in MC1
            Skip: 0
        }
    });

    return {
        dialogs,
        isLoading,
        errorMessage,
        refresh
    };
}
