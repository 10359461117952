import { useEffect } from 'react';

type ArrowKeys = 'ArrowUp' | 'ArrowDown' | 'ArrowLeft' | 'ArrowRight';

type Callback = (keyName: ArrowKeys) => void;

export default function useArrowKeyReleased(isActive: boolean, callback: Callback) {
    useEffect(() => {
        if (isActive) {
            const releaseHandler = ({ key }: { key: string }) => {
                if (
                    key === 'ArrowUp' ||
                    key === 'ArrowDown' ||
                    key === 'ArrowLeft' ||
                    key === 'ArrowRight'
                ) {
                    callback(key);
                }
            };

            window.addEventListener('keyup', releaseHandler);

            return () => {
                window.removeEventListener('keyup', releaseHandler);
            };
        }
        return () => {};
    }, [isActive, callback]);
}
