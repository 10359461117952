import { useCallback } from 'react';
import { DutyAvailable, DutyRequested } from 'models/Duty';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import RequestedDutyDetailCard from './cards/RequestedDutyDetailCard';
import DutyRequestDeleteDialog from './DutyRequestDeleteDialog';

function getDutyDescriptionString(
    dutyRequested: DutyRequested,
    relatedAvailableDuty?: DutyAvailable
) {
    const timeStr = `${dutyRequested.originalStartTime} - ${dutyRequested.originalEndTime}`;

    const firstPart = `${timeStr} ${dutyRequested.dutyStrId}`;
    const lastPart = relatedAvailableDuty
        ? ` | ${relatedAvailableDuty.dutyDescriptions}`
        : dutyRequested.dutyTypeDescription;

    return `${firstPart}${lastPart}`;
}

export default function RequestedDutyDetails({
    requestedDuty,
    onDelete,
    relatedAvailableDuty
}: {
    requestedDuty: DutyRequested;
    relatedAvailableDuty?: DutyAvailable;
    onDelete: VoidFunction;
}) {
    const { showDialog } = useDialog();

    const handleDeleteRequestClick = useCallback(async () => {
        showDialog((onClose) => (
            <DutyRequestDeleteDialog
                dutyRequest={requestedDuty}
                onSuccess={() => {
                    onClose();
                    onDelete();
                }}
                onCancel={onClose}
            />
        ));
    }, [requestedDuty, onDelete, showDialog]);

    const { t } = useTranslation();

    return (
        <RequestedDutyDetailCard
            description={getDutyDescriptionString(requestedDuty, relatedAvailableDuty)}
            title={t('calendar.dutyRequested')}
            onDeleteClick={handleDeleteRequestClick}
        />
    );
}
RequestedDutyDetails.defaultProps = {
    relatedAvailableDuty: undefined
};
