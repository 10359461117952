import { useFeatureAccess } from 'features/misc/employeeSettings';
import { useEmployeeSettings } from 'features/profile';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { getLanguageWhenLoggedOut, getUseableLanguageFromLanguageString } from 'i18n';
import { Fragment, PropsWithChildren, createElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

export default function EmployeeSettingsApplier({ children }: PropsWithChildren) {
    const { employeeSettings } = useEmployeeSettings();
    const { isLoading: isLoadingFeatureAccess, access } = useFeatureAccess();
    const isLoadingFeatureAccessFirstTime = isLoadingFeatureAccess && !access;

    // Apply language
    const {
        i18n: { language: currentLanguage, changeLanguage }
    } = useTranslation();
    const preferredLanguage = employeeSettings.language;
    const useableLanguageFromPreferredLanguage = preferredLanguage
        ? getUseableLanguageFromLanguageString(preferredLanguage)
        : null;
    const newTargetLanguage =
        useableLanguageFromPreferredLanguage &&
        useableLanguageFromPreferredLanguage !== currentLanguage
            ? useableLanguageFromPreferredLanguage
            : null;
    useEffect(() => {
        if (newTargetLanguage) {
            changeLanguage(newTargetLanguage);
        }
    }, [changeLanguage, newTargetLanguage]);

    // Component unmounts on logout. Ensure language is reset to logged out language.
    // Will also run unexpectedly in dev mode due to React.StrictMode. This is fine.
    const loggedOutLanguage = getLanguageWhenLoggedOut();
    useEffect(
        () => () => {
            changeLanguage(loggedOutLanguage);
        },
        [changeLanguage, loggedOutLanguage]
    );

    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('EmployeeSettingsApplier', isLoadingFeatureAccessFirstTime);
    }, [isLoadingFeatureAccessFirstTime, setSourceIsLoading]);

    // Only need to hold off rendering children while loading feature access. See previous commit if employee settings and language also should be prepped up before rendering children.
    if (isLoadingFeatureAccessFirstTime) return null;

    return createElement(Fragment, null, children); // Tried setting allowExpressions and wrap children in a regular fragment tag as suggested here https://stackoverflow.com/a/73285055. Still got the error "Fragments should contain more than one child".
}
