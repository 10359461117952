import { autocompleteClasses, styled } from '@mui/material';

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        margin: 0,
        color: 'inherit',
        fontSize: 13,
        borderRadius: 0
    },
    [`& .${autocompleteClasses.listbox}`]: {
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
        padding: 0,
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            padding: 8
        }
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative'
    }
}));

interface SelectWithSearchAutocompletePopperProps {
    anchorEl?: any;
    disablePortal?: boolean;
    open: boolean;
}
export default function SelectWithSearchAutocompletePopper(
    props: SelectWithSearchAutocompletePopperProps
) {
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}
SelectWithSearchAutocompletePopper.defaultProps = {
    anchorEl: undefined,
    disablePortal: undefined
};
