import { createContext, useMemo } from 'react';
import { DutyAvailable, DutyPlanned, DutyRequested, DutyWish, DutyWishPeriod } from 'models/Duty';
import { DateISOString, dateToIsoString } from 'utils/datetime';
import { useFetchArray } from 'hooks/useFetch';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import { UseFetchArrayOptions } from 'hooks/useFetch.types';
import { DutiesContextType, DutiesProviderProps } from './DutiesProvider.types';

export const DutiesContext = createContext<DutiesContextType>(null!);

type GenericGetDutiesInput = {
    fromDate: DateISOString;
    toDate: DateISOString;
};

export default function DutiesProvider({ children, fromDate, toDate }: DutiesProviderProps) {
    const { plan: isPlanAccessible } = useFeatureAccess().access;

    const dateNowString = dateToIsoString(new Date());
    const fromDateString = dateToIsoString(fromDate);
    const toDateString = dateToIsoString(toDate);

    // Fetch options by duty variants where past records are included
    const genericFetchDutiesOptions = useMemo<UseFetchArrayOptions<unknown, GenericGetDutiesInput>>(
        () => ({
            reqData: {
                fromDate: fromDateString,
                toDate: toDateString
            },
            manuallyTriggerRequest: !isPlanAccessible // Don't do request if without access to plan
        }),
        [fromDateString, toDateString, isPlanAccessible]
    );
    // Fetch options for variants where past records are to be omitted
    const genericFetchDutiesOptionsOmittingPastDates = useMemo<
        UseFetchArrayOptions<unknown, GenericGetDutiesInput>
    >(() => {
        const fromDateStringNeverInThePast =
            fromDateString < dateNowString ? dateNowString : fromDateString;
        const isPastDateRange = toDateString < dateNowString;

        return {
            reqData: {
                fromDate: fromDateStringNeverInThePast,
                toDate: toDateString
            },
            manuallyTriggerRequest: !isPlanAccessible || isPastDateRange // Don't do request if without access to plan or if past date range
        };
    }, [fromDateString, dateNowString, toDateString, isPlanAccessible]);

    // Planned duties
    const [
        dutiesPlanned,
        {
            isLoading: isLoadingDutiesPlanned,
            errorMessage: errorMessageDutiesPlanned,
            sendRequest: refreshDutiesPlanned
        }
    ] = useFetchArray<DutyPlanned, GenericGetDutiesInput>(
        '/getPlannedDutiesForEmployee',
        genericFetchDutiesOptions
    );

    // Available duties
    const [
        dutiesAvailable,
        {
            isLoading: isLoadingDutiesAvailable,
            errorMessage: errorMessageDutiesAvailable,
            sendRequest: refreshDutiesAvailable
        }
    ] = useFetchArray<DutyAvailable, GenericGetDutiesInput>(
        '/getAvaliableDuties',
        genericFetchDutiesOptionsOmittingPastDates
    );

    // Requested duties
    const [
        dutiesRequested,
        {
            isLoading: isLoadingDutiesRequested,
            errorMessage: errorMessageDutiesRequested,
            sendRequest: refreshDutiesRequested
        }
    ] = useFetchArray<DutyRequested, GenericGetDutiesInput>(
        '/getRequestedDuties',
        genericFetchDutiesOptions
    );

    // Duty wishes
    const [
        dutyWishes,
        {
            isLoading: isLoadingDutyWishes,
            errorMessage: errorMessageDutyWishes,
            sendRequest: refreshDutyWishes
        }
    ] = useFetchArray<DutyWish, GenericGetDutiesInput>('/getDutyWish', genericFetchDutiesOptions);

    // Duty wish periods
    const [
        dutyWishPeriods,
        {
            isLoading: isLoadingDutyWishPeriods,
            errorMessage: errorMessageDutyWishPeriods,
            sendRequest: refreshDutyWishPeriods
        }
    ] = useFetchArray<DutyWishPeriod, GenericGetDutiesInput>(
        '/getDutyWishPeriods',
        genericFetchDutiesOptions
    );

    const value = useMemo(
        (): DutiesContextType => ({
            isLoading:
                isLoadingDutiesPlanned ||
                isLoadingDutiesAvailable ||
                isLoadingDutiesRequested ||
                isLoadingDutyWishPeriods ||
                isLoadingDutyWishes,
            planned: {
                isLoading: isLoadingDutiesPlanned,
                errorMessage: errorMessageDutiesPlanned,
                items: dutiesPlanned,
                refresh: refreshDutiesPlanned
            },
            available: {
                isLoading: isLoadingDutiesAvailable,
                errorMessage: errorMessageDutiesAvailable,
                items: dutiesAvailable,
                refresh: refreshDutiesAvailable
            },
            requested: {
                isLoading: isLoadingDutiesRequested,
                errorMessage: errorMessageDutiesRequested,
                items: dutiesRequested,
                refresh: refreshDutiesRequested
            },
            wish: {
                isLoading: isLoadingDutyWishes,
                errorMessage: errorMessageDutyWishes,
                items: dutyWishes,
                refresh: refreshDutyWishes
            },
            wishPeriods: {
                isLoading: isLoadingDutyWishPeriods,
                errorMessage: errorMessageDutyWishPeriods,
                items: dutyWishPeriods,
                refresh: refreshDutyWishPeriods
            }
        }),
        [
            dutiesAvailable,
            dutiesPlanned,
            dutiesRequested,
            dutyWishPeriods,
            dutyWishes,
            errorMessageDutiesAvailable,
            errorMessageDutiesPlanned,
            errorMessageDutiesRequested,
            errorMessageDutyWishPeriods,
            errorMessageDutyWishes,
            isLoadingDutiesAvailable,
            isLoadingDutiesPlanned,
            isLoadingDutiesRequested,
            isLoadingDutyWishPeriods,
            isLoadingDutyWishes,
            refreshDutiesAvailable,
            refreshDutiesPlanned,
            refreshDutiesRequested,
            refreshDutyWishPeriods,
            refreshDutyWishes
        ]
    );

    return <DutiesContext.Provider value={value}>{children}</DutiesContext.Provider>;
}
