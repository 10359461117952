// Based on https://github.com/react-hook-form/react-hook-form/issues/612#issuecomment-1307594057
import { GenericPostInput } from 'hooks/useApiPost';
import { useEffect, useState } from 'react';
import { FormState } from 'react-hook-form';
import { DATE_INPUT_IDENTIFIER_CLASS } from 'utils/input';

type FormErrors = FormState<GenericPostInput>['errors'];

function isFocusSuitedInputElement(inputElement: HTMLElement) {
    // Avoid the risk of date picker to float above the error message
    if (inputElement.classList.contains(DATE_INPUT_IDENTIFIER_CLASS)) {
        return false;
    }

    const ignoredNodeNames = [
        'select', // Focus on a drop down can get stuck on mobile. This is the case when submitting an absence request without providing an absence code on mobile.
        'div'
    ];

    if (ignoredNodeNames.includes(inputElement.nodeName.toLowerCase())) {
        return false;
    }

    return true;
}

function scrollToFirstError(fieldsNamesWithError: Array<string>) {
    // TODO:: Make lookup of first error message more bullet proof. Theoretically one can end up with focus on an error message outside of the form.

    // We're scrolling to...
    // First INPUT with error message OR first error MESSAGE possibly unrelated to any input
    const firstErrorMessage = document.getElementsByClassName('MuiAlert-standardError')[0] as
        | HTMLElement
        | undefined;

    const inputsWithErrors = fieldsNamesWithError
        .map((name) => document.getElementsByName(name)[0])
        .filter((el) => !!el);

    const elements = firstErrorMessage
        ? [...inputsWithErrors, firstErrorMessage]
        : inputsWithErrors;

    const scrollTargets = elements.map((element) =>
        element.getAttribute('type') !== 'hidden' ? element : (element.parentElement as HTMLElement)
    );

    scrollTargets.sort((a, b) => a.getBoundingClientRect().top - b.getBoundingClientRect().top);

    if (scrollTargets.length > 0) {
        const scrollTarget = scrollTargets[0];

        if (scrollTarget && typeof scrollTarget.scrollIntoView !== 'undefined') {
            scrollTarget.scrollIntoView({ block: 'center' }); // scrollIntoView options are not supported in Safari

            if (isFocusSuitedInputElement(scrollTarget)) {
                scrollTarget.focus({ preventScroll: true });
            }

            return true;
        }
    }

    return false;
}

export default function useAutoFocusErrors(errors: FormErrors) {
    const [isWatching, setIsWatching] = useState(false);

    // errors are often defined twice (dunno why) so we get new object with same values
    const stringifiedFieldNamesWithError = JSON.stringify(Object.keys(errors).sort());
    useEffect(() => {
        const fieldNamesWithError = JSON.parse(stringifiedFieldNamesWithError);
        if (isWatching && fieldNamesWithError.length) {
            const didScroll = scrollToFirstError(fieldNamesWithError);
            if (didScroll) {
                setIsWatching(false);
            }
        }
    }, [stringifiedFieldNamesWithError, isWatching]);

    return {
        watchForErrors: () => setIsWatching(true)
    };
}
