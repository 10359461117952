import { useCapitechDataVisibility } from 'features/dashboard/providers';
import { Box, BoxProps } from '@mui/material';
import {
    CapitechDataSwitchName,
    capitechDataSwitchVisuals
} from 'features/dashboard/util/capitechData';
import { Clear, HelpOutline, QuestionMark } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import { useEffect } from 'react';
import CircledIconButton from './CircledIconButton';
import DutyFilterRequiredDialog from '../DutyFilterRequiredDialog';
import CapitechDataVisibilityExplanationDialog from '../CapitechDataVisibilityExplanationDialog';

type CapitechDataVisibilityIconsRowProps = {
    sx?: BoxProps['sx'];
};

export default function CapitechDataVisibilitySwitchesRow({
    sx
}: CapitechDataVisibilityIconsRowProps) {
    const { allDataSwitchNamesAvailable, setDataSwitchState, isDataSwitchEnabled, resetSwitches } =
        useCapitechDataVisibility();
    const { t } = useTranslation();

    // Dialog with info about why duty wish data is not visible
    const { showDialog, clearDialogs } = useDialog();
    useEffect(() => clearDialogs, [clearDialogs]); // Clears dialogs on unmount

    const showDutyDataUnavailableDialog = (
        triggerContext: 'displayVacantDuties' | 'displayDutyWish'
    ) => {
        showDialog((onClose) => (
            <DutyFilterRequiredDialog onClose={onClose} open triggerContext={triggerContext} />
        ));
    };

    // Data visibility switch modal
    const handleSwitchChange = (switchName: CapitechDataSwitchName) => {
        const isSuccessfullySet = setDataSwitchState(switchName, !isDataSwitchEnabled(switchName));
        if (isSuccessfullySet) return;

        if (switchName === 'dutyAvailable') {
            showDutyDataUnavailableDialog('displayVacantDuties');
        }
    };

    // Data visibility switch modal
    const showExplanationModal = () => {
        showDialog((onClose) => <CapitechDataVisibilityExplanationDialog onClose={onClose} />);
    };

    const getDataSwitchTitle = (switchName: CapitechDataSwitchName) => {
        const isEnabled = isDataSwitchEnabled(switchName);

        return t(isEnabled ? 'actions.hideSomething' : 'actions.showSomething', {
            something: t(`dashboard.dataVisibilitySwitches.${switchName}`).toLocaleLowerCase()
        });
    };

    // Revert visibility selection on unmount
    useEffect(() => resetSwitches, [resetSwitches]);

    return (
        <Box sx={{ display: 'flex', flex: 1, flexDirection: 'row', gap: 2, ...sx }}>
            {allDataSwitchNamesAvailable.map((switchName) => (
                <CircledIconButton
                    sx={{ opacity: isDataSwitchEnabled(switchName) ? 1 : '.5' }}
                    badgeIcon={isDataSwitchEnabled(switchName) ? undefined : Clear}
                    key={switchName}
                    color={
                        isDataSwitchEnabled(switchName)
                            ? capitechDataSwitchVisuals[switchName].color
                            : 'inactive'
                    }
                    fillVariant={capitechDataSwitchVisuals[switchName].fillVariant}
                    title={getDataSwitchTitle(switchName)}
                    icon={capitechDataSwitchVisuals[switchName].icon || QuestionMark}
                    onClick={() => handleSwitchChange(switchName)}
                />
            ))}
            <CircledIconButton
                color="info"
                fillVariant="none"
                title={t('actions.showSomething', {
                    something: t('dashboard.iconGlossary').toLocaleLowerCase()
                })}
                icon={HelpOutline}
                onClick={() => showExplanationModal()}
            />
        </Box>
    );
}
CapitechDataVisibilitySwitchesRow.defaultProps = {
    sx: {}
};
