import {
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from '@mui/material';
import ErrorMessage from 'components/error/ErrorMessage';
import { useTranslation } from 'react-i18next';
import { useRef } from 'react';
import useSizeMaintainer from 'hooks/useSizeMaintainer';
import { VacationBalance } from '../Balances.types';

type VacationBalanceTableViewProps = {
    isLoading: boolean;
    errorMessage: string | null;
    vacationBalance: VacationBalance | null;
    isDense?: boolean;
};

export default function VacationBalanceTableView({
    isLoading,
    errorMessage,
    isDense,
    vacationBalance
}: VacationBalanceTableViewProps) {
    const { t } = useTranslation();

    const vacationBalanceRef = useRef(null);
    const sizeAccuTimeCardIfLoading = useSizeMaintainer(vacationBalanceRef, isLoading, true);

    return (
        <>
            {errorMessage && <ErrorMessage message={errorMessage} />}
            {!errorMessage && (
                <TableContainer
                    component={Paper}
                    ref={vacationBalanceRef}
                    sx={sizeAccuTimeCardIfLoading}
                >
                    <Table size="small" aria-label={t('balances.vacation.summaryTable.label')}>
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('balances.vacation.summaryTable.days')}</TableCell>
                                <TableCell align="right">
                                    {t('balances.vacation.summaryTable.used')}
                                </TableCell>
                                {!isDense && (
                                    <TableCell
                                        align="right"
                                        sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                    >
                                        {t('balances.vacation.summaryTable.remaining')}
                                    </TableCell>
                                )}
                                <TableCell align="right">
                                    {t('balances.vacation.summaryTable.planned')}
                                </TableCell>
                                <TableCell align="right">
                                    {t('balances.vacation.summaryTable.balance')}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                {!isLoading && !vacationBalance && (
                                    <TableCell colSpan={isDense ? 4 : 5}>
                                        {t('balances.noData')}
                                    </TableCell>
                                )}
                                {!isLoading && vacationBalance && (
                                    <>
                                        <TableCell>{vacationBalance.openingBalance}</TableCell>
                                        <TableCell align="right">
                                            {vacationBalance.usedDays}
                                        </TableCell>
                                        {!isDense && (
                                            <TableCell
                                                align="right"
                                                sx={{ display: { xs: 'none', sm: 'table-cell' } }}
                                            >
                                                {vacationBalance.openingBalance -
                                                    vacationBalance.usedDays}
                                            </TableCell>
                                        )}
                                        <TableCell align="right">
                                            {vacationBalance.plannedDays}
                                        </TableCell>
                                        <TableCell align="right">
                                            {vacationBalance.avaliableDays}
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </>
    );
}

VacationBalanceTableView.defaultProps = {
    isDense: false
};
