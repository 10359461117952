import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import DialogSpinner from 'components/dialog/DialogSpinner';
import {
    Box,
    BoxProps,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    useMediaQuery,
    useTheme
} from '@mui/material';
import HFFormProvider from 'ctx/HFFormProvider';
import { useTranslation } from 'react-i18next';
import HFDateRangePicker from 'components/hookForm/HFDateRangePicker';
import HFTextField from 'components/hookForm/HFTextField';
import ActionButton from 'components/button/ActionButton';
import HFCheckbox from 'components/hookForm/HFCheckbox';
import HFCheckboxGroup from 'components/hookForm/HFCheckboxGroup';
import ErrorMessage from 'components/error/ErrorMessage';
import { getTranslatedOrFallback } from 'utils/translation';
import {
    PostCreateDutyWishPayload,
    PostCreateDutyWishPeriodInput
} from './hooks/useDutyWishForm/useDutyWishForm.types';
import { DutyWishRegistrationContextType } from '../providers/registration/DutyWishRegistrationProvider.types';

type DutyWishRegistrationViewProps = {
    formProps: UseFormWithApiIntegrationReturn<
        PostCreateDutyWishPayload | PostCreateDutyWishPeriodInput
    >;
    isRegisteringNew: boolean;
    isLoading: boolean;
    onCancel: () => void;
    options: Pick<
        DutyWishRegistrationContextType['optionsAvailable'],
        'daysOfWeek' | 'departments' | 'dutyTypes'
    >;
    sx?: BoxProps['sx'];
};

export default function DutyWishRegistrationView({
    formProps,
    isLoading,
    sx,
    onCancel,
    options,
    isRegisteringNew
}: DutyWishRegistrationViewProps) {
    const { t } = useTranslation();

    const isWideMode = useMediaQuery(useTheme().breakpoints.up('md'));
    const isMobileMode = useMediaQuery(useTheme().breakpoints.up('sm'));

    const checkboxGroupWrapperStyle = {
        border: '1px solid',
        borderColor: '#d7d7d7',
        backgroundColor: 'white',
        width: '100%'
    };

    const checkboxGroupStyle = {
        position: 'relative',
        overflow: 'auto',
        maxHeight: 385,
        '& .MuiListItemText-root:first-letter': {
            textTransform: 'uppercase'
        },
        ...(isWideMode ? { height: 385 } : {})
    };

    if (isLoading) {
        return <DialogSpinner />;
    }

    return (
        <Box sx={sx}>
            <HFFormProvider formProps={formProps}>
                <DialogTitle mb={1}>{t('dutyWishRegistration.title')}</DialogTitle>
                <DialogContent sx={{ overflowY: 'visible' }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={8}>
                            <HFDateRangePicker
                                sx={{
                                    display: 'flex',
                                    flexDirection: isMobileMode ? 'row' : 'column',
                                    gap: 2
                                }}
                                fromPickerProps={{
                                    name: 'fromDate',
                                    label: t('dutyWishRegistration.fromDate'),
                                    fullWidth: true,
                                    required: true,
                                    disablePast: isRegisteringNew
                                }}
                                toPickerProps={{
                                    name: 'toDate',
                                    label: t('dutyWishRegistration.toDate'),
                                    sx: { flex: 1 },
                                    fullWidth: true,
                                    required: true,
                                    disablePast: isRegisteringNew
                                }}
                            />
                        </Grid>

                        {isWideMode && <Grid item xs={0} md={4} />}

                        <Grid item xs={12} md={4}>
                            <HFCheckboxGroup
                                listSx={checkboxGroupStyle}
                                wrapperSx={checkboxGroupWrapperStyle}
                                items={options.daysOfWeek || []}
                                name="requestedDaysOfWeek"
                                title={t('dutyWishRegistration.daysForExtraDuty')}
                                listHeaderBackgroundColor="white"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <HFCheckboxGroup
                                listSx={checkboxGroupStyle}
                                wrapperSx={checkboxGroupWrapperStyle}
                                items={options.dutyTypes || []}
                                name="requestedDutyTypeIds"
                                title={t('dutyWishRegistration.dutyTypesForExtraDuty')}
                                listHeaderBackgroundColor="white"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <HFCheckboxGroup
                                listSx={checkboxGroupStyle}
                                wrapperSx={checkboxGroupWrapperStyle}
                                items={options.departments || []}
                                name="requestedDepartmentIds"
                                title={t('dutyWishRegistration.departmentsForExtraDuty')}
                                listHeaderBackgroundColor="white"
                            />
                        </Grid>

                        <Grid item xs={12} md={4}>
                            <HFTextField
                                name="commentFromEmployee"
                                label={t('dutyWishRegistration.notice')}
                                multiline
                                rows={3}
                                sx={{ width: '100%' }}
                            />
                        </Grid>

                        <Grid item xs={12} md={8}>
                            <HFCheckbox
                                name="isEmployeeAvaliableOnShortNotice"
                                label={t('dutyWishRegistration.canShowUpOnShortNotice')}
                            />
                        </Grid>

                        {formProps.displayErrorMessage && (
                            <Grid item xs={12}>
                                <ErrorMessage
                                    message={getTranslatedOrFallback(
                                        formProps.displayErrorMessage,
                                        'dutyWishRegistration.errors.genericFailedToCreate'
                                    )}
                                />
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ActionButton type="button" variant="outlined" onClick={onCancel}>
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formProps.formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}
DutyWishRegistrationView.defaultProps = {
    sx: undefined
};
