import useErrorScreen from 'hooks/useErrorScreen';
import { useFetchArrayOutsideAuthContext } from 'hooks/useFetchOutsideAuthContext';
import useLoadingScreen from 'hooks/useLoadingScreen';
import CapitechClient from 'models/CapitechClient';
import { createContext, PropsWithChildren, useEffect, useMemo } from 'react';

export type ClientListContextType = {
    clientList: Array<CapitechClient>;
    isLoading: boolean;
    isSingleClient: () => boolean;
    singleClient: CapitechClient | null;
};

export const ClientListContext = createContext<ClientListContextType>(null!);

export function ClientListProvider({ children }: PropsWithChildren) {
    const [clientList, { isLoading, errorMessage }] =
        useFetchArrayOutsideAuthContext<CapitechClient>('getCapitechClients', {
            isGet: true,
            isAnonymous: true
        });
    const { setSourceIsLoading } = useLoadingScreen();
    const { setError } = useErrorScreen();

    const value = useMemo(
        () => ({
            clientList,
            isLoading,
            isSingleClient: () => clientList.length === 1,
            singleClient: clientList.length === 1 ? clientList[0] : null
        }),
        [clientList, isLoading]
    );

    useEffect(() => {
        setSourceIsLoading('ClientListProvider', isLoading);
    }, [isLoading, setSourceIsLoading]);

    useEffect(() => {
        if (errorMessage) {
            setError({ message: 'Unable to fetch clients' });
        }
    }, [errorMessage, setError]);

    // Note: loading is non-blocking for components below
    if (errorMessage) {
        return null;
    }

    return <ClientListContext.Provider value={value}>{children}</ClientListContext.Provider>;
}
