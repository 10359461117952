import RequireAuth from 'router/RequireAuthRoute';
import { Outlet } from 'react-router-dom';
import LoggedInProviders from 'ctx/LoggedInProviders';
import NavBar from 'features/navbar/NavBar';
import { useEffect } from 'react';
import useContentKillSwitch from 'hooks/useContentKillSwitch';
import EmployeeSettingsApplier from './EmployeeSettingsApplier';

export default function LoggedInView() {
    const contentKillSwitch = useContentKillSwitch();

    useEffect(() => {
        if (contentKillSwitch.isOn) {
            contentKillSwitch.turnOff();
        }
    }, [contentKillSwitch]);

    return (
        <RequireAuth>
            <LoggedInProviders>
                <EmployeeSettingsApplier>
                    <NavBar />
                    {contentKillSwitch.isOn ? null : <Outlet />}
                </EmployeeSettingsApplier>
            </LoggedInProviders>
        </RequireAuth>
    );
}
