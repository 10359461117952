import useSize from '@react-hook/size';
import React, { useEffect, useState } from 'react';

/**
 * Helps out in situations where an element content temporarily changes causing it's size to shift,
 * and you'd like to keep the original size. This function keeps track of the elements size
 * when returnMaintainedSize is false, and returns the last known size when returnMaintainedSize
 * is set to true.
 *
 * Used for keepin the size of a button
 *
 * @param target
 * @param returnMaintainedSize
 * @returns
 */
export default function useSizeMaintainer<T extends HTMLElement>(
    target: T | React.RefObject<T> | null,
    returnMaintainedSize: boolean,
    returnAsPx = false
) {
    const [width, height] = useSize(target);

    const [preferredSize, setPreferredSize] = useState<{ width: number; height: number }>({
        width: 0,
        height: 0
    });

    useEffect(() => {
        if (!returnMaintainedSize) {
            setPreferredSize({ width, height });
        }
    }, [width, height, returnMaintainedSize]);

    const shouldReturnAnything =
        returnMaintainedSize && preferredSize.height && preferredSize.width;
    if (shouldReturnAnything) {
        return returnAsPx
            ? {
                  width: `${preferredSize.width}px`,
                  height: `${preferredSize.height}px`
              }
            : preferredSize;
    }
    return undefined;
}
