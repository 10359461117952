import {
    Box,
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
    Typography
} from '@mui/material';
import { useState } from 'react';

export type TextFieldProps = {
    maxLength?: number;
    hideCounter?: boolean;
} & MuiTextFieldProps;

export default function TextField({ hideCounter, helperText, ...props }: TextFieldProps) {
    const valueFromProps = props.value ?? props.defaultValue;
    const [count, setCount] = useState(
        typeof valueFromProps === 'string' ? valueFromProps.length : 0
    );

    const { maxLength } = props;
    const hasMaxLength = typeof maxLength !== 'undefined';
    const breaksMaxLength = hasMaxLength && count > maxLength;

    const counterText = count > 0 ? `${count} / ${maxLength}` : '‎';

    return (
        <MuiTextField
            {...props}
            error={props.error || breaksMaxLength}
            onChange={(event) => {
                setCount(event.target.value.length);
                if (props.onChange) {
                    props.onChange(event);
                }
            }}
            helperText={
                <Box component="span">
                    {!hideCounter && hasMaxLength && (
                        <Typography
                            component="span"
                            fontSize="small"
                            color={breaksMaxLength ? 'error' : 'inherit'}
                            fontWeight={breaksMaxLength ? 'bold' : 'inherit'}
                            sx={{ float: 'right', paddingLeft: '4px' }}
                        >
                            {counterText}
                        </Typography>
                    )}
                    <Typography component="span">{helperText}</Typography>
                </Box>
            }
        />
    );
}

TextField.defaultProps = {
    hideCounter: false,
    maxLength: undefined
};
