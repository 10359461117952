import { postToWebtidApi } from 'utils/http';
import { getJwtCookie, cleanUpAuthCookies, getRefreshTokenCookie } from 'utils/cookie';
import {
    AuthData,
    GetStoredAuthenticationResult,
    SendLogOutRequestAndDeleteCookiesResult
} from './auth.types';
import { convertJwtToAuthData } from './auth.helpers';
import getStoredAccessToken from './getStoredAccessToken';

/**
 * This function is used to get the stored authentication data from the JWT cookie.
 * It will not refresh the JWT cookie if it is expired. This allows it to be synchronous.
 * This is _NOT_ the preferred method of getting the authentication data and
 * unless you have very good reasons to use this method, you should use getStoredAuthentication instead.
 * @returns The parsed content of the JWT cookie
 */
export function getStoredAuthData(): AuthData | undefined {
    const jwt = getJwtCookie();
    if (!jwt) {
        return undefined;
    }

    return convertJwtToAuthData(jwt);
}

/**
 * @returns The parsed content of the JWT cookie
 */
export async function getStoredAuthentication(): GetStoredAuthenticationResult {
    const jwt = await getStoredAccessToken();
    if (!jwt) {
        return undefined;
    }

    return convertJwtToAuthData(jwt);
}

export async function sendLogOutRequestAndDeleteCookies(): SendLogOutRequestAndDeleteCookiesResult {
    const authData = await getStoredAuthentication();
    if (authData) {
        await postToWebtidApi(
            'signOut',
            {
                refreshToken: authData.refreshToken ?? getRefreshTokenCookie()
            },
            {
                onAuthError: () => {} // We deliberately don't care for errors in this case. User is logged out in any case.
            }
        );
    }
    cleanUpAuthCookies();
}
