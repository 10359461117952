import { deleteCookie, getCookie, setCookie } from '../cookie';
import { JWT_COOKIE_NAME, REFRESH_TOKEN_COOKIE_NAME } from '../cookie.constants';
import {
    CleanUpAuthCookiesResult,
    DeleteJwtCookieResult,
    DeleteRefreshTokenCookieResult,
    GetJwtCookieResult,
    GetRefreshTokenCookieResult,
    SetJwtCookieResult,
    SetRefreshTokenCookieResult
} from './authCookie.types';

export function setJwtCookie(payload: string, expiry: Date): SetJwtCookieResult {
    setCookie(JWT_COOKIE_NAME, payload, expiry);
}

export function deleteJwtCookie(): DeleteJwtCookieResult {
    deleteCookie(JWT_COOKIE_NAME);
}

export function getJwtCookie(): GetJwtCookieResult {
    return getCookie(JWT_COOKIE_NAME);
}

export function setRefreshTokenCookie(payload: string, expiry: Date): SetRefreshTokenCookieResult {
    setCookie(REFRESH_TOKEN_COOKIE_NAME, payload, expiry);
}

export function deleteRefreshTokenCookie(): DeleteRefreshTokenCookieResult {
    deleteCookie(REFRESH_TOKEN_COOKIE_NAME);
}

export function getRefreshTokenCookie(): GetRefreshTokenCookieResult {
    return getCookie(REFRESH_TOKEN_COOKIE_NAME);
}

export function cleanUpAuthCookies(): CleanUpAuthCookiesResult {
    deleteJwtCookie();
    deleteRefreshTokenCookie();
}
