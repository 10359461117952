import { Container } from '@mui/material';
import ProfileFooter from './ProfileFooter';
import ProfileTabs from './ProfileTabs';

export default function ProfilePage() {
    // For now it's just the main profile content that's available. Later on there'll be tabs.
    return (
        <Container
            component="main"
            maxWidth="md"
            sx={{ mt: 4, mb: 4, flexGrow: 1, overflow: 'auto' }}
        >
            <ProfileTabs />
            <ProfileFooter />
        </Container>
    );
}
