import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { IconButton, Stack, Typography } from '@mui/material';
import { PickersCalendarHeaderProps } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';

interface CustomHeaderProps extends PickersCalendarHeaderProps<Dayjs> {
    onMonthClick: VoidFunction;
}

export default function CustomHeader({
    onMonthClick,
    currentMonth,
    onMonthChange,
    labelId
}: CustomHeaderProps) {
    const { t } = useTranslation();

    const previousMonth = currentMonth.subtract(1, 'month');
    const nextMonth = currentMonth.add(1, 'month');

    return (
        <Stack direction="row" sx={{ justifyContent: 'space-between', alignItems: 'center' }}>
            <IconButton
                aria-label={t('calendar.previousMonth')}
                onClick={() => onMonthChange(previousMonth, 'left')}
            >
                <KeyboardArrowLeft fontSize="medium" />
            </IconButton>

            <Typography
                id={labelId}
                component="h1"
                variant="h5"
                sx={{ textTransform: 'uppercase', cursor: 'pointer' }}
                onClick={onMonthClick}
            >
                {currentMonth.format('MMMM YYYY')}
            </Typography>

            <IconButton
                aria-label={t('calendar.nextMonth')}
                onClick={() => onMonthChange(nextMonth, 'right')}
            >
                <KeyboardArrowRight fontSize="medium" />
            </IconButton>
        </Stack>
    );
}
