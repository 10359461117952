import { capitechDataVisuals } from 'features/dashboard/util';
import { MoreTime } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import TransactionCard from './TransactionCard';
import { TransactionCardProps } from './TransactionCard.types';
import { DetailCardButton } from './buttons';

export default function PlannedDutyDetailCard({
    title,
    description,
    onQuickAddClick,
    isSubmitting,
    hasQuickAdd
}: TransactionCardProps & {
    onQuickAddClick: VoidFunction;
    hasQuickAdd: boolean;
    isSubmitting: boolean;
}) {
    const { t } = useTranslation();
    const { icon: Icon } = capitechDataVisuals.dutyPlanned;
    return (
        <TransactionCard
            sx={{ bgcolor: 'duty.main' }}
            icon={<Icon />}
            title={title}
            description={description}
            actions={
                hasQuickAdd && (
                    <DetailCardButton
                        onClick={onQuickAddClick}
                        label={t('actions.quickAdd')}
                        icon={<MoreTime />}
                        isloading={isSubmitting}
                    />
                )
            }
        />
    );
}
