import {
    TableContainer,
    Paper,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TableProps
} from '@mui/material';
import ErrorMessage from 'components/error/ErrorMessage';
import useSizeMaintainer from 'hooks/useSizeMaintainer';
import { lowerFirst } from 'lodash';
import AccumulatedTimeResult from 'models/AccumulatedTime';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

type AccumulatedTimeSummaryTableViewProps = {
    accumulatedTimeLines: Array<AccumulatedTimeResult>;
    isLoading: boolean;
    label: string;
    errorMessage: string;
    typeLabel?: string;
    sx?: TableProps['sx'];
};

export default function AccumulatedTimeSummaryTableView({
    accumulatedTimeLines,
    isLoading,
    label,
    errorMessage,
    typeLabel,
    sx
}: AccumulatedTimeSummaryTableViewProps) {
    const { t } = useTranslation();

    const accuTimeTableRef = useRef(null);
    const sizeAccuTimeCardIfLoading = useSizeMaintainer(accuTimeTableRef, isLoading, true);
    const sxToUse = { ...sx, ...sizeAccuTimeCardIfLoading };

    return (
        <>
            {errorMessage !== '' ? <ErrorMessage message={errorMessage} /> : null}
            <TableContainer component={Paper} ref={accuTimeTableRef} sx={sxToUse}>
                <Table
                    size="small"
                    aria-label={t('summaryTable.labelForSomething', {
                        something: lowerFirst(label)
                    })}
                >
                    <TableHead>
                        <TableRow>
                            <TableCell>{typeLabel || t('summaryTable.type')}</TableCell>
                            <TableCell align="right">{t('summaryTable.quantity')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!isLoading &&
                            accumulatedTimeLines.map((row) => (
                                <TableRow
                                    key={row.timeCategoryId}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {row.timeCategoryDescription}
                                    </TableCell>
                                    <TableCell align="right">{row.amount}</TableCell>
                                </TableRow>
                            ))}
                        {!isLoading && accumulatedTimeLines.length === 0 && (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    {t('summaryTable.noRows')}
                                </TableCell>
                                <TableCell align="right" />
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

AccumulatedTimeSummaryTableView.defaultProps = {
    typeLabel: undefined,
    sx: undefined
};
