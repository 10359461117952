import useNativeInput from 'hooks/useNativeInput';
import { omit } from 'lodash';
import React from 'react';
import Select from './Select';
import SelectWithSearch, { SelectWithSearchProps } from './SelectWithSearch';
import { SelectProps } from './Select/Select.types';

export const MAX_OPTION_COUNT_BEFORE_SEARCH = 40;

export type DropdownType = 'select' | 'select-with-search';
export type DropdownProps = { disableSearch?: boolean } & (SelectProps & SelectWithSearchProps);

// TODO: Remove the commented block below before TIMEPLAN-1289 is merged
/*
Doesn't quite work, yet. Only "respects" properties by shared interfaces when disableSearch varies.
*/
/*
interface DropdownBaseProps {
    disableSearch?: boolean;
}

interface DropdownSelectProps extends DropdownBaseProps, SelectProps {
    disableSearch: true;
}

interface DropdownIndeterminateProps extends DropdownBaseProps, SelectProps, SelectWithSearchProps {
    disableSearch?: false;
}
export type DropdownProps = DropdownSelectProps | DropdownIndeterminateProps;
*/

function getDropdownType(
    { options, disableSearch }: DropdownProps,
    isPreferredNativeByDevice: boolean
): DropdownType {
    if (disableSearch) return 'select';

    const optionCount = options?.length || 0;
    if (isPreferredNativeByDevice && optionCount <= MAX_OPTION_COUNT_BEFORE_SEARCH) {
        return 'select';
    }

    return 'select-with-search';
}

const Dropdown = React.forwardRef<HTMLDivElement, DropdownProps>((props: DropdownProps, ref) => {
    const { preferNativeSelect: isPreferredNativeByDevice } = useNativeInput();

    const variantToUse = getDropdownType(props, isPreferredNativeByDevice);
    const dropdownTypeSpecificProps = omit(props, 'disableSearch');

    if (variantToUse === 'select') {
        const selectProps = dropdownTypeSpecificProps as SelectProps;
        return <Select ref={ref} {...selectProps} isNativeSelect={isPreferredNativeByDevice} />;
    }

    const selectWithAutocompleteProps = dropdownTypeSpecificProps as SelectWithSearchProps;
    return <SelectWithSearch {...selectWithAutocompleteProps} />;
});

Dropdown.defaultProps = {
    disableSearch: false
};

export default Dropdown;
