/*
 * Popover functionality is a combination of https://mui.com/material-ui/react-autocomplete/#githubs-picker
 * and https://mui.com/material-ui/react-autocomplete/#virtualization
 */
import { Autocomplete, AutocompleteCloseReason, Box, InputBase, styled } from '@mui/material';
import useNativeInput from 'hooks/useNativeInput';
import React, { ReactNode, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { InputOption } from '../../InputOption';
import SelectWithSearchAutocompleteListbox, {
    OptionRowData
} from './SelectWithSearchAutocompleteListbox';
import SelectWithSearchAutocompletePopper from './SelectWithSearchAutocompletePopper';

const StyledInput = styled(InputBase)(({ theme }) => ({
    padding: 10,
    width: '100%',
    borderBottom: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
    '& input': {
        borderRadius: 4,
        backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#0d1117',
        padding: 8,
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        border: `1px solid ${theme.palette.mode === 'light' ? '#eaecef' : '#30363d'}`,
        '&:focus': {
            boxShadow: `0px 0px 0px 3px ${
                theme.palette.mode === 'light' ? 'rgba(3, 102, 214, 0.3)' : 'rgb(12, 45, 107)'
            }`,
            borderColor: theme.palette.mode === 'light' ? '#0366d6' : '#388bfd'
        }
    }
}));

interface SelectWithSearchAutocompleteProps {
    onClose: () => void;
    onSelectOption: (option: InputOption | null) => void;
    options: Array<InputOption>;
    selectedOption?: InputOption | null;
}

const SelectWithSearchAutocomplete = React.forwardRef<
    HTMLDivElement,
    SelectWithSearchAutocompleteProps
>(
    (
        { onClose, onSelectOption, options, selectedOption }: SelectWithSearchAutocompleteProps,
        ref
    ) => {
        const { t } = useTranslation();

        const { preferNativeSelect } = useNativeInput();
        const shouldAutoFocus = !preferNativeSelect;

        /* Autofocus hack. TODO:: Look into why focus is immediately lost to begin with. */
        const inputWrapperRef = useRef<HTMLDivElement>(null);
        useEffect(() => {
            if (!shouldAutoFocus) return;

            const inputElement = inputWrapperRef.current?.querySelector('input');
            if (!inputElement) return;
            inputElement.focus();
        }, [shouldAutoFocus]);

        return (
            <Autocomplete
                ref={ref}
                open
                disableListWrap
                autoHighlight
                freeSolo
                onClose={(event: React.ChangeEvent<{}>, reason: AutocompleteCloseReason) => {
                    if (reason === 'escape') {
                        onClose();
                    }
                }}
                onChange={(event, newOptionSelected, reason) => {
                    if (
                        event.type === 'keydown' &&
                        (event as React.KeyboardEvent).key === 'Backspace' &&
                        reason === 'removeOption'
                    ) {
                        return;
                    }

                    if (typeof newOptionSelected === 'object' && newOptionSelected?.isSelectable) {
                        onSelectOption(newOptionSelected);
                    } else {
                        onClose();
                    }
                }}
                onKeyDown={(evt) => {
                    if (evt.key === 'Tab') {
                        onClose();
                    }
                }}
                PopperComponent={SelectWithSearchAutocompletePopper}
                ListboxComponent={SelectWithSearchAutocompleteListbox}
                renderTags={() => null}
                noOptionsText={t('noOptions')}
                getOptionDisabled={(option) => !option.isSelectable}
                renderOption={(props, option, state) => {
                    const optionRowData: OptionRowData = {
                        liElementAttributes: props,
                        option: option as InputOption,
                        renderOptionState: state,
                        isSelected: Boolean(selectedOption && selectedOption.value === option.value)
                    };

                    /*
                     * What follows builds further on https://mui.com/material-ui/react-autocomplete/#virtualization
                     * No need for unknown if data is returned as an array instead, but our intent is to pass data down
                     * to virtualization plugin anyways.
                     */
                    return optionRowData as unknown as ReactNode;
                }}
                options={options}
                renderInput={(params) => (
                    <Box ref={inputWrapperRef}>
                        <StyledInput
                            ref={params.InputProps.ref}
                            inputProps={params.inputProps}
                            autoFocus={shouldAutoFocus}
                            placeholder={t('actions.typeToSearch')}
                        />
                    </Box>
                )}
            />
        );
    }
);
SelectWithSearchAutocomplete.defaultProps = {
    selectedOption: null
};

export default SelectWithSearchAutocomplete;
