import { Box, CircularProgress, Fade } from '@mui/material';

// Component meant to be used as a progress indicator in a dialog
// when the dialog is loading data from the server and you
// want to display a simple progress spinner on top of the backdrop
// without the dialog shadow and background.
export default function DialogSpinner() {
    return (
        <Box
            aria-busy
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                display: 'grid',
                justifyContent: 'center',
                alignItems: 'center',
                opacity: 0.25
            }}
        >
            <Fade in timeout={2000} style={{ transitionDelay: '300ms' }}>
                <CircularProgress sx={{ color: 'white' }} />
            </Fade>
        </Box>
    );
}
