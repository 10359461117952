import { useAbsenceRequestsAffectingDate } from 'features/absence';
import { useDutiesOnDateInSetDateRange } from 'features/plan';
import { dateToIsoString } from 'utils/datetime';
import { useCapitechDataVisibility } from 'features/dashboard/providers';
import { CapitechDataName, capitechDataNames } from 'features/dashboard/util/capitechData';
import { partition, pick } from 'lodash';
import { UseHiddenCapitechDataItemsResult } from './useHiddenCapitechDataItems.types';
import useAbsenceTransactions from '../useAbsenceTransactions';
import useTimeTransactionsForDate from '../useTimeTransactionsForDate';

export default function useHiddenCapitechDataItemsInSelectedTimePeriod(
    date: Date
): UseHiddenCapitechDataItemsResult {
    const dateString = dateToIsoString(date);

    const timeTransactions = useTimeTransactionsForDate(date, true);
    const [completedTimeTransactions, incompleteTimeTransactions] = partition(
        timeTransactions,
        (timeTransaction) => timeTransaction.timeIn && timeTransaction.timeOut
    );

    const absenceTransactions = useAbsenceTransactions().absenceTransactions.filter(
        (absenceTransaction) =>
            absenceTransaction.dateFrom <= dateString && absenceTransaction.dateTo >= dateString
    );
    const absenceRequestsOnDate = useAbsenceRequestsAffectingDate(dateString);
    const duties = useDutiesOnDateInSetDateRange(date);

    const dataVisibility = useCapitechDataVisibility();
    const isHiddenData = (dataName: CapitechDataName) => !dataVisibility.isVisibleData(dataName);

    const hiddenItemsByDataName = {
        holiday: undefined,
        time: isHiddenData('time') ? completedTimeTransactions : undefined,
        timeIncomplete: isHiddenData('timeIncomplete') ? incompleteTimeTransactions : undefined,
        absence: isHiddenData('absence') ? absenceTransactions : undefined,
        absenceRequest: isHiddenData('absenceRequest')
            ? absenceRequestsOnDate.absenceRequests
            : undefined,
        dutyPlanned: isHiddenData('dutyPlanned') ? duties.planned.items : undefined,
        dutyAvailable: isHiddenData('dutyAvailable') ? duties.available.items : undefined,
        dutyRequested: isHiddenData('dutyRequested') ? duties.requested.items : undefined,
        dutyWish: isHiddenData('dutyWish') ? duties.wish.items : undefined,
        dutyWishPeriod: isHiddenData('dutyWishPeriod') ? duties.wishPeriods.items : undefined
    };

    // Trim data set omitting keys where undefined. Also return undefined if there are no hidden items at all.
    const dataNamesWithHiddenItems = capitechDataNames.filter((key) => {
        const setToKey = hiddenItemsByDataName[key];
        return Array.isArray(setToKey) ? setToKey.length > 0 : false;
    });
    const trimmedDataSet = pick(hiddenItemsByDataName, dataNamesWithHiddenItems);
    return Object.keys(trimmedDataSet).length > 0 ? trimmedDataSet : undefined;
}
