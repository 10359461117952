/**
 * A note on accessibility (a11y) with VoiceOver users particuallary in mind (2022-12-23)
 *
 * - day name read from column header
 * - date (with month) read from label of date cell
 * - event(s) description read from content when provided as description to date cell (aria-describedby)
 *     `- minimal mode? the dots are images with a label summarizing event(s)
 *     `- normal mode? the visible text from the different labels is the content. no tricks.
 *
 */

import { Box, Typography } from '@mui/material';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { isFirstDayOfMonth, isLastDayOfMonth, isWeekendDay } from 'utils/datetime';
import { DateEventType } from './DateEvent';
import { DateEventSet } from './DateEventGroupWrapper';
import DateEventSetSlice from './DateEventSetSlice';

function extractFirstMatchingHolidayEvent(dateEventSet: DateEventSet): DateEventType | undefined {
    const holidayEventOrGroupPartOf = dateEventSet.find((eventGroupOrSingleEvent) =>
        'items' in eventGroupOrSingleEvent
            ? eventGroupOrSingleEvent.items.find((dateEvent) => dateEvent.isHoliday)
            : eventGroupOrSingleEvent.isHoliday
    );

    if (!holidayEventOrGroupPartOf) {
        return undefined;
    }

    return 'items' in holidayEventOrGroupPartOf
        ? (holidayEventOrGroupPartOf.items.find(
              (dateEvent) => dateEvent.isHoliday
          ) as DateEventType)
        : holidayEventOrGroupPartOf;
}

export default function DateWithEvents({
    day,
    dateEventSet,
    monthFocused,
    ...rest
}: PickersDayProps<dayjs.Dayjs> & {
    day: dayjs.Dayjs;
    monthFocused: Date;
    dateEventSet: DateEventSet;
}) {
    const date = day.toDate();
    const isLastDayOfOtherMonth =
        isLastDayOfMonth(date) && date.getMonth() !== monthFocused.getMonth();

    const dayId = `events-${day.format('YY-MM-DD')}`;

    const contentId = `event-wrapper-${dayId}`;

    // If any of the events are holidays, we want to color the day
    const holidayEventFromSet = extractFirstMatchingHolidayEvent(dateEventSet);
    const dateNumberColor = holidayEventFromSet ? `${holidayEventFromSet.color}.main` : 'inherit';

    return (
        <PickersDay
            day={day}
            {...rest}
            sx={{
                ...rest.sx,
                '& .date-container': { color: dateNumberColor },
                '&.Mui-selected .date-container': { color: 'inherit' }
            }}
            aria-label={day.format('D. MMMM')}
            aria-describedby={contentId}
            className={isWeekendDay(date) ? 'week-end-day' : ''}
        >
            <Typography className="date-container" aria-hidden="true">
                {day.format(isFirstDayOfMonth(date) || isLastDayOfOtherMonth ? 'D. MMM' : 'D')}
            </Typography>

            <Box className="date-events-container" id={contentId} aria-hidden="true">
                {dateEventSet.map((eventGroupOrSingleEvent, index) => (
                    <DateEventSetSlice
                        // eslint-disable-next-line react/no-array-index-key
                        key={`date-event-set-${index}`}
                        dateEventSetSlice={eventGroupOrSingleEvent}
                    />
                ))}
            </Box>
        </PickersDay>
    );

    // ActivityGroup
}
