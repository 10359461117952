import { useContext, useMemo } from 'react';
import { UseAbsenceRequestsStartingOnOrAfterDateResult } from './useAbsenceRequestsStartingOnOrAfterDate.types';
import { AbsenceRequestsContext } from '../../AbsenceRequestsProvider';

export default function useAbsenceRequestsStartingOnOrAfterDate(
    minDateInIsoFormat: string
): UseAbsenceRequestsStartingOnOrAfterDateResult {
    const ctx = useContext(AbsenceRequestsContext);
    if (!ctx) {
        throw new Error('useAbsenceRequests must be used within an AbsenceRequestsProvider');
    }

    const { absenceRequests, isLoading, errorMessage, refreshAbsenceRequests } = ctx;

    const selectedAbsenceRequests = useMemo(
        () =>
            absenceRequests.filter(
                (absenceRequest) => absenceRequest.fromDate >= minDateInIsoFormat
            ),
        [minDateInIsoFormat, absenceRequests]
    );

    return {
        absenceRequests: selectedAbsenceRequests,
        isLoading,
        errorMessage,
        refreshAbsenceRequests
    };
}
