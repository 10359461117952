import { MenuItem, TextField } from '@mui/material';
import { isEmpty, noop } from 'lodash';
import React, { createElement } from 'react';
import { NUMBER_FOR_EMPTY } from 'utils/input';
import useOutputOptions from './useOutputOptions';
import { SelectProps } from './Select.types';

export const DefaultProps = {
    errorMessage: '',
    nullLabel: undefined,
    options: [],
    isNativeSelect: false,
    readOnly: false,
    onChange: noop
};

const Select = React.forwardRef<HTMLDivElement, SelectProps>(
    (
        {
            isNativeSelect,
            options,
            isLoadingOptions,
            enterKeyHint,
            nullLabel,
            errorMessage = '',
            readOnly,
            onChange,
            defaultValue: passedDefaultValue,
            disabled,
            ...textFieldProps
        }: SelectProps,
        ref
    ) => {
        const outputOptions = useOutputOptions({
            isNativeSelect,
            isOptional: !textFieldProps.required,
            value: textFieldProps.value,
            options,
            isLoadingOptions,
            nullLabel,
            textIfEmpty: textFieldProps.label || ''
        });

        const ChildComponent = isNativeSelect ? 'option' : MenuItem;
        const { name } = textFieldProps;

        const passedValue = textFieldProps.value === NUMBER_FOR_EMPTY ? '' : textFieldProps.value; // Lets us have comprehensive input validation for numeric fields without screams of non-existent element in list.
        const value = isNativeSelect && typeof passedValue === 'undefined' ? '' : passedValue; // A clear option is provided as non-selectable if native select is set as required, which makes the select appear like the customm select. Value needs to be set as '' for this option to be selected. Else the first option will be preselected.

        // Mui would like us to use defaultValue for uncontrolled components to have some kind of element selected
        let defaultValue: SelectProps['defaultValue'];
        if (typeof passedDefaultValue !== 'undefined') {
            defaultValue = passedDefaultValue;
        } else if (typeof value === 'undefined') {
            defaultValue = outputOptions[0]?.value; // The component is uncontrolled. Starts out with first option selected, typically blank value.
        }

        const isVerifiedMissingOptions = (!options || options.length === 0) && !isLoadingOptions;

        return (
            <TextField
                sx={{
                    '& .MuiSelect-outlined.Mui-disabled': {
                        cursor: 'not-allowed'
                    }
                }}
                ref={ref}
                select
                {...textFieldProps}
                defaultValue={defaultValue}
                value={value}
                error={!isEmpty(errorMessage)}
                helperText={errorMessage}
                InputProps={{
                    ...textFieldProps.InputProps,
                    readOnly
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                inputProps={{
                    ...textFieldProps.inputProps,
                    enterKeyHint
                }}
                SelectProps={{
                    native: isNativeSelect,
                    displayEmpty: isVerifiedMissingOptions
                }}
                onChange={(evt) => {
                    if (onChange) {
                        onChange(evt.target.value);
                    }
                }}
                InputLabelProps={{ shrink: isVerifiedMissingOptions || Boolean(value) }}
                disabled={isLoadingOptions || disabled}
            >
                {outputOptions &&
                    outputOptions.map((item) =>
                        createElement(
                            ChildComponent,
                            {
                                key: `${name}_${item.value}`,
                                value: item.value,
                                disabled: !item.isSelectable
                            },
                            item.value === '' && !isNativeSelect ? (
                                <em>{item.label}</em>
                            ) : (
                                item.label
                            )
                        )
                    )}
            </TextField>
        );
    }
);
Select.defaultProps = DefaultProps;

export default Select;
