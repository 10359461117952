import { DimensionInputName, DimensionName } from 'models/TimeRegistrationModels';
import { useCallback, useState, useMemo, useEffect } from 'react';
import {
    DimensionValuesByDimension,
    getDimensionNameAsInputNameForHourRegistration,
    getDimensionNamesAffectedByDimensionValueChanges
} from 'utils/dimension';
import { useDimensionOptionsSet } from 'features/misc/dimensionInput';
import { keyByAndFill } from 'utils/object';
import usePrevious from 'utils/usePrevious';
import { isEmpty } from 'lodash';
import useDimensionInputRules from '../hooks/useDimensionInputRules';
import useHourRegistrationForm from '../hooks/useHourRegistrationForm';
import HourRegistrationView from './HourRegistrationView';
import { pickDimensionValuesFromDefaultValues } from './HourRegistration.helpers';
import useTimeRegistrationAccess from '../../hooks/useTimeRegistrationAccess';
import { HourRegistrationProps } from './HourRegistration.types';

export default function HourRegistration({
    date,
    onSuccess,
    transaction,
    onCancel,
    initErrorMessage: errorMessageFromProps,
    isCheckingIn,
    prefilledDefaultValues
}: HourRegistrationProps) {
    const isEditMode = Boolean(transaction);
    const { dimensionInputRules } = useDimensionInputRules(date);

    const { defaultValues, ...formProps } = useHourRegistrationForm(
        date,
        isCheckingIn,
        dimensionInputRules,
        transaction,
        onSuccess,
        prefilledDefaultValues,
        errorMessageFromProps ? 'onChange' : undefined
    );

    const initDimensionValuesByDimensionName = useMemo(() => {
        const dimensionValuesFromDefault = pickDimensionValuesFromDefaultValues(defaultValues);
        return dimensionValuesFromDefault;
    }, [defaultValues]);

    const [selectedDimensionValues, setSelectedDimensionValues] =
        useState<DimensionValuesByDimension>(initDimensionValuesByDimensionName);
    const { dimensionOptionsSet, isDescriptionsInitialized, dimensionNamesLoading } =
        useDimensionOptionsSet(
            dimensionInputRules,
            selectedDimensionValues,
            initDimensionValuesByDimensionName
        );

    // Makes init values available when fetching data. Also supports further dimensions arriving while the form is open when refreshing employee registration information.
    // TODO:: Remove need for this when selected dimension values are decided by watched values
    useEffect(() => {
        const initDimensionNamesPresent = Object.keys(initDimensionValuesByDimensionName);
        const selectedDimensionNamesPresent = Object.keys(selectedDimensionValues);

        const newDimensionNamesIfAny = initDimensionNamesPresent.filter(
            (dimensionName) => !selectedDimensionNamesPresent.includes(dimensionName)
        );

        if (newDimensionNamesIfAny.length) {
            setSelectedDimensionValues({
                ...initDimensionValuesByDimensionName,
                ...selectedDimensionValues
            });
        }
    }, [initDimensionValuesByDimensionName, selectedDimensionValues, setSelectedDimensionValues]);

    // TODO:: Watch certain dimension inputs, and update current dimension values accordingly instead of passing onChange. That way we get a binding directly onto the form instead.

    // Whenever selectedDimensions get called
    const onDimensionChange = useCallback(
        (dimensionName: DimensionName, value: string) => {
            if (!dimensionInputRules) return;

            const newDimensionValuesIgnoringSubdependencies = {
                ...selectedDimensionValues,
                [dimensionName]: value
            };

            const dimensionNamesToClear = getDimensionNamesAffectedByDimensionValueChanges(
                selectedDimensionValues,
                newDimensionValuesIgnoringSubdependencies
            );

            if (dimensionNamesToClear.length) {
                // We clear values by setting them as blank to detect if suddenly any new dimension appears in an updated employee registration information set
                const dimensionSetWithClearedValues = keyByAndFill(dimensionNamesToClear, '');

                setSelectedDimensionValues({
                    ...selectedDimensionValues,
                    ...dimensionSetWithClearedValues,
                    [dimensionName]: value
                });
            } else {
                setSelectedDimensionValues(newDimensionValuesIgnoringSubdependencies);
            }
        },
        [selectedDimensionValues, setSelectedDimensionValues, dimensionInputRules]
    );

    // Catch dimension changes
    const { watch } = formProps;
    useEffect(() => {
        // We need input names to easily find it's name based on input name
        const dimensionNamesInForm = (dimensionInputRules || [])?.map(
            (dimensionInputRule) => dimensionInputRule.name
        );

        // Input names we'd like to watch
        const dimensionInputNamesInForm = dimensionNamesInForm.map((dimensionName) =>
            getDimensionNameAsInputNameForHourRegistration(
                dimensionName,
                isEditMode ? 'update' : 'create'
            )
        );

        // We're listening for all kinds of input
        const subscription = watch((data, { name }) => {
            const nameAsDimensionInputName = name as DimensionInputName;
            if (dimensionInputNamesInForm.includes(nameAsDimensionInputName)) {
                const newValue = data[nameAsDimensionInputName];
                const dimensionName =
                    dimensionNamesInForm[
                        dimensionInputNamesInForm.indexOf(nameAsDimensionInputName)
                    ];
                onDimensionChange(dimensionName, newValue);
            }
        });
        return () => subscription.unsubscribe();
    }, [watch, dimensionInputRules, isEditMode, onDimensionChange]);

    const { isTimeInEditable, isTimeOutEditable } = useTimeRegistrationAccess(date);

    /**
     * Hour registration form can be initialized with a predefined error message. This message
     * is often related to validation erorrs. So often, that we'd like to ignore the message
     * if the form starts out with validation errors.
     *
     * As soon as the user interacts with the form, any display of predefined error message
     * should still be be based on if there were any errors at the start, not validation errors
     * later on. This is all covered by how isProvidedErrorNotCoveredByValidation is set.
     */
    const [isProvidedErrorNotCoveredByValidation, setIsProvidedErrorNotCoveredByValidation] =
        useState(false);
    const [hasRunInitValidation, setHasRunInitValidation] = useState(false);
    const previousHasRunInitValidation = usePrevious(hasRunInitValidation);
    useEffect(() => {
        const hasRunInitValidationJustNow = hasRunInitValidation && !previousHasRunInitValidation;
        if (
            hasRunInitValidationJustNow && // So, this will only be allowed _once_ at startup to ensure validation errors later on won't mess up isProvidedErrorNotCoveredByValidation
            errorMessageFromProps?.length &&
            isEmpty(formProps.formState.errors)
        ) {
            setIsProvidedErrorNotCoveredByValidation(true);
        }
    }, [
        errorMessageFromProps,
        formProps.formState.errors,
        hasRunInitValidation,
        previousHasRunInitValidation
    ]);

    // Display error message from props -if any- until first submit
    if (
        isProvidedErrorNotCoveredByValidation &&
        !formProps.formState.submitCount &&
        !formProps.displayErrorMessage
    ) {
        formProps.displayErrorMessage = errorMessageFromProps || null;
    }

    // Run validation ONCE if errorMessage is set after descriptions are loaded. Will typically highlight missing dimension values after failed check in.
    const previousIsDescriptionsInitialized = usePrevious(isDescriptionsInitialized);
    const { trigger: validateForm } = formProps;
    useEffect(() => {
        if (
            isDescriptionsInitialized &&
            !previousIsDescriptionsInitialized &&
            errorMessageFromProps
        ) {
            validateForm();
            setHasRunInitValidation(true);
        }
    }, [
        isDescriptionsInitialized,
        previousIsDescriptionsInitialized,
        errorMessageFromProps,
        validateForm
    ]);

    return (
        <HourRegistrationView
            date={date}
            isLoading={!isDescriptionsInitialized}
            onCancel={onCancel}
            formProps={formProps}
            dimensionInputRules={useMemo(() => dimensionInputRules || [], [dimensionInputRules])}
            dimensionOptionsSet={dimensionOptionsSet}
            dimensionNamesLoading={dimensionNamesLoading}
            isEditing={Boolean(transaction)}
            initDimensionValuesByDimensionName={initDimensionValuesByDimensionName}
            isTimeInEditable={isTimeInEditable || false}
            isTimeOutEditable={isTimeOutEditable || false}
        />
    );
}

HourRegistration.defaultProps = {
    transaction: undefined,
    initErrorMessage: undefined,
    prefilledDefaultValues: undefined
};
