import { createTheme, ThemeOptions } from '@mui/material/styles';
import { amber, blue, grey, lightGreen, red } from '@mui/material/colors';

declare module '@mui/material/styles/createPalette' {
    export interface PaletteOptions {
        hours: PaletteColorOptions;
        absence: PaletteColorOptions;
        duty: PaletteColorOptions;
        dutyRequest: PaletteColorOptions; // covers both dutyRequested and dutyWish
        shift: PaletteColorOptions;
        holiday: PaletteColorOptions;
        messageOutgoing: PaletteColorOptions;
        inactive: PaletteColorOptions;
    }
}

declare module '@mui/material/Button' {
    interface ButtonPropsColorOverrides {
        hours: true;
        absence: true;
        duty: true;
        dutyRequest: true;
        shift: true;
        inactive: true;
    }
}

declare module '@mui/material/SvgIcon' {
    interface SvgIconPropsColorOverrides {
        hours: true;
        absence: true;
        duty: true;
        dutyRequest: true;
        shift: true;
        holiday: true;
        inactive: true;
    }
}

const { palette } = createTheme();
const { augmentColor } = palette;
const createColor = (mainColor: string) => augmentColor({ color: { main: mainColor } });

const themeOptions: ThemeOptions = {
    typography: {
        fontFamily: '"GT-Walsheim-Regular"'
    },
    components: {
        MuiSkeleton: { defaultProps: { animation: 'wave' } },
        MuiUseMediaQuery: {
            defaultProps: {
                noSsr: true
            }
        },
        MuiButtonBase: {
            defaultProps: {
                disableRipple: true
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'contained',
                disableElevation: true
            },
            styleOverrides: {
                root: {
                    borderRadius: 0
                }
            }
        },
        MuiPaper: {
            defaultProps: {
                elevation: 0
            },
            styleOverrides: {
                root: {
                    backgroundColor: grey[100]
                },
                rounded: {
                    backgroundColor: grey[100]
                }
            }
        },
        MuiCardActions: {
            styleOverrides: {
                root: {
                    paddingTop: '12px',
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    paddingBottom: '12px'
                }
            }
        },
        MuiDialogActions: {
            styleOverrides: {
                root: {
                    paddingTop: '0',
                    paddingLeft: '24px',
                    paddingRight: '24px',
                    paddingBottom: '20px'
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff'
                }
            }
        }
    },
    palette: {
        mode: 'light',
        contrastThreshold: 4.5,
        background: {
            default: '#fff',
            paper: grey[100]
        },
        primary: {
            main: grey[900]
        },
        secondary: {
            main: grey[900]
        },
        hours: createColor(amber[300]),
        absence: createColor(red[200]),
        duty: createColor(blue[100]),
        dutyRequest: createColor(lightGreen[200]), // covers both dutyRequested and dutyWish
        shift: createColor(lightGreen[200]),
        holiday: createColor(red[700]),
        messageOutgoing: {
            main: '#007aff',
            contrastText: '#fff'
        },
        inactive: createColor(grey[300])
    }
};

export default themeOptions;
