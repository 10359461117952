import { lowerFirst } from 'lodash';

export function chopPrefix(textToChop: string, prefix: string, lowerFirstOnResult?: boolean) {
    if (!textToChop.toLowerCase().startsWith(prefix.toLowerCase())) return textToChop;

    const chopped = textToChop.substring(prefix.length);

    return lowerFirstOnResult ? lowerFirst(chopped) : chopped;
}

export function chopSuffix(textToChop: string, suffix: string) {
    if (!textToChop.toLowerCase().endsWith(suffix.toLowerCase())) return textToChop;
    const chopped = textToChop.substring(0, textToChop.length - suffix.length);
    return chopped;
}
