export const supportedLanguages = ['en', 'no'] as const;

export type SupportedLanguage = typeof supportedLanguages[number];
export const DEFAULT_LANGUAGE: SupportedLanguage = 'no';
export const DEFAULT_LOCALE_CODE = 'nb-NO';

export function isSupportedLanguage(language: string): language is SupportedLanguage {
    const languageAssumedSupported = language as SupportedLanguage;
    return supportedLanguages.includes(languageAssumedSupported);
}

export function getUseableLanguageFromLanguageString(language: string) {
    let languageToTry;

    const lcLanguage = language.toLocaleLowerCase();
    if (lcLanguage.endsWith('-no') || lcLanguage === 'nb' || lcLanguage === 'nn') {
        languageToTry = 'no';
    } else if (language.startsWith('en-')) {
        languageToTry = 'en';
    } else {
        languageToTry = language;
    }

    const useableLanguage: SupportedLanguage = isSupportedLanguage(languageToTry)
        ? languageToTry
        : DEFAULT_LANGUAGE;

    return useableLanguage;
}
