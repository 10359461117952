import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import useLoadingScreen from 'hooks/useLoadingScreen';
import { keysFromRecord } from 'utils/object';
import { useDepartmentAndDutyFilter } from 'features/plan';
import { isEqual } from 'lodash';
import { CapitechDataVisibilityContextType } from './CapitechDataVisibilityProvider.types';
import {
    CapitechDataName,
    capitechDataSwitchMap,
    capitechDataSwitchNames
} from '../util/capitechData';

export const CapitechDataVisibilityContext = createContext<CapitechDataVisibilityContextType>(
    null!
);

export default function CapitechDataVisibilityProvider({ children }: PropsWithChildren) {
    const { access: featureAccess, isLoadingFirstTime: isLoadingFeatureAccessFirstTime } =
        useFeatureAccess();

    const dutyFilter = useDepartmentAndDutyFilter();

    const allDataNamesAvailable = useMemo<Array<CapitechDataName>>(() => {
        // If the feature access is not loaded yet, we assume that only allowed-for-all names are accessible. We don't want to indicate broader access than what ends up available.
        if (!featureAccess) {
            return ['holiday'];
        }

        const dataAvailableRecord: Record<CapitechDataName, boolean | null> = {
            holiday: true,
            absence: featureAccess.absence || featureAccess.simployerAbsence,
            absenceRequest: featureAccess.absence,
            time: featureAccess.time,
            timeIncomplete: featureAccess.time,
            dutyPlanned: featureAccess.plan,
            dutyAvailable: featureAccess.plan,
            dutyRequested: featureAccess.plan,
            dutyWish: featureAccess.plan,
            dutyWishPeriod: featureAccess.plan
        };

        return keysFromRecord(dataAvailableRecord).filter(
            (dataName) => dataAvailableRecord[dataName]
        );
    }, [featureAccess]);

    const allDataNamesWithDataAccessible: Array<CapitechDataName> = useMemo(() => {
        const dataAccessibleRecord: Record<CapitechDataName, boolean | null> = {
            holiday: allDataNamesAvailable.includes('holiday'),
            absence: allDataNamesAvailable.includes('absence'),
            absenceRequest: allDataNamesAvailable.includes('absenceRequest'),
            time: allDataNamesAvailable.includes('time'),
            timeIncomplete: allDataNamesAvailable.includes('timeIncomplete'),
            dutyPlanned: allDataNamesAvailable.includes('dutyPlanned'),
            dutyAvailable:
                allDataNamesAvailable.includes('dutyAvailable') &&
                !dutyFilter.isBlockingDutyDataDisplay,
            dutyRequested:
                allDataNamesAvailable.includes('dutyRequested') &&
                !dutyFilter.isBlockingDutyDataDisplay,
            dutyWish:
                allDataNamesAvailable.includes('dutyWish') && !dutyFilter.isBlockingDutyDataDisplay,
            dutyWishPeriod:
                allDataNamesAvailable.includes('dutyWishPeriod') &&
                !dutyFilter.isBlockingDutyDataDisplay
        };

        return keysFromRecord(dataAccessibleRecord).filter(
            (dataName) => dataAccessibleRecord[dataName]
        );
    }, [allDataNamesAvailable, dutyFilter.isBlockingDutyDataDisplay]);

    const [visibleData, setVisibleData] = useState<Array<CapitechDataName>>(
        allDataNamesWithDataAccessible
    );

    useEffect(() => {
        /**
         * Reset the visible data when list of data names with accessible data changes. A more circumstatial way of doing this would be to check
         * if visibleData contains any items not in allDataNamesWithDataAccessible, and if so, remove them. Or if there are items new items in
         * allDataNamesWithDataAccessible, add those to visibleData. But just reseting the set is a more straightforward way of doing it.
         * It's mostly just during startup this is relevant anyways.
         * */
        setVisibleData(allDataNamesWithDataAccessible);
    }, [allDataNamesWithDataAccessible]);

    const resetSwitches = useCallback(() => {
        setVisibleData(allDataNamesWithDataAccessible);
    }, [allDataNamesWithDataAccessible]);

    const value = useMemo<CapitechDataVisibilityContextType>(() => {
        const allDataSwitchNamesAvailable = capitechDataSwitchNames.filter((switchName) =>
            capitechDataSwitchMap[switchName].every((dataName) =>
                allDataNamesAvailable.includes(dataName)
            )
        );

        const allDataSwitchNamesWithDataAccessible = capitechDataSwitchNames.filter((switchName) =>
            capitechDataSwitchMap[switchName].every((dataName) =>
                allDataNamesWithDataAccessible.includes(dataName)
            )
        );

        return {
            allDataSwitchNamesAvailable,
            allDataSwitchNamesWithDataAccessible,
            allDataNamesWithDataAccessible,
            resetSwitches,
            setDataSwitchState: (switchName, enabled) => {
                if (enabled && !allDataSwitchNamesWithDataAccessible.includes(switchName)) {
                    return false;
                }

                const dataNamesWithinSwitch = capitechDataSwitchMap[switchName];
                setVisibleData((prev) => {
                    if (enabled) {
                        // We're sure to not add any items that are already in the list. We're also sure to not remove any items that are not in the list, as they relate to another switch.
                        const arrayWithPotentiallyAddedItems = prev.concat(
                            dataNamesWithinSwitch.filter((name) => !prev.includes(name))
                        );
                        return isEqual(arrayWithPotentiallyAddedItems, prev)
                            ? prev
                            : arrayWithPotentiallyAddedItems;
                    }

                    const arrayWithPotentiallyRemovedItems = prev.filter(
                        (dataName) => !dataNamesWithinSwitch.includes(dataName)
                    );
                    return isEqual(arrayWithPotentiallyRemovedItems, prev)
                        ? prev
                        : arrayWithPotentiallyRemovedItems;
                });
                return true;
            },
            isDataSwitchEnabled: (switchName) =>
                capitechDataSwitchMap[switchName].every((dataName) =>
                    visibleData.includes(dataName)
                ),
            isVisibleData: (dataName) => visibleData.includes(dataName)
        };
    }, [visibleData, allDataNamesAvailable, allDataNamesWithDataAccessible, resetSwitches]);

    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('CapitechDataVisibilityProvider', isLoadingFeatureAccessFirstTime);
    }, [isLoadingFeatureAccessFirstTime, setSourceIsLoading]);

    return (
        <CapitechDataVisibilityContext.Provider value={value}>
            {children}
        </CapitechDataVisibilityContext.Provider>
    );
}
