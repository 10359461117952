import { Paper, Avatar, Typography } from '@mui/material';
import DepartmentIcon from 'components/icon/DepartmentIcon';
import GroupIcon from 'components/icon/GroupIcon';
import PersonIcon from 'components/icon/PersonIcon';
import { useTranslation } from 'react-i18next';
import Dialog from '../models/Dialog';

type DialogHeaderProps = {
    dialog: Dialog | undefined | null;
};

export default function DialogHeader({ dialog }: DialogHeaderProps) {
    const { t } = useTranslation();
    return (
        <Paper elevation={0} sx={{ flex: 'none', mx: { xs: 0, md: 1 }, p: 2 }}>
            <center>
                <Avatar sx={(theme) => ({ width: 44, height: 44, mb: theme.spacing(1) })}>
                    {dialog?.dialogType === 'Department' && <DepartmentIcon />}
                    {dialog?.dialogType === 'Employee' && <PersonIcon />}
                    {dialog?.dialogType === 'Group' && <GroupIcon />}
                </Avatar>
                <Typography variant="overline">{t('messages.metadata.startedBy')}</Typography>
                <br />
                {dialog?.startedBy}
                <br />
                <Typography variant="overline">{t('messages.metadata.receivers')}</Typography>
                <br />
                {dialog?.receiverNames.join(', ')}
                <br />
                <Typography variant="overline">{t('messages.metadata.subject')}</Typography>
                <br />
                <Typography fontFamily="GT-Walsheim-Bold">{dialog?.topic}</Typography>
            </center>
        </Paper>
    );
}
