import { useMemo } from 'react';
import { dateToIsoString } from 'utils/datetime';
import { useDepartmentAndDutyFilter } from 'features/plan/providers';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import { UseDutyWishRegistrationAccessResult } from './useDutyWishRegistrationAccess.types';

export default function useDutyWishRegistrationAccess(
    dateInIsoFormat?: string
): UseDutyWishRegistrationAccessResult {
    const { access } = useFeatureAccess();
    const dutyFilter = useDepartmentAndDutyFilter();

    const dateToday = useMemo(() => dateToIsoString(new Date()), []);
    const isDateOk = dateInIsoFormat ? dateToday <= dateInIsoFormat : true;

    const isDutyWishRegistrationAvailable = isDateOk && access.plan;
    const isDutyWishRegistrationAccessible = isDateOk && !dutyFilter.isBlockingDutyWishRegistration;

    return {
        isDutyWishRegistrationAvailable,
        isDutyWishRegistrationAccessible,
        isLoading: dutyFilter.isLoading
    };
}
