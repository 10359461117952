import { useContext } from 'react';
import { DepartmentAndDutyFilterContext } from '../../DepartmentAndDutyFilterProvider';
import { UseDepartmentAndDutyFilterResult } from './useDepartmentAndDutyFilter.types';

export default function useDepartmentAndDutyFilter(): UseDepartmentAndDutyFilterResult {
    const ctx = useContext(DepartmentAndDutyFilterContext);

    if (!ctx) {
        throw new Error(
            'useDepartmentAndDutyFilter must be used within DepartmentAndDutyWishFilterProvider'
        );
    }

    return ctx;
}
