import { Fade, LinearProgress, Stack } from '@mui/material';
import ScreenOverlay from 'components/ScreenOverlay';
import MyCapitechLogo from 'features/login/components/MyCapitechLogo';
import { useTranslation } from 'react-i18next';

export default function LoadingScreen() {
    const { t } = useTranslation();
    return (
        <ScreenOverlay>
            <Fade in timeout={300} style={{ transitionDelay: '1000ms' }}>
                <div role="status" aria-busy>
                    <MyCapitechLogo />
                    <Stack sx={{ color: '#ff3046' }}>
                        <LinearProgress
                            aria-label={t('loadingScreen.progressLabel')}
                            color="inherit"
                        />
                    </Stack>
                </div>
            </Fade>
        </ScreenOverlay>
    );
}
