import {
    Box,
    BoxProps,
    FormControl,
    FormControlLabel,
    useMediaQuery,
    useTheme
} from '@mui/material';
import Switch from 'components/Input/Switch';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import { useCapitechDataVisibility } from '../../providers';
import { CapitechDataSwitchName, capitechDataSwitchVisuals } from '../../util/capitechData';
import DutyFilterRequiredDialog from '../DutyFilterRequiredDialog';

type CapitechDataVisibilitySwitchesProps = {
    sx?: BoxProps['sx'];
};

export default function CapitechDataVisibilitySwitches({
    sx
}: CapitechDataVisibilitySwitchesProps) {
    const { setDataSwitchState, isDataSwitchEnabled, allDataSwitchNamesAvailable } =
        useCapitechDataVisibility();

    const { t } = useTranslation();

    // Component supports horizontal and vertical layout. In case of horizontal layout, we want to support at least 2 vertical switches in a row
    const possibleSections: Array<CapitechDataSwitchName[]> = [
        ['time'],
        ['absence', 'absenceRequest'],
        ['dutyPlanned'],
        ['dutyAvailable']
    ];

    const filteredSections = possibleSections
        .map((sectionWithAllPossibleSwitchNames) =>
            sectionWithAllPossibleSwitchNames.filter((switchName) =>
                allDataSwitchNamesAvailable.includes(switchName)
            )
        )
        .filter((sectionWithOnlyVisibleElements) => sectionWithOnlyVisibleElements.length > 0);

    const { spacing } = useTheme();

    const isSlimScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const gapBetweenSwitches = spacing(1);

    // Dialog with info about why duty wish data is not visible
    const { showDialog } = useDialog();
    const showDutyDataUnavailableDialog = (
        triggerContext: 'displayVacantDuties' | 'displayDutyWish'
    ) => {
        showDialog((onClose) => (
            <DutyFilterRequiredDialog onClose={onClose} open triggerContext={triggerContext} />
        ));
    };

    const handleSwitchChange = (switchName: CapitechDataSwitchName) => {
        const isSuccessfullySet = setDataSwitchState(switchName, !isDataSwitchEnabled(switchName));
        if (isSuccessfullySet) return;

        if (switchName === 'dutyAvailable') {
            showDutyDataUnavailableDialog('displayVacantDuties');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: isSlimScreen ? 'column' : 'row',
                justifyContent: 'space-evenly',
                gap: gapBetweenSwitches,
                ...sx
            }}
        >
            {filteredSections.map((rowData) => (
                <FormControl key={rowData.join('-')} sx={{ flex: 1, gap: gapBetweenSwitches }}>
                    {rowData.map((switchName) => {
                        const isActive = isDataSwitchEnabled(switchName);
                        const dataVisuals = capitechDataSwitchVisuals[switchName];
                        const colorApplicableToStyle = `${dataVisuals.color}.main`; // isActive ? `${dataVisuals.color}.light` : `${dataVisuals.color}.main`;
                        const borderWidthWhenHollow = spacing(0.45);
                        const paddingRight = 2;
                        return (
                            <FormControlLabel
                                sx={{
                                    mx: 0,
                                    userSelect: 'none',
                                    ...(dataVisuals.fillVariant === 'full'
                                        ? {
                                              padding: borderWidthWhenHollow,
                                              paddingRight: paddingRight + borderWidthWhenHollow,
                                              backgroundColor: colorApplicableToStyle
                                          }
                                        : {
                                              paddingRight,
                                              borderStyle: 'solid',
                                              borderWidth: borderWidthWhenHollow,
                                              borderColor: colorApplicableToStyle
                                          })
                                }}
                                key={switchName}
                                label={t(`dashboard.dataVisibilitySwitches.${switchName}`)}
                                control={
                                    <Switch
                                        activeValue="true"
                                        value={isActive ? 'true' : 'false'}
                                        isBusy={false}
                                        onChange={() => handleSwitchChange(switchName)}
                                    />
                                }
                            />
                        );
                    })}
                </FormControl>
            ))}
        </Box>
    );
}
CapitechDataVisibilitySwitches.defaultProps = {
    sx: {}
};
