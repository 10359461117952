import { PropsWithChildren, createContext, useCallback, useEffect, useMemo } from 'react';

import AbsenceRequest from 'models/AbsenceRequest';
import { useFetchArray } from 'hooks/useFetch';
import { dateToIsoString } from 'utils/datetime';
import { UseFetchArrayOptions } from 'hooks/useFetch.types';
import usePrevious from 'utils/usePrevious';
import { sortBy } from 'lodash';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import { AbsenceRequestsContextType } from './AbsenceRequestsProvider.types';
import { useDateStartMinAbsenceRequests } from './hooks';

type GetAbsenceRequestsInput = {
    startDate: string;
};

type AbsenceRequestsFetchOptions = UseFetchArrayOptions<AbsenceRequest, GetAbsenceRequestsInput>;

export const AbsenceRequestsContext = createContext<AbsenceRequestsContextType>(null!);

/**
 * Provides absence requests for the current user on-demand. The absence requests go back as far back
 * from current date as dateStartMinAbsenceRequests allows. Only does something if absence access is in
 * order. Stays silent if not.
 *
 * @param param0 Children
 * @returns
 */
export default function AbsenceRequestsProvider({ children }: PropsWithChildren) {
    const { absence: isAbsenceRequestsAccessible } = useFeatureAccess().access;

    const minStartDate = useDateStartMinAbsenceRequests();

    // Fetch options according to min start date. Memoization of options keeps sendRequest the same each render.
    const fetchOptions = useMemo<AbsenceRequestsFetchOptions>(
        () => ({
            reqData: {
                startDate: dateToIsoString(minStartDate)
            },
            manuallyTriggerRequest: !isAbsenceRequestsAccessible // Hold off request if without access to absence
        }),
        [minStartDate, isAbsenceRequestsAccessible]
    );

    // Kicks off request if access is ok
    const [absenceRequests, { isLoading, errorMessage, sendRequest }] = useFetchArray<
        AbsenceRequest,
        GetAbsenceRequestsInput
    >('/getAbsenceRequests', fetchOptions);

    // Refresh callback that ensures provider stays silent if without absence access
    const refreshAbsenceRequests = useCallback(
        () => (isAbsenceRequestsAccessible ? sendRequest() : Promise.resolve()),
        [isAbsenceRequestsAccessible, sendRequest]
    );

    // Refresh data whenever min start date has changed if absence access is in order
    const previousMinStartDate = usePrevious(minStartDate);
    useEffect(() => {
        if (
            isAbsenceRequestsAccessible &&
            previousMinStartDate &&
            minStartDate !== previousMinStartDate
        ) {
            sendRequest();
        }
    }, [isAbsenceRequestsAccessible, previousMinStartDate, minStartDate, sendRequest]);

    const value = useMemo<AbsenceRequestsContextType>(() => {
        // Sort absence requests by date and time in ascending order
        const sorted = sortBy(absenceRequests, ['fromDate', 'fromTime']);
        return {
            absenceRequests: sorted,
            refreshAbsenceRequests,
            isLoading,
            errorMessage
        };
    }, [absenceRequests, errorMessage, isLoading, refreshAbsenceRequests]);

    return (
        <AbsenceRequestsContext.Provider value={value}>{children}</AbsenceRequestsContext.Provider>
    );
}
