import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToReadableString } from 'utils/datetime';
import { DEFAULT_LANGUAGE, isSupportedLanguage } from '../i18n';

export default function useReadableDate(dateVariant: string | Date) {
    const date = useMemo(
        () => (typeof dateVariant === 'string' ? new Date(dateVariant) : dateVariant),
        [dateVariant]
    );

    const { i18n } = useTranslation();

    const supportedLanguage = isSupportedLanguage(i18n.language) ? i18n.language : DEFAULT_LANGUAGE;

    return dateToReadableString(date, supportedLanguage);
}
