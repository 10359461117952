import { MyCapitechSettingsContext } from 'ctx/MyCapitechSettingsProvider/MyCapitechSettingsProvider';
import { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { UseEmployeeSettingsResult } from './useEmployeeSettings.types';

export default function useEmployeeSettings(
    returnEmptyIfUnavailable = false
): UseEmployeeSettingsResult {
    let ctx = useContext(MyCapitechSettingsContext);

    if (!ctx && !returnEmptyIfUnavailable) {
        throw new Error('useEmployeeSettings only available inside MyCapitechSettingsContext');
    } else if (!ctx) {
        ctx = {
            isLoading: false,
            hasAppliedStartupSettings: true,
            errorMessage: '',
            myCapitechSettings: [],
            refreshMyCapitechSettings: () => {}
        };
    }

    const { authData } = useAuth();

    const { t } = useTranslation();

    // useMemo is used to avoid looking up the same settings over and over again
    return useMemo<UseEmployeeSettingsResult>(() => {
        // If we're not logged in, we don't have any settings
        if (!authData) {
            return {
                employeeSettings: {
                    language: undefined,
                    useNativeTimeInput: undefined,
                    selectCurrentDateOnStartup: undefined
                },
                isLoading: false,
                hasAppliedStartupSettings: ctx.hasAppliedStartupSettings,
                errorMessage: t('profile.settings.errors.missingAuthData')
            };
        }

        // Narrow settings down to the current employee and client
        const { employeeId, clientId } = authData;
        const employeeSettings = ctx.myCapitechSettings.filter(
            (s) => s.employeeId === employeeId && s.clientId === clientId
        );

        // Extract the settings we're interested in from the narrowed down list
        const langaugeSetting = employeeSettings.find((s) => s.key === 'language');
        const useNativeTimeInputSetting = employeeSettings.find(
            (s) => s.key === 'useNativeTimeInput'
        );
        const selectCurrentDateOnStartupSetting = employeeSettings.find(
            (s) => s.key === 'selectCurrentDateOnStartup'
        );

        // Return the settings we're interested in, properly typed
        return {
            employeeSettings: {
                language: langaugeSetting?.value ?? undefined,
                useNativeTimeInput: useNativeTimeInputSetting
                    ? useNativeTimeInputSetting.value === 'true'
                    : undefined,
                selectCurrentDateOnStartup: selectCurrentDateOnStartupSetting
                    ? selectCurrentDateOnStartupSetting.value === 'true'
                    : undefined
            },
            isLoading: ctx.isLoading,
            hasAppliedStartupSettings: ctx.hasAppliedStartupSettings,
            errorMessage: ctx.errorMessage
        };
    }, [
        authData,
        ctx.errorMessage,
        ctx.isLoading,
        ctx.myCapitechSettings,
        ctx.hasAppliedStartupSettings,
        t
    ]);
}
