import ActionButton from 'components/button/ActionButton';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import MicrosoftIcon from 'components/icon/MicrosoftIcon';
import { useFormContext } from 'react-hook-form';
import useExternalLoginProvider from 'hooks/useExternalLoginProvider';

export default function AzureADLoginButton() {
    const { t } = useTranslation();
    const { trigger } = useFormContext();
    const { login } = useExternalLoginProvider();

    const handleAzureADLoginClick = useCallback(async () => {
        const validationRestult = await trigger('clientId');

        if (!validationRestult) {
            return;
        }
        login();
    }, [login, trigger]);

    return (
        <ActionButton
            startIcon={<MicrosoftIcon />}
            onClick={handleAzureADLoginClick}
            fullWidth
            variant="outlined"
            type="button"
        >
            {t('loginScreen.azureADLoginBtn')}
        </ActionButton>
    );
}
