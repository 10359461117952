import { PropsWithChildren, createContext, useEffect, useMemo } from 'react';
import { useFetchArray } from 'hooks/useFetch';
import { useTranslation } from 'react-i18next';
import { ExternalLink } from 'models/ExternalLink';
import useLoadingScreen from 'hooks/useLoadingScreen';
import useAuth from 'hooks/useAuth';
import { ExternalLinksContextType } from './ExternalLinksProvider.types';

export const ExternalLinksContext = createContext<ExternalLinksContextType>(null!);

export type GetExternalLinksInput = {};

export default function ExternalLinksProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation();

    const [externalLinks, { isLoading, errorMessage: genericErrorMessage }] = useFetchArray<
        ExternalLink,
        GetExternalLinksInput
    >('getExternalLinks');

    const errorMessageLoadFailed = t('externalLinks.errors.loadFailed');

    const { authData } = useAuth();
    const { clientId } = authData || {};
    const value: ExternalLinksContextType = useMemo<ExternalLinksContextType>(
        () => ({
            externalLinks: externalLinks.filter(
                (externalLink) => externalLink.clientId === 0 || externalLink.clientId === clientId
            ),
            isLoading,
            errorMessage: genericErrorMessage ? errorMessageLoadFailed : null
        }),
        [externalLinks, isLoading, genericErrorMessage, errorMessageLoadFailed, clientId]
    );

    // These links are not critical for the app to function, so no error screen is shown if they fail to load

    const { setSourceIsLoading } = useLoadingScreen();
    useEffect(() => {
        setSourceIsLoading('ExternalLinks', isLoading);
    }, [isLoading, setSourceIsLoading]);

    return <ExternalLinksContext.Provider value={value}>{children}</ExternalLinksContext.Provider>;
}
