import { Paper, Divider } from '@mui/material';
import { Fragment, useRef, useImperativeHandle, forwardRef } from 'react';
import DialogListItem, { DialogListItemRef } from './DialogListItem';
import Dialog from '../models/Dialog';

type DialogsListProps = {
    dialogs: Dialog[];
    selectedDialog: Dialog | null;
};

type DialogsListRef = {
    scrollToDialog: (dialogId: string) => void;
};

const DialogsList = forwardRef<DialogsListRef, DialogsListProps>(
    ({ dialogs, selectedDialog }, ref) => {
        const listItemRefs = useRef<Map<string, DialogListItemRef>>(new Map());

        useImperativeHandle(ref, () => ({
            scrollToDialog: (dialogId: string) => {
                if (listItemRefs.current.has(dialogId)) {
                    listItemRefs.current.get(dialogId)?.scrollIntoView();
                }
            }
        }));

        return (
            <Paper
                elevation={0}
                sx={{
                    display: 'flex',
                    flexDirection: 'column'
                }}
            >
                {dialogs.map((dialog) => (
                    <Fragment key={dialog.id}>
                        <DialogListItem
                            dialog={dialog}
                            selected={selectedDialog?.id === dialog.id}
                            ref={(itemRef) => {
                                if (itemRef) {
                                    listItemRefs.current.set(dialog.id, itemRef);
                                } else {
                                    listItemRefs.current.delete(dialog.id);
                                }
                            }}
                        />
                        <Divider />
                    </Fragment>
                ))}
            </Paper>
        );
    }
);

export default DialogsList;
