import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, BoxProps, Theme } from '@mui/material';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import DatePicker from 'components/Input/DatePicker';
import { DatePickerProps } from 'components/Input/DatePicker/DatePicker.types';
import usePrevious from 'utils/usePrevious';

type HFDatePickerProps = {
    name: string;
    label: string;
    fullWidth?: boolean;
} & Omit<DatePickerProps, 'onChange'>;

export type HFDateRangePickerProps = {
    fromPickerProps: HFDatePickerProps;
    toPickerProps: HFDatePickerProps;
    sx?: BoxProps['sx'];
};

export default function HFDateRangePicker({
    fromPickerProps,
    toPickerProps,
    sx
}: HFDateRangePickerProps) {
    const context = useFormContext();
    const {
        getValues,
        getFieldState,
        setValue,
        control,
        trigger,
        formState: { isValidating }
    } = context;

    const fromName = fromPickerProps.name;
    const toName = toPickerProps.name;

    useFieldNameGuard(fromName, context);
    useFieldNameGuard(toName, context);

    const flagDateFromChangedByUser = useRef(false);
    const flagDateToChangedByUser = useRef(false);

    const { submitCount } = context.formState;
    useEffect(() => {
        if (submitCount > 0) {
            flagDateFromChangedByUser.current = false;
            flagDateToChangedByUser.current = false;
        }
    }, [submitCount]);

    // Solely here for the purpose of having a good testing coverage
    const [isBusyWithBlur, setIsBusyWithBlur] = useState<false | { startedWithErrors: boolean }>(
        false
    );
    const previousIsValidating = usePrevious(isValidating);
    const justFinishedValidating = !isValidating && previousIsValidating;
    useEffect(() => {
        if (isBusyWithBlur && justFinishedValidating) setIsBusyWithBlur(false);
    }, [isBusyWithBlur, justFinishedValidating]);

    const syncFromDateByToDate = useCallback(async () => {
        const isToValueChanged = flagDateToChangedByUser.current;
        flagDateToChangedByUser.current = false;
        if (!isToValueChanged) return true;

        const fromError = getFieldState(fromName).error;
        const toError = getFieldState(toName).error;

        await trigger(toName); // Should always re-validate own field if it has an error. date limit might have been breached, or a previous issue might have been resolved.
        if (fromError) {
            // User has either...
            // - hit submit button with an invalid date whilst keeping the field in focus
            // - just tried to resolved previous issue by correcting the to date
            await trigger(fromName);
            // In both cases we should not proceed with the sync
            return true;
        }
        if (toError) {
            // Typically means the user has submitted by hitting enter and an error saying "toDate can't be before fromDate" is displayed. Moving the toDate closer to fromDate should resolve the issue by moving the fromDate to same as toDate.
            return true;
        }

        const immediateFromValue: string | null = getValues(fromName);
        const immediateToValue: string | null = getValues(toName);

        const isFullyFormedFromValue = immediateFromValue && immediateFromValue.length === 10;
        const isFullyFormedToValue = immediateToValue && immediateToValue.length === 10;

        const hasFromDateAfterToDate =
            isFullyFormedFromValue && isFullyFormedToValue && immediateFromValue > immediateToValue;

        if (hasFromDateAfterToDate) {
            setValue(fromName, immediateToValue, { shouldValidate: false });
            await trigger([fromName, toName]);
            return true;
        }
        return true;
    }, [getFieldState, toName, fromName, getValues, setValue, trigger]);

    const syncToDateByFromDate = useCallback(async () => {
        // console.log('syncing to date by from date', getValues(fromName), getValues(toName));
        const isFromValueChanged = flagDateFromChangedByUser.current;
        flagDateFromChangedByUser.current = false;
        if (!isFromValueChanged) return true;

        await trigger(fromName); // Should always re-validate own field. date limit might have been breached, or a previous issue might have been resolved.
        if (getFieldState(toName).error) {
            // User has either...
            // - hit submit button with an invalid date whilst keeping the field in focus
            // - just tried to resolved previous issue by correcting the from date
            await trigger(toName);
            // In both cases we should not proceed with the sync
            return true;
        }

        const immediateFromValue: string | null = getValues(fromName);
        const immediateToValue: string | null = getValues(toName);

        const isFullyFormedFromValue = immediateFromValue?.length === 10;
        const isFullyFormedToValue = immediateToValue?.length === 10;

        const hasToDateBeforeFromDate =
            isFullyFormedFromValue && isFullyFormedToValue && immediateToValue < immediateFromValue;

        if (hasToDateBeforeFromDate) {
            setValue(toName, immediateFromValue, { shouldValidate: false });
            await trigger([toName, fromName]);
            return true;
        }
        return true;
    }, [getFieldState, fromName, toName, getValues, setValue, trigger]);

    return (
        <Box sx={sx} className="date-range-wrapper" data-is-busy-with-blur={!!isBusyWithBlur}>
            <Controller
                control={control}
                name={fromName}
                render={({ field: { ref, ...field }, fieldState }) => (
                    <DatePicker
                        {...fromPickerProps}
                        {...field}
                        onChange={(newValue) => {
                            flagDateFromChangedByUser.current = true;
                            setValue(fromName, newValue);
                        }}
                        onBlur={() => {
                            setIsBusyWithBlur({
                                startedWithErrors:
                                    !!getFieldState(fromName).error || !!getFieldState(toName).error
                            });
                            setTimeout(async () => {
                                await syncToDateByFromDate();
                                return setIsBusyWithBlur(false);
                            }, 150);
                        }}
                        onAccept={syncToDateByFromDate}
                        error={
                            (!isBusyWithBlur || isBusyWithBlur.startedWithErrors) &&
                            !!fieldState.error
                        }
                        helperText={
                            (!isBusyWithBlur || isBusyWithBlur.startedWithErrors) &&
                            fieldState.error?.message
                        }
                        inputRef={ref}
                    />
                )}
            />

            <Controller
                control={control}
                name={toName}
                render={({ field: { ref, ...field }, fieldState }) => (
                    <DatePicker
                        {...toPickerProps}
                        {...field}
                        onChange={(newValue) => {
                            flagDateToChangedByUser.current = true;
                            setValue(toName, newValue);
                        }}
                        onBlur={() => {
                            setIsBusyWithBlur({
                                startedWithErrors:
                                    !!getFieldState(toName).error || !!getFieldState(fromName).error
                            });
                            setTimeout(async () => {
                                await syncFromDateByToDate();
                                setIsBusyWithBlur(false);
                            }, 150);
                        }}
                        onAccept={syncFromDateByToDate}
                        error={
                            (!isBusyWithBlur || isBusyWithBlur.startedWithErrors) &&
                            !!fieldState.error
                        }
                        helperText={
                            (!isBusyWithBlur || isBusyWithBlur.startedWithErrors) &&
                            fieldState.error?.message
                        }
                        inputRef={ref}
                    />
                )}
            />
        </Box>
    );
}
HFDateRangePicker.defaultProps = {
    sx: (theme: Theme) => ({
        display: 'flex',
        flexDirection: 'row',
        gap: theme.spacing(2)
    })
};
