import {
    cleanUpAuthCookies,
    getRefreshTokenCookie,
    setRefreshTokenCookie,
    setJwtCookie
} from 'utils/cookie';
import { postToWebtidApi } from 'utils/http';
import { RefreshTokenUnauthorizedError } from '../auth.errors';
import { AuthApiResult } from '../auth.types';
import { RefreshAuthResult } from './refreshAuth.types';

let isRefreshingAccessToken = false;
let refreshAccessTokenQueue: Array<PromiseCalls> = [];

type PromiseCalls = {
    resolve: (authData: AuthApiResult) => void;
    reject: (error: Error) => void;
};

/**
 * Performs an API call to refresh auth token, and sets a new JWT cookie.
 * Wrapped by refreshAuth to prevent concurrent calls to the API.
 *
 * @param refreshToken
 * @returns Auth results from the API
 */
async function performRefresh(refreshToken: string) {
    const result = await postToWebtidApi<Array<AuthApiResult>>(
        'refreshAuthorization',
        {
            refreshToken
        },
        {
            isAnonymous: true
        }
    );

    if (!result.success) {
        /**
         * LocalizationKey: Errors_Authorization_401 is the only authorization error thrown by the end point.
         * postToWebTid swallows every exception and returns it as result.success === false. This can also
         * include connection errors.
         */
        if (result.displayErrorMessage === 'LocalizationKey: Errors_Authorization_401') {
            cleanUpAuthCookies();
            throw new RefreshTokenUnauthorizedError(result.displayErrorMessage);
        } else {
            throw new Error(result.displayErrorMessage);
        }
    }

    const data = result.content[0];
    const useRememberMe = getRefreshTokenCookie() !== undefined;

    if (useRememberMe) {
        setRefreshTokenCookie(data.refreshToken, new Date(data.refreshTokenExpires));
    }

    setJwtCookie(data.accessToken, new Date(data.accessTokenExpires));

    return data;
}

/**
 * Performs an API call to refresh auth token, and sets a new JWT cookie.
 * It queues requests while refreshing is in progress to avoid multiple calls to the API.
 *
 * @param refreshToken The refresh token to use (not auto-extracted from cookie to allow for testing, and for auth0 which uses GET)
 * @returns A promise with auth results from the API
 */
export default async function refreshAuth(refreshToken: string): RefreshAuthResult {
    if (isRefreshingAccessToken) {
        return new Promise<AuthApiResult>((resolve, reject) => {
            refreshAccessTokenQueue.push({ resolve, reject });
        });
    }

    return new Promise<AuthApiResult>((resolve, reject) => {
        isRefreshingAccessToken = true;
        performRefresh(refreshToken)
            .then((authData) => {
                resolve(authData);
                refreshAccessTokenQueue.forEach((entry) => entry.resolve(authData));
            })
            .catch((error: Error) => {
                reject(error);
                refreshAccessTokenQueue.forEach((entry) => entry.reject(error));
            })
            .finally(() => {
                isRefreshingAccessToken = false;
            });
    });
}

// included for test and mock purposes ONLY
export function resetRefreshAuthTokenVariables() {
    refreshAccessTokenQueue = [];
    isRefreshingAccessToken = false;
}
