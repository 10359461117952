import { ListItemButton, ListItemText, ListItemAvatar } from '@mui/material';
import AbsenceRequest from 'models/AbsenceRequest';
import { Link } from 'react-router-dom';
import { useSmartAbsenceTimespanString } from '../util';
import AbsenceAvatar from '../components/AbsenceAvatar';

type AbsencesListItemProps = {
    absence: AbsenceRequest;
    isSelected: boolean;
};

export default function AbsencesListItem({ absence, isSelected }: AbsencesListItemProps) {
    return (
        <ListItemButton
            disableGutters={false}
            component={Link}
            to={`/absence?absenceId=${absence.id}`}
            sx={(theme) => ({
                '&:hover': {
                    backgroundColor: isSelected ? theme.palette.primary.main : undefined
                },
                backgroundColor: isSelected ? theme.palette.primary.main : undefined
            })}
        >
            <ListItemAvatar>
                <AbsenceAvatar absence={absence} />
            </ListItemAvatar>
            <ListItemText
                primary={absence.absenceCodeDescription}
                primaryTypographyProps={{
                    color: isSelected ? 'white' : undefined
                }}
                secondary={useSmartAbsenceTimespanString(absence)}
                secondaryTypographyProps={{
                    color: isSelected ? 'white' : undefined
                }}
            />
        </ListItemButton>
    );
}
