import AbsenceIcon from 'components/icon/AbsenceIcon';
import TransactionCard from './TransactionCard';
import { TransactionCardProps } from './TransactionCard.types';

export default function AbsenceTransactionDetailCard({ title, description }: TransactionCardProps) {
    return (
        <TransactionCard
            sx={{ bgcolor: 'absence.main' }}
            icon={<AbsenceIcon />}
            title={title}
            description={description}
        />
    );
}
