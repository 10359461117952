import { useFormContext, Controller } from 'react-hook-form';
import TextField, { TextFieldProps } from 'components/Input/TextField';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import { EnterKeyHint } from 'utils/input';

type HFTextFieldProps = {
    name: string;
    enterKeyHint?: EnterKeyHint;
} & TextFieldProps;

function HFTextField({ enterKeyHint, ...textFieldProps }: HFTextFieldProps) {
    const context = useFormContext();
    const {
        control,
        formState: { errors }
    } = context;

    const { name } = textFieldProps;
    useFieldNameGuard(name, context);

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, onBlur, ref } }) => (
                <TextField
                    {...textFieldProps}
                    inputRef={ref}
                    value={value ?? ''}
                    onChange={(ev) => {
                        onChange(ev);
                        if (typeof textFieldProps.onChange === 'function') {
                            textFieldProps.onChange(ev);
                        }
                    }}
                    onBlur={onBlur}
                    {...{
                        inputProps: {
                            ...textFieldProps.inputProps,
                            ...(enterKeyHint ? { enterKeyHint } : null)
                        }
                    }}
                    error={!!errors[name]}
                    helperText={errors[name] ? (errors[name]?.message as unknown as string) : ''}
                />
            )}
        />
    );
}

HFTextField.defaultProps = {
    enterKeyHint: undefined
};

export default HFTextField;
