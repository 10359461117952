import { useFetch } from 'hooks/useFetch';
import { PropsWithChildren, createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useInterval } from 'usehooks-ts';

type MessagesUnreadContextType = {
    messagesUnreadCount: number;
    refreshCountManually: () => void;
    errorMessage: string;
};

export const MessagesUnreadContext = createContext<MessagesUnreadContextType>({
    messagesUnreadCount: 0,
    refreshCountManually: () => {},
    errorMessage: ''
});

type GetNumberOfPendingIncomingMessagesPayload = number;

export default function MessagesUnreadProvider({ children }: PropsWithChildren<{}>) {
    const [messagesUnreadCount, { errorMessage, sendRequest }] =
        useFetch<GetNumberOfPendingIncomingMessagesPayload>('getNumberOfPendingIncomingMessages', {
            ignoreErrors: true
        });

    // Polling
    const INTERVAL_DELAY = 10000;
    const [intervalDelay, setIntervalDelay] = useState<number | null>(INTERVAL_DELAY);
    useInterval(sendRequest, intervalDelay);
    // Offer manual refresh, which should break the polling cycle
    const refreshCountManually = useCallback(() => {
        setIntervalDelay(null);
        sendRequest();
    }, [sendRequest]);
    // Whenever the polling cycle is broken, reset it
    useEffect(() => {
        if (intervalDelay === null) {
            setIntervalDelay(INTERVAL_DELAY);
        }
    }, [intervalDelay]);

    const value: MessagesUnreadContextType = useMemo(
        () => ({
            messagesUnreadCount: messagesUnreadCount || 0,
            refreshCountManually,
            errorMessage
        }),
        [errorMessage, messagesUnreadCount, refreshCountManually]
    );

    return (
        <MessagesUnreadContext.Provider value={value}>{children}</MessagesUnreadContext.Provider>
    );
}
