import { ExternalLoginProviderContext } from 'ctx/ExternalLoginProvider/ExternalLoginProvider';
import { useContext } from 'react';
import { UseExternalLoginProviderResult } from './useExternalLoginProvider.types';

export default function useExternalLoginProvider(): UseExternalLoginProviderResult {
    const ctx = useContext(ExternalLoginProviderContext);

    if (!ctx) {
        throw new Error(
            'useExternalLoginProvider only available inside ExternalLoginProviderContext'
        );
    }

    return ctx;
}
