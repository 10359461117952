import ActionButton from 'components/button/ActionButton';
import AlertDialog from 'components/dialog/AlertDialog';
import { useDialog } from 'ctx/DialogCtx';
import { useFetch } from 'hooks/useFetch';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getTranslatedOrFallback } from 'utils/translation';

type ConfirmMessageInput = {
    MessageId: number;
    DialogId?: string;
};

type ConfirmMessagePayload = boolean;

type DialogMessageReadConfirmProps = {
    messageId: number;
    dialogId?: string;
    onSuccess: () => void;
};

export default function DialogMessageReadConfirm({
    messageId,
    dialogId,
    onSuccess
}: DialogMessageReadConfirmProps) {
    const [isConfirmed, setIsConfirmed] = useState(false);

    const { t } = useTranslation();

    const { showDialog } = useDialog();
    const showError = useCallback(
        (errorMessage: string) =>
            showDialog((onClose) => (
                <AlertDialog
                    open
                    severity="error"
                    title={getTranslatedOrFallback(
                        errorMessage,
                        'messages.confirmRead.errors.generic'
                    )}
                    onConfirm={onClose}
                    isRunningConfirmOperation={false}
                />
            )),
        [showDialog]
    );

    const [, { isLoading, sendRequest }] = useFetch<ConfirmMessagePayload, ConfirmMessageInput>(
        'confirmMessage',
        {
            manuallyTriggerRequest: true,
            onSuccess: () => {
                onSuccess();
                setIsConfirmed(true);
            },
            onErrorMessage: showError,
            reqData: { MessageId: messageId, DialogId: dialogId }
        }
    );

    return isConfirmed ? null : (
        <ActionButton type="button" isloading={isLoading} onClick={sendRequest}>
            {t('messages.confirmRead.actions.confirm')}
        </ActionButton>
    );
}

DialogMessageReadConfirm.defaultProps = {
    dialogId: undefined
};
