import { Box, Button } from '@mui/material';
import { PickersActionBarProps } from '@mui/x-date-pickers';
import { useTranslation } from 'react-i18next';

export default function DatePickarActionBar({ className, onSetToday }: PickersActionBarProps) {
    const { t } = useTranslation();

    return (
        <Box className={className} sx={{ flex: 1, display: 'flex' }}>
            <Button
                variant="text"
                sx={{ flex: 1, textTransform: 'none', py: 1 }}
                onClick={onSetToday}
            >
                {t('calendar.today')}
            </Button>
        </Box>
    );
}
