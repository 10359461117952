import { useDialog } from 'ctx/DialogCtx';
import { useEffect } from 'react';
import { dateToIsoString } from 'utils/datetime';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import {
    AbsenceRequestRegistration,
    useAbsenceRequestRegistrationAccess,
    useAbsenceRequestsAffectingDate,
    useAbsenceRequestsAffectingDateRange
} from 'features/absence';
import {
    useDutiesOnDateInSetDateRange,
    DutyWishRegistration,
    useDutyWishRegistrationAccess,
    useDutiesInSetDateRange
} from 'features/plan';
import { pick, sum } from 'lodash';
import useHasQuickAdd from 'features/misc/employeeSettings/hooks/useHasQuickAdd';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import useTimeRegistrationAccess from '../hooks/useTimeRegistrationAccess';
import { HourRegistration } from '../hour';
import DayDetailsView from './DayDetailsView';
import { useCapitechDataVisibility } from '../providers';
import useTimeTransactionsForDate from '../hooks/useTimeTransactionsForDate';
import useAbsenceTransactions from '../hooks/useAbsenceTransactions';
import DutyFilterRequiredDialog from './DutyFilterRequiredDialog';
import useHiddenCapitechDataItems from '../hooks/useHiddenCapitechDataItems';
import useInOutAvailabilityForDate from '../hooks/useInOutAvailabilityForDate';

export default function DayDetails() {
    const { selectedDate } = useSelectedTimePeriod();

    if (!selectedDate) {
        throw new Error('Cannot display details for no date in particular');
    }

    const { planned, available, requested, wish, wishPeriods } =
        useDutiesOnDateInSetDateRange(selectedDate);
    const hasDutiesPlanned = planned.items.length > 0;
    const hasDutiesAvailable = available.items.length > 0;
    const hasDutiesRequested = requested.items.length > 0;
    const hasDutiesWished = wish.items.length > 0 || wishPeriods.items.length > 0;

    const dateInIsoFormat = dateToIsoString(selectedDate);
    const { isTimeEditAccessible, isTimeRegistrationAccessible } = useTimeRegistrationAccess(
        dateInIsoFormat,
        true
    );
    const { isAbsenceRequestRegistrationAccessible } =
        useAbsenceRequestRegistrationAccess(dateInIsoFormat);
    const { isDutyWishRegistrationAvailable, isDutyWishRegistrationAccessible } =
        useDutyWishRegistrationAccess(dateInIsoFormat);

    const inOutAvailability = useInOutAvailabilityForDate(selectedDate);

    const timeTransactions = useTimeTransactionsForDate(selectedDate, true);
    const hasTimeTransactions = timeTransactions.length > 0;
    const { absenceTransactions } = useAbsenceTransactions();
    const hasAbsenceTransactions =
        absenceTransactions.filter((t) => t.dateFrom === dateToIsoString(selectedDate)).length > 0;
    const { absenceRequests } = useAbsenceRequestsAffectingDate(dateToIsoString(selectedDate));
    const hasAbsenceRequests = absenceRequests.length > 0;

    const showEmptyDayMessage =
        !hasTimeTransactions &&
        !hasAbsenceTransactions &&
        !hasAbsenceRequests &&
        !hasDutiesPlanned &&
        !hasDutiesRequested &&
        !hasDutiesWished &&
        !hasDutiesAvailable;

    const capitechDataVisibility = useCapitechDataVisibility();

    // Dialog
    const { showDialog, clearDialogs } = useDialog();
    useEffect(() => clearDialogs, [clearDialogs]); // Clears dialogs on unmount

    // Register time modal
    const { refreshSelectedTimePeriod } = useTimeTransactions();
    const showRegisterTimeModal = () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <HourRegistration
                    isCheckingIn={false}
                    date={dateInIsoFormat}
                    onSuccess={() => {
                        refreshSelectedTimePeriod();
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    };

    // Register absence requests modal
    const { fromDate, toDate } = useSelectedTimePeriod();
    const { refreshAbsenceRequests } = useAbsenceRequestsAffectingDateRange({
        from: fromDate,
        to: toDate
    });
    const showRegisterAbsenceRequestModal = () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <AbsenceRequestRegistration
                    sx={{ maxWidth: '400px' }}
                    date={dateInIsoFormat}
                    onSuccess={() => {
                        refreshAbsenceRequests();
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    };

    // Register duty wish modal
    const dutiesDataInSetDateRange = useDutiesInSetDateRange();
    const showRegisterDutyWishModal = () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open maxWidth="lg">
                <DutyWishRegistration
                    initDate={dateInIsoFormat}
                    onSuccess={(response, input) => {
                        const isDutyWishPeriodSuccess = 'fromDate' in input;
                        if (isDutyWishPeriodSuccess) {
                            dutiesDataInSetDateRange.wishPeriods.refresh();
                        } else {
                            dutiesDataInSetDateRange.wish.refresh();
                        }
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    };
    // Duty filter required dialog
    const showDutyFilterRequiredDialog = () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <DutyFilterRequiredDialog
                    onClose={onClose}
                    open
                    triggerContext="registerDutyWish"
                />
            </DialogWithCloseBtn>
        ));
    };

    const hiddenItemsSet = useHiddenCapitechDataItems(selectedDate);
    const hiddenItemsByFilterSet = pick(
        hiddenItemsSet,
        capitechDataVisibility.allDataNamesWithDataAccessible
    );
    const hiddenItemsByFilterCountList = Object.values(hiddenItemsByFilterSet || {}).map(
        (values) => values?.length || 0
    );
    const hiddenItemsByFilterCount = sum(hiddenItemsByFilterCountList);

    const isLoading =
        isTimeEditAccessible === null || isAbsenceRequestRegistrationAccessible === null;

    const hasQuickAdd = useHasQuickAdd();

    return (
        <DayDetailsView
            isLoading={isLoading}
            selectedDay={selectedDate}
            showEmptyDayMessage={showEmptyDayMessage}
            isRegistrationClosed={false}
            showRegisterTimeButton={Boolean(
                isTimeEditAccessible && capitechDataVisibility.isVisibleData('time')
            )}
            onRegisterTimeClick={showRegisterTimeModal}
            showRegisterInOutButtons={Boolean(
                inOutAvailability.isInOrOutAvailable && capitechDataVisibility.isVisibleData('time')
            )}
            showRegisterQuickAddButton={Boolean(
                isTimeRegistrationAccessible &&
                    capitechDataVisibility.isVisibleData('time') &&
                    hasQuickAdd &&
                    isTimeEditAccessible
            )}
            showRegisterDutyWishButton={Boolean(
                isDutyWishRegistrationAvailable &&
                    (capitechDataVisibility.isVisibleData('dutyWish') ||
                        capitechDataVisibility.isVisibleData('dutyWishPeriod'))
            )}
            onRegisterDutyWishClick={
                isDutyWishRegistrationAccessible
                    ? showRegisterDutyWishModal
                    : showDutyFilterRequiredDialog
            }
            showRegisterAbsenceRequestButton={Boolean(
                isAbsenceRequestRegistrationAccessible &&
                    capitechDataVisibility.isVisibleData('absenceRequest')
            )}
            onRegisterAbsenceRequestClick={showRegisterAbsenceRequestModal}
            hiddenItemsByFilterCount={hiddenItemsByFilterCount}
        />
    );
}
