import 'assets/fonts/fonts.css';
import AppProviders from 'ctx/AppProviders';
import AppRoutes from 'router/AppRoutes';
import './i18n';

export default function App() {
    return (
        <AppProviders>
            <AppRoutes />
        </AppProviders>
    );
}
