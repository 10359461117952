import { useFetchArray } from 'hooks/useFetch';
import DialogMessage from '../models/DialogMessage';

type GetDialogMessagesInput = {
    threadId: string;
    Take: number;
    Skip: number;
};

export default function useDialogMessages(
    threadId: string,
    onSuccess: (messages: DialogMessage[]) => void
) {
    const [messages, { isLoading, errorMessage, sendRequest: getDialogMessages }] = useFetchArray<
        DialogMessage,
        GetDialogMessagesInput
    >('/getDialogMessages', {
        manuallyTriggerRequest: false,
        reqData: {
            threadId,
            Take: 1000,
            Skip: 0
        },
        onSuccess
    });

    return {
        messages,
        isLoading,
        errorMessage,
        getDialogMessages
    };
}
