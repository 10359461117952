import { useScreen } from 'usehooks-ts';
import { useEmployeeSettings } from 'features/profile';
import { useTheme } from '@mui/material';
import useBrowserSupport from '../useBrowserSupport';
import { UseNativeInputResult } from './useNativeInput.types';

/**
 * Determines if native input should be used for select and time inputs
 * @returns {UseNativeInputResult}
 */
export default function useNativeInput(): UseNativeInputResult {
    const screen = useScreen();

    const employeeSettings = useEmployeeSettings(true);
    const preferNativeTimeInputIfDeviceFits =
        employeeSettings?.employeeSettings?.useNativeTimeInput ?? true;

    const browserSupport = useBrowserSupport();

    // navigator.platform not deprecated https://groups.google.com/g/mozilla.dev.mdc/c/tIx2iiH2u3o
    const isAppleTouchDevice =
        ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(
            navigator.platform
        ) ||
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

    const theme = useTheme();
    const isSmallScreen = screen && theme.breakpoints.values.md > screen.width;

    return {
        preferNativeSelect: isSmallScreen || isAppleTouchDevice,
        preferNativeDate: isSmallScreen || isAppleTouchDevice,
        preferNativeTime:
            preferNativeTimeInputIfDeviceFits &&
            browserSupport.inputTime &&
            (isSmallScreen || isAppleTouchDevice)
    };
}
