/* eslint-disable no-case-declarations */
import { without } from 'lodash';
import { IEmployeeRegistrationInformation } from 'models/TimeRegistrationModels';
import { getUnixTime } from 'utils/datetime';

export type EmployeeRegistrationInformationState = {
    employeeRegistrationInformationByDate: Partial<
        Record<string, IEmployeeRegistrationInformation | null>
    >;
    loadingDates: Array<string>;
    employeeRegistrationInformationAddedTimeByDate: Partial<Record<string, number>>;
};

export enum EmployeeRegistrationInformationActionType {
    NotifyItemFetchStart = 'NOTIFY_ITEM_FETCH_START',
    AddItem = 'SET_ITEM'
}

export type EmployeeRegistrationInformationAction =
    | {
          type: EmployeeRegistrationInformationActionType.NotifyItemFetchStart;
          datesInIsoFormat: Array<string>;
      }
    | {
          type: EmployeeRegistrationInformationActionType.AddItem;
          dateInIsoFormat: string;
          registrationInformation: IEmployeeRegistrationInformation | null;
      };

export function employeeRegistratoinInformationReducer(
    state: EmployeeRegistrationInformationState,
    action: EmployeeRegistrationInformationAction
): EmployeeRegistrationInformationState {
    switch (action.type) {
        case EmployeeRegistrationInformationActionType.NotifyItemFetchStart:
            const newDates = action.datesInIsoFormat.filter(
                (possibleNewDate) => !state.loadingDates.includes(possibleNewDate)
            );

            if (!newDates.length) {
                return state;
            }

            return {
                ...state,
                loadingDates: [...state.loadingDates, ...newDates]
            };
        case EmployeeRegistrationInformationActionType.AddItem:
            return {
                ...state,
                loadingDates: without(state.loadingDates, action.dateInIsoFormat),
                employeeRegistrationInformationByDate: {
                    ...state.employeeRegistrationInformationByDate,
                    [action.dateInIsoFormat]: action.registrationInformation
                },
                employeeRegistrationInformationAddedTimeByDate: {
                    ...state.employeeRegistrationInformationAddedTimeByDate,
                    [action.dateInIsoFormat]: getUnixTime()
                }
            };
        default:
            return state; // Do nothing
    }
}
