import { TimeCategory } from 'models/TimeCategory';
import { useFetchArray } from 'hooks/useFetch';
import { UseTimeCategoriesForManualAdditionResult } from './useTimeCategoriesForManualAddition.types';

export default function useTimeCategoriesForManualAddition(): UseTimeCategoriesForManualAdditionResult {
    const [timeCategories, { isLoading }] = useFetchArray<TimeCategory>(
        'getTimeCategoryForManualAddition',
        {}
    );

    return { timeCategories, isLoading };
}
