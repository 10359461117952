import { ZodTypeAny, nullable, string } from 'zod';
import { formatNumberAsString, formatStringAsNumber } from 'features/dashboard/util/util';
import { i18n } from '../i18n';
import { dateToString, isIsoDateString } from './datetime';

const regexDate = /^\d{4}-\d{2}-\d{2}$/;
const regexDateOptional = /^$|^\d{4}-\d{2}-\d{2}$/;

const regexSomethingWithNumberOrEmpty = /^\s*$|\d+/;

export interface DateValidationProps {
    minDateInIsoFormat?: string;
    errorTextMinDateBreach?: string;
    maxDateInIsoFormat?: string;
    errorTextMaxDateBreach?: string;
}

export interface NumericStringValidationProps {
    min?: number;
    errorTextMin?: string;
    max?: number;
    errorTextMax?: string;
    ignoreNonNumericChars: boolean;
}

// Inspired by https://github.com/colinhacks/zod/issues/37#issuecomment-985765388
export default {
    date: (props?: DateValidationProps) => {
        /*
        Considereed something along the lines of...

        coerce
            .date()
            .min(new Date('2023-04-21'), { message: 'Must be greater than...' })
            .transform(dateToIsoString)

        this would remove the need for those regexes and refine, which would be nice.
        But we have cases of {min, max, dateExceptions}. In those cases refine would most
        likely come into play anyway. Leaving out coerce and converting to date back
        and forth reduces complexity. Given that the RegExes doesn't put you off. X-D
        */
        let validator: ZodTypeAny = string({
            invalid_type_error: i18n.t('dateTime.errors.invalidDate') || ''
        })
            .regex(regexDate, {
                message: i18n.t('dateTime.errors.incompleteDate') || '' // Incomplete instead of invalid date format because input field clearly states desired format
            })
            .refine(isIsoDateString, {
                message: i18n.t('dateTime.errors.invalidDate') || ''
            });

        const {
            minDateInIsoFormat,
            maxDateInIsoFormat,
            errorTextMinDateBreach,
            errorTextMaxDateBreach
        } = props || {};
        if (minDateInIsoFormat) {
            const readableMinDate = dateToString(new Date(minDateInIsoFormat));

            validator = validator.refine((dateValue: string) => dateValue >= minDateInIsoFormat, {
                message:
                    errorTextMinDateBreach ||
                    i18n.t('dateTime.errors.breaksMinDate', {
                        relatableMin: readableMinDate
                    }) ||
                    ''
            });
        }

        if (maxDateInIsoFormat) {
            const readableMaxDate = dateToString(new Date(maxDateInIsoFormat));

            validator = validator.refine((dateValue: string) => dateValue <= maxDateInIsoFormat, {
                message:
                    errorTextMaxDateBreach ||
                    i18n.t('dateTime.errors.breaksMaxDate', {
                        readableMaxDate
                    }) ||
                    ''
            });
        }

        //  An empty/unfilled date field has a value of null. Instances of null should cause a pleaseFill error and not an invalid_type kind of error.
        return nullable(validator).refine((dateValueOrNull) => dateValueOrNull !== null, {
            message: i18n.t('pleaseFill') || ''
        });
    },
    dateOptional: () =>
        nullable(
            string()
                .regex(regexDateOptional, {
                    message: i18n.t('dateTime.errors.incompleteDate') || ''
                })
                .refine((input) => (input.length ? isIsoDateString(input) : true), {
                    message: i18n.t('dateTime.errors.invalidDate') || ''
                })
        ),
    time: () =>
        string().nonempty({
            message: i18n.t('pleaseFill') || ''
        }), // Until this comment was commited we've had more elaborate validation with regex and refine. Removed due to input element ensuring correct format. Having extra validation would only cause noise when filling in value after a failed submit.
    timeOptional: () => string(),
    numericString: (props?: NumericStringValidationProps) => {
        let validator: ZodTypeAny = string().regex(regexSomethingWithNumberOrEmpty, {
            message: i18n.t('numericString.errors.invalidNumber') || ''
        });

        const { min, errorTextMin, max, errorTextMax, ignoreNonNumericChars } = props || {};

        // Ensure all non-numeric chars are ignored, or report error if there are any
        const regexNonNumericChars = /[^0-9,.+-\\ \\]/g;
        if (ignoreNonNumericChars) {
            validator = validator.transform((valueWithNumber: string) =>
                valueWithNumber.replace(regexNonNumericChars, '')
            );
        } else {
            validator = validator.refine(
                (valueWithNumber: string) => !valueWithNumber.match(regexNonNumericChars),
                {
                    message: i18n.t('numericString.errors.invalidNumber') || ''
                }
            );
        }

        // Here we would usually ensure the value is not empty, but empty values gets parsed into 0 by Number()

        // Prefer to work with value as number instead of string from here on out
        validator = validator.transform((valueWithNumber: string) =>
            Number(formatStringAsNumber(valueWithNumber))
        );

        if (min !== undefined) {
            validator = validator.refine((number: number) => number >= min, {
                message:
                    errorTextMin ||
                    i18n.t('numericString.errors.breaksMin', {
                        min
                    }) ||
                    ''
            });
        }

        if (max !== undefined) {
            validator = validator.refine((number: number) => number <= max, {
                message:
                    errorTextMax ||
                    i18n.t('numericString.errors.breaksMax', {
                        max
                    }) ||
                    ''
            });
        }

        /**
         * As a consequence of subsequent use of refine and transform we've lost track of the original decimal count.
         * Ideally we'd like to keep the inital amount of decimals and offer the possibility of a manual override
         * through props. We should be able to get around this by using superRefine, but it would probably make things
         * more complex that it's worth. For now it seems like we're just using the validator to check if input
         * validates anyways, not caring about the result.
         */
        validator = validator.transform((number: number) => formatNumberAsString(number));

        return validator;
    }
};
