import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import ActionButton from 'components/button/ActionButton';
import { useTranslation } from 'react-i18next';
import CapitechDataVisibilityExplanations from './CapitechDataVisibilityExplanations';

type CapitechDataVisibilityExplanationDialogProps = {
    onClose: () => void;
};

export default function CapitechDataVisibilityExplanationDialog({
    onClose
}: CapitechDataVisibilityExplanationDialogProps) {
    const { t } = useTranslation();

    return (
        <DialogWithCloseBtn onClose={onClose} open closeOnBackdropClick>
            <DialogTitle sx={{ marginRight: '50px' }}>{t('dashboard.iconGlossary')}</DialogTitle>
            <DialogContent>
                <CapitechDataVisibilityExplanations />
            </DialogContent>
            <DialogActions>
                <ActionButton type="button" variant="text" onClick={onClose} autoFocus>
                    {t('actions.close')}
                </ActionButton>
            </DialogActions>
        </DialogWithCloseBtn>
    );
}
