import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { InputOption } from '../InputOption';

// eslint-disable-next-line import/prefer-default-export
export function useNullOption(predefinedLabel?: string) {
    const { t } = useTranslation();

    const nullOption: InputOption = useMemo(
        () => ({
            label: predefinedLabel || t('none') || '-',
            value: '',
            isSelectable: true
        }),
        [predefinedLabel, t]
    );

    return nullOption;
}
