import useAppSettings from 'hooks/useAppSettings';
import useClientList from 'hooks/useClientList';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';

import { ForgotPasswordView } from './ForgotPasswordView';
import useForgotPasswordForm from './hooks/useForgotPasswordForm';

export default function ForgotPassword() {
    const settings = useAppSettings();
    const navigate = useNavigate();
    const formProps = useForgotPasswordForm();
    const { isSingleClient } = useClientList();

    useEffect(() => {
        if (!settings.showForgotPassword) {
            navigate(PageRoutes.Login);
        }
    });

    return <ForgotPasswordView formProps={formProps} isSingleClientLogin={isSingleClient()} />;
}
