import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { Dispatch } from 'react';
import { dateToIsoString } from 'utils/datetime';
import { postToWebtidApi } from 'utils/http';
import { AuthError } from 'utils/auth/auth.errors';
import {
    TimeTransactionsAction,
    TimeTransactionsDispatchType
} from '../ctx/TimeTransactionReducer.types';
import { CreateDefaultTimeTransactionResult, RefreshTimeDataFromApiResult } from './util.types';
import { DefaultValuesForCreate } from '../hour/hooks/useHourRegistrationForm';

export async function refreshTimeDataFromApi(
    fromDate: Date,
    toDate: Date,
    dispatch: Dispatch<TimeTransactionsAction>,
    onAuthError: (erro: AuthError) => void
): RefreshTimeDataFromApiResult {
    dispatch({ type: TimeTransactionsDispatchType.UpdatingTransactions });
    const from = dateToIsoString(fromDate);
    const to = dateToIsoString(toDate);

    const response = await postToWebtidApi<Array<ITimeTransactionDetailResult>>(
        'getTimeTransactionDetails',
        { fromDate: from, toDate: to },
        {
            onAuthError
        }
    );

    if (!response.success) {
        dispatch({
            type: TimeTransactionsDispatchType.UpdatingFailed,
            errorMessage: response.displayErrorMessage
        });
    } else {
        dispatch({
            type: TimeTransactionsDispatchType.SetTransactions,
            transactions: response.content
        });
    }
}

export async function createDefaultTimeTransaction(
    direction: 'in' | 'out',
    fromDate: Date,
    toDate: Date,
    dispatch: Dispatch<TimeTransactionsAction>,
    onAuthError: (error: AuthError) => void
): CreateDefaultTimeTransactionResult {
    dispatch({
        type: TimeTransactionsDispatchType.UpdatingTransactions
    });

    const url = direction === 'in' ? 'createDefaultTimeTransactionIn' : 'createTimeTransactionOut';
    const res = await postToWebtidApi<ITimeTransactionDetailResult>(url, {}, { onAuthError });
    if (res.success) {
        await refreshTimeDataFromApi(fromDate, toDate, dispatch, onAuthError);
    } else {
        dispatch({
            type: TimeTransactionsDispatchType.UpdatingFailed,
            errorMessage: res.displayErrorMessage || ''
        });
    }
    return res.displayErrorMessage;
}

export async function createCustomTimeTransaction(
    data: DefaultValuesForCreate,
    fromDate: Date,
    toDate: Date,
    dispatch: Dispatch<TimeTransactionsAction>,
    onAuthError: (error: AuthError) => void
): CreateDefaultTimeTransactionResult {
    dispatch({
        type: TimeTransactionsDispatchType.UpdatingTransactions
    });

    const url = 'createCustomTimeTransaction';
    const res = await postToWebtidApi<ITimeTransactionDetailResult>(url, data, { onAuthError });
    if (res.success) {
        await refreshTimeDataFromApi(fromDate, toDate, dispatch, onAuthError);
    } else {
        dispatch({
            type: TimeTransactionsDispatchType.UpdatingFailed,
            errorMessage: res.displayErrorMessage || ''
        });
    }
    return res.displayErrorMessage;
}

export function getTransactionWithoutOutTime(transactions: Array<ITimeTransactionDetailResult>) {
    return transactions.filter((t) => t.timeOut === null);
}

const DECIMAL_COUNT = 2;

/**
 * Formats a string to a number with two decimals separated by a dot
 * @param input the string to be formatted
 * @returns Returns a string formatted as X.XX
 */
export function formatStringAsNumber(input: string) {
    let newString = input.replace(/[^0-9.,-]/g, '');
    newString = newString.replace(',', '.');
    const newNumber = Number(newString);
    return Number.isNaN(newNumber) ? '0.00' : newNumber.toFixed(DECIMAL_COUNT);
}

export function formatNumberAsString(input: number) {
    return input.toFixed(DECIMAL_COUNT);
}
