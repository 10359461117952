import { Alert, Snackbar } from '@mui/material';
import { useEffect, useState } from 'react';

type SnackBarBriefAlertProps = {
    message: string | null;
    severity: 'error' | 'warning' | 'info' | 'success';
};

export default function SnackbarBriefAlert({ message, severity }: SnackBarBriefAlertProps) {
    const [displaySnackbar, setDisplaySnackbar] = useState(false);

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setDisplaySnackbar(false);
    };

    useEffect(() => {
        setDisplaySnackbar(!!message);
    }, [message]);

    return (
        <Snackbar open={displaySnackbar} autoHideDuration={5000} onClose={handleClose}>
            <Alert severity={severity}>{message}</Alert>
        </Snackbar>
    );
}
