import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { object, string, z } from 'zod';

type UsePasswordChangeFormProps = {
    onBeforeHandleSubmit: () => boolean;
};

export default function usePasswordChangeForm({
    onBeforeHandleSubmit
}: UsePasswordChangeFormProps) {
    const { t } = useTranslation();

    const formTarget = 'changePassword';
    const passwordChangeSchema = object({
        oldPassword: string().nonempty(t('pleaseFill') || undefined),
        newPassword: string().nonempty(t('pleaseFill') || undefined),
        newPasswordRepeated: string().nonempty(t('pleaseFill') || undefined)
    }).superRefine(({ oldPassword, newPassword, newPasswordRepeated }, ctx) => {
        if (oldPassword === newPassword) {
            ctx.addIssue({
                code: 'custom',
                message: t('profile.passwordChange.newPasswordMustBeDifferent'),
                path: ['newPassword']
            });
        }

        if (newPassword !== newPasswordRepeated) {
            ctx.addIssue({
                code: 'custom',
                message: t('profile.passwordChange.passwordsDoNotMatch'),
                path: ['newPasswordRepeated']
            });
        }
    });

    type PasswordChangeRequest = z.infer<typeof passwordChangeSchema>;

    const formProps = useFormWithApiIntegration<PasswordChangeRequest>(
        formTarget,
        passwordChangeSchema,
        {
            oldPassword: '',
            newPassword: '',
            newPasswordRepeated: ''
        },
        {
            onBeforeHandleSubmit
        }
    );

    const { watch } = formProps;

    /**
     * A couple of words on upcoming 'eslint-disable-next-line react-hooks/exhaustive-deps':
     *
     * We deliberately disable exhaustive deps here to avoid double validation on submit, and to avoid any unintentional
     * subscriptions to formState. The useEffects are only here to re-validate as the user changes the input fields.
     */

    // Re-validate new password as old password changes - if validation is active and new password is dirty
    useEffect(() => {
        if (formProps.formState.isValidating && formProps.formState.dirtyFields.newPassword) {
            formProps.trigger('newPassword');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('oldPassword')]); // See 'A couple of words' comment above

    // Re-validate new password repeated as new password changes - if validation is active and new password repeated is dirty
    useEffect(() => {
        if (
            formProps.formState.isValidating &&
            formProps.formState.dirtyFields.newPasswordRepeated
        ) {
            formProps.trigger('newPasswordRepeated');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('newPassword')]); // See 'A couple of words' comment above

    return formProps;
}
