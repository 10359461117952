import { Check } from '@mui/icons-material';
import {
    Box,
    CircularProgress,
    Switch as MuiSwitch,
    SwitchProps as MuiSwitchProps,
    useTheme
} from '@mui/material';

interface SwitchIconProps {
    isBusy: boolean;
    isActive: boolean;
    color?: string;
}
function SwitchIcon({ isBusy, isActive, color }: SwitchIconProps) {
    const theme = useTheme();
    const fontColor = isActive ? theme.palette.primary.contrastText : theme.palette.primary.main;
    const activeColor = color || theme.palette.primary.main;

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: 20,
                height: 20,
                borderRadius: '50%',
                backgroundColor: isActive ? activeColor : theme.palette.background.default,
                boxShadow: theme.shadows[1],
                /* Becaus CircularProgress' color property doesn't allow for contrast color to be specified, we do it like this instead */
                '& .MuiCircularProgress-colorPrimary': {
                    color: fontColor
                },
                color: fontColor
            }}
        >
            {isBusy && <CircularProgress size={14} thickness={6} />}
            {!isBusy && isActive && <Check fontSize="inherit" />}
        </Box>
    );
}
SwitchIcon.defaultProps = {
    color: undefined
};

interface SwitchProps extends Omit<MuiSwitchProps, 'checked' | 'color'> {
    isBusy: boolean;
    activeValue: string;
    value: MuiSwitchProps['value'];
    color?: string;
}

export default function Switch({
    isBusy,
    activeValue,
    value,
    color,
    ...muiSwitchProps
}: SwitchProps) {
    return (
        <MuiSwitch
            checkedIcon={
                <SwitchIcon isBusy={isBusy} isActive={value === activeValue} color={color} />
            }
            icon={<SwitchIcon isBusy={isBusy} isActive={value === activeValue} color={color} />}
            checked={value === activeValue}
            value={value}
            {...muiSwitchProps}
        />
    );
}
Switch.defaultProps = {
    color: 'primary.main'
};
