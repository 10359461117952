import { useFetchArray } from 'hooks/useFetch';
import IAbsenceTransactionResult from 'models/AbsenceTransactionResult';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { dateToIsoString } from 'utils/datetime';
import { sortBy, uniqWith } from 'lodash';
import { useSelectedTimePeriod } from './SelectedTimePeriodCtx';

type GetAbsenceTransactionInput = {
    fromDate: string;
    toDate: string;
};

type AbsenceTransactionsState = {
    absenceTransactions: Array<IAbsenceTransactionResult>;
    isLoading: boolean;
    errorMessage?: string;
};

const initialState: AbsenceTransactionsState = {
    absenceTransactions: [],
    isLoading: false,
    errorMessage: undefined
};

export const AbsenceTransactionsContext = createContext<AbsenceTransactionsState>(initialState);

export default function AbsenceTransactionsProvider({ children }: PropsWithChildren) {
    const { fromDate, toDate } = useSelectedTimePeriod();

    const fromDateString = dateToIsoString(fromDate);
    const toDateString = dateToIsoString(toDate);

    const [absenceTransactions, { isLoading, errorMessage }] = useFetchArray<
        IAbsenceTransactionResult,
        GetAbsenceTransactionInput
    >('getAbsenceTransactions', {
        reqData: {
            fromDate: fromDateString,
            toDate: toDateString
        }
    });

    const value = useMemo(() => {
        // Remove duplicate absence transactions where all properties are the same
        // This is done because absences with multiple time categories are returned from the API
        // as separate transactions with the same properties (time category is not part of the data returned from the API)
        const uniqueTransactions = uniqWith(
            absenceTransactions,
            (a, b) => JSON.stringify(a) === JSON.stringify(b)
        );

        // Sort absence transactions by date and time in ascending order
        const sortedTransactions = sortBy(uniqueTransactions, ['dateFrom', 'timeFrom']);

        return { absenceTransactions: sortedTransactions, isLoading, errorMessage };
    }, [absenceTransactions, isLoading, errorMessage]);

    return (
        <AbsenceTransactionsContext.Provider value={value}>
            {children}
        </AbsenceTransactionsContext.Provider>
    );
}
