import { enUS as datePickerEnUS, nbNO as datePickerNbNO } from '@mui/x-date-pickers';
import { enUS as coreEnUS, nbNO as coreNbNO } from '@mui/material/locale';
import { SupportedLanguage } from './support';

const MuiTranslations: Record<SupportedLanguage, Array<object>> = {
    en: [datePickerEnUS, coreEnUS],
    no: [datePickerNbNO, coreNbNO]
};

export default function getMuiTranslations(language: SupportedLanguage) {
    return MuiTranslations[language];
}
