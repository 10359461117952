import { LoadingScreenContext } from 'ctx/LoadingScreenProvider';
import { useContext } from 'react';
import { UseLoadingScreenResult } from './useLoadingScreen.types';

export default function useLoadingScreen(): UseLoadingScreenResult {
    const ctx = useContext(LoadingScreenContext);

    if (!ctx) {
        throw new Error(
            "useLoadingScreen only available inside LoadingScreenContext. You've probably forgot to set up a LoginScreenProvider."
        );
    }

    return ctx;
}
