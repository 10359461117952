import { useTranslation } from 'react-i18next';
import { InputOption } from '../../InputOption';
import { useNullOption } from '../../hooks/useNullOption';
import { UseOutputOptionsProps, UseOutputOptionsResult } from './useOutputOptions.types';

export default function useOutputOptions({
    isNativeSelect,
    isOptional,
    value,
    options,
    isLoadingOptions,
    nullLabel
}: UseOutputOptionsProps): UseOutputOptionsResult {
    const nullOption = useNullOption(nullLabel);

    // In case there are no options, just return non-selectable option indicating there no options available.
    const isVerifiedMissingOptions = (!options || options.length === 0) && !isLoadingOptions;
    const { t } = useTranslation();
    if (isVerifiedMissingOptions) {
        return [
            {
                isSelectable: false,
                label: t('noAvailableOptions'),
                value: ''
            }
        ];
    }

    /**
     * In case of native select, the select element is actually displayed within the TextField. This causes the moving label to collide with the empty option label.
     * There are two ways to go on about this:
     *
     * 1. Make the TextField always have the label up top - never inside the TextField
     * 2. Remove the conflict by setting the clear option's text as blank. Label behaves just like our custom Select.
     *
     * We opt for the second, particularly because there can be a mix of non native and native select components appearing on the page. It's important that they
     * appear the same until interacted with.
     */
    let outputOptions: Array<InputOption>;
    if (isNativeSelect) {
        /**
         * Our Custom Select inputs set as required start out being empty, but require a value to be set once interacted with. Native selects don't quite work that way.
         * Those will always show the first option in list as selected from the get-go. We achieve the same user experience as in our non native Selects by including a
         * blank first option until a value has been selected.
         */
        const isEmptySelection = !value;
        const includeNullOption = isOptional || isEmptySelection;

        if (includeNullOption) {
            const nullOptionWhenNative: InputOption = {
                isSelectable: isOptional,
                label: '',
                value: ''
            };

            outputOptions = [nullOptionWhenNative].concat(options || []);
        } else {
            outputOptions = options || [];
        }
    } else {
        const includeNullOption = isOptional;
        outputOptions = includeNullOption ? [nullOption].concat(options || []) : options || [];
    }

    return outputOptions;
}
