import { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AlertDialog from 'components/dialog/AlertDialog';
import useDashboardLoadingState from '../hooks/useDashboardLoadingState';

export default function DashboardLoadWrapper({ children }: PropsWithChildren) {
    const { errorMessage } = useDashboardLoadingState();
    const { t } = useTranslation();
    const [isErrorDialogOpen, setIsErrorDialogOpen] = useState(false);

    useEffect(() => {
        if (errorMessage) {
            setIsErrorDialogOpen(true);
        }
    }, [errorMessage]);

    return (
        <>
            <AlertDialog
                open={isErrorDialogOpen}
                severity="error"
                title={errorMessage || ''}
                confirmText={t('actions.ok')}
                onConfirm={() => setIsErrorDialogOpen(false)}
                isRunningConfirmOperation={false}
            />

            {children}
        </>
    );
}
