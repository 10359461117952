import {
    Card,
    CardContent,
    Typography,
    CardActions,
    Button,
    Alert,
    AlertTitle,
    Grid,
    Box
} from '@mui/material';
import { upperFirst } from 'lodash';
import { Trans, useTranslation } from 'react-i18next';
import { dateToIsoString, dateToStringLong } from 'utils/datetime';
import useScrollIntoViewIfNeeded from 'hooks/useScrollIntoViewIfNeeded';
import { useEffect } from 'react';
import DialogSpinner from 'components/dialog/DialogSpinner';
import { useDialog } from 'ctx/DialogCtx';
import TimeTransactionsDetails from './TimeTransactionsDetails';
import AbsenceTransactionsDetails from './AbsenceTransactionsDetails';
import InOutButtons from './InOutButtons';
import useHolidays from '../hooks/useHolidays/useHolidays';
import DutyDetails from './DutyDetails';
import CapitechDataVisibilitySwitchesDialog from './CapitechDataVisibilitySwitchesDialog';
import QuickAddButton from './QuickAddButton';

type DayDetailsProps = {
    selectedDay: Date;
    isRegistrationClosed: boolean;
    showEmptyDayMessage: boolean;
    showRegisterTimeButton?: boolean;
    showRegisterInOutButtons?: boolean;
    showRegisterQuickAddButton?: boolean;
    showRegisterAbsenceRequestButton?: boolean;
    showRegisterDutyWishButton?: boolean;
    onRegisterTimeClick: () => void;
    onRegisterAbsenceRequestClick: () => void;
    onRegisterDutyWishClick: () => void;
    hiddenItemsByFilterCount: number;
    isLoading: boolean;
};

export default function DayDetailsView({
    selectedDay,
    isRegistrationClosed,
    showEmptyDayMessage,
    showRegisterTimeButton,
    showRegisterInOutButtons,
    showRegisterQuickAddButton,
    showRegisterAbsenceRequestButton,
    showRegisterDutyWishButton,
    onRegisterTimeClick,
    onRegisterAbsenceRequestClick,
    onRegisterDutyWishClick,
    hiddenItemsByFilterCount,
    isLoading
}: DayDetailsProps) {
    const { t } = useTranslation();

    // Scrolls into view when selected day changes
    const [scrollTargetRef, scrollToTargetIfNeeded] = useScrollIntoViewIfNeeded<HTMLDivElement>();
    useEffect(() => {
        if (selectedDay && !isLoading) {
            scrollToTargetIfNeeded({ prioritizeEndOfElementIfBiggerThanViewport: true });
        }
    }, [isLoading, selectedDay, scrollToTargetIfNeeded]);

    const { holidays } = useHolidays();
    const holidayRecordsOnDate = holidays.filter(
        (holiday) => holiday.date === dateToIsoString(selectedDay)
    );

    // Dialog
    const { showDialog, clearDialogs } = useDialog();
    useEffect(() => clearDialogs, [clearDialogs]); // Clears dialogs on unmount

    // Data visibility switch dialog
    const showSwitchesDialog = () => {
        showDialog((onClose) => <CapitechDataVisibilitySwitchesDialog onClose={onClose} />);
    };

    // Handle dialog trigger keydown
    const handleKeyDownOnDialogTrigger = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
            showSwitchesDialog();
        }
    };

    if (isLoading) {
        return <DialogSpinner />;
    }

    return (
        <Card sx={{ mb: 2 }} ref={scrollTargetRef}>
            <CardContent sx={{ pb: 0 }}>
                <Typography
                    gutterBottom={!holidayRecordsOnDate.length}
                    variant="h5"
                    component="div"
                >
                    {upperFirst(dateToStringLong(selectedDay))}
                </Typography>
                {holidayRecordsOnDate.map((holiday) => (
                    <Typography
                        key={holiday.name}
                        color="holiday.main"
                        gutterBottom
                        sx={{ pb: '4px' }}
                    >
                        {holiday.name}
                    </Typography>
                ))}
                {hiddenItemsByFilterCount > 0 && (
                    <Box sx={{ pb: '4px' }}>
                        <Trans
                            i18nKey="dayDetails.hiddenItemsByFilterCount"
                            count={hiddenItemsByFilterCount}
                        >
                            {{ count: hiddenItemsByFilterCount }}
                            items hidden by
                            <Typography
                                component="span"
                                aria-haspopup="dialog"
                                title={t('dayDetails.showFilter')}
                                tabIndex={0}
                                onKeyDown={handleKeyDownOnDialogTrigger}
                                onClick={showSwitchesDialog}
                                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                            >
                                filter
                            </Typography>
                        </Trans>
                    </Box>
                )}
                {showEmptyDayMessage ? (
                    <Typography>{t('dayDetails.nothingRegisteredOnThisDate')}</Typography>
                ) : (
                    <>
                        <DutyDetails />
                        <AbsenceTransactionsDetails />
                        <TimeTransactionsDetails />
                    </>
                )}
                <Typography variant="body1" component="div">
                    {isRegistrationClosed && (
                        <Alert severity="warning">
                            <AlertTitle>{t('dayDetails.warning.registrationClosed')}</AlertTitle>
                        </Alert>
                    )}
                </Typography>
            </CardContent>
            {(showRegisterTimeButton ||
                showRegisterInOutButtons ||
                showRegisterAbsenceRequestButton ||
                showRegisterDutyWishButton) && (
                <CardActions disableSpacing>
                    <Grid container spacing={1}>
                        {(showRegisterTimeButton || showRegisterInOutButtons) && (
                            <Grid item container spacing={1}>
                                {showRegisterTimeButton && (
                                    <Grid item>
                                        <Button
                                            size="small"
                                            color="hours"
                                            onClick={onRegisterTimeClick}
                                        >
                                            {t('dayDetails.button.registerTime')}
                                        </Button>
                                    </Grid>
                                )}
                                {showRegisterInOutButtons && (
                                    <Grid item>
                                        <InOutButtons />
                                    </Grid>
                                )}
                                {showRegisterQuickAddButton && (
                                    <Grid item>
                                        <QuickAddButton />
                                    </Grid>
                                )}
                            </Grid>
                        )}
                        {showRegisterAbsenceRequestButton && (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="absence"
                                    onClick={onRegisterAbsenceRequestClick}
                                >
                                    {t('dayDetails.button.requestAbsence')}
                                </Button>
                            </Grid>
                        )}
                        {showRegisterDutyWishButton && (
                            <Grid item>
                                <Button
                                    size="small"
                                    color="shift"
                                    onClick={onRegisterDutyWishClick}
                                >
                                    {t('dayDetails.button.registerDutyWish')}
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </CardActions>
            )}
        </Card>
    );
}

DayDetailsView.defaultProps = {
    showRegisterTimeButton: false,
    showRegisterAbsenceRequestButton: false,
    showRegisterDutyWishButton: false,
    showRegisterInOutButtons: false,
    showRegisterQuickAddButton: false
};
