import { Person } from '@mui/icons-material';
import { Box, Container, Divider, Grid, InputAdornment, Link } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import ErrorMessage from 'components/error/ErrorMessage';
import SuccessMessage from 'components/form/SuccessMessage';
import HFTextField from 'components/hookForm/HFTextField';
import HFFormProvider from 'ctx/HFFormProvider';
import { UseFormWithApiIntegrationReturn } from 'hooks/useFormWithApiIntegration';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import ClientSelect from './components/ClientSelect';
import MyCapitechLogo from './components/MyCapitechLogo';
import { PostForgotPasswordPayload } from './hooks/useForgotPasswordForm';

export type ForgotPasswordViewProps = {
    formProps: UseFormWithApiIntegrationReturn<PostForgotPasswordPayload>;
    isSingleClientLogin: boolean;
};
export function ForgotPasswordView({ formProps, isSingleClientLogin }: ForgotPasswordViewProps) {
    const { t } = useTranslation();

    return (
        <Container component="main" maxWidth="sm">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    spacing: 0,
                    minHeight: '100vh',
                    p: 3
                }}
            >
                <HFFormProvider formProps={formProps}>
                    <MyCapitechLogo />
                    <Box sx={{ my: 2 }}>
                        {isSingleClientLogin
                            ? t('loginScreen.forgotPassword.helptext.singleClient')
                            : t('loginScreen.forgotPassword.helptext.multiClient')}
                    </Box>
                    <ClientSelect enterKeyHint="next" />
                    <HFTextField
                        enterKeyHint="send"
                        name="emailAddress"
                        type="text"
                        label={t('email')}
                        required
                        fullWidth
                        margin="normal"
                        autoComplete="email"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Person />
                                </InputAdornment>
                            )
                        }}
                    />

                    {formProps.displayErrorMessage && (
                        <ErrorMessage message={formProps.displayErrorMessage} />
                    )}

                    {formProps.formState.isSubmitSuccessful ? (
                        <Grid container>
                            <SuccessMessage sx={{ my: 2 }}>
                                {t('loginScreen.forgotPassword.checkYourEmail')}
                            </SuccessMessage>
                        </Grid>
                    ) : (
                        <ActionButton
                            isloading={formProps.formState.isSubmitting}
                            fullWidth
                            sx={{ my: 2 }}
                        >
                            {t('loginScreen.forgotPassword.sendResetBtn')}
                        </ActionButton>
                    )}

                    <>
                        <Divider variant="middle" sx={{ my: 2 }} />
                        <Grid container textAlign="center">
                            <Grid item xs>
                                <Link component={RouterLink} to={PageRoutes.Login} variant="body2">
                                    {t('loginScreen.forgotPassword.backToLoginLink')}
                                </Link>
                            </Grid>
                        </Grid>
                    </>
                </HFFormProvider>
            </Box>
        </Container>
    );
}
