import { Card, CardContent, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { useEmployeeSettingDefinitions, useEmployeeSettings } from '../../hooks';
import { EmployeeSettingDefinition } from '../../hooks/useEmployeeSettingDefinitions';
import SettingSwitch from './SettingSwitch';
import SettingSelectChip from './SettingSelectChip';

export default function ProfileSettings() {
    const { t } = useTranslation();
    const currentSettings = useEmployeeSettings();
    const settingDefinitions = useEmployeeSettingDefinitions();

    const isSwitchableSetting = useCallback(
        (settingDefinition: EmployeeSettingDefinition) => {
            const toggleOnLabel = t('yes');
            const toggleOffLabel = t('no');

            return (
                settingDefinition.options.length === 2 &&
                settingDefinition.options.every((definition) =>
                    [toggleOnLabel, toggleOffLabel].includes(definition.label)
                )
            );
        },
        [t]
    );

    const isSelectableSetting = useCallback(
        (settingDefinition: EmployeeSettingDefinition) => !isSwitchableSetting(settingDefinition),
        [isSwitchableSetting]
    );

    const isMinMode = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <Card>
            <CardContent sx={{ pb: 0 }}>
                <Typography component="h2" variant="h5" gutterBottom>
                    {t('profile.settings.header')}
                </Typography>

                <Grid container rowSpacing={2}>
                    {settingDefinitions.map((settingDefinition) => (
                        <Grid item xs={12} key={`${settingDefinition.key}-row`}>
                            <Grid container key={settingDefinition.key}>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    sx={{ display: 'flex', alignItems: 'center' }}
                                >
                                    <Typography
                                        component="span"
                                        variant="body1"
                                        gutterBottom={isMinMode}
                                    >
                                        {settingDefinition.label}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    {isSelectableSetting(settingDefinition) && (
                                        <SettingSelectChip
                                            name={settingDefinition.key}
                                            initValue={`${
                                                currentSettings.employeeSettings[
                                                    settingDefinition.key
                                                ] ?? ''
                                            }`}
                                        />
                                    )}
                                    {isSwitchableSetting(settingDefinition) && (
                                        <SettingSwitch
                                            name={settingDefinition.key}
                                            initValue={`${
                                                currentSettings.employeeSettings[
                                                    settingDefinition.key
                                                ] ?? ''
                                            }`}
                                        />
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
}
