import { Card, CardContent, Typography } from '@mui/material';
import KeyValueList, { KeyValueListItem } from 'components/KeyValueList/KeyValueList';
import DialogSpinner from 'components/dialog/DialogSpinner';
import useAppSettings from 'hooks/useAppSettings';
import { useAuth0ClientList } from 'hooks/useAuth0ClientList';
import { useFetch } from 'hooks/useFetch';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

type GetEmployeePersonalInformationResponse = {
    clientId: number;
    employeeId: number;
    departmentId: number | null;
    departmentName: string;
    name: string;
    address1: string;
    address2: string;
    zipCode: string;
    phoneNumber: string;
    cellPhoneNumber: string;
    emailAddress: string;
    hasAccessToUpdateTheirInformation: boolean;
};

export default function ProfileDetails() {
    const { t } = useTranslation();
    const [profileData, { isLoading }] = useFetch<GetEmployeePersonalInformationResponse>(
        'getEmployeePersonalInformation'
    );

    const isAuth0 = useAppSettings().loginSimployerAuth0;
    const { selectedClient } = useAuth0ClientList();

    // build key-value list for table from profileData
    const profileDataEntries: Array<KeyValueListItem> = useMemo(() => {
        if (!profileData) return [];

        // If typescript wouldn't complain when using the `...(profileData.address1.length ? { ... } : [])` syntax, we could avoid the unnecessary undefined entries and following filter
        const unfilteredProfileDataEntries: Array<KeyValueListItem | undefined> = [
            {
                key: t('profile.profileDetails.employeeId'),
                value: profileData?.employeeId.toString() || ''
            },
            {
                key: t('profile.profileDetails.name'),
                value: profileData?.name || ''
            },
            profileData.address1.length
                ? {
                      key: t('profile.profileDetails.address'),
                      value: [profileData.address1, profileData.address2, profileData.zipCode]
                          .filter((addressPart) => addressPart !== '')
                          .join('\r\n')
                  }
                : undefined,
            {
                key: t('profile.profileDetails.emailAddress'),
                value: profileData?.emailAddress || ''
            },
            {
                key: t('profile.profileDetails.cellPhoneNumber'),
                value: profileData?.cellPhoneNumber || ''
            },
            {
                key: t('profile.profileDetails.phoneNumber'),
                value: profileData?.phoneNumber || ''
            },
            isAuth0
                ? {
                      key: t('profile.profileDetails.selectedClient'),
                      value: selectedClient?.clientName || ''
                  }
                : undefined
        ];

        // As long as address entry is potentially inserted as undefined, we need to filter out the undefined entries
        return unfilteredProfileDataEntries.filter(
            (item): item is KeyValueListItem => item !== null && item !== undefined
        );
    }, [isAuth0, profileData, selectedClient?.clientName, t]);

    if (isLoading && !profileDataEntries.length) {
        return <DialogSpinner />;
    }
    return (
        <Card>
            <CardContent sx={{ pb: 0 }}>
                <Typography component="h2" variant="h5" gutterBottom>
                    {t('profile.profileDetails.header')}
                </Typography>

                <KeyValueList
                    aria-labelledby="absence-request-overview-header"
                    items={profileDataEntries}
                />
            </CardContent>
        </Card>
    );
}
