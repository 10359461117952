import useFieldNameGuard from 'hooks/useFieldNameGuard';
import { useFormContext } from 'react-hook-form';

type InputElement = JSX.IntrinsicElements['input']; // Referenced type is not used directly in HFHiddenProps purely for readability reasons

type HFHIddenProps = {
    name: string;
} & Omit<InputElement, 'type'>;

export default function HFHidden({ name, ...restOfProps }: HFHIddenProps) {
    const context = useFormContext();
    const { register } = context;

    useFieldNameGuard(name, context);

    return <input type="hidden" {...register(name)} {...restOfProps} />;
}
