import { Box, Container, Tab, Tabs } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PropsWithChildren, SyntheticEvent, useState } from 'react';
import LoadingOverlay from 'components/loading/LoadingOverlay';
import { VacationBalanceTab, FlexiBalanceTab } from './components';
import useBalanceNamesAvailable from './hooks/useBalanceNamesAvailable/useBalanceNamesAvailable';
import { BalanceName } from './hooks/useBalanceNamesAvailable/useBalanceNamesAvailable.types';

interface TabPanelProps {
    index: number;
    value: number;
}

function CustomTabPanel(props: PropsWithChildren<TabPanelProps>) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 1 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `tab-${index}`,
        'aria-controls': `tabpanel-${index}`
    };
}

type TabSection = {
    id: BalanceName;
    tabLabel: string;
    component: React.FC;
};

export default function Balances() {
    const { balanceNamesAvailable, isLoading } = useBalanceNamesAvailable();

    const { t } = useTranslation();

    const [value, setValue] = useState(0);

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const allPossibleBalanceNamesOrder = ['flexitime', 'vacation'] as const;
    const allPossibleTabSectionsByBalanceName: Record<BalanceName, TabSection> = {
        vacation: {
            id: 'vacation',
            tabLabel: t('balances.tabLabels.vacation'),
            component: VacationBalanceTab
        },
        flexitime: {
            id: 'flexitime',
            tabLabel: t('balances.tabLabels.flexiTime'),
            component: FlexiBalanceTab
        }
    };

    const sortedBalancesNamesAvailable =
        balanceNamesAvailable?.sort((balanceName) =>
            allPossibleBalanceNamesOrder.indexOf(balanceName)
        ) || undefined;

    const tabSectionsAvailable: Array<TabSection> = (sortedBalancesNamesAvailable || []).map(
        (balanceName) => allPossibleTabSectionsByBalanceName[balanceName]
    );

    if (!tabSectionsAvailable.length) {
        return isLoading ? <LoadingOverlay isLoading /> : null;
    }

    if (tabSectionsAvailable.length === 1) {
        const AccessibleSection = tabSectionsAvailable[0].component;

        return (
            <Container
                maxWidth="md"
                component="main"
                sx={{ mt: 4, mb: 4, flexGrow: 1, overflow: 'auto' }}
            >
                <LoadingOverlay isLoading={isLoading} />
                <AccessibleSection />
            </Container>
        );
    }

    return (
        <Container
            maxWidth="md"
            component="main"
            sx={{ mt: 4, mb: 4, flexGrow: 1, overflow: 'auto' }}
        >
            <LoadingOverlay isLoading={isLoading} />
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    {tabSectionsAvailable.map((section, index) => (
                        <Tab key={section.id} label={section.tabLabel} {...a11yProps(index)} />
                    ))}
                </Tabs>
            </Box>
            {tabSectionsAvailable.map((section, index) => (
                <CustomTabPanel key={section.id} value={value} index={index}>
                    <section.component />
                </CustomTabPanel>
            ))}
        </Container>
    );
}
