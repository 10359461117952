import { AccessTime } from '@mui/icons-material';
import TransactionCard from './TransactionCard';
import { EditButton, DeleteButton } from './buttons';
import {
    DeletableTransactionCardProps,
    EditableTransactionCardProps,
    TransactionCardProps
} from './TransactionCard.types';

export default function IncompleteTimeTransactionDetailCard({
    title,
    description,
    onEditClick,
    onDeleteClick,
    isEditable,
    isDeletable
}: TransactionCardProps & EditableTransactionCardProps & DeletableTransactionCardProps) {
    return (
        <TransactionCard
            icon={<AccessTime />}
            title={title}
            description={description}
            actions={
                (isDeletable || isEditable) && (
                    <>
                        {isEditable && <EditButton onClick={onEditClick} />}
                        {isDeletable && <DeleteButton onClick={onDeleteClick} />}
                    </>
                )
            }
            sx={{
                bgcolor: '#fff',
                borderWidth: '5px',
                borderColor: 'hours.main',
                borderStyle: 'solid'
            }}
        />
    );
}
