import { DialogActions, DialogContent, DialogTitle } from '@mui/material';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { useTranslation } from 'react-i18next';
import ActionButton from 'components/button/ActionButton';
import CapitechDataVisibilitySwitches from './CapitechDataVisibilitySwitches';

type CapitechDataVisibilitySwitchesDialogProps = {
    onClose: () => void;
};

export default function CapitechDataVisibilitySwitchesDialog({
    onClose
}: CapitechDataVisibilitySwitchesDialogProps) {
    const { t } = useTranslation();

    return (
        <DialogWithCloseBtn onClose={onClose} open closeOnBackdropClick>
            <DialogTitle>{t('dashboard.dataVisibilitySwitches.title')}</DialogTitle>
            <DialogContent>
                <CapitechDataVisibilitySwitches sx={{ flexDirection: 'column' }} />
            </DialogContent>
            <DialogActions>
                <ActionButton type="button" variant="text" onClick={onClose}>
                    {t('actions.close')}
                </ActionButton>
            </DialogActions>
        </DialogWithCloseBtn>
    );
}
