import { Avatar, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { dateToSmartString } from 'utils/datetime';
import DepartmentIcon from 'components/icon/DepartmentIcon';
import PersonIcon from 'components/icon/PersonIcon';
import GroupIcon from 'components/icon/GroupIcon';
import { useTranslation } from 'react-i18next';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { Link } from 'react-router-dom';
import Dialog from '../models/Dialog';

export type DialogListItemProps = {
    dialog: Dialog;
    selected: boolean;
};

export type DialogListItemRef = {
    scrollIntoView: () => void;
};

const DialogListItem = forwardRef<DialogListItemRef, DialogListItemProps>(
    ({ dialog, selected }, ref) => {
        const { t } = useTranslation();
        const listItemRef = useRef<any>(null);

        useImperativeHandle(ref, () => ({
            scrollIntoView: () => {
                if (listItemRef.current) {
                    listItemRef.current.scrollIntoView({ behavior: 'instant', block: 'center' });
                }
            }
        }));

        function getTitle(d: Dialog) {
            if (d.dialogType === 'Group') {
                const names = dialog.receiverNames.filter((r) => r.trim() !== d.startedBy);
                names.push(dialog.startedBy);
                return names.join(', ');
            }

            if (dialog.isOutgoing) {
                return dialog.receiverNames.filter((r) => r.trim() !== d.startedBy).join(', ');
            }

            if (dialog.isIncoming && dialog.dialogType === 'Department') {
                const names = dialog.receiverNames.filter((r) => r.trim() !== d.startedBy);
                names.push(dialog.startedBy);
                if (names.length > 2) {
                    return `${names.slice(0, 2).join(', ')}, +${names.length - 2}`;
                }
                return names.join(', ');
            }

            return dialog.startedBy;
        }

        function getDialogTypeText(dialogType: string) {
            if (dialogType === 'Department') {
                return t('messages.dialogTypes.department');
            }

            if (dialogType === 'Employee') {
                return t('messages.dialogTypes.employee');
            }

            if (dialogType === 'Group') {
                return t('messages.dialogTypes.group');
            }

            return undefined;
        }

        return (
            <ListItemButton
                ref={listItemRef}
                component={Link}
                to={`/messages?dialogId=${dialog.id}`}
                disableGutters={false}
                sx={(theme) => ({
                    '&:hover': {
                        backgroundColor: selected ? theme.palette.primary.main : undefined
                    },
                    backgroundColor: selected ? theme.palette.primary.main : undefined,
                    borderLeft: dialog.hasUnAcknowledgedMessages
                        ? `4px solid ${theme.palette.error.light}`
                        : undefined,
                    paddingLeft: dialog.hasUnAcknowledgedMessages ? '12px' : undefined
                })}
            >
                <ListItemIcon>
                    <Avatar
                        sx={(theme) => ({
                            p: theme.spacing(0),
                            background: selected ? theme.palette.grey[800] : undefined
                        })}
                        alt={getDialogTypeText(dialog.dialogType) ?? ''}
                        title={getDialogTypeText(dialog.dialogType) ?? ''}
                    >
                        {dialog.dialogType === 'Department' && <DepartmentIcon />}
                        {dialog.dialogType === 'Employee' && <PersonIcon />}
                        {dialog.dialogType === 'Group' && <GroupIcon />}
                    </Avatar>
                </ListItemIcon>
                <ListItemText
                    primaryTypographyProps={{
                        fontFamily: dialog.hasUnAcknowledgedMessages
                            ? 'GT-Walsheim-Bold'
                            : undefined,
                        color: selected ? 'white' : undefined
                    }}
                    secondaryTypographyProps={{
                        fontFamily: dialog.hasUnAcknowledgedMessages
                            ? 'GT-Walsheim-Bold'
                            : undefined,
                        color: selected ? 'white' : undefined
                    }}
                    primary={dialog.topic}
                    secondary={getTitle(dialog)}
                />
                <Typography
                    noWrap
                    flexShrink={0}
                    sx={{
                        ml: 1,
                        textAlign: 'right',
                        verticalAlign: 'middle',
                        fontFamily: dialog.hasUnAcknowledgedMessages
                            ? 'GT-Walsheim-Bold'
                            : undefined,
                        color: selected ? 'white' : undefined
                    }}
                >
                    {dateToSmartString(dialog.lastMessageCreatedOn)}
                </Typography>
            </ListItemButton>
        );
    }
);

export default DialogListItem;
