import { Stack } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import { useTranslation } from 'react-i18next';

type InOutButtonsViewProps = {
    handleInClick: VoidFunction;
    handleOutClick: VoidFunction;
    isInBtnDisabled: boolean;
    isOutBtnDisabled: boolean;
    showSpinnerIn: boolean;
    showSpinnerOut: boolean;
};

export default function InOutButtonsView({
    handleInClick,
    handleOutClick,
    isInBtnDisabled,
    isOutBtnDisabled,
    showSpinnerIn,
    showSpinnerOut
}: InOutButtonsViewProps) {
    const { t } = useTranslation();
    return (
        <Stack direction="row" spacing={1}>
            <ActionButton
                color="hours"
                size="small"
                sx={{ minWidth: '45px' }} // MAGIC NUMBER ALERT necessary to fit 4 buttons in a row on mobile
                onClick={handleInClick}
                isloading={showSpinnerIn}
                disabled={isInBtnDisabled}
            >
                {t('inoutbtn.button.in')}
            </ActionButton>

            <ActionButton
                color="hours"
                size="small"
                sx={{ minWidth: '45px' }} // MAGIC NUMBER ALERT necessary to fit 4 buttons in a row on mobile
                onClick={handleOutClick}
                disabled={isOutBtnDisabled}
                isloading={showSpinnerOut}
            >
                {t('inoutbtn.button.out')}
            </ActionButton>
        </Stack>
    );
}
