import { PropsWithChildren, createContext, useMemo } from 'react';
import { dateToIsoString } from 'utils/datetime';
import { useFetchArray } from 'hooks/useFetch';
import { Holiday } from 'models/Holiday';
import { useSelectedTimePeriod } from '../SelectedTimePeriodCtx';
import { HolidaysState } from './HolidaysProvider.types';

type GetHolidaysInput = {
    fromDate: string;
    toDate: string;
};

export const initialState: HolidaysState = {
    holidays: [],
    isLoading: false,
    errorMessage: undefined
};

export const HolidaysContext = createContext<HolidaysState>(initialState);

export default function HolidaysProvider({ children }: PropsWithChildren) {
    const { fromDate, toDate } = useSelectedTimePeriod();

    const fromDateString = dateToIsoString(fromDate);
    const toDateString = dateToIsoString(toDate);

    const [holidays, { isLoading, errorMessage }] = useFetchArray<Holiday, GetHolidaysInput>(
        'getHolidays',
        {
            reqData: {
                fromDate: fromDateString,
                toDate: toDateString
            }
        }
    );

    const value = useMemo(
        () => ({ holidays, isLoading, errorMessage }),
        [holidays, isLoading, errorMessage]
    );

    return <HolidaysContext.Provider value={value}>{children}</HolidaysContext.Provider>;
}
