import {
    Card,
    CardProps,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    SxProps,
    Theme,
    useMediaQuery
} from '@mui/material';
import useFieldNameGuard from 'hooks/useFieldNameGuard';
import { useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';

type HFCheckboxGroupProps = {
    title?: string;
    items: Array<CheckboxItem>;
    name: string;
    listSx?: SxProps<Theme>;
    wrapperSx?: CardProps['sx'];
    listHeaderBackgroundColor?: string;
};

type CheckboxItem = {
    label: string;
    value: string | number;
};

export default function HFCheckboxGroup({
    title,
    items,
    name,
    listSx,
    wrapperSx,
    listHeaderBackgroundColor
}: HFCheckboxGroupProps) {
    const standardListStyling: SxProps<Theme> = {
        paddingTop: 0,
        ...listSx
    };
    const isMobileViewport = useMediaQuery((theme: Theme) => theme.breakpoints.only('xs'));
    const mobileListStyling: SxProps<Theme> = {
        // 385px is the approx height of 7 items plus a bit of leeway to indicate scrolling on iOS.
        // 7 items chosen because one of the lists is a selection of the days of the week
        maxHeight: 385,
        position: 'relative',
        overflow: 'auto',
        ...standardListStyling
    };
    const context = useFormContext();
    const { control, formState } = context;
    const hasErrors = formState.submitCount > 0 && formState.errors[name]?.message !== undefined;

    useFieldNameGuard(name, context);

    const handleCheckboxChange = useCallback(
        (value: string | number, selectedValues: Array<string | number>) => {
            if (selectedValues.includes(value)) {
                return selectedValues.filter((v) => v !== value);
            }
            return [...selectedValues, value];
        },
        []
    );

    const handleToggleAllChecked = useCallback(
        (isAllChecked: boolean) => {
            if (isAllChecked) {
                return [];
            }
            return items.map((item) => item.value);
        },
        [items]
    );

    return (
        <Card
            sx={{
                ...wrapperSx,
                ...(hasErrors && { borderColor: 'error.main', border: 1 })
            }}
        >
            <Controller
                name={name}
                control={control}
                render={({ field: { onChange, value: selectedValues } }) => {
                    const isAllChecked = selectedValues.length === items.length;
                    const isNoneChecked = selectedValues.length === 0;
                    const hasItems = items.length > 0;

                    return (
                        <FormControl component="fieldset" variant="standard" fullWidth>
                            <List
                                dense
                                component="div"
                                role="list"
                                sx={isMobileViewport ? mobileListStyling : standardListStyling}
                            >
                                <ListSubheader
                                    disableGutters
                                    sx={
                                        listHeaderBackgroundColor
                                            ? {
                                                  backgroundColor: listHeaderBackgroundColor
                                              }
                                            : {}
                                    }
                                >
                                    <ListItemButton
                                        role="listitem"
                                        onClick={() =>
                                            onChange(handleToggleAllChecked(isAllChecked))
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                indeterminate={!isAllChecked && !isNoneChecked}
                                                checked={isAllChecked && hasItems}
                                                disabled={!hasItems}
                                                onChange={() =>
                                                    onChange(handleToggleAllChecked(isAllChecked))
                                                }
                                            />
                                        </ListItemIcon>
                                        <FormLabel component="legend" focused={false}>
                                            <ListItemText
                                                primary={title}
                                                secondary={
                                                    hasErrors
                                                        ? `${formState.errors[name]?.message}`
                                                        : undefined
                                                }
                                                secondaryTypographyProps={{ color: 'error' }}
                                            />
                                        </FormLabel>
                                    </ListItemButton>
                                    <Divider />
                                </ListSubheader>

                                {items.map((item) => (
                                    <ListItemButton
                                        key={name + item.value}
                                        role="listitem"
                                        onClick={() =>
                                            onChange(
                                                handleCheckboxChange(item.value, selectedValues)
                                            )
                                        }
                                    >
                                        <ListItemIcon>
                                            <Checkbox
                                                onChange={() =>
                                                    onChange(
                                                        handleCheckboxChange(
                                                            item.value,
                                                            selectedValues
                                                        )
                                                    )
                                                }
                                                checked={selectedValues.includes(item.value)}
                                                name={name}
                                                value={item.value}
                                            />
                                        </ListItemIcon>
                                        <ListItemText primary={item.label} />
                                    </ListItemButton>
                                ))}
                            </List>
                            {/* </FormGroup> */}
                        </FormControl>
                    );
                }}
            />
        </Card>
    );
}

HFCheckboxGroup.defaultProps = {
    title: undefined,
    listSx: {},
    wrapperSx: {},
    listHeaderBackgroundColor: undefined
};
