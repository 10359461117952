import {
    AccessTokenInvalidError,
    ERROR_RESPONSE_MESSAGE_INVALID_TOKEN,
    getStoredAccessToken
} from 'utils/auth';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { BASE_URL } from './constants';
import {
    PostOptionsAnonymous,
    PostOptionsLoggedIn,
    ApiResponse,
    PostToWebtidApiResult,
    NetworkError
} from './postToWebtidApi.types';

/**
 * Performs an API request to the WebTid API
 * @param url The API URI
 * @param data (Optional) Request data as any
 * @param isAnonymous (Optional) Determines if this request is anonymous (without auth token)
 * @returns The api result
 */
export default async function postToWebtidApi<T = any>(
    url: string,
    data: any,
    options: PostOptionsAnonymous | PostOptionsLoggedIn
): PostToWebtidApiResult<T> {
    const onAuthError = !options.isAnonymous ? options.onAuthError : undefined;
    const isAnonymous = options?.isAnonymous || false;
    const method = options?.method || 'POST';

    try {
        let accessToken;
        if (!isAnonymous) {
            const storedAuth = await getStoredAccessToken();

            if (!storedAuth) {
                throw new AccessTokenInvalidError(ERROR_RESPONSE_MESSAGE_INVALID_TOKEN);
            }

            accessToken = storedAuth;
        } else {
            accessToken = '';
        }

        let request: AxiosRequestConfig<ApiResponse> = {
            url: `${BASE_URL}/${url}`,
            method
        };

        if (method === 'POST') {
            const requestBody = { ...data, accessToken };
            request = {
                ...request,
                data: requestBody
            };
        }

        const response = await axios(request).catch((err) => {
            const errorMessageFromServer = err?.response?.data?.displayErrorMessage;

            let errorMessage: string;

            if (errorMessageFromServer) {
                errorMessage = errorMessageFromServer;
            } else if (err instanceof AxiosError && err.code === AxiosError.ERR_NETWORK) {
                throw new NetworkError('LocalizationKey: requestErrors.networkError');
            } else if (err instanceof AxiosError && err.code === AxiosError.ERR_BAD_RESPONSE) {
                errorMessage = 'LocalizationKey: requestErrors.badResponse';
            } else {
                errorMessage = 'LocalizationKey: requestErrors.unknownError';
            }

            throw errorMessage === ERROR_RESPONSE_MESSAGE_INVALID_TOKEN
                ? new AccessTokenInvalidError(errorMessage)
                : new Error(errorMessage);
        });

        const { displayErrorMessage } = response.data;
        if (displayErrorMessage === ERROR_RESPONSE_MESSAGE_INVALID_TOKEN) {
            throw new AccessTokenInvalidError(displayErrorMessage);
        }

        response.data.content = response.data.content as T;

        return response.data;
    } catch (e: any) {
        if (onAuthError) {
            onAuthError(e);
        }

        let error: string = `${e}`;
        if (e instanceof Error) {
            error = e.message;
        }

        return {
            content: [],
            displayErrorMessage: error,
            serverErrorMessage: e,
            success: false
        };
    }
}
