import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import customValidators from 'utils/customValidators';
import { array, boolean, number, object, string, z } from 'zod';
import { DateISOString, dateToIsoString } from 'utils/datetime';
import { useEffect, useState } from 'react';
import { omit } from 'lodash';
import {
    useDutyWishRegistrationValues,
    useSetDutyWishRegistrationValue
} from 'features/plan/providers/registration';
import {
    PostCreateDutyWishInput,
    PostCreateDutyWishPayload,
    PostCreateDutyWishPeriodInput,
    PostCreateDutyWishPeriodPayload
} from './useDutyWishForm.types';

/**
 * This is a bit of an odd case. The form covers both single day and period wishes, and the API
 * has different end points for these. In case date range is more than one day, we post data to
 * the period endpoint, otherwise we post to the single day endpoint. The end points differ slightly
 * in the data they expect, so we need to transform the data just before sending it.
 */

type GetDutyWishSchemaProps = {
    t: TFunction;
};

function getDutyWishSchema({ t }: GetDutyWishSchemaProps) {
    const today = dateToIsoString(new Date());

    return object({
        fromDate: customValidators.date(),
        toDate: customValidators.date(),
        requestedDaysOfWeek: array(number()).nonempty(t('pleaseSelect') || undefined),
        requestedDepartmentIds: array(number()).nonempty(t('pleaseSelect') || undefined),
        requestedDutyTypeIds: array(number()).nonempty(t('pleaseSelect') || undefined),
        commentFromEmployee: string(),
        isEmployeeAvaliableOnShortNotice: boolean()
    }).superRefine(({ fromDate, toDate }, ctx) => {
        if (fromDate < today) {
            ctx.addIssue({
                code: 'custom',
                message: t('dateTime.errors.cannotBeInThePast') as string,
                path: ['fromDate']
            });
        }

        if (toDate < fromDate) {
            ctx.addIssue({
                code: 'custom',
                message: t('dateTime.errors.cannotBeBeforeFromDate') as string,
                path: ['toDate']
            });
        }
    });
}

export type DutyWishFormData = z.infer<ReturnType<typeof getDutyWishSchema>>;

export default function useDutyWishForm(
    selectedDate: DateISOString,
    onSuccess: (
        data: PostCreateDutyWishPayload | PostCreateDutyWishPeriodPayload,
        input: PostCreateDutyWishInput | PostCreateDutyWishPeriodInput
    ) => void
) {
    const { t } = useTranslation();

    const storedValues = useDutyWishRegistrationValues(selectedDate);
    const [initValues] = useState(storedValues);

    const { fromDate: currentFromDate, toDate: currentToDate } = storedValues;
    const isPeriodWish = currentFromDate !== currentToDate;

    const schema = getDutyWishSchema({ t });

    const form = useFormWithApiIntegration<
        PostCreateDutyWishPeriodPayload | PostCreateDutyWishPayload
    >(
        isPeriodWish ? 'createDutyWishPeriod' : 'createDutyWish',
        schema,
        {
            fromDate: initValues.fromDate || selectedDate,
            toDate: initValues.toDate || selectedDate,
            requestedDaysOfWeek: initValues.requestedDaysOfWeek || [],
            requestedDepartmentIds: initValues.requestedDepartmentIds || [],
            requestedDutyTypeIds: initValues.requestedDutyTypeIds || [],
            commentFromEmployee: initValues.commentFromEmployee || '',
            isEmployeeAvaliableOnShortNotice: initValues.isEmployeeAvaliableOnShortNotice || false
        },
        {
            onSuccess,
            // TODO:: Improve 'any' type of data below. Better off left to a later PR as this would mean updating types within useFormWithApiIntegration.
            transformValidatedDataBeforeSend: (data) =>
                isPeriodWish
                    ? data
                    : {
                          ...omit(data, ['fromDate', 'toDate']),
                          date: data.fromDate
                      }
        }
    );

    const { watch: watchForm } = form;
    // Watch and update context values when form values change
    const setDutyWishRegistrationValue = useSetDutyWishRegistrationValue();
    useEffect(() => {
        const subscription = watchForm((data, { name }) => {
            const assumedDutyWishRegistrationName = name as keyof DutyWishFormData;

            // consider omitting commentFromEmployee; if (assumedDutyWishRegistrationName !== 'commentFromEmployee')
            setDutyWishRegistrationValue(
                assumedDutyWishRegistrationName,
                data[assumedDutyWishRegistrationName]
            );
        });

        return () => subscription.unsubscribe();
    }, [watchForm, setDutyWishRegistrationValue]);

    return form;
}
