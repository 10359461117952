import { useCallback } from 'react';
import { DutyAvailable } from 'models/Duty';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { AvailableDutyDetailCard } from './cards';
import RequestDutyDialog from './RequestDutyDialog';

function getDutyDescriptionString(duty: DutyAvailable) {
    const timeStr = `${duty.originalStartTime} - ${duty.originalEndTime}`;

    return `${timeStr} ${duty.dutyStrId} | ${duty.dutyDescriptions}`;
}

export default function AvailableDutyDetails({
    duty,
    onRequestDutySuccess
}: {
    duty: DutyAvailable;
    onRequestDutySuccess: VoidFunction;
}) {
    const { showDialog } = useDialog();
    const handleRequestDutyClick = useCallback(async () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn open onClose={onClose}>
                <RequestDutyDialog
                    onCancel={onClose}
                    duty={duty}
                    onSuccess={() => {
                        onRequestDutySuccess();
                        onClose();
                    }}
                />
            </DialogWithCloseBtn>
        ));
    }, [duty, onRequestDutySuccess, showDialog]);

    const { t } = useTranslation();

    return (
        <AvailableDutyDetailCard
            description={getDutyDescriptionString(duty)}
            title={t('calendar.dutyAvailable')}
            onRequestDutyClick={handleRequestDutyClick}
        />
    );
}
