import LoadingScreen from 'components/loading/LoadingScreen';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState
} from 'react';

export type LoadingScreenContextType = {
    isVisible: boolean;
    setSourceIsLoading: (sourceId: string, isLoading: boolean) => void;
};

export const LoadingScreenContext = createContext<LoadingScreenContextType>(null!);

const MS_TO_ALLOW_SUBSEQUENT_LOADING_EVENTS = 200;
/**
 * Provides one consistent full screen loader layer accessible for all components within the app.
 * Instead of using wrappers around each component requesting a loading screen to be displayed, this
 * should make cases of...
 * - "why ain't autoFocus in my form behaving as expected".
 * - one component after another triggering a loading event
 * ...a bit easier to relate to.
 *
 * Lets the loading screen be displayed for 200ms (MS_TO_ALLOW_SUBSEQUENT_LOADING_EVENTS) after the
 * last loading event has been resolved. This is to prevent the loading screen from flashing in and
 * out of view when multiple loading events are triggered in quick succession. Each firing after the
 * previous has been resolved.
 *
 * @param param0 props
 * @returns
 */
export default function LoadingScreenProvider({ children }: PropsWithChildren) {
    const [activeSources, setActiveSources] = useState<Array<string>>([]);
    const [isVisible, setIsVisible] = useState(false);
    const timeoutActive = useRef<any>(null);

    const setSourceIsLoading = useCallback<LoadingScreenContextType['setSourceIsLoading']>(
        (sourceId, isLoading) => {
            if (isLoading && !activeSources.includes(sourceId)) {
                const listWithNewSource = activeSources.concat(sourceId);
                setActiveSources(listWithNewSource);
            } else if (!isLoading && activeSources.includes(sourceId)) {
                const listWithoutSource = activeSources.filter(
                    (someSourceId) => someSourceId !== sourceId
                );
                setActiveSources(listWithoutSource);
            }
        },
        [activeSources]
    );

    useEffect(() => {
        if (activeSources.length) {
            setIsVisible(true);
            clearTimeout(timeoutActive.current);
        } else {
            timeoutActive.current = setTimeout(() => {
                setIsVisible(false);
            }, MS_TO_ALLOW_SUBSEQUENT_LOADING_EVENTS);
        }
    }, [activeSources.length]);

    const value = useMemo<LoadingScreenContextType>(
        () => ({
            isVisible,
            setSourceIsLoading
        }),
        [isVisible, setSourceIsLoading]
    );

    return (
        <LoadingScreenContext.Provider value={value}>
            {isVisible && <LoadingScreen />}
            {children}
        </LoadingScreenContext.Provider>
    );
}
