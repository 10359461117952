import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useFetch } from 'hooks/useFetch';
import { DutyWish, DutyWishPeriod } from 'models/Duty';
import { useTranslation } from 'react-i18next';

type DutyWishDeleteDialogProps = {
    dutyWish: DutyWish | DutyWishPeriod;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};

type PostDeleteDutyWishPayload = [];
type PostDeleteDutyWishInput = {
    date: string;
};
type PostDeleteDutyWishPeriodInput = {
    originalFromDate: string;
};

export default function DutyWishDeleteDialog({
    dutyWish,
    onSuccess,
    onCancel
}: DutyWishDeleteDialogProps) {
    const isDutyWishPeriod = 'fromDate' in dutyWish;
    const reqData = isDutyWishPeriod
        ? { originalFromDate: dutyWish.fromDate }
        : { date: dutyWish.date };
    const endpointURL = isDutyWishPeriod ? 'deleteDutyWishPeriod' : 'deleteDutyWish';

    const [, { errorMessage, isLoading: isDeleting, sendRequest: deleteDutyWish }] = useFetch<
        PostDeleteDutyWishPayload,
        PostDeleteDutyWishInput | PostDeleteDutyWishPeriodInput
    >(endpointURL, {
        reqData,
        manuallyTriggerRequest: true,
        onSuccess: () => onSuccess()
    });

    const { t } = useTranslation();

    return (
        <ConfirmDialog
            open
            severity="warning"
            title={t('actions.confirmDeletion')}
            confirmText={t('actions.delete')}
            isRunningConfirmOperation={isDeleting}
            onCancel={onCancel}
            onConfirm={deleteDutyWish}
            errorMessage={errorMessage}
        />
    );
}
