import { Controller, Path, useFormContext } from 'react-hook-form';
import {
    Checkbox,
    CheckboxProps,
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormGroup,
    FormHelperText
} from '@mui/material';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import useFieldNameGuard from 'hooks/useFieldNameGuard';

export type HFCheckboxProps<T extends FieldValues> = Omit<CheckboxProps, 'name'> & {
    name: Path<T>;
    label: FormControlLabelProps['label'];
    helperText?: string;
};

export default function CheckboxElement<TFieldValues extends FieldValues>({
    required,
    label,
    helperText,
    ...checkboxProps
}: HFCheckboxProps<TFieldValues>) {
    const context = useFormContext();
    const { control } = context;

    const { name } = checkboxProps;
    useFieldNameGuard(name, context);

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { value, onChange }, fieldState: { error } }) => {
                const invalid = Boolean(error);
                const errorMessage = error?.message || '';
                const displayMessage = invalid ? errorMessage : helperText;

                return (
                    <FormControl required={required} error={invalid}>
                        <FormGroup row>
                            <FormControlLabel
                                color={invalid ? 'error.main' : undefined}
                                label={label || ''}
                                control={
                                    <Checkbox
                                        {...checkboxProps}
                                        color={checkboxProps.color || 'primary'}
                                        sx={{
                                            ...checkboxProps.sx,
                                            color: invalid ? 'error.main' : undefined
                                        }}
                                        value={value}
                                        checked={!!value}
                                        onChange={() => {
                                            onChange(!value);
                                        }}
                                    />
                                }
                            />
                        </FormGroup>
                        {displayMessage && (
                            <FormHelperText error={invalid}>{displayMessage}</FormHelperText>
                        )}
                    </FormControl>
                );
            }}
        />
    );
}
CheckboxElement.defaultProps = {
    helperText: ''
};
