import { useCallback, useEffect, useState } from 'react';
import { postToWebtidApi } from 'utils/http';
import usePrevious from 'utils/usePrevious';
import useAuth from './useAuth';

export type GenericPostInput = Record<string, unknown>;
export type GenericApiPayload = unknown;

export type UseApiPostResult<
    SuccessPayload extends GenericApiPayload = GenericApiPayload, // After options.popContentArray has affected things internally
    Input extends GenericPostInput = GenericPostInput
> = [
    {
        successPayload: SuccessPayload | null;
        errorMessage: string | null;
        isSubmitting: boolean;
        clearResponse: () => void;
    },
    (postData: Input) => Promise<boolean>
];

/**
 * Most of our responses come wrapped in an array. This has become
 * daunting over the years, and from now on we'd like to unwrap this
 * array by default and define types accordingly. Thus SuccessPayload
 * should be typed like the array is not there. For those occasions where
 * you are expecting an array -and it ain't wrapped in another-, be sure
 * to set options.popContentArray to false.
 *
 * @param endpointUrl
 * @param options Additional options you might need.
 * @returns
 */
export default function useApiPost<
    SuccessPayload extends GenericApiPayload = GenericApiPayload, // After options.popContentArray has affected things internally
    Input extends GenericPostInput = GenericPostInput
>(
    endpointUrl: string,
    options: {
        popContentArray: boolean;
        isAnonymous?: boolean;
        onSuccess?: (data: SuccessPayload, formInput?: Input | null) => void;
    } = { popContentArray: true, isAnonymous: false }
): UseApiPostResult<SuccessPayload, Input> {
    const [isSubmittedWithSuccess, setIsSubmittedWithSuccess] = useState(false);
    const [successPayload, setSuccessPayload] = useState<SuccessPayload | null>(null);
    const [formInput, setFormInput] = useState<Input | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const { onAuthError } = useAuth();

    // We'd like to run the success callback as the state is updated. In order for things to fire in a most orderly way, we do it through an effect.
    const onSuccessCallback = options.onSuccess;
    const previousIsSubmittedWithSuccess = usePrevious(isSubmittedWithSuccess);
    useEffect(() => {
        if (onSuccessCallback && isSubmittedWithSuccess && !previousIsSubmittedWithSuccess) {
            const succesPayloadAsReturned = successPayload as SuccessPayload; // To support null as a possible expected value.
            onSuccessCallback(succesPayloadAsReturned, formInput);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmittedWithSuccess, previousIsSubmittedWithSuccess, successPayload, onSuccessCallback]);

    const clearResponse = useCallback(() => {
        setErrorMessage(null);
        setSuccessPayload(null);
    }, []);

    const request = async (postData: Input) => {
        let isSuccess = false;
        setIsSubmitting(true);
        setIsSubmittedWithSuccess(false);
        setFormInput(postData);
        setErrorMessage(null);

        try {
            // Would be more preferable have the possibility to type it like "options.popContentArray ? Array<SuccessPayload> : SuccessPayload", but that doesn't quite seem to work out - even with a generic extending boolean. Most preferable would be for the server to stop wrapping everything in an array, though.
            type ApiResponseContent = Array<SuccessPayload> | SuccessPayload;
            const response = await postToWebtidApi<ApiResponseContent>(endpointUrl, postData, {
                isAnonymous: options.isAnonymous,
                onAuthError
            });

            let payload: SuccessPayload | null;
            if (options.popContentArray) {
                const contentWithPayloadInArray = response.content as Array<SuccessPayload> | null;
                payload = Array.isArray(contentWithPayloadInArray)
                    ? contentWithPayloadInArray[0]
                    : null;
            } else {
                const contentIsPayload = response.content as SuccessPayload | null;
                payload = contentIsPayload;
            }

            setIsSubmittedWithSuccess(response.success);
            setSuccessPayload(response.success ? payload : null);
            setErrorMessage(
                response.displayErrorMessage ? `${response.displayErrorMessage}` : null
            );

            isSuccess = response.success;
        } catch (error) {
            setErrorMessage(`${error}`);
            setIsSubmittedWithSuccess(false);
        } finally {
            setIsSubmitting(false);
        }

        return isSuccess;
    };

    return [{ successPayload, errorMessage, isSubmitting, clearResponse }, request];
}
