import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PageRoutes from 'router/pageRoutes';
import { useDialog } from 'ctx/DialogCtx';
import { useEffect } from 'react';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import useLoadingScreen from 'hooks/useLoadingScreen';

type RequireFeatureRouteProps = {
    featureName: string;
};

export default function RequireFeatureRoute({ featureName }: RequireFeatureRouteProps) {
    const { access, isLoading } = useFeatureAccess();
    const accessUnknown = access.absence === null;
    const accessDenied = access.absence === false;
    const isIndeterminate = accessUnknown || (accessDenied && isLoading); // unavailable && loading should be given the benifit of doubt

    const { setSourceIsLoading } = useLoadingScreen();
    const location = useLocation();
    const { clearDialogs } = useDialog();

    useEffect(() => {
        if (accessDenied) {
            clearDialogs();
        }
    }, [accessDenied, clearDialogs]);

    // Loading screen if indeterminate
    useEffect(() => {
        setSourceIsLoading(`loading-feature-${featureName}-access`, isIndeterminate);
    }, [featureName, isIndeterminate, setSourceIsLoading]);
    if (isIndeterminate) {
        return null;
    }

    if (accessDenied) {
        return <Navigate to={PageRoutes.Home} state={{ from: location }} replace />;
    }

    return <Outlet />;
}
