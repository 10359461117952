// eslint-disable-next-line max-classes-per-file
export abstract class AuthError extends Error {}

export class RefreshTokenUnauthorizedError extends AuthError {
    constructor(message: string) {
        super(message);
        this.name = 'RefreshTokenUnauthorizedError';
        this.message = message;
    }
}

export const ERROR_RESPONSE_MESSAGE_INVALID_TOKEN = 'LocalizationKey: Errors_InvalidAccessToken';

export class AccessTokenInvalidError extends AuthError {
    constructor(message: string) {
        super(message);
        this.name = 'AccessTokenInvalidError';
        this.message = message;
    }
}

export class RefreshTokenExpiredError extends AuthError {
    constructor(message: string) {
        super(message);
        this.name = 'RefreshTokenExpiredError';
        this.message = message;
    }
}
