import {
    TimeTransactionsAction,
    TimeTransactionsDispatchType,
    TimeTransactionsState
} from './TimeTransactionReducer.types';

export default function timeTransactionsReducer(
    state: TimeTransactionsState,
    action: TimeTransactionsAction
) {
    switch (action.type) {
        case TimeTransactionsDispatchType.UpdatingTransactions:
            return { ...state, isLoading: true };
        case TimeTransactionsDispatchType.SetTransactions:
            return { ...state, isLoading: false, timeTransactions: action.transactions };
        case TimeTransactionsDispatchType.Create:
            return {
                ...state,
                isLoading: false,
                timeTransactions: [...state.timeTransactions, action.newTrans]
            };
        case TimeTransactionsDispatchType.RefreshSummary:
            return { ...state, shouldRefreshSummary: action.shouldRefreshSummary };
        default:
            return { ...state, isLoading: false };
    }
}
