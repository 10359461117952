import {
    ErrorOutline,
    InfoOutlined,
    PrivacyTipOutlined,
    SentimentDissatisfied,
    WarningOutlined
} from '@mui/icons-material';

export type ErrorScreenIconName = 'error' | 'warning' | 'info' | 'sad' | 'authError';

export default function ErrorScreenIcon({ iconName }: { iconName: ErrorScreenIconName }) {
    switch (iconName) {
        case 'error':
            return <ErrorOutline fontSize="inherit" />;
        case 'warning':
            return <WarningOutlined fontSize="inherit" />;
        case 'info':
            return <InfoOutlined fontSize="inherit" />;
        case 'sad':
            return <SentimentDissatisfied fontSize="inherit" />;
        case 'authError':
            return <PrivacyTipOutlined fontSize="inherit" />;
        default:
            return <SentimentDissatisfied fontSize="inherit" />;
    }
}
