import { useCallback, useEffect, useRef, useState } from 'react';
import {
    ScrollOptionProps,
    UseScrollIntoViewIfNeededResult
} from './useScrollIntoViewIfNeeded.types';

export default function useScrollIntoViewIfNeeded<
    T extends Element
>(): UseScrollIntoViewIfNeededResult<T> {
    const ref = useRef<T>(null);
    const [shouldScroll, setShouldScroll] = useState(false);
    const scrollOptions = useRef<ScrollOptionProps | undefined>(undefined);

    useEffect(() => {
        if (ref.current && shouldScroll) {
            /**
             * scroll to element if outside of viewport
             * - if there is enough room for the entire element, scroll to the top of the element
             * - if there is not enough room for the entire element and displaying the end is a priority (scrolloptions.prioritizeEndOfElementIfBiggerThanViewport), make sure the end of the element is visible at the bottom of the viewport
             */
            const { top, bottom, height } = ref.current.getBoundingClientRect();
            const needToScroll = top < 0 || bottom > window.innerHeight;

            if (needToScroll) {
                if (
                    height > window.innerHeight &&
                    scrollOptions.current?.prioritizeEndOfElementIfBiggerThanViewport
                ) {
                    const isTinyAppleTouchDevice = /iPhone|iPod/i.test(navigator.userAgent);

                    if (isTinyAppleTouchDevice) {
                        // Apple's tiny touch devices with auto hiding address bar seem to consistently miss the mark with scrollIntoView when starting out at the bottom of the page and targeting an element just added to the page. Typically when switching between dates in calendar of MyCapitech2.
                        window.scrollBy({ behavior: 'smooth', top: bottom - window.innerHeight });
                    } else {
                        ref.current!.scrollIntoView({ behavior: 'smooth', block: 'end' });
                    }
                } else {
                    ref.current!.scrollIntoView({ behavior: 'smooth' });
                }
            }
        }
        setShouldScroll(false);
    }, [shouldScroll]);

    const scrollToRef = useCallback((scrollProps?: ScrollOptionProps) => {
        scrollOptions.current = scrollProps;
        setShouldScroll(true);
    }, []);

    return [ref, scrollToRef];
}
