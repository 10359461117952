import { Close } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';

type ModalCloseButtonProps = {
    onClick: VoidFunction;
};

export default function ModalCloseButton({ onClick }: ModalCloseButtonProps) {
    const { t } = useTranslation();
    return (
        <IconButton
            size="small"
            onClick={onClick}
            aria-label={t('actions.close')}
            title={t('actions.close')}
            sx={(theme) => ({
                position: 'absolute',
                top: theme.spacing(2),
                right: theme.spacing(2),
                color: theme.palette.grey[500]
            })}
        >
            <Close />
        </IconButton>
    );
}
