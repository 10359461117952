import { Divider, List } from '@mui/material';
import AbsenceRequest from 'models/AbsenceRequest';
import { Fragment } from 'react';
import AbsencesListItem from './AbsencesListItem';

type AbsencesListProps = {
    absences: Array<AbsenceRequest>;
    currentlySelectedAbsence: AbsenceRequest | null;
};

export default function AbsencesList({ absences, currentlySelectedAbsence }: AbsencesListProps) {
    return (
        <List disablePadding sx={(theme) => ({ backgroundColor: theme.palette.background.paper })}>
            {absences.map((absence) => (
                <Fragment key={absence.id}>
                    <AbsencesListItem
                        absence={absence}
                        isSelected={currentlySelectedAbsence?.id === absence.id}
                    />
                    <Divider />
                </Fragment>
            ))}
        </List>
    );
}
