import { useContext } from 'react';
import { AbsenceTransactionsContext } from '../ctx/AbsenceTransactionsCtx';

export default function useAbsenceTransactions() {
    const ctx = useContext(AbsenceTransactionsContext);

    if (!ctx) {
        throw new Error('useAbsenceTransactions must be used within a AbsenceTransactionsProvider');
    }

    return ctx;
}
