import { useCallback, useMemo, useState } from 'react';
import { dateToIsoString } from 'utils/datetime';
import useAuth from 'hooks/useAuth';
import { useDialog } from 'ctx/DialogCtx';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import AlertDialog from 'components/dialog/AlertDialog';
import { getTranslatedError } from 'utils/translation';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import InOutButtonsView from './InOutButtonsView';
import {
    ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_BREACHING_PROCEDURE,
    ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION,
    createDefaultTimeTransaction
} from '../util';
import HourRegistration from '../hour/HourRegistration';
import useInOutAvailabilityForDate from '../hooks/useInOutAvailabilityForDate';

export default function InOutButtons() {
    const { dispatch } = useTimeTransactions();
    const [isInClicked, setIsInClicked] = useState(false);
    const [isOutClicked, setIsOutClicked] = useState(false);
    const { fromDate, toDate, selectedDate } = useSelectedTimePeriod();

    const date = useMemo(() => selectedDate || new Date('1900-01-01'), [selectedDate]);
    const { isInAvailable, isOutAvailable } = useInOutAvailabilityForDate(date);

    const { onAuthError } = useAuth();

    const { refreshSelectedTimePeriod } = useTimeTransactions();
    const { showDialog } = useDialog();

    const showError = useCallback(
        (errorMessage: string) => {
            showDialog((onClose) =>
                [
                    ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION,
                    ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_BREACHING_PROCEDURE
                ].includes(errorMessage) ? (
                    <AlertDialog
                        open
                        severity="error"
                        title={getTranslatedError(errorMessage, '')}
                        onConfirm={onClose}
                        isRunningConfirmOperation={false}
                    />
                ) : (
                    <DialogWithCloseBtn onClose={onClose} open>
                        <HourRegistration
                            isCheckingIn
                            date={dateToIsoString(date)}
                            onSuccess={() => {
                                refreshSelectedTimePeriod();
                                onClose();
                            }}
                            onCancel={onClose}
                            initErrorMessage={errorMessage}
                        />
                    </DialogWithCloseBtn>
                )
            );
        },
        [refreshSelectedTimePeriod, date, showDialog]
    );

    const handleInClick = useCallback(async () => {
        setIsInClicked(true);
        const error = await createDefaultTimeTransaction(
            'in',
            fromDate,
            toDate,
            dispatch,
            onAuthError
        );
        if (error) {
            showError(error);
        }
        setIsInClicked(false);
    }, [fromDate, toDate, dispatch, onAuthError, showError]);

    const handleOutClick = useCallback(async () => {
        setIsOutClicked(true);
        const error = await createDefaultTimeTransaction(
            'out',
            fromDate,
            toDate,
            dispatch,
            onAuthError
        );
        if (error) {
            showError(error);
        }
        setIsOutClicked(false);
    }, [fromDate, toDate, dispatch, onAuthError, showError]);

    const shouldRender = isInAvailable || isOutAvailable;
    if (!shouldRender) {
        return null;
    }

    return (
        <InOutButtonsView
            handleInClick={handleInClick}
            handleOutClick={handleOutClick}
            isInBtnDisabled={!isInAvailable || isInClicked || isOutClicked}
            isOutBtnDisabled={!isOutAvailable || isInClicked || isOutClicked}
            showSpinnerIn={isInClicked}
            showSpinnerOut={isOutClicked}
        />
    );
}
