import { MyCapitechClientSettingsContext } from 'ctx/MyCapitechClientSettingsProvider';
import { useContext } from 'react';

export default function useMyCapitechClientSettings() {
    const ctx = useContext(MyCapitechClientSettingsContext);

    if (!ctx) {
        throw new Error(
            'useMyCapitechClientSettings only available inside MyCapitechClientSettingsContext'
        );
    }

    return ctx;
}
