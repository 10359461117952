import { getRefreshTokenCookie } from 'utils/cookie';
import { getJWTPayloadStringFromCookie, parseJwtPayload } from '../auth.helpers';
import { ACCESS_TOKEN_REFRESH_BEFORE_EXPIRY_SECONDS } from '../auth.const';

/**
 * Determines if there is need to attempt to refresh the JWT token
 * @returns True if
 * - the JWT token is about to expire
 * - the JWT token is expired and we have a refresh token
 * - the JWT token is invalid and we have a refresh token
 * - the JWT token is missing and we have a refresh token
 *
 * False if
 * - the JWT token expiry is within ACCESS_TOKEN_REFRESH_BEFORE_EXPIRY_SECONDS
 * - the JWT token is expired and we don't have a refresh token
 */
export default function shouldRefreshAuthToken() {
    const jwtPayloadString = getJWTPayloadStringFromCookie();
    const refreshToken = getRefreshTokenCookie();

    if (!jwtPayloadString) {
        return !!refreshToken;
    }

    const storedAuth = parseJwtPayload(jwtPayloadString);
    const currentTime = Date.now();
    const storedAuthExpiryInMs = storedAuth.exp * 1000;
    const isExpired = storedAuthExpiryInMs <= currentTime;

    if (isExpired) {
        return !!refreshToken;
    }

    const earlyRefreshTime = currentTime + ACCESS_TOKEN_REFRESH_BEFORE_EXPIRY_SECONDS * 1000; // Refresh happens some time before access token expires
    const isStoredTokenOk = storedAuthExpiryInMs > earlyRefreshTime;

    if (isStoredTokenOk) {
        return false;
    }

    return true;
}
