import { Grid, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import { formatStringAsNumber } from 'features/dashboard/util/util';
import { useFormContext } from 'react-hook-form';

type TimeCategoryRowProps = {
    isEditable?: boolean;
    timeCategoryId: number;
    description: string;
    index: number;
};

export default function TimeCategoryRow({
    isEditable,
    timeCategoryId,
    description,
    index
}: TimeCategoryRowProps) {
    const ctx = useFormContext();
    const { register, setValue, getFieldState } = ctx;
    const { error } = getFieldState(`details.${index}.amount`);

    return (
        <ListItem divider sx={{ paddingX: 0, paddingY: 1 }}>
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <input
                        type="hidden"
                        {...register(`details.${index}.originalTimeCategoryId` as const)}
                    />
                    <input
                        type="hidden"
                        {...register(`details.${index}.newTimeCategoryId` as const)}
                    />

                    {/* Note on the use of TextField here rather than HFTextField used in the application at large:
                     * This is done in an effort to save time from typing errors that occured when using HFTextField in combination with useFieldArray().
                     * It is _not_ meant as an example to be followed.
                     * Please refer to TimeRegistration form or other components for examples of how to use HFTextField.
                     */}
                    <TextField
                        sx={{ maxWidth: '110px' }} // comfortable width for 999999.99 (6 digits + 2 decimals (max value))
                        size="small"
                        inputProps={{
                            inputMode: 'decimal',
                            pattern: '[0-9.,]*',
                            style: { textAlign: 'right' }
                        }}
                        {...register(`details.${index}.amount` as const)}
                        onBlur={(event) => {
                            const newValue = formatStringAsNumber(event.target.value);
                            setValue(`details.${index}.amount`, newValue);
                        }}
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        disabled={!isEditable}
                        aria-describedby={`catRowInput${timeCategoryId}`}
                        error={!!error}
                    />
                </Grid>
                <Grid item>
                    <ListItemText id={`catRowInput${timeCategoryId}`}>{description}</ListItemText>
                </Grid>
                <Grid item xs={12}>
                    <Typography color="error">{error?.message}</Typography>
                </Grid>
            </Grid>
        </ListItem>
    );
}

TimeCategoryRow.defaultProps = {
    isEditable: true
};
