import { dateToIsoString } from 'utils/datetime';
import { useCallback, useState } from 'react';
import { DutyPlanned } from 'models/Duty';
import { useTranslation } from 'react-i18next';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';
import useMyCapitechClientSettings from 'hooks/useMyCapitechClientSettings';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { useDialog } from 'ctx/DialogCtx';
import AlertDialog from 'components/dialog/AlertDialog';
import { getTranslatedError } from 'utils/translation';
import useAuth from 'hooks/useAuth';
import TimeRangePossiblyAdjusted from 'components/TimeRangePossiblyAdjusted';
import { Box } from '@mui/material';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import { PlannedDutyDetailCard } from './cards';
import { DefaultValuesForCreate } from '../hour/hooks/useHourRegistrationForm';
import { HourRegistration } from '../hour';
import { createCustomTimeTransaction } from '../util/util';
import { ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION } from '../util';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import useTimeRegistrationAccess from '../hooks/useTimeRegistrationAccess';

/**
 * Makes default values for the HourRegistration form based on the given duty.
 * It purposefully sets dimensions to undefined if they are not set on the duty rather than letting
 * the form use the default values for the dimensions. This is done because the values for this
 * registration should rely solely on the duty to avoid auto-filling values that might be incorrect.
 * @param duty The duty to make default values for
 * @param hasFlowPlan True if the system uses Capitech Flow Plan, false if it uses the old Plan
 * @returns An object ready to be used as default values for the HourRegistration form
 */
function makeTimeRegistrationDefaultValues(
    duty: DutyPlanned,
    hasFlowPlan: boolean
): DefaultValuesForCreate {
    return {
        dateIn: duty.dutyStartDate,
        dateOut: duty.dutyEndDate,
        timeIn: duty.dutyStartTime,
        timeOut: duty.dutyEndTime,
        competenceRoleId: duty.competenceRoleId ? duty.competenceRoleId.toString() : '',
        departmentId: duty.departmentId.toString(),
        // MC1 comment: If system uses the old Plan we need to set dutyId, and ignore dutyDefinitionId.
        dutyId: !hasFlowPlan ? duty.dutyId.toString() : '',
        // MC1 comment: If system uses Capitech Flow Plan we need to set dutyDefinitionId, and ignore
        // dutyId property because this only points at the uid for the duty in flow plan
        dutyDefinitionId: hasFlowPlan ? duty.dutyDefinitionId?.toString() : '',
        text: '',
        fridim1Id: duty.freeDimension1Id ? duty.freeDimension1Id.toString() : '',
        fridim2Id: duty.freeDimension2Id ? duty.freeDimension2Id.toString() : '',
        orderId: duty.orderId ? duty.orderId.toString() : '',
        phaseId: duty.phaseId ? duty.phaseId.toString() : '',
        projectId: duty.projectId ? duty.projectId.toString() : '',
        subProjectId: duty.subProjectId ? duty.subProjectId.toString() : '',
        taskId: duty.taskId ? duty.taskId.toString() : ''
    };
}

export default function PlannedDutyDetails({ duty }: { duty: DutyPlanned }) {
    const { fromDate, toDate, selectedDate } = useSelectedTimePeriod();
    const [isQuickAddClicked, setIsQuickAddClicked] = useState(false);

    if (!selectedDate) {
        throw new Error('Cannot display planned duties for no date in particular');
    }
    const selectedDateAsStr = dateToIsoString(selectedDate);

    const { dispatch } = useTimeTransactions();

    const { onAuthError } = useAuth();

    const { refreshSelectedTimePeriod } = useTimeTransactions();
    const { employeeRegistrationInformation } =
        useEmployeeRegistrationInformation(selectedDateAsStr);
    const { isTimeRegistrationAccessible } = useTimeRegistrationAccess(selectedDateAsStr);

    const { myCapitechClientSettings } = useMyCapitechClientSettings();

    const formDefaults = makeTimeRegistrationDefaultValues(
        duty,
        Boolean(employeeRegistrationInformation?.hasCapitechFlowPlan)
    );

    const hasQuickAdd =
        !!myCapitechClientSettings?.isQuickAddVisible && (isTimeRegistrationAccessible || false);

    const { showDialog } = useDialog();

    const showError = useCallback(
        (errorMessage: string) => {
            showDialog((onClose) =>
                errorMessage ===
                ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION ? (
                    <AlertDialog
                        open
                        severity="error"
                        title={getTranslatedError(errorMessage, '')}
                        onConfirm={onClose}
                        isRunningConfirmOperation={false}
                    />
                ) : (
                    <DialogWithCloseBtn onClose={onClose} open>
                        <HourRegistration
                            prefilledDefaultValues={formDefaults}
                            isCheckingIn={false}
                            date={selectedDateAsStr}
                            onSuccess={() => {
                                refreshSelectedTimePeriod();
                                onClose();
                            }}
                            onCancel={onClose}
                            initErrorMessage={errorMessage}
                        />
                    </DialogWithCloseBtn>
                )
            );
        },
        [showDialog, formDefaults, selectedDateAsStr, refreshSelectedTimePeriod]
    );

    const handleQuickAddClick = useCallback(async () => {
        setIsQuickAddClicked(true);

        const error = await createCustomTimeTransaction(
            formDefaults,
            fromDate,
            toDate,
            dispatch,
            onAuthError
        );

        if (error) {
            showError(error);
        }

        setIsQuickAddClicked(false);
    }, [dispatch, formDefaults, fromDate, onAuthError, showError, toDate]);

    const { t } = useTranslation();

    const isStartAdjusted = duty.isAdjusted && duty.dutyStartTime !== duty.definitionDutyStartTime;
    const isStopAdjusted = duty.isAdjusted && duty.dutyEndTime !== duty.definitionDutyEndTime;

    return (
        <PlannedDutyDetailCard
            isSubmitting={isQuickAddClicked}
            key={duty.dutyId}
            description={
                <>
                    <TimeRangePossiblyAdjusted
                        start={duty.dutyStartTime}
                        stop={duty.dutyEndTime}
                        isStartAdjusted={isStartAdjusted}
                        isStopAdjusted={isStopAdjusted}
                    />
                    {` ${duty.dutyIdString} | ${duty.dutyDescriptions}`}
                    {duty.isAdjusted && (isStartAdjusted || isStopAdjusted) && (
                        <Box sx={{ fontStyle: 'italic', mt: '8px' }}>
                            {`* ${t('detailsCard.dutyAdjustedTime', {
                                fromTime: duty.definitionDutyStartTime,
                                toTime: duty.definitionDutyEndTime
                            })}`}
                        </Box>
                    )}
                </>
            }
            title={t('calendar.dutyPlanned')}
            onQuickAddClick={handleQuickAddClick}
            hasQuickAdd={hasQuickAdd}
        />
    );
}
