import {
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    listItemClasses
} from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import useReadableDate from 'hooks/useReadableDate';
import { DutyAvailable } from 'models/Duty';
import { useTranslation } from 'react-i18next';
import HFFormProvider from 'ctx/HFFormProvider';
import HFHidden from 'components/hookForm/HFHidden';
import HFTextField from 'components/hookForm/HFTextField';
import ErrorMessage from 'components/error/ErrorMessage';
import { getTranslatedOrFallback } from 'utils/translation';
import useRequestDutyForm from '../hooks/useRequestDutyForm';

type RequestDutyFormProps = {
    duty: DutyAvailable;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};

export default function RequestDutyDialog({ duty, onCancel, onSuccess }: RequestDutyFormProps) {
    const { t } = useTranslation();
    const readableDate = useReadableDate(duty.startDate);
    const formProps = useRequestDutyForm(duty, onSuccess);

    return (
        <HFFormProvider formProps={formProps}>
            <HFHidden name="departmentId" />
            <HFHidden name="dutyPlanId" />
            <HFHidden name="dutyId" />
            <HFHidden name="originalEmployeeId" />
            <HFHidden name="originalFromDate" />
            <DialogTitle>{t('requestDuty.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {`${readableDate} - ${duty.originalStartTime} - ${duty.originalEndTime}`}
                </DialogContentText>
                <List
                    dense
                    sx={{
                        [`& .${listItemClasses.root}`]: { display: 'inline-block' }
                    }}
                >
                    <ListItem disableGutters>
                        <strong>{t('requestDuty.department').concat(': ')}</strong>
                        {`${duty.departmentId} - ${duty.departmentDescription}`}
                    </ListItem>
                    <ListItem disableGutters>
                        <strong>{t('requestDuty.duty').concat(': ')}</strong>
                        {`${duty.dutyStrId} ${duty.dutyTypeDescription || ''}`}
                    </ListItem>
                    <ListItem disableGutters>
                        <strong>{t('requestDuty.planNo').concat(': ')}</strong>
                        {duty.dutyPlanId}
                    </ListItem>
                    {duty.notes && duty.notes !== '' && (
                        <ListItem disableGutters>
                            <strong>{t('requestDuty.notes').concat(': ')}</strong>
                            {duty.notes}
                        </ListItem>
                    )}
                </List>
                <HFTextField
                    name="commentFromEmployee"
                    label={t('requestDuty.comment')}
                    maxLength={4000}
                    enterKeyHint="enter"
                    multiline
                    fullWidth
                    rows={4}
                />
                {formProps.displayErrorMessage && formProps.displayErrorMessage !== '' && (
                    <ErrorMessage
                        message={getTranslatedOrFallback(formProps.displayErrorMessage)}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <ActionButton type="button" variant="outlined" onClick={onCancel}>
                    {t('actions.cancel')}
                </ActionButton>
                <ActionButton isloading={formProps.formState.isSubmitting}>
                    {t('requestDuty.sendRequest')}
                </ActionButton>
            </DialogActions>
        </HFFormProvider>
    );
}
