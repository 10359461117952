import { lowerFirst, mapKeys, pick } from 'lodash';
import {
    allDimensionNamesSorted,
    DimensionInputName,
    DimensionInputNameUpdate,
    DimensionName
} from 'models/TimeRegistrationModels';
import {
    DimensionValuesByDimension,
    getDimensionNameAsInputNameForAbsenceRegistration
} from 'utils/dimension';
import { DimensionPostData } from './hooks/useAbsenceRequestForm';

/* eslint-disable import/prefer-default-export */
function parseDimensionNameFromDimensionInputName(
    inputName: DimensionInputName | DimensionInputNameUpdate
): DimensionName | undefined {
    const pascalCasedCandidate = inputName.substring(0, inputName.length - 2);
    const camelCasedCandidate = lowerFirst(pascalCasedCandidate);

    const assumedDimensionName = camelCasedCandidate as DimensionName;
    return allDimensionNamesSorted.includes(assumedDimensionName)
        ? assumedDimensionName
        : undefined;
}

// TODO:: See if there's a way to avoid dependency to DimensionPostData
export function pickDimensionValuesFromDefaultValues(defaultValues: Partial<DimensionPostData>) {
    const propsToLookFor = allDimensionNamesSorted.map((dimensionName) =>
        getDimensionNameAsInputNameForAbsenceRegistration(dimensionName)
    );

    const dimensionInputProps = pick(defaultValues, propsToLookFor);
    const probableSet = mapKeys(dimensionInputProps, (_value, dimensionInputName) => {
        const name =
            parseDimensionNameFromDimensionInputName(
                dimensionInputName as DimensionInputName | DimensionInputNameUpdate
            ) || dimensionInputName;
        return name;
    });

    return probableSet as DimensionValuesByDimension;
}
