import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function MyCapitechLogo() {
    const { t } = useTranslation();

    return (
        <Typography
            component="h1"
            variant="h3"
            color="#ff3046"
            mb={2}
            style={{ cursor: 'default', userSelect: 'none' }}
        >
            {t('brand.my')}
            <strong>{t('brand.capitech')}</strong>
        </Typography>
    );
}
