import { Alert, AlertTitle, AlertProps } from '@mui/material';

export type ErrorMessageProps = {
    message?: string;
    title?: string;
};

const defaultProps: ErrorMessageProps = {
    message: '',
    title: ''
};

export default function ErrorMessage({ message, title, ...rest }: ErrorMessageProps & AlertProps) {
    return (
        <Alert severity="error" data-testid="error-message" {...rest}>
            {title && <AlertTitle>{title}</AlertTitle>}
            {message}
        </Alert>
    );
}

ErrorMessage.defaultProps = defaultProps;
