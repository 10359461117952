import Dashboard from 'features/dashboard/Dashboard';
import ForgotPassword from 'features/login/ForgotPassword';
import Login from 'features/login/Login';
import { AbsenceOverview } from 'features/absence';
import { Route, Routes } from 'react-router-dom';
import { Messages } from 'features/messages';
import { ProfilePage } from 'features/profile';
import { Reports } from 'features/reports';
import { Balances } from 'features/balances';
import LoggedInView from './LoggedInView';
import PageRoutes from './pageRoutes';
import RequireFeatureRoute from './RequireFeatureRoute/RequireFeatureRoute';

export default function AppRoutes() {
    return (
        <Routes>
            <Route path={PageRoutes.Login} element={<Login />} />
            <Route path={PageRoutes.PasswordForgot} element={<ForgotPassword />} />
            <Route element={<LoggedInView />}>
                <Route path={PageRoutes.Home} element={<Dashboard />} />
                <Route path={PageRoutes.Messages} element={<Messages />} />
                <Route element={<RequireFeatureRoute featureName="absence" />}>
                    <Route path={PageRoutes.Absence} element={<AbsenceOverview />} />
                </Route>
                <Route path={PageRoutes.Balance} element={<Balances />} />
                <Route path={PageRoutes.Profile} element={<ProfilePage />} />
                <Route path={PageRoutes.Reports} element={<Reports />} />
            </Route>
        </Routes>
    );
}
