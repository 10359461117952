import { useDialog } from 'ctx/DialogCtx';
import { sortBy } from 'lodash';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { useCallback, useEffect, useState } from 'react';
import { dateToIsoString } from 'utils/datetime';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { useSelectedDate } from '../ctx/SelectedTimePeriodCtx';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import useTimeRegistrationAccess from '../hooks/useTimeRegistrationAccess';
import { HourRegistration } from '../hour';
import { TimeTransactionDetailCard, IncompleteTimeTransactionDetailCard } from './cards';
import TimeTransactionDeleteDialog from './TimeTransactionDeleteDialog';
import { ManualAdditions } from '../hour/additions';
import { TimeTransactionsDispatchType } from '../ctx/TimeTransactionReducer.types';
import useTimeTransactionsForDate from '../hooks/useTimeTransactionsForDate';

export default function TimeTransactionsDetails() {
    const selectedDate = useSelectedDate();

    const dateInIsoFormat = dateToIsoString(selectedDate);
    const { isTimeRegistrationAccessible, isManualAdditionsAvailable, isTimeEditAccessible } =
        useTimeRegistrationAccess(dateInIsoFormat, true);

    const { refreshSelectedTimePeriod, dispatch } = useTimeTransactions();

    const [renderAsExpanded, setRenderAsExpanded] = useState('');

    useEffect(() => {
        if (renderAsExpanded !== '') {
            setRenderAsExpanded('');
        }
    }, [renderAsExpanded]);

    const visibleTimeTransactionForSelectedDay = useTimeTransactionsForDate(selectedDate);

    const { showDialog, clearDialogs } = useDialog();
    useEffect(() => clearDialogs, [clearDialogs]);

    const handleEditClick = useCallback(
        (trans: ITimeTransactionDetailResult) => {
            showDialog((onClose) => (
                <DialogWithCloseBtn onClose={onClose} open>
                    <HourRegistration
                        isCheckingIn={false}
                        date={dateToIsoString(selectedDate)}
                        transaction={trans}
                        onSuccess={() => {
                            refreshSelectedTimePeriod();
                            onClose();
                        }}
                        onCancel={onClose}
                    />
                </DialogWithCloseBtn>
            ));
        },
        [refreshSelectedTimePeriod, selectedDate, showDialog]
    );

    const handleDeleteClick = useCallback(
        (transaction: ITimeTransactionDetailResult) => {
            showDialog((onClose) => (
                <TimeTransactionDeleteDialog
                    onCancel={onClose}
                    transaction={transaction}
                    onSuccess={() => {
                        onClose();
                        refreshSelectedTimePeriod();
                    }}
                />
            ));
        },
        [showDialog, refreshSelectedTimePeriod]
    );

    const handleSaveSuccess = useCallback(
        (timeTransaction: ITimeTransactionDetailResult) => {
            dispatch({
                type: TimeTransactionsDispatchType.RefreshSummary,
                shouldRefreshSummary: true
            });
            setRenderAsExpanded(timeTransaction.timeIn);
        },
        [dispatch]
    );

    const handleManualAdditionsClick = useCallback(
        (transaction: ITimeTransactionDetailResult) => {
            showDialog((onClose) => (
                <DialogWithCloseBtn maxWidth="sm" fullWidth onClose={onClose} open>
                    <ManualAdditions
                        onCancel={onClose}
                        timeTransaction={transaction}
                        onSaveSuccess={handleSaveSuccess}
                    />
                </DialogWithCloseBtn>
            ));
        },
        [handleSaveSuccess, showDialog]
    );

    const timeTransactionSortOrder: Array<Partial<keyof ITimeTransactionDetailResult>> = [
        'dateIn',
        'timeIn',
        'dateOut',
        'timeOut'
    ];
    const sortedTimeTransactions = sortBy(
        visibleTimeTransactionForSelectedDay,
        timeTransactionSortOrder
    );

    return (
        <>
            {sortedTimeTransactions.map((transaction) => {
                if (!transaction.timeOut) {
                    return (
                        <IncompleteTimeTransactionDetailCard
                            key={transaction.timeIn + transaction.dateIn + transaction.departmentId}
                            title={`${transaction.timeIn} - XX:XX`}
                            description={transaction.transactionDescriptions}
                            onEditClick={() => handleEditClick(transaction)}
                            onDeleteClick={() => handleDeleteClick(transaction)}
                            isEditable={isTimeRegistrationAccessible || false}
                            isDeletable={isTimeEditAccessible || false}
                        />
                    );
                }

                return (
                    <TimeTransactionDetailCard
                        shouldForceExpanded={renderAsExpanded === transaction.timeIn}
                        timeIn={transaction.timeIn}
                        description={transaction.transactionDescriptions}
                        key={transaction.timeIn + transaction.dateIn + transaction.departmentId}
                        title={`${transaction.timeIn} - ${transaction.timeOut || ''}`}
                        onEditClick={() => handleEditClick(transaction)}
                        onDeleteClick={() => handleDeleteClick(transaction)}
                        hasManualAdditionButton={isManualAdditionsAvailable}
                        onManualAdditionsClick={() => handleManualAdditionsClick(transaction)}
                        isEditable={isTimeRegistrationAccessible || false}
                        isDeletable={isTimeEditAccessible || false}
                        comment={transaction.comment}
                    />
                );
            })}
        </>
    );
}
