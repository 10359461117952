import { TripOrigin, Circle } from '@mui/icons-material';
import { Box, SvgIconTypeMap } from '@mui/material';
import TimeRangePossiblyAdjusted from 'components/TimeRangePossiblyAdjusted';
import { useTranslation } from 'react-i18next';

export type VisualFillVariant = 'hollow' | 'full' | 'none';

export type MuiColorType = Required<SvgIconTypeMap['props']>['color'];

export interface DateEventMetaData {
    color: MuiColorType;
    fillVariant: VisualFillVariant;
    titleFromTranslationKey: string;
}

export interface SubsetMetaData extends DateEventMetaData {
    count: number;
}

export interface DateEventType<
    TypeVariants extends string = string,
    SourceData extends object = object
> extends DateEventMetaData {
    type: TypeVariants;
    sourceData: SourceData;
    color: MuiColorType;
    isWholeDay?: boolean;
    isHoliday?: boolean;
    timeStart?: string;
    timeStop?: string | null;
    isTimeStartAdjusted?: boolean;
    isTimeStopAdjusted?: boolean;
    descripiton: string;
    fillVariant: VisualFillVariant;
    dateString: string;
    titleFromTranslationKey: string;
    timeStartAsDate: Date | null;
    timeStopAsDate: Date | null;
}

export default function DateEvent({ dateEvent }: { dateEvent: DateEventType }) {
    const { t } = useTranslation();

    let dateEventText = '';
    let dateEventContent: React.ReactElement | string;

    if (dateEvent.isWholeDay) {
        dateEventText = dateEvent.descripiton;
        dateEventContent = dateEventText;
    } else {
        const timeStartText = dateEvent.timeStart || 'XX-XX';
        const timeStopText = dateEvent.timeStop || 'XX-XX';

        const possiblyAsteriksedTimeStartText =
            dateEvent.isTimeStartAdjusted && !dateEvent.isTimeStopAdjusted
                ? `${timeStartText}*`
                : timeStartText;
        const possiblyAsteriksedTimeStopText = dateEvent.isTimeStopAdjusted
            ? `${timeStopText}*`
            : timeStopText;

        dateEventText = `${possiblyAsteriksedTimeStartText}-${possiblyAsteriksedTimeStopText} ${dateEvent.descripiton}`;
        dateEventContent = (
            <>
                <TimeRangePossiblyAdjusted
                    start={timeStartText}
                    stop={timeStopText}
                    isStartAdjusted={dateEvent.isTimeStartAdjusted || false}
                    isStopAdjusted={dateEvent.isTimeStopAdjusted || false}
                    trimSpaces
                />
                {` ${dateEvent.descripiton}`}
            </>
        );
    }

    const edgeWidth = `2px`;

    // No fillvariant, no dot - and margin instead of border
    if (dateEvent.fillVariant === 'none') {
        return (
            <Box className="date-event">
                <Box
                    className="date-event-text"
                    sx={{
                        paddingLeft: '2px',
                        margin: edgeWidth,
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '100%',
                        fontSize: '.9em',
                        color: !dateEvent.isHoliday ? `${dateEvent.color}.main` : 'inherit' // Holidays colorize the day number instead
                    }}
                >
                    <span title={dateEventText}>{dateEventContent}</span>
                </Box>
            </Box>
        );
    }

    const DotComponent = dateEvent.fillVariant === 'full' ? Circle : TripOrigin;

    return (
        <Box className="date-event">
            <span className="date-event-dot" title={dateEvent.descripiton}>
                <DotComponent
                    role="img"
                    color={dateEvent.color}
                    fontSize="inherit"
                    aria-label={`${t(dateEvent.titleFromTranslationKey)}: ${dateEvent.descripiton}`}
                />
            </span>
            <Box
                className="date-event-text"
                sx={{
                    paddingLeft: '2px',
                    border: `${edgeWidth} solid`,
                    borderColor: `${dateEvent.color}.main`,
                    backgroundColor:
                        dateEvent.fillVariant === 'hollow' ? 'inherit' : `${dateEvent.color}.main`,
                    color:
                        dateEvent.fillVariant === 'hollow'
                            ? 'inherit'
                            : `${dateEvent.color}.contrastText`,
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100%',
                    fontSize: '.9em'
                }}
            >
                <span title={dateEventText}>{dateEventContent}</span>
            </Box>
        </Box>
    );
}
