import { useAbsenceRequestsAffectingDateRange } from 'features/absence';
import { dateToIsoString } from 'utils/datetime';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import { useTimeTransactionsState } from '../ctx/TimeTransactionsProvider';
import useAbsenceTransactions from './useAbsenceTransactions';

type UseDashboardLoadingStateResult = {
    isLoading: boolean;
    errorMessage: string | null;
};

export default function useDashboardLoadingState(): UseDashboardLoadingStateResult {
    const { fromDate: from, toDate: to, selectedDate } = useSelectedTimePeriod();
    const dateInIsoFormat = selectedDate ? dateToIsoString(selectedDate) : '';
    const { isLoading: isLoadingTimeTransactions } = useTimeTransactionsState();
    const { isLoading: isLoadingAbsenceTransactions, errorMessage: errorMessageTransactions } =
        useAbsenceTransactions();
    const { isLoading: isLoadingAbsenceRequests, errorMessage: errorMessageRequests } =
        useAbsenceRequestsAffectingDateRange({
            from,
            to
        });

    const { isLoading: isLoadingEmpRegInfo } = useEmployeeRegistrationInformation(dateInIsoFormat);

    return {
        isLoading:
            isLoadingEmpRegInfo ||
            isLoadingTimeTransactions ||
            isLoadingAbsenceTransactions ||
            isLoadingAbsenceRequests,
        errorMessage: errorMessageTransactions || errorMessageRequests || null
    };
}
