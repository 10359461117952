import { getJwtCookie, getRefreshTokenCookie, deleteJwtCookie } from 'utils/cookie';
import { convertJwtToAuthData } from '../auth.helpers';
import { GetStoredAccessTokenResult } from './getStoredAccessToken.types';
import refreshAuth from '../refreshAuth/refreshAuth';
import shouldRefreshAuthToken from '../shouldRefreshAuthToken';
import { AuthData } from '../auth.types';
import { AuthError } from '../auth.errors';

function isAuthDataWithinExpiry(authData: AuthData | undefined): boolean {
    if (!authData) {
        return false;
    }
    if (authData.accessTokenExpires.getTime() < new Date().getTime()) {
        return false;
    }
    return true;
}

/**
 * Method to retrieve the stored access token from the cookie. Automatically refreshes the token if it is
 * expired and a refresh token is available.
 * @returns The stored access token if it is not expired, or undefined if it is expired or not stored
 */
export default async function getStoredAccessToken(): GetStoredAccessTokenResult {
    try {
        const shouldRefresh = shouldRefreshAuthToken();

        if (shouldRefresh) {
            let refreshToken = getRefreshTokenCookie();

            if (!refreshToken) {
                const oldJwt = getJwtCookie();

                if (!oldJwt) {
                    return undefined;
                }

                const previousAuthData = convertJwtToAuthData(oldJwt);

                if (!previousAuthData || !isAuthDataWithinExpiry(previousAuthData)) {
                    return undefined;
                }

                refreshToken = previousAuthData.refreshToken;

                if (!refreshToken) {
                    return undefined;
                }
            }

            await refreshAuth(refreshToken);
        }
        const jwt = getJwtCookie();

        if (!jwt) {
            return undefined;
        }

        const authData = convertJwtToAuthData(jwt);

        if (!authData || !isAuthDataWithinExpiry(authData)) {
            return undefined;
        }

        return jwt;
    } catch (e) {
        if (e instanceof AuthError) {
            deleteJwtCookie();
            // Deliberately leave refresh token cookie intact
        }
    }

    return undefined;
}
