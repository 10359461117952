import { Box, FormControl, FormHelperText, Popover, TextFieldProps } from '@mui/material';
import useSize from '@react-hook/size';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InputOption } from '../InputOption';
import { useNullOption } from '../hooks/useNullOption';
import SelectWithSearchAutocomplete from './SelectWithSearchAutocomplete';
import SelectWithSearchTrigger from './SelectWithSearchTrigger';

export interface SelectWithSearchProps
    extends Omit<TextFieldProps, 'select' | 'children' | 'type' | 'onChange'> {
    name: string;
    options?: Array<InputOption>;
    isLoadingOptions: boolean;
    nullLabel?: string;
    readOnly?: boolean;
    errorMessage?: string;
    value?: string;
    onChange?: (newValue: string) => void;
}

const DefaultProps = {
    errorMessage: '',
    nullLabel: undefined,
    options: [],
    readOnly: false,
    value: undefined,
    onChange: undefined
};

export default function SelectWithSearch({
    label,
    errorMessage,
    options: passedOptions,
    disabled,
    value: externallyDefinedValue,
    onChange,
    nullLabel,
    required,
    name,
    readOnly,
    isLoadingOptions
}: SelectWithSearchProps) {
    const [popoverAnchor, setPopoverAnchor] = useState<HTMLDivElement | null>(null);
    const triggerRef = useRef<HTMLDivElement>(null);
    const inputValueRef = useRef<HTMLInputElement>(null);
    const [triggerWidth] = useSize(triggerRef);

    const nullOption = useNullOption(nullLabel);
    const options = useMemo(() => {
        const dataConnectedOptions = passedOptions || [];
        return !required ? [nullOption].concat(dataConnectedOptions) : dataConnectedOptions;
    }, [passedOptions, nullOption, required]);

    const isOpenPopOver = Boolean(popoverAnchor);
    const selectedValue =
        typeof externallyDefinedValue !== 'undefined'
            ? externallyDefinedValue
            : inputValueRef.current?.value || ''; // || '' Enforces hidden input to be controlled from the get-go. It'll happen after the first render anyways when inputValueRef returns '' as value.
    const selectedOption = useMemo(
        () => options.find((option) => selectedValue !== '' && option.value === selectedValue),
        [selectedValue, options]
    );

    const { t } = useTranslation();

    const textIfEmpty =
        (!passedOptions || passedOptions.length === 0) && !isLoadingOptions
            ? t('noAvailableOptions')
            : '';

    const [displayedText, setDisplayedText] = useState<string>(
        selectedOption?.label || textIfEmpty
    );

    const openPopover = useCallback(() => {
        setPopoverAnchor(triggerRef.current);
    }, [setPopoverAnchor, triggerRef]);

    const closePopover = useCallback(() => {
        setPopoverAnchor(null);
    }, [setPopoverAnchor]);

    const setValue = useCallback(
        (newValue: string) => {
            if (inputValueRef.current) {
                inputValueRef.current.value = newValue;
            }

            if (onChange) {
                onChange(newValue);
            }
        },
        [onChange]
    );

    const setOptionAndClose = useCallback(
        (option: InputOption | null) => {
            const newValue = option?.value || '';
            setValue(newValue);
            setPopoverAnchor(null);
        },
        [setPopoverAnchor, setValue]
    );

    // Sync text according to selected value
    useEffect(() => {
        // Leave the text as-is as while popover is open
        if (isOpenPopOver) return;

        if (selectedOption) {
            if (displayedText !== selectedOption.label) {
                setDisplayedText(selectedOption.label);
            }
        } else {
            setDisplayedText(textIfEmpty);
        }
    }, [
        setDisplayedText,
        displayedText,
        selectedOption,
        selectedValue,
        isOpenPopOver,
        textIfEmpty
    ]);

    // If value is set without a corresponding option, it should be cleared
    useEffect(() => {
        if (selectedValue && !selectedOption) {
            setValue('');
        }
    }, [setValue, selectedOption, selectedValue]);

    return (
        <FormControl fullWidth error={!!errorMessage}>
            <input name={name} ref={inputValueRef} type="hidden" value={selectedValue} />
            <SelectWithSearchTrigger
                ref={triggerRef}
                label={label}
                textToDisplay={displayedText}
                onTrigger={openPopover}
                isTriggered={isOpenPopOver}
                errorMessage={errorMessage}
                disabled={isLoadingOptions || disabled}
                readOnly={readOnly}
                required={required}
            />
            <FormHelperText>{errorMessage}</FormHelperText>
            <Popover
                id="popover"
                open={isOpenPopOver}
                anchorEl={popoverAnchor}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transitionDuration={0}
            >
                <Box
                    sx={{
                        width: `${triggerWidth}px`,
                        // TODO:: Consider global style
                        ul: {
                            my: 0
                        }
                    }}
                >
                    <SelectWithSearchAutocomplete
                        selectedOption={selectedOption}
                        onClose={closePopover}
                        onSelectOption={setOptionAndClose}
                        options={options}
                    />
                </Box>
            </Popover>
        </FormControl>
    );
}
SelectWithSearch.defaultProps = DefaultProps;
