import { TextFieldProps } from '@mui/material';
import { DatePickerProps as MuiDatePickerProps } from '@mui/x-date-pickers';
import { FieldChangeHandlerContext } from '@mui/x-date-pickers/internals';
import { DateI18n } from 'i18n';

type MuiDatePickerWithDateI18nProps = MuiDatePickerProps<DateI18n>;

/* Try to set type such that both are required if one is specified */
interface MuiDatePickerPropsOverridden {
    value?: string;
    onChange?: (value: string | null, context?: FieldChangeHandlerContext<unknown>) => void;
}
type MuiDatePickerPropsOverriddenNames = keyof MuiDatePickerPropsOverridden;

type MuiDatePickerPropsProxied = Omit<
    MuiDatePickerWithDateI18nProps,
    MuiDatePickerPropsOverriddenNames
>;

export type MuiDatePickerPropsChangingValueFormat = Pick<
    MuiDatePickerWithDateI18nProps,
    MuiDatePickerPropsOverriddenNames
>;

export const datePickerPropNamesJustForTextFieldSlot = [
    'onBlur',
    'error',
    'helperText',
    'required',
    'fullWidth'
] as const;
type TextFieldSlotProps = typeof datePickerPropNamesJustForTextFieldSlot[number];

export type DatePickerProps = MuiDatePickerPropsProxied &
    MuiDatePickerPropsOverridden &
    Pick<TextFieldProps, TextFieldSlotProps | 'name'> & {
        sourceFormat?: string;
        compact?: boolean;
    };

export type DatePickerResult = JSX.Element;

export type NativeDatePickerProps = Pick<
    DatePickerProps,
    'value' | 'onChange' | 'name' | 'maxDate' | 'minDate' | 'label' | 'required'
> &
    Omit<TextFieldProps, 'onChange'>;
