import {
    Box,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Stack
} from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import { useTranslation } from 'react-i18next';
import HFFormProvider from 'ctx/HFFormProvider';
import HFTextField from 'components/hookForm/HFTextField';
import HFDropdown from 'components/hookForm/HFDropdown';
import { useDimensionOptionsSet } from 'features/misc/dimensionInput';
import { RegistrationVisibility } from 'models/TimeRegistrationModels';
import ErrorMessage from 'components/error/ErrorMessage';
import { getTranslatedOrFallback } from 'utils/translation';
import useStartDialogRequestForm from './hooks/useStartDialogRequestForm';

interface StartDialogProps {
    onSuccess: (dialogId: string) => void;
    onCancel: VoidFunction;
}

export default function StartDialog({ onCancel, onSuccess }: StartDialogProps) {
    const { t } = useTranslation();
    const formProps = useStartDialogRequestForm(onSuccess);

    const { dimensionOptionsSet, dimensionNamesLoading } = useDimensionOptionsSet(
        [
            {
                name: 'department',
                isRequired: true,
                visibility: RegistrationVisibility.Show,
                defaultValue: null
            }
        ],
        { department: '' },
        {}
    );

    return (
        <Box>
            <HFFormProvider formProps={formProps}>
                <DialogTitle>{t('messages.startDialog.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText marginBottom={2}>
                        {t('messages.startDialog.description')}
                    </DialogContentText>
                    <Stack spacing={2}>
                        <HFDropdown
                            name="receivingDepartmentId"
                            label={t('dimension.department')}
                            enterKeyHint="next"
                            fullWidth
                            required
                            options={dimensionOptionsSet.department}
                            isLoadingOptions={dimensionNamesLoading.includes('department')}
                        />

                        <HFTextField
                            name="subject"
                            label={t('messages.startDialog.subject')}
                            enterKeyHint="next"
                            fullWidth
                            required
                        />

                        <HFTextField
                            name="body"
                            label={t('absenceRequestRegistration.message')}
                            enterKeyHint="enter"
                            multiline
                            fullWidth
                            rows={4}
                        />
                        {formProps.displayErrorMessage && (
                            <ErrorMessage
                                message={getTranslatedOrFallback(
                                    formProps.displayErrorMessage,
                                    'messages.startDialog.errors.generic'
                                )}
                            />
                        )}
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <ActionButton type="button" variant="outlined" onClick={onCancel}>
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formProps.formState.isSubmitting}>
                        {t('messages.startDialog.actions.send')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}

StartDialog.defaultProps = {};
