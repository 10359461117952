import { capitechDataVisuals } from 'features/dashboard/util';
import TransactionCard from './TransactionCard';
import {
    DeletableTransactionCardProps,
    EditableTransactionCardProps,
    TransactionCardProps
} from './TransactionCard.types';
import { DeleteButton, EditButton } from './buttons';

export default function DutyWishDetailCard({
    title,
    description,
    isEditable,
    onDeleteClick,
    expandedInfo,
    onEditClick
}: TransactionCardProps & EditableTransactionCardProps & DeletableTransactionCardProps) {
    const { icon: Icon } = capitechDataVisuals.dutyRequested;
    return (
        <TransactionCard
            sx={{ bgcolor: 'shift.main' }}
            icon={<Icon />}
            title={title}
            description={description}
            expandedInfo={expandedInfo}
            actions={
                <>
                    {isEditable && <EditButton onClick={onEditClick} />}
                    <DeleteButton onClick={onDeleteClick} />
                </>
            }
        />
    );
}
