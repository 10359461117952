import { useContext, useEffect } from 'react';
import { EmployeeRegistrationInformationContext } from '../../ctx/EmployeeRegistrationInformationProvider';
import { UseEmployeeRegistrationInformationResult } from './useEmployeeRegistrationInformation.types';

export default function useEmployeeRegistrationInformation(
    dateInIsoFormat: string,
    requireFreshData = false
): UseEmployeeRegistrationInformationResult {
    const ctx = useContext(EmployeeRegistrationInformationContext);

    if (!ctx) {
        throw new Error(
            'useEmployeeRegistrationInformation only available inside EmployeeRegistrationInformationContext'
        );
    }

    const {
        getFetchedEmployeeRegistrationInformation,
        isLoadingDate,
        hasOldDataForDate,
        startFetchingRegistrationInformation
    } = ctx;

    const employeeRegistrationInformation =
        getFetchedEmployeeRegistrationInformation(dateInIsoFormat);
    const isLoading = isLoadingDate(dateInIsoFormat);
    const isProvidedOldData = hasOldDataForDate(dateInIsoFormat);

    const needsFetching =
        (!employeeRegistrationInformation && !isLoading) || (isProvidedOldData && requireFreshData);

    useEffect(() => {
        if (needsFetching) {
            startFetchingRegistrationInformation(dateInIsoFormat);
        }
    }, [needsFetching, startFetchingRegistrationInformation, dateInIsoFormat]);

    return {
        employeeRegistrationInformation,
        isLoading
    };
}
