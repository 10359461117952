import { ListItem, ListItemButton, ListItemText, ListSubheader } from '@mui/material';
import useApiPost from 'hooks/useApiPost';
import useAuth from 'hooks/useAuth';
import { useAuth0ClientList } from 'hooks/useAuth0ClientList';
import useErrorScreen from 'hooks/useErrorScreen';
import { useCallback, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AuthApiResult } from 'utils/auth';
import { getRefreshTokenCookie, setPreviouslySelectedClientIdCookie } from 'utils/cookie';

export default function Auth0ClientSelectionMenu() {
    const { clientList } = useAuth0ClientList();
    const { t } = useTranslation();
    const { onSignInSuccess } = useAuth();
    const { setError } = useErrorScreen();
    const refreshToken = getRefreshTokenCookie();

    // We need to set the client id into the cookie, but ideally only after the client swap
    // was successful. Client id is not available in the response, so rather than muddy up
    // the useApiPost hook with this, we use a ref. This is hacky and not ideal, but it works.
    const newRequestedClientId = useRef<number | null>(null);

    const [{ isSubmitting, errorMessage }, requestNewClient] = useApiPost<AuthApiResult>(
        '/changeClient',
        {
            popContentArray: true,
            isAnonymous: true,
            onSuccess: (data) => {
                if (!data) {
                    setError({ message: errorMessage || t('error') });
                    return;
                }

                onSignInSuccess(data, true);

                if (newRequestedClientId.current) {
                    setPreviouslySelectedClientIdCookie(newRequestedClientId.current.toString());
                }

                window.location.reload();
            }
        }
    );

    const handleSwapClientClick = useCallback(
        (clientId: number) => {
            if (isSubmitting) return;
            newRequestedClientId.current = clientId;

            requestNewClient({ clientId, refreshToken });
        },
        [isSubmitting, refreshToken, requestNewClient]
    );

    return (
        <>
            <ListSubheader>{t('navbar.swapClient')}</ListSubheader>
            {clientList.map((client) => (
                <ListItem
                    disableGutters
                    disablePadding
                    key={client.clientId}
                    value={client.clientId}
                >
                    <ListItemButton onClick={() => handleSwapClientClick(client.clientId)}>
                        <ListItemText>
                            {client.clientId} {' - '} {client.clientName}
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            ))}
        </>
    );
}
