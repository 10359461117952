import { Fade, LinearProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

type InlineLoadingSpinnerProps = {
    msTransitionDelay?: number;
};

export default function InlineLoadingSpinner({ msTransitionDelay }: InlineLoadingSpinnerProps) {
    const { t } = useTranslation();
    return (
        <Fade in timeout={300} style={{ transitionDelay: `${msTransitionDelay}ms` }}>
            <Stack role="status" aria-busy my={1}>
                <LinearProgress aria-label={t('loadingScreen.progressLabel')} color="inherit" />
                <Typography mt={1}>{t('loadingScreen.progressLabel')}</Typography>
            </Stack>
        </Fade>
    );
}

InlineLoadingSpinner.defaultProps = {
    msTransitionDelay: 1000
};
