import { Container, Grid, Theme, useMediaQuery } from '@mui/material';
import useQuery from 'hooks/useQuery';
import { useMemo, useRef } from 'react';
import { dateToIsoString } from 'utils/datetime';
import InlineLoadingSpinner from 'components/loading/InlineLoadingSpinner';
import ErrorMessage from 'components/error/ErrorMessage';
import dayjs from 'dayjs';
import AbsencesList from './AbsencesList/AbsencesList';
import { useAbsenceRequestsStartingOnOrAfterDate } from '../providers';
import AbsencePageHeader from './AbsencePageHeader';
import AbsenceDetails from './AbsenceDetails/AbsenceDetails';

export default function AbsenceOverview() {
    const query = useQuery();
    const absenceId = useMemo(() => query.get('absenceId'), [query]);

    const firstDateThisYearInIsoFormat = useMemo(() => {
        const firstDateThisYear = new Date(new Date().getFullYear(), 0, 1);
        return dateToIsoString(firstDateThisYear);
    }, []);

    const { absenceRequests, isLoading, errorMessage } = useAbsenceRequestsStartingOnOrAfterDate(
        firstDateThisYearInIsoFormat
    );

    const currentlySelectedAbsence = useMemo(
        () => absenceRequests?.find((d) => d.id.toString() === absenceId) ?? null,
        [absenceId, absenceRequests]
    );

    // sort absence requests by fromDate in descending order
    const sortedAbsenceRequests = useMemo(
        () =>
            absenceRequests?.sort((a, b) => (dayjs(a.fromDate).isAfter(b.fromDate) ? -1 : 1)) ?? [],
        [absenceRequests]
    );

    const listContainerRef = useRef<any>(null);
    const { current: currentContainerRef } = listContainerRef;
    const topOffset = useMemo(() => {
        if (!currentContainerRef) {
            return 0;
        }
        return Math.floor(currentContainerRef.offsetTop);
    }, [currentContainerRef]);

    const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
    const showHeaderAndList = !isMobile || !currentlySelectedAbsence;

    if (isLoading) {
        return <InlineLoadingSpinner />;
    }

    if (errorMessage && errorMessage.length > 0) {
        <ErrorMessage message={errorMessage} sx={{ m: 2 }} />;
    }

    return (
        <Container maxWidth={currentlySelectedAbsence ? 'lg' : 'md'}>
            {showHeaderAndList && <AbsencePageHeader />}
            <Grid
                container
                spacing={1}
                marginTop={2}
                ref={listContainerRef}
                sx={{
                    height: currentlySelectedAbsence ? `calc(100vh - ${topOffset}px)` : 'auto',
                    boxSizing: 'border-box'
                }}
            >
                {showHeaderAndList && (
                    <Grid
                        item
                        xs
                        sx={{
                            height: '100%',
                            overflow: 'auto',
                            display: {
                                xs: currentlySelectedAbsence ? 'none' : 'block',
                                md: 'block'
                            }
                        }}
                    >
                        <AbsencesList
                            absences={sortedAbsenceRequests}
                            currentlySelectedAbsence={currentlySelectedAbsence}
                        />
                    </Grid>
                )}
                {currentlySelectedAbsence && (
                    <Grid item xs>
                        <AbsenceDetails absence={currentlySelectedAbsence} />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
}
