import { SvgIcon, SvgIconProps } from '@mui/material';

type SimployerLogoMinProps = SvgIconProps;

export default function DutyRequestIcon(props: SimployerLogoMinProps) {
    return (
        <SvgIcon {...props}>
            <svg
                version="1.1"
                id="svg1"
                viewBox="0 0 1097.1251 1280.4974"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs id="defs1">
                    <clipPath clipPathUnits="userSpaceOnUse" id="clipPath10">
                        <rect
                            opacity={0.34}
                            fill="#000000"
                            strokeWidth={7.5}
                            id="rect11"
                            width="8228.4385"
                            height="9603.7314"
                            x="5897.4365"
                            y="-14801.865"
                            transform="scale(1,-1)"
                        />
                    </clipPath>
                </defs>
                <g id="g1" transform="translate(-786.32484,-693.08473)">
                    <g id="group-R5">
                        <path
                            id="path3"
                            d="M 7900,7400 V 9000 H 6300"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={600}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path4"
                            d="M 10088.1,7200 H 8348.53 c -159.13,0 -311.74,63.2 -424.27,175.7 L 6275.73,9024.3 C 6163.21,9136.8 6100,9289.4 6100,9448.5 V 14600 h 5600 V 9656"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={600}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path5"
                            d="m 11700,6800 v 0 c -662.7,0 -1200,537.3 -1200,1200 v 800 l -856.27,1107.1 c -196.5,254 -173.57,614.5 53.54,841.7 v 0 c 249.22,249.2 653.33,249.2 902.53,0 L 12088,9260.5"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={600}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path6"
                            d="m 13900,5400 v 600 h -3200 v -600"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={600}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path7"
                            d="m 12300.6,11699.3 708,-767 c 312.4,-338.3 463.8,-794.8 415.6,-1252.7 L 13100,6600 l 200,-600"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={600}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path8"
                            d="M 8100,13400 H 9700"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={500}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                        <path
                            id="path10"
                            d="m 7300,12200 h 3200"
                            fill="none"
                            stroke="#313b38"
                            strokeWidth={500}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit={10}
                            strokeDasharray="none"
                            strokeOpacity={1}
                            transform="matrix(0.13333333,0,0,-0.13333333,0,2666.6667)"
                        />
                    </g>
                </g>
            </svg>
        </SvgIcon>
    );
}
