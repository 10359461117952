import { useDutyWishRegistrationInputOptions } from '../providers/registration';
import { DutyWishRegistrationProps } from './DutyWishRegistration.types';
import DutyWishRegistrationView from './DutyWishRegistrationView';
import { useDutyWishForm } from './hooks';

export default function DutyWishRegistration({
    sx,
    onCancel,
    onSuccess,
    initDate
}: DutyWishRegistrationProps) {
    const formSetup = useDutyWishForm(initDate, onSuccess);

    const options = useDutyWishRegistrationInputOptions();

    return (
        <DutyWishRegistrationView
            sx={sx}
            formProps={formSetup}
            isRegisteringNew
            isLoading={options.isLoading}
            options={options}
            onCancel={onCancel}
        />
    );
}
