/**
 * Component meant for DateCalendar. Started out just doing things directly in slots.day, but then there was this;
 * https://github.com/jsx-eslint/eslint-plugin-react/blob/master/docs/rules/no-unstable-nested-components.md
 */

import { PickersDayProps } from '@mui/x-date-pickers';
import { dateToIsoString } from 'utils/datetime';
import { DateI18n } from '../../i18n';
import { DateEventSetsByDate } from './DateEventGroupWrapper';
import DateWithEvents from './DateWithEvents';

type CustomDayProps = PickersDayProps<DateI18n> & {
    dateEventSets: DateEventSetsByDate;
    monthInFocusDate: Date;
};

function CustomDay({ day, dateEventSets, monthInFocusDate, ...restOfProps }: CustomDayProps) {
    const fullDate = dateToIsoString(day.toDate());
    const dateEventsetToDay = dateEventSets[fullDate] || [];

    return (
        <DateWithEvents
            day={day}
            monthFocused={monthInFocusDate}
            dateEventSet={dateEventsetToDay}
            {...restOfProps}
        />
    );
}

export default CustomDay;
