import HFHidden from 'components/hookForm/HFHidden';
import HFDropdown from 'components/hookForm/HFDropdown';
import { isEmpty } from 'lodash';
import { DimensionName, RegistrationVisibility } from 'models/TimeRegistrationModels';
import { useTranslation } from 'react-i18next';
import { DimensionValuesByDimension } from 'utils/dimension';
import { ArrayElement } from 'utils/types';
import { DimensionOptionsByDimension } from './hooks/useDimensionOptionsSet.helpers';
import { DimensionInputRules } from './types/DimensionInput';

export type OnDimensionChange = (dimensionName: DimensionName, value: string) => void;

type DimensionInputListProps = {
    inputRules: DimensionInputRules;
    dimensionOptionsSet: DimensionOptionsByDimension;
    dimensionNamesLoading: Array<DimensionName>;
    transformInputName?: (name: DimensionName) => string; // Necessary for instances differs between create and update of an item
    initValuesByDimensionName: DimensionValuesByDimension;
};

function isHiddenDimension(rule: ArrayElement<DimensionInputRules>, initValue: string) {
    const isSuggestingHidden = rule.visibility === RegistrationVisibility.Hidden;
    const isPreventingHidden = rule.isRequired && isEmpty(initValue); // If we'd check against actual value the field would disappear as soon as an option is selected, which is not what we want.

    return isSuggestingHidden && !isPreventingHidden;
}

function isReadOnlyDimension(rule: ArrayElement<DimensionInputRules>, initValue: string) {
    const isSuggestingReadOnly = rule.visibility === RegistrationVisibility.ReadOnly;
    const isPreventingReadOnly = rule.isRequired && isEmpty(initValue); // If we'd check against actual value the field would be read only as soon as an option is selected, which is not what we want.

    return isSuggestingReadOnly && !isPreventingReadOnly;
}

export default function DimensionInputList({
    inputRules,
    dimensionOptionsSet,
    dimensionNamesLoading,
    transformInputName,
    initValuesByDimensionName // We need these as default values are not yet exposed by form in a simple manner
}: DimensionInputListProps) {
    const { t } = useTranslation();
    return (
        <>
            {inputRules.map((dimensionInputRule) =>
                isHiddenDimension(
                    dimensionInputRule,
                    initValuesByDimensionName[dimensionInputRule.name] || ''
                ) ? (
                    <HFHidden
                        key={`time-dimension-${dimensionInputRule.name}`}
                        name={
                            transformInputName
                                ? transformInputName(dimensionInputRule.name)
                                : dimensionInputRule.name
                        }
                    />
                ) : (
                    <HFDropdown
                        key={`time-dimension-${dimensionInputRule.name}`}
                        name={
                            transformInputName
                                ? transformInputName(dimensionInputRule.name)
                                : dimensionInputRule.name
                        }
                        enterKeyHint="next"
                        label={
                            dimensionInputRule.customLabel ||
                            t(`dimension.${dimensionInputRule.name}`)
                        }
                        fullWidth
                        required={dimensionInputRule.isRequired}
                        options={dimensionOptionsSet[dimensionInputRule.name] || []}
                        isLoadingOptions={dimensionNamesLoading.includes(dimensionInputRule.name)}
                        disabled={!dimensionOptionsSet[dimensionInputRule.name]?.length}
                        readOnly={isReadOnlyDimension(
                            dimensionInputRule,
                            initValuesByDimensionName[dimensionInputRule.name] || ''
                        )}
                    />
                )
            )}
        </>
    );
}
DimensionInputList.defaultProps = {
    transformInputName: undefined
};
