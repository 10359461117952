import { useCallback } from 'react';
import { DutyWish, DutyWishPeriod } from 'models/Duty';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import useReadableDate from 'hooks/useReadableDate';
import { List, ListItem, ListItemText, TypographyProps, listItemClasses } from '@mui/material';
import { useDutyWishRegistrationInputOptions } from 'features/plan/providers/registration';
import DutyWishDetailCard from './cards/DutyWishDetailCard';
import DutyWishDeleteDialog from './DutyWishDeleteDialog';

export type ExpandedWishInfoProps = {
    dutyWish: DutyWish | DutyWishPeriod;
};

function ExpandedWishInfo({ dutyWish }: ExpandedWishInfoProps) {
    const { t } = useTranslation();
    const primaryTypographyProps: TypographyProps = {
        variant: 'subtitle1'
    };

    const { departments, dutyTypes } = useDutyWishRegistrationInputOptions();
    const departmentNames =
        departments
            ?.filter((d) => dutyWish.requestedDepartmentIds.includes(d.value))
            .map((d) => d.label) ?? [];
    const dutyTypeNames =
        dutyTypes
            ?.filter((d) => dutyWish.requestedDutyTypeIds.includes(d.value))
            .map((d) => d.label) ?? [];

    return (
        <List
            dense
            sx={{
                paddingTop: '4px',
                [`& .${listItemClasses.root}`]: { display: 'inline-block' }
            }}
        >
            {dutyWish.isEmployeeAvaliableOnShortNotice && (
                <ListItemText
                    primary={t('dutyWishRegistration.canShowUpOnShortNotice')}
                    primaryTypographyProps={primaryTypographyProps}
                />
            )}
            <ListItem disableGutters>
                <ListItemText
                    primary={t('dutyWishRegistration.departmentsForExtraDuty')}
                    primaryTypographyProps={primaryTypographyProps}
                    secondary={departmentNames.join(', ')}
                />
            </ListItem>
            <ListItem disableGutters>
                <ListItemText
                    primary={t('dutyWishRegistration.dutyTypesForExtraDuty')}
                    primaryTypographyProps={primaryTypographyProps}
                    secondary={dutyTypeNames.join(', ')}
                />
            </ListItem>
            {dutyWish.commentFromEmployee && (
                <ListItem disableGutters>
                    <ListItemText
                        primary={t('dutyWishRegistration.notice')}
                        primaryTypographyProps={primaryTypographyProps}
                        secondary={dutyWish.commentFromEmployee}
                    />
                </ListItem>
            )}
        </List>
    );
}

type DutyWishDetailsProps = {
    dutyWish: DutyWish | DutyWishPeriod;
    onDelete: VoidFunction;
};

export default function DutyWishDetails({ dutyWish, onDelete }: DutyWishDetailsProps) {
    const { showDialog } = useDialog();
    const { t } = useTranslation();

    const isDutyWishPeriod = 'fromDate' in dutyWish;

    const handleEditDutyWish = useCallback(async () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn open onClose={onClose}>
                {t('placeholder')}
            </DialogWithCloseBtn>
        ));
    }, [showDialog, t]);

    const handleDeleteDutyWish = useCallback(async () => {
        showDialog((onClose) => (
            <DialogWithCloseBtn open onClose={onClose}>
                <DutyWishDeleteDialog
                    dutyWish={dutyWish}
                    onSuccess={() => {
                        onDelete();
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    }, [dutyWish, onDelete, showDialog]);

    const fromDate = useReadableDate(isDutyWishPeriod ? dutyWish.fromDate : '');
    const toDate = useReadableDate(isDutyWishPeriod ? dutyWish.toDate : '');

    const title = isDutyWishPeriod ? 'dayDetails.dutyWishPeriod' : 'dayDetails.dutyWish';
    const description = isDutyWishPeriod ? `${fromDate} - ${toDate}` : '';

    return (
        <DutyWishDetailCard
            title={t(title)}
            description={description}
            isEditable={false}
            expandedInfo={<ExpandedWishInfo dutyWish={dutyWish} />}
            isDeletable
            onEditClick={handleEditDutyWish}
            onDeleteClick={handleDeleteDutyWish}
        />
    );
}
