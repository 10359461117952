import { Chip, CircularProgress, Stack } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useCallback } from 'react';
import SnackbarBriefAlert from 'components/SnackbarBriefAlert';
import { AllKnownEmployeeSettings } from '../../hooks/useEmployeeSettings.types';
import { useEmployeeSettingDefinitions, useEmployeeSettingValuePost } from '../../hooks';

type SettingSelectChipProps = {
    name: AllKnownEmployeeSettings;
    initValue: string;
    size?: 'small' | 'medium';
};

export default function SettingSelectChip({
    name,
    initValue,
    size: chipSize
}: SettingSelectChipProps) {
    const settingDefinitions = useEmployeeSettingDefinitions();
    const settingDefinition = settingDefinitions.find((definition) => definition.key === name);

    const { currentValue, translatedErrorMessage, isSubmitting, postValue } =
        useEmployeeSettingValuePost(name, initValue);

    const onClickChip = useCallback(
        (value: string) => {
            if (!isSubmitting && value !== currentValue) postValue(value);
        },
        [postValue, isSubmitting, currentValue]
    );

    return (
        <>
            <SnackbarBriefAlert severity="error" message={translatedErrorMessage} />
            <Stack direction="row" spacing={1}>
                {settingDefinition?.options.map((option) => {
                    const iconSize = chipSize === 'small' ? 16 : 20;

                    let icon;
                    if (option.value === currentValue) {
                        icon = isSubmitting ? <CircularProgress size={iconSize} /> : <DoneIcon />;
                    }
                    return (
                        <Chip
                            key={option.value}
                            icon={icon}
                            label={option.label}
                            variant={option.value === currentValue ? 'filled' : 'outlined'}
                            disabled={isSubmitting && option.value !== currentValue}
                            onClick={() => onClickChip(option.value)}
                            size={chipSize}
                            color={option.value === currentValue ? 'primary' : 'default'}
                        />
                    );
                })}
            </Stack>
        </>
    );
}

SettingSelectChip.defaultProps = {
    size: 'medium'
};
