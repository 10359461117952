import { t } from 'i18next';
import { dateToIsoString, dateToReadableString } from 'utils/datetime';
import { useTranslation } from 'react-i18next';
import { DEFAULT_LANGUAGE, dayjs, isSupportedLanguage } from 'i18n';
import useTimeRegistrationAccess from '../useTimeRegistrationAccess';
import { UseTimeRegistrationAlertMessageForDateResult } from './useTimeRegistrationAlertMessageForDate.types';

export default function useTimeRegistrationAlertMessageForDate(
    selectedDate: Date | null
): UseTimeRegistrationAlertMessageForDateResult {
    const { i18n } = useTranslation();
    const supportedLanguage = isSupportedLanguage(i18n.language) ? i18n.language : DEFAULT_LANGUAGE;
    const toRadableDate = (date: Date) => dateToReadableString(date, supportedLanguage);

    const dateToday = dayjs().startOf('day').toDate();
    // Kinda code smell: we fall back on today's date if no selected date is provided - else we can't do useTimeRegistrationAccess. Code straight after hook call ensures null is returned unless selectedDate is provdied though.
    const selectedDateOrTodayString = dateToIsoString(selectedDate || new Date());

    const {
        isTimeRegistrationAccessible,
        employeeRegistrationAccessInformation: accessInformation
    } = useTimeRegistrationAccess(selectedDateOrTodayString, false);

    if (!selectedDate) {
        return null;
    }

    const isPreparingAccessData =
        isTimeRegistrationAccessible === null || accessInformation === null;
    if (isPreparingAccessData) {
        return null;
    }

    // Remember; isTimeRegistrationAccessible also takes grace period and such into account
    if (isTimeRegistrationAccessible) {
        return undefined;
    }

    if (!accessInformation.canRegisterAtAll) {
        return t('dashboard.timeAccess.timeRegistrationNotAccessible');
    }

    // Cases where all in future or all in past is not allowed
    const canGenerallyRegisterDatesBack =
        accessInformation.numberOfDaysBackInTimeRegisteringIsAllowed > 0 &&
        accessInformation.canRegisterBackInTime; // Including accessInformation.canRegisterBackInTime seems like an overkill but it's what the old code solely did, so it's there for safety measure. canRegisterBackInTime returns true for time earlier the same day (as earlier hours are available), meaning it's not sufficient to just check that.
    const canGenerallyRegisterDatesForward =
        accessInformation.numberOfDaysForwardInTimeRegisteringIsAllowed > 0 &&
        accessInformation.canRegisterForwardInTime;

    if (!canGenerallyRegisterDatesBack && selectedDate < dateToday) {
        return t('dashboard.timeAccess.datesInPastCannotBeEdited');
    }
    if (!canGenerallyRegisterDatesForward && selectedDate > dateToday) {
        return t('dashboard.timeAccess.datesInFutureCannotBeEdited');
    }

    // Cases where specific date limits have been breached
    const minSelectableDate = dayjs(dateToday)
        .subtract(accessInformation.numberOfDaysBackInTimeRegisteringIsAllowed, 'day')
        .toDate();
    if (canGenerallyRegisterDatesBack && selectedDate < minSelectableDate) {
        return t('dashboard.timeAccess.datesBeforeMinLimitCannotBeEdited', {
            readableDate: toRadableDate(minSelectableDate)
        });
    }
    const maxSelectableDate = dayjs(dateToday)
        .add(accessInformation.numberOfDaysForwardInTimeRegisteringIsAllowed, 'day')
        .toDate();
    if (canGenerallyRegisterDatesForward && selectedDate > maxSelectableDate) {
        return t('dashboard.timeAccess.datesAfterMaxLimitCannotBeEdited', {
            readableDate: dayjs(maxSelectableDate).format('LL')
        });
    }

    // If we get here, something originally above has been removed. Could throw an error, but we just return undefined instead of risking anything breaks.
    return undefined;
}
