import { IEmployeeRegistrationInformation } from 'models/TimeRegistrationModels';
import { AuthError } from 'utils/auth';
import { getUnixTime } from 'utils/datetime';
import { postToWebtidApi } from 'utils/http';

export type FetchEmployeeRegistrationInformationCallBack = (
    dateInIsoFormat: string,
    registrationInformation?: IEmployeeRegistrationInformation | null
) => void;

export function fetchEmployeeRegistrationInformation(
    dateInIsoFormat: string,
    onDone: FetchEmployeeRegistrationInformationCallBack,
    onAuthError: (error: AuthError) => void
) {
    const postData = dateInIsoFormat !== '' ? { capitechPlanDutyDate: dateInIsoFormat } : undefined;

    return postToWebtidApi<Array<IEmployeeRegistrationInformation>>(
        'getEmployeeRegistrationInformation',
        postData,
        {
            onAuthError
        }
    )
        .then((response) => {
            if (response.success) {
                onDone(dateInIsoFormat, response.content[0] || null);
            } else {
                throw new Error(response.displayErrorMessage);
            }
        })
        .catch((e) => {
            throw e;
        });
}

const EMPLOYEE_REGISTRATION_TTL_SECONDS = 5;
export function isEmployeeRegistrationInformationOld(downloadTimestamp: number | undefined) {
    const timeNow = getUnixTime();
    const isOldInfo = downloadTimestamp
        ? timeNow - downloadTimestamp > EMPLOYEE_REGISTRATION_TTL_SECONDS
        : false;
    return isOldInfo;
}
