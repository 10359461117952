import { SvgIcon, SvgIconProps } from '@mui/material';

export default function AbsenceRequestIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props}>
            <svg
                id="Layer_1"
                data-name="Layer 1"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
            >
                <path d="M0,0H24V24H0V0ZM24,24H0V0H24V24Z" fill="none" />
                <path d="M5,4.07H20.85V19.93H5V4.07Z" fill="none" />
                <g>
                    <rect x="1" y="5" width="2" height="2" />
                    <rect x="1" y="9" width="2" height="2" />
                    <rect x="1" y="13" width="2" height="2" />
                    <path d="M22,1H5V3H21V21H5v2H22a1,1,0,0,0,1-1V21h0V2A1,1,0,0,0,22,1Z" />
                    <path d="M1,22a1,1,0,0,0,1,1H3V21H1v1Z" />
                    <path d="M1,2V3H3V1H2A1,1,0,0,0,1,2Z" />
                    <rect x="1" y="17" width="2" height="2" />
                    <path d="M12.31,19.45l1.08-1c3.68-3.73,6.14-6.19,6.14-9a4.08,4.08,0,0,0-4-4.37,4.19,4.19,0,0,0-3.25,1.67A4.19,4.19,0,0,0,9.06,5a4.12,4.12,0,0,0-4,4.37c0,3,2.46,5.48,6.14,9ZM6.53,9.37A2.6,2.6,0,0,1,9.06,6.59a2.94,2.94,0,0,1,2.6,1.9H13a2.79,2.79,0,0,1,2.6-1.9,2.6,2.6,0,0,1,2.53,2.78c-0.07,2.22-2.31,4.52-5.78,7.94H12.24C8.77,13.89,6.53,11.59,6.53,9.37Z" />
                    <polygon points="13.1 15 13.1 13.34 14.62 13.34 14.62 11.67 13.1 11.67 13.1 9.92 11.59 9.92 11.59 11.67 10 11.67 10 13.34 11.59 13.34 11.59 15 13.1 15" />
                </g>
            </svg>
        </SvgIcon>
    );
}
