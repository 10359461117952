import useErrorScreen from 'hooks/useErrorScreen';
import { difference, uniq } from 'lodash';
import { IEmployeeRegistrationInformation } from 'models/TimeRegistrationModels';
import {
    createContext,
    PropsWithChildren,
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
    useState
} from 'react';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import {
    fetchEmployeeRegistrationInformation,
    isEmployeeRegistrationInformationOld
} from '../util';
import {
    EmployeeRegistrationInformationActionType,
    EmployeeRegistrationInformationState,
    employeeRegistratoinInformationReducer
} from './EmployeeRegistratoinInformationReducer';

type EmployeeRegistrationInformationContextType = {
    isLoadingDate: (dateInIsoFormat: string) => boolean;
    getFetchedEmployeeRegistrationInformation: (
        dateInIsoFormat: string
    ) => IEmployeeRegistrationInformation | null;
    startFetchingRegistrationInformation: (dateInIsoFormat: string) => void;
    hasOldDataForDate: (dateInIsoFormat: string) => boolean;
};

const initialReducerState: EmployeeRegistrationInformationState = {
    employeeRegistrationInformationByDate: {},
    employeeRegistrationInformationAddedTimeByDate: {},
    loadingDates: []
};

export const EmployeeRegistrationInformationContext =
    createContext<EmployeeRegistrationInformationContextType>(null!);

export default function EmployeeRegistrationInformationProvider({
    children
}: PropsWithChildren<{}>) {
    const [state, dispatchEmployeeRegistrationAction] = useReducer(
        employeeRegistratoinInformationReducer,
        initialReducerState
    );

    /**
     * Ended up storing queue in a mutable object to make sure all the latest
     * fetch requests are available when effect is run. A flag (stored in state) is
     * raised whenever there are requests coming in.
     *
     * Having startFetchingRegistrationInformation dispatching actions directly
     * caused data to be out of sync.
     *
     * Feel free to have a go at solving this differently, but make sure test
     * "only makes one request if requesting registration information twice"
     * runs fine afterwards.
     */
    const queuedDatesRef = useRef<Array<string>>([]);
    const [processQueueFlag, setProcessQueueFlag] = useState(false);

    // Prepare to deal with error messages
    const { t } = useTranslation();
    const { setError } = useErrorScreen();
    const processFetchErrorException = useCallback(() => {
        const displayMessage = t('employeeRegistrationInformation.errors.loadFailed');
        setError({ message: displayMessage });
    }, [t, setError]);
    const { onAuthError } = useAuth();

    const { loadingDates } = state;
    useEffect(() => {
        if (!processQueueFlag) return;

        const queuedDates = queuedDatesRef.current;

        const datesToFetch = queuedDates.filter(
            (fetchCandidate) => !loadingDates.includes(fetchCandidate)
        );

        if (datesToFetch.length) {
            setProcessQueueFlag(false);
            queuedDatesRef.current = [];

            dispatchEmployeeRegistrationAction({
                type: EmployeeRegistrationInformationActionType.NotifyItemFetchStart,
                datesInIsoFormat: datesToFetch
            });

            datesToFetch.forEach((dateInIsoFormatToFetch) => {
                fetchEmployeeRegistrationInformation(
                    dateInIsoFormatToFetch,
                    (resultDateInIsoFormat, resultRegistrationInformation) => {
                        dispatchEmployeeRegistrationAction({
                            type: EmployeeRegistrationInformationActionType.AddItem,
                            dateInIsoFormat: resultDateInIsoFormat,
                            registrationInformation: resultRegistrationInformation || null
                        });
                    },
                    onAuthError
                ).catch(processFetchErrorException);
            });
        }
    }, [processQueueFlag, loadingDates, processFetchErrorException, onAuthError]);

    const startFetchingRegistrationInformation = useCallback(
        (dateInIsoFormat: string) => {
            const newQueue = uniq([...queuedDatesRef.current, dateInIsoFormat]);
            const newQueueFiltered = newQueue.filter(
                (queueCandidate) => !loadingDates.includes(queueCandidate)
            );

            if (difference(newQueueFiltered, queuedDatesRef.current).length) {
                queuedDatesRef.current = newQueueFiltered;
                setProcessQueueFlag(true);
            }
        },
        [queuedDatesRef, setProcessQueueFlag, loadingDates]
    );

    const value = useMemo<EmployeeRegistrationInformationContextType>(
        () => ({
            isLoadingDate: (date: string) => state.loadingDates.includes(date),
            getFetchedEmployeeRegistrationInformation: (dateInIsoFormat: string) =>
                state.employeeRegistrationInformationByDate[dateInIsoFormat] || null,
            hasOldDataForDate: (dateInIsoFormat: string) => {
                const timeWhenAdded =
                    state.employeeRegistrationInformationAddedTimeByDate[dateInIsoFormat];

                return isEmployeeRegistrationInformationOld(timeWhenAdded);
            },
            startFetchingRegistrationInformation
        }),
        [
            state.loadingDates,
            state.employeeRegistrationInformationAddedTimeByDate,
            state.employeeRegistrationInformationByDate,
            startFetchingRegistrationInformation
        ]
    );

    return (
        <EmployeeRegistrationInformationContext.Provider value={value}>
            {children}
        </EmployeeRegistrationInformationContext.Provider>
    );
}
