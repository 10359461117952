import { InputOption } from 'components/Input/Dropdown/InputOption';
import { mapValues, pickBy, isUndefined, isNull, keys } from 'lodash';
import {
    IGenericDimensionDescription,
    IGenericDimensionDescriptionWithSelectable,
    DimensionName
} from 'models/TimeRegistrationModels';
import { DimensionValuesByDimension } from 'utils/dimension';
import { keyByAndFill } from 'utils/object';
import {
    DescriptionsByDimensionName,
    DescriptionStore
} from './useDimensionDescriptionStore.helpers';

export type DimensionOptionsByDimension = Partial<Record<DimensionName, Array<InputOption>>>;

function isDimensionDescriptionWithSelectable(
    description: IGenericDimensionDescription
): description is IGenericDimensionDescriptionWithSelectable {
    return 'isSelectable' in description;
}

export function transformDescriptionsToSelectOptions(
    descriptions: Array<IGenericDimensionDescription>
): Array<InputOption> {
    const inputOptions = descriptions.map<InputOption>((description) => ({
        label: description.description,
        value:
            !isUndefined(description.value) && !isNull(description.value) // Just to be entirely sure we're operating with strings when options are defined
                ? String(description.value)
                : description.value,
        isSelectable: isDimensionDescriptionWithSelectable(description)
            ? description.isSelectable
            : true
    }));
    return inputOptions;
}

export function filterDisabledDimensionOptions(
    dimensionName: DimensionName,
    options: Array<InputOption>,
    initDimensionValues?: DimensionValuesByDimension
) {
    const initValue = initDimensionValues?.[dimensionName];

    return options.filter(
        (option) => option.isSelectable || (initValue && option.value === initValue)
    );
}

export function getDisplayableOptionsFromStore(
    descriptionStore: DescriptionStore | null,
    initDimensionValues: DimensionValuesByDimension | undefined
) {
    const dimensionDescriptionsByDimension = pickBy(
        descriptionStore
    ) as Required<DescriptionsByDimensionName>; // This is used for communicating that there'll be no props with undefined as a value in the result
    const allOptionsByDimensionsInStore = mapValues(
        dimensionDescriptionsByDimension,
        (dimensionDescriptions) => transformDescriptionsToSelectOptions(dimensionDescriptions)
    );

    const allInitDimensionNames = keys(initDimensionValues || {}) as Array<DimensionName>;
    const allInitDimensionsEmpty = keyByAndFill(allInitDimensionNames, []);

    const allOptionsByDimension = { ...allInitDimensionsEmpty, ...allOptionsByDimensionsInStore };
    const filteredOptionsByDimension = mapValues(allOptionsByDimension, (options, dimensionName) =>
        filterDisabledDimensionOptions(dimensionName as DimensionName, options, initDimensionValues)
    );

    return filteredOptionsByDimension as DimensionOptionsByDimension;
}
