import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useFetch } from 'hooks/useFetch';
import { DutyRequested } from 'models/Duty';
import { useTranslation } from 'react-i18next';

type DutyRequestDeleteDialogProps = {
    dutyRequest: DutyRequested;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};

type PostDeleteDutyRequestPayload = [];
type PostDeleteDutyRequestInput = {
    departmentId: number;
    dutyPlanId: number;
    dutyId: number;
    fromDate: string;
};

export default function DutyRequestDeleteDialog({
    dutyRequest,
    onSuccess,
    onCancel
}: DutyRequestDeleteDialogProps) {
    const [, { errorMessage, isLoading: isDeleting, sendRequest: deleteDutyRequest }] = useFetch<
        PostDeleteDutyRequestPayload,
        PostDeleteDutyRequestInput
    >('cancelRequestForAvaliableDuty', {
        reqData: {
            departmentId: dutyRequest.departmentId || 0,
            dutyPlanId: dutyRequest.dutyPlanId || 0,
            dutyId: dutyRequest.dutyId || 0,
            fromDate: dutyRequest.startDate
        },
        manuallyTriggerRequest: true,
        onSuccess: () => onSuccess()
    });

    const { t } = useTranslation();

    return (
        <ConfirmDialog
            open
            severity="warning"
            title={t('actions.confirmDeletion')}
            confirmText={t('actions.delete')}
            isRunningConfirmOperation={isDeleting}
            onCancel={onCancel}
            onConfirm={deleteDutyRequest}
            errorMessage={errorMessage}
        />
    );
}
