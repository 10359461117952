import {
    Box,
    Collapse,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    Typography
} from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import useReadableDate from 'hooks/useReadableDate';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { useTranslation } from 'react-i18next';
import DialogSpinner from 'components/dialog/DialogSpinner';
import { useState } from 'react';
import HFFormProvider from 'ctx/HFFormProvider';
import ErrorMessage from 'components/error/ErrorMessage';
import HFHidden from 'components/hookForm/HFHidden';
import { useFieldArray } from 'react-hook-form';
import { getTranslatedError } from 'utils/translation';
import TimeCategoryRow from './TimeCategoryRow';
import useManualAdditionsForm from '../../providers/useManualAdditionsForm';
import { UpdateManualAdditionInput } from '../../providers/useManualAdditionsForm/useManualAdditionsForm.types';
import AddableTimeCategoryRow from './AddableTimeCategoryRow';
import {
    useAccumulatedTimeForTransaction,
    useTimeCategoriesForManualAddition
} from '../../providers';

type ManualAdditionsProps = {
    timeTransaction: ITimeTransactionDetailResult;
    onCancel: VoidFunction;
    onSaveSuccess: (transaction: ITimeTransactionDetailResult) => void;
};

export default function ManualAdditions({
    timeTransaction,
    onCancel,
    onSaveSuccess
}: ManualAdditionsProps) {
    const { t } = useTranslation();
    const readableDate = useReadableDate(timeTransaction.dateIn);
    const [isExpanded, setIsExpanded] = useState(false);
    const { accumulatedTimeCategories, isLoading: isLoadingAccTime } =
        useAccumulatedTimeForTransaction({
            inDate: timeTransaction.dateIn,
            inTime: timeTransaction.timeIn
        });

    const { timeCategories: availableTimeCategories, isLoading: isLoadingManAdditions } =
        useTimeCategoriesForManualAddition();

    const isLoading = isLoadingAccTime || isLoadingManAdditions;

    const formProps = useManualAdditionsForm(
        timeTransaction.dateIn,
        timeTransaction.timeIn,
        accumulatedTimeCategories,
        () => {
            onSaveSuccess(timeTransaction);
            onCancel();
        }
    );
    const { control, formState } = formProps;

    const { fields: currentAdditions, append: appendAddition } = useFieldArray<
        UpdateManualAdditionInput,
        'details',
        'newTimeCategoryId'
    >({
        name: 'details',
        control
    });

    const unaddedTimeCategories = availableTimeCategories.filter(
        (tc) => !currentAdditions.find((field) => field.newTimeCategoryId === tc.timeCategoryId)
    );

    const addableTimeCategoryRows = unaddedTimeCategories.map((timeCategory, index) => ({
        timeCategory,
        hidden: index >= 3 || isExpanded // prevent rerendering of all rows when expanding or adding new row
    }));

    if (isLoading) {
        return <DialogSpinner />;
    }

    return (
        <Box>
            <HFFormProvider formProps={formProps}>
                <HFHidden name="inDate" />
                <HFHidden name="inTime" />
                <DialogTitle>{t('manualAdditions.hoursAndExtraRates')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {`${readableDate}, ${timeTransaction.timeIn} - ${timeTransaction.timeOut}`}
                    </DialogContentText>

                    <List>
                        {currentAdditions.map((field, index) => {
                            const timeCategory = accumulatedTimeCategories.find(
                                (atc) => atc.timeCategoryId === Number(field.newTimeCategoryId)
                            ) || {
                                amount: 0,
                                isManualAdjustment: true,
                                ...availableTimeCategories.find(
                                    (atc) => atc.timeCategoryId === Number(field.newTimeCategoryId)
                                )
                            };

                            return (
                                <TimeCategoryRow
                                    index={index}
                                    key={field.newTimeCategoryId}
                                    isEditable={timeCategory?.isManualAdjustment}
                                    timeCategoryId={Number(field.newTimeCategoryId)}
                                    description={timeCategory?.timeCategoryDescription || ''}
                                />
                            );
                        })}
                    </List>
                    {addableTimeCategoryRows.length > 0 && (
                        <>
                            <Typography variant="h6" mt={2}>
                                {t('manualAdditions.addNewManualAddition')}
                            </Typography>
                            <List>
                                {addableTimeCategoryRows
                                    .filter((e) => !e.hidden)
                                    .map((row) => (
                                        <AddableTimeCategoryRow
                                            key={row.timeCategory.timeCategoryId}
                                            timeCategory={row.timeCategory}
                                            onAdd={(amount) => {
                                                appendAddition(
                                                    {
                                                        amount,
                                                        newTimeCategoryId:
                                                            row.timeCategory.timeCategoryId,
                                                        originalTimeCategoryId: null
                                                    },
                                                    {
                                                        shouldFocus: true,
                                                        focusName: `details.${currentAdditions.length}.amount`
                                                    }
                                                );
                                            }}
                                        />
                                    ))}

                                {!isExpanded && (
                                    <ActionButton
                                        onClick={() => setIsExpanded(!isExpanded)}
                                        variant="text"
                                        type="button"
                                    >
                                        {t('manualAdditions.showAllCategories')}
                                    </ActionButton>
                                )}

                                <Collapse in={isExpanded}>
                                    {addableTimeCategoryRows
                                        .filter((e) => e.hidden)
                                        .map((row) => (
                                            <AddableTimeCategoryRow
                                                key={row.timeCategory.timeCategoryId}
                                                timeCategory={row.timeCategory}
                                                onAdd={(amount) => {
                                                    appendAddition(
                                                        {
                                                            amount,
                                                            newTimeCategoryId:
                                                                row.timeCategory.timeCategoryId,
                                                            originalTimeCategoryId: null
                                                        },
                                                        {
                                                            shouldFocus: true,
                                                            focusName: `details.${currentAdditions.length}.amount`
                                                        }
                                                    );
                                                }}
                                            />
                                        ))}
                                </Collapse>
                            </List>
                        </>
                    )}
                    {formProps.displayErrorMessage && (
                        <ErrorMessage message={getTranslatedError(formProps.displayErrorMessage)} />
                    )}
                </DialogContent>
                <DialogActions>
                    <ActionButton onClick={onCancel} variant="outlined" type="button">
                        {t('actions.cancel')}
                    </ActionButton>
                    <ActionButton isloading={formState.isSubmitting}>
                        {t('actions.save')}
                    </ActionButton>
                </DialogActions>
            </HFFormProvider>
        </Box>
    );
}
