import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { version } from 'version';

export default function ProfileFooter() {
    const { t } = useTranslation();

    return (
        <Typography
            component="footer"
            variant="subtitle2"
            align="center"
            mb={2}
            mt={4}
            color="textSecondary"
        >
            {`${t('brand.my')}${t('brand.capitech')}`} {version}
        </Typography>
    );
}
