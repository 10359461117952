export interface IEmployeeRegistrationInformation {
    id: number;
    name: string;
    hasCapitechTid: boolean;
    hasCapitechWebtid: boolean;
    hasCapitechPlan: boolean;
    hasCapitechFlowPlan: boolean;
    hasFlexitime: boolean;
    hasAbsenceRegistration: boolean;
    hasSimployerAbsence: boolean;
    hasShifts: boolean;
    canRegisterAtAll: boolean;
    canRegisterBackInTime: boolean;
    canRegisterForwardInTime: boolean;
    canDoManualAddition: boolean;
    numberOfDaysBackInTimeRegisteringIsAllowed: number;
    numberOfDaysForwardInTimeRegisteringIsAllowed: number;
    registrationRules: IClientRegistrationRules;
    registrationDefaults: IEmployeeRegistrationDefaults;
    registrationRequiredFields: IClientRegistrationRequiredFields;
    registrationVisibilityRules: IClientRegistrationVisibilityRules;
    absenceRegistrationRules: IClientAbsenceRegistrationRules;
    defaultWorkingHours?: IDefaultWorkingHours | null;
}

export interface IDefaultWorkingHours {
    startTime: string;
    endTime: string;
}

export type EmployeeRegistrationAccessInformation = Pick<
    IEmployeeRegistrationInformation,
    | 'canRegisterAtAll'
    | 'canRegisterBackInTime'
    | 'canRegisterForwardInTime'
    | 'numberOfDaysBackInTimeRegisteringIsAllowed'
    | 'numberOfDaysForwardInTimeRegisteringIsAllowed'
    | 'canDoManualAddition'
>;

export interface IClientRegistrationRules {
    registerTask: boolean;
    registerOrder: boolean;
    registerProject: boolean;
    registerSubProject: boolean;
    registerPhase: boolean;
    registerShift: boolean;
    registerFridim1: boolean;
    registerFridim2: boolean;
    registerDuty: boolean;
    registerCompetenceRole: boolean;
    registerDutyDefinition: boolean;
    registrationCloseDate: string | null;
}

export interface IEmployeeRegistrationDefaults {
    departmentId: number | null;
    taskId: number | null;
    orderId: number | null;
    projectId: number | null;
    subProjectId: number | null;
    phaseId: number | null;
    shiftId: number | null;
    fridim1Id: number | null;
    fridim2Id: number | null;
    dutyId: number | null;
    competenceRoleId: number | null;
    dutyDefinitionId: number | null;
    notes: string | null;
}

export interface IClientRegistrationRequiredFields {
    isDepartmentIdRequired: boolean;
    isTaskIdRequired: boolean;
    isOrderIdRequired: boolean;
    isProjectIdRequired: boolean;
    isSubProjectIdRequired: boolean;
    isPhaseIdRequired: boolean;
    isShiftIdRequired: boolean;
    isFridim1IdRequired: boolean;
    isFridim2IdRequired: boolean;
    isDutyIdRequired: boolean;
    isCompetenceRoleIdRequired: boolean;
    isDutyDefinitionIdRequired: boolean;
}

/* All of the dimension names, in the order they are meant to be sorted */
export const allDimensionNamesSorted = [
    'department',
    'dutyDefinition',
    'competenceRole',
    'task',
    'duty',
    'shift',
    'order',
    'project',
    'subProject',
    'phase',
    'fridim1',
    'fridim2'
] as const;

export const dimensionDependencies: Partial<Record<DimensionName, Array<DimensionName>>> = {
    subProject: ['project'],
    phase: ['subProject'],
    duty: ['department'],
    task: ['department']
};

export type DimensionName = typeof allDimensionNamesSorted[number];
export type DimensionNamePlural = `${DimensionName}s`;
export type DimensionReferencePropertyName = `${DimensionName}Id`;
export type DimensionInputName = `${DimensionName}Id`;
export type DimensionInputNameUpdate = `new${Capitalize<DimensionName>}Id`;

export enum RegistrationVisibility {
    Show = 1,
    Hidden = 2,
    ReadOnly = 3
}
export type IClientRegistrationVisibilityRules = {
    [key in DimensionName]: RegistrationVisibility;
};

export type DimensionNameInAbsenceRegistrationRules =
    | Extract<
          DimensionName,
          'fridim1' | 'fridim2' | 'order' | 'phase' | 'project' | 'subProject' | 'task'
      >
    | 'competence'; // competenceRole seems to be the only intended dimension name for MyCapitech and this one has snuck in as API output. Regarding it as a dimension name equal to competenceRole only complicates things for now.
export type IClientAbsenceRegistrationRules = {
    [key in
        | `register${Capitalize<DimensionNameInAbsenceRegistrationRules>}`
        | `requires${Capitalize<DimensionNameInAbsenceRegistrationRules>}`]: boolean;
};

// TODO:: Move to different location, along with IGenericDimensionDescriptionWithSelectable
export interface IGenericDimensionDescription {
    description: string;
    value: string;
}

export interface IGenericDimensionDescriptionWithSelectable extends IGenericDimensionDescription {
    isSelectable: boolean;
}

export type DepartmentDescription = IGenericDimensionDescriptionWithSelectable;
export type TaskDescription = IGenericDimensionDescription & {
    departmentId: string;
};
export type OrderDescription = IGenericDimensionDescriptionWithSelectable;
export type ProjectDescription = IGenericDimensionDescriptionWithSelectable & {
    hasSubProjects: boolean;
};
export type SubProjectDescription = Omit<IGenericDimensionDescription, 'isSelectable'> & {
    projectId: string;
    status: string;
    hasPhase: boolean;
};
export type PhaseDescription = Omit<IGenericDimensionDescription, 'isSelectable'> & {
    projectId: string;
    subProjectId: string;
    status: string;
};
export type ShiftDescription = Omit<IGenericDimensionDescription, 'isSelectable'>;
export type Fridim1Description = IGenericDimensionDescriptionWithSelectable;
export type Fridim2Description = IGenericDimensionDescriptionWithSelectable;
export type DutyDescription = Omit<IGenericDimensionDescription, 'isSelectable'> & {
    departmentId: string;
};
export type CompetenceRoleDescription = Omit<IGenericDimensionDescription, 'isSelectable'>;
export type DutyDefinitionDescription = IGenericDimensionDescriptionWithSelectable;
