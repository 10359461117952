import { useContext, useEffect } from 'react';
import { DateISOString } from 'utils/datetime';
import { DutyWishRegistrationContext } from '../../DutyWishRegistrationProvider';
import { UseDutyWishRegistrationValuesResult } from './useDutyWishRegistrationValues.types';

export default function useDutyWishRegistrationValues(
    date: DateISOString
): UseDutyWishRegistrationValuesResult {
    const ctx = useContext(DutyWishRegistrationContext);

    if (!ctx) {
        throw new Error(
            'useDutyWishRegistrationInputOptions must be used within DutyWishRegistrationProvider'
        );
    }

    /**
     * When data for a different date is requested, fromDate and toDate should be reset.
     * The code which follows ensures this is not a concern when using the hook.
     */
    const shouldUpdateDatesValuesValidFor = ctx.dateValuesValidFor !== date;
    useEffect(() => {
        if (shouldUpdateDatesValuesValidFor) {
            ctx.setValues({
                ...ctx.values,
                fromDate: date,
                toDate: date
            });
            ctx.setDateValuesValidFor(date);
        }
    });

    return shouldUpdateDatesValuesValidFor
        ? { ...ctx.values, fromDate: date, toDate: date }
        : ctx.values;
}
