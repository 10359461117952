import { AvatarProps, Avatar } from '@mui/material';
import AbsenceIcon from 'components/icon/AbsenceIcon';
import AbsenceRequest from 'models/AbsenceRequest';
import AbsenceRequestAvatar from './AbsenceRequestAvatar';
import AbsenceRequestRejectedAvatar from './AbsenceRequestRejectedAvatar';

export default function AbsenceAvatar({ sx, absence }: AvatarProps & { absence: AbsenceRequest }) {
    const isAwaitingApproval = absence.absenceRequestStatus === 'Created';
    const isAbsenceRejected = absence.absenceRequestStatus === 'Rejected';

    if (isAbsenceRejected) {
        return <AbsenceRequestRejectedAvatar sx={sx} />;
    }

    if (isAwaitingApproval) {
        return <AbsenceRequestAvatar sx={sx} />;
    }

    return (
        <Avatar
            sx={{
                ...sx,
                backgroundColor: 'absence.main'
            }}
        >
            <AbsenceIcon />
        </Avatar>
    );
}
