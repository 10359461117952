import { UseFormReturn } from 'react-hook-form';

export function getFieldNames(setup: UseFormReturn) {
    // Seems like the closest thing we have to accomplish this for now
    const values = setup.getValues();
    return Object.keys(values);
}

export function isFieldNamePresent(name: string, setup: UseFormReturn) {
    const fieldNames = getFieldNames(setup);
    return fieldNames.includes(name);
}
