import { useContext } from 'react';
import { DutyWishRegistrationContext } from '../../DutyWishRegistrationProvider';
import { UseSetDutyWishRegistrationValueResult } from './useSetDutyWishRegistrationValue.types';

export default function useSetDutyWishRegistrationValue(): UseSetDutyWishRegistrationValueResult {
    const ctx = useContext(DutyWishRegistrationContext);

    if (!ctx) {
        throw new Error(
            'useSetDutyWishRegistrationValue must be used within DutyWishRegistrationProvider'
        );
    }

    return ctx.setValue;
}
