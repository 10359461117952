import { useMemo } from 'react';
import { dateToIsoString, isToday } from 'utils/datetime';
import { dayjs } from 'i18n';
import { last, sortBy } from 'lodash';
import { ITimeTransactionDetailResult } from 'models/TimeTransactionDetailResult';
import { useTimeTransactions } from 'features/dashboard/ctx/TimeTransactionsProvider';
import { UseInOutAvailabilityForDateResult } from './useInOutAvailabilityForDate.types';
import useTimeRegistrationAccess from '../useTimeRegistrationAccess';

export default function useInOutAvailabilityForDate(date: Date): UseInOutAvailabilityForDateResult {
    const { state } = useTimeTransactions();

    const today = dayjs();
    const yesterday = today.subtract(1, 'day');
    const todayString = dateToIsoString(today.toDate());
    const yesterdayString = dateToIsoString(yesterday.toDate());

    const { isTimeRegistrationAccessible: isTimeRegistrationAccessibleToday } =
        useTimeRegistrationAccess(todayString);

    // In
    const isInAvailable = isToday(date) && !!isTimeRegistrationAccessibleToday;
    // Out
    const isOutAvailable = useMemo(() => {
        const transactionsOnSelectedDate = date
            ? state.timeTransactions.filter((t) => t.dateIn === dateToIsoString(date))
            : [];
        // Sort according to display order in the time transaction card. We should consider centralizing this post-hotfix.
        const timeTransactionSortOrder: Array<Partial<keyof ITimeTransactionDetailResult>> = [
            'dateIn',
            'timeIn',
            'dateOut',
            'timeOut'
        ];
        const sortedTransactionsOnSelectedDate: Array<ITimeTransactionDetailResult> = sortBy(
            transactionsOnSelectedDate,
            timeTransactionSortOrder
        );
        const latestTransaction = last(sortedTransactionsOnSelectedDate);
        return !!(
            isTimeRegistrationAccessibleToday &&
            latestTransaction &&
            !latestTransaction.timeOut &&
            (latestTransaction.dateIn === todayString ||
                latestTransaction.dateIn === yesterdayString)
        );
    }, [
        date,
        isTimeRegistrationAccessibleToday,
        state.timeTransactions,
        todayString,
        yesterdayString
    ]);

    return {
        isInOrOutAvailable: isInAvailable || isOutAvailable,
        isInAvailable,
        isOutAvailable
    };
}
