import {
    differenceWith,
    fromPairs,
    isEqual,
    keys,
    mapKeys,
    mapValues,
    pick,
    toPairs
} from 'lodash';

/**
 * Returns object properties which have modified from one object to the next.
 *
 * @param original
 * @param modifier
 * @returns
 */
export function getModifiedProperties<T extends object>(original: T, modifier: T): Partial<T> {
    const changeList = differenceWith(toPairs(original), toPairs(modifier), isEqual);
    const valuesChanged = fromPairs(changeList);

    const modifiedProps = pick(modifier, keys(valuesChanged));
    return modifiedProps;
}

export function keyByAndFill<T extends string | number | symbol, Y>(
    list: Array<T>,
    fillWith: Y
): Record<T, Y> {
    return mapValues(mapKeys(list), () => fillWith) as Record<T, Y>;
}

export const keysFromRecord = Object.keys as <T>(obj: T) => Array<keyof T>;
