import { useDateStartMinAbsenceRequests } from 'features/absence/providers/absenceRequests';
import { t } from 'i18next';
import useReadableDate from 'hooks/useReadableDate';
import { Alert, Box, BoxProps } from '@mui/material';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import useTimeRegistrationAlertMessageForDate from '../hooks/useTimeRegistrationAlertMessageForDate';

export default function TimePeriodSelectedAlerts(wrapperProps: BoxProps) {
    const { fromDate, selectedDate } = useSelectedTimePeriod();
    const minStartDateAbsenceRequests = useDateStartMinAbsenceRequests();
    const readableMinDate = useReadableDate(minStartDateAbsenceRequests);

    const alertComponents: React.ReactElement[] = [];

    const timeRegistrationAlertMessage = useTimeRegistrationAlertMessageForDate(selectedDate);
    if (timeRegistrationAlertMessage) {
        alertComponents.push(
            <Alert severity="info" key="warning-timeRegistrationDateLimitPassed">
                {timeRegistrationAlertMessage}
            </Alert>
        );
    }

    const isBeforeAbsenceRequestsAvailable = fromDate < minStartDateAbsenceRequests;
    if (isBeforeAbsenceRequestsAvailable) {
        alertComponents.push(
            <Alert severity="info" key="warning-absenceRequestsDateLimitPassed">
                {t('dashboard.absenceRequestsDateLimitPassed', {
                    readableDate: readableMinDate
                })}
            </Alert>
        );
    }

    if (alertComponents.length) {
        return <Box {...wrapperProps}>{alertComponents}</Box>;
    }

    return null;
}
