/* eslint-disable import/prefer-default-export */
import {
    getPreviouslySelectedClientIdCookie,
    getLegacyPreviouslySelectedClientIdCookie
} from 'utils/cookie';

export function loginAuth0(signInUrl: string) {
    const currentUrl = window.location.origin + window.location.pathname;
    const signInUrlWithReturnUrl = `${signInUrl}?returnUrl=${encodeURIComponent(currentUrl)}`;
    let clientId = getPreviouslySelectedClientIdCookie();

    if (!clientId) {
        clientId = getLegacyPreviouslySelectedClientIdCookie();
    }

    if (clientId && Number(clientId) > 0) {
        window.location.href = `${signInUrlWithReturnUrl}&capitechclientid=${clientId}`;
    } else {
        window.location.href = signInUrlWithReturnUrl;
    }
}

export function logoutAuth0(signOutUrl: string) {
    const currentUrl = window.location.origin + window.location.pathname;
    // Manually override the signOutUrl provided by the server in order to return back to MyCapitech2 after logout
    const appUrl = /(.*\/MyCapitech2).*/i.exec(currentUrl);

    // If no mycapitech2 url is found, redirect to the signOutUrl (default behavior)
    if (!appUrl) {
        window.location.href = signOutUrl;
        return;
    }

    // Construct a fallback url without url parameters
    const url = new URL(currentUrl);
    const fallbackUrl = url.origin + url.pathname;

    // Force same casing as in Auth0 whitelist
    const returnToUrl = (appUrl ? appUrl[1] : fallbackUrl).replace(
        /\/apps\/mycapitech2\/?/i,
        '/apps/MyCapitech2'
    );

    const signOutUrlObj = new URL(signOutUrl);
    const overriddenSignOutUrl = `${signOutUrlObj.origin}${
        signOutUrlObj.pathname
    }?returnTo=${encodeURIComponent(returnToUrl)}`;

    window.location.href = overriddenSignOutUrl;
}
