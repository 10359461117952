import { Box, Tab, Tabs, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DutyFilterMain from 'features/profile/dutyFilter/DutyFilterMain';
import { useFeatureAccess } from 'features/misc/employeeSettings';
import useQuery from 'hooks/useQuery';
import ProfileMain from './ProfileMain';

const tabNames = ['main', 'dutyFilter'] as const;
type TabName = typeof tabNames[number];

function getProfileTabId(name: TabName) {
    return `profile-tab-${name}`;
}

function getProfileTabPanelId(name: TabName) {
    return `profile-tabpanel-${name}`;
}

interface TabPanelProps {
    children: React.ReactNode;
    panelsTabName: TabName;
    selectedTabName: TabName;
}

// In time we'lll have TabContext and TabList available in MUI, but for now we'll use CustomTabPanel and getTabA11yProps
// When that time comes we should also reimplement the tabs found in Balances.tsx
function CustomTabPanel(props: TabPanelProps) {
    const { children, selectedTabName, panelsTabName, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={panelsTabName !== selectedTabName}
            id={getProfileTabPanelId(panelsTabName)}
            aria-labelledby={getProfileTabId(panelsTabName)}
            {...other}
        >
            {panelsTabName === selectedTabName && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

function getTabA11yProps(tabName: TabName) {
    return {
        id: getProfileTabId(tabName),
        'aria-controls': getProfileTabPanelId(tabName)
    };
}

export default function ProfileTabs() {
    const { t } = useTranslation();
    // Define selectedTabName state, which is passed to CustomTabPanel
    const initTabFromQuery = useQuery().get('initTab') as TabName | null;
    const initTab =
        initTabFromQuery && tabNames.includes(initTabFromQuery) ? initTabFromQuery : 'main';
    const [selectedTabName, setSelectedTabName] = useState<TabName>(initTab);

    const { access } = useFeatureAccess();
    const hasPlan = Boolean(access?.plan);

    return (
        <>
            {hasPlan ? (
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs
                        value={selectedTabName}
                        onChange={(event, targetTabName: TabName) =>
                            setSelectedTabName(targetTabName)
                        }
                    >
                        <Tab
                            label={t('profile.tabBar.main')}
                            value="main"
                            {...getTabA11yProps('main')}
                        />{' '}
                        <Tab
                            label={t('profile.tabBar.dutyFilter')}
                            value="dutyFilter"
                            {...getTabA11yProps('dutyFilter')}
                        />
                    </Tabs>
                </Box>
            ) : (
                <Typography
                    id="absence-request-overview-header"
                    component="h1"
                    variant="h5"
                    gutterBottom
                    sx={{
                        textTransform: 'uppercase'
                    }}
                >
                    {t('profile.tabBar.main')}
                </Typography>
            )}
            <CustomTabPanel panelsTabName="main" selectedTabName={selectedTabName}>
                <ProfileMain />
            </CustomTabPanel>
            <CustomTabPanel panelsTabName="dutyFilter" selectedTabName={selectedTabName}>
                <DutyFilterMain />
            </CustomTabPanel>
        </>
    );
}
