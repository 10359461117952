import { useDepartmentAndDutyFilter } from 'features/plan';
import useFormWithApiIntegration from 'hooks/useFormWithApiIntegration';
import { useState } from 'react';
import { array, boolean, number, object } from 'zod';

type PostDutyWishFilterForm = {
    departmentIds: Array<number>;
    dutyTypeIds: Array<number>;
    displayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties: boolean;
};

const schema = object({
    departmentIds: array(number()),
    dutyTypeIds: array(number()),
    displayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties: boolean()
});

export default function useDutyWishFilterForm(onBeforeHandleSubmit: () => boolean) {
    const { values: savedValues, refresh: refreshDutyFilter } = useDepartmentAndDutyFilter();

    const defaultValues = {
        departmentIds: savedValues?.departmentIds.filter((v) => v >= 0) ?? [], // For some reason, the API sometimes returns -1 as an ID
        dutyTypeIds: savedValues?.dutyTypeIds.filter((v) => v >= 0) ?? [],
        displayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties:
            savedValues?.displayAvaliableDutiesOnDaysIAlreadyHaveOtherDuties ?? false
    };

    const [updatedValues, setUpdatedValues] = useState<PostDutyWishFilterForm>(undefined!);

    return useFormWithApiIntegration<any>( // any because the API doesn't return anything
        '/saveDutyWishFilter',
        schema,
        updatedValues ?? defaultValues,
        {
            onBeforeHandleSubmit,
            onSuccess: (returnValue, formInput) => {
                if (formInput) {
                    setUpdatedValues(formInput);
                }
                refreshDutyFilter();
            }
        }
    );
}
