import { useFetchArrayOutsideAuthContext } from 'hooks/useFetchOutsideAuthContext';
import CapitechClient from 'models/CapitechClient';
import { createContext, PropsWithChildren, useMemo } from 'react';
import { getRefreshTokenCookie } from 'utils/cookie';
import { useEffectOnce } from 'usehooks-ts';
import useAppSettings from 'hooks/useAppSettings';
import useAuth from 'hooks/useAuth';
import { Auth0ClientListContextType } from './Auth0ClientListProvider.types';

export const Auth0ClientListContext = createContext<Auth0ClientListContextType>(null!);

export default function Auth0ClientListProvider({ children }: PropsWithChildren) {
    const refreshToken = getRefreshTokenCookie() || '';
    const isAuth0 = useAppSettings().loginSimployerAuth0;

    const [clientList, { isLoading, sendRequest: fetchCapitechClients }] =
        useFetchArrayOutsideAuthContext<CapitechClient>('getAuthorizedCapitechClients', {
            isAnonymous: true,
            manuallyTriggerRequest: true,
            reqData: {
                // I know what you're thinking; "What the fuck is this shit?"
                // This looks dirty. This smells dirty. That's because it IS dirty.
                // The API, for some reason, authenticates this endpoint using the refresh
                // token. Not the accessToken like we based all our fetch logic upon.
                // But better to confine the dirt to this one component, rather than
                // muddy up our core fetch logic to support using the refresh token. Maybe
                // someday we'll clean up the API endpoint and this atrocity can be removed.
                refreshToken
            }
        });

    useEffectOnce(() => {
        if (isAuth0 && refreshToken !== '') {
            fetchCapitechClients();
        }
    });

    const { authData } = useAuth();
    const { clientId } = authData || {};

    const selectedClient = useMemo(
        () => clientList.find((client) => client.clientId === clientId),
        [clientList, clientId]
    );

    const value = useMemo(
        () => ({
            clientList,
            isLoading,
            isSingleClient: clientList.length === 1,
            selectedClient
        }),
        [clientList, isLoading, selectedClient]
    );

    // Note: loading is non-blocking for components below, as are errors, which can be handled where applicable
    return (
        <Auth0ClientListContext.Provider value={value}>{children}</Auth0ClientListContext.Provider>
    );
}
