import { TextField } from '@mui/material';
import { NativeDatePickerProps } from './DatePicker.types';

export default function NativeDatePicker({
    onChange,
    maxDate,
    minDate,
    ...rest
}: NativeDatePickerProps) {
    return (
        <TextField
            inputProps={{
                type: 'date',
                sx: {
                    textAlign: 'left',
                    '&::-webkit-date-and-time-value': {
                        textAlign: 'left'
                    }
                },
                // Attempt to hint at date format for browsers that don't support date input
                // No guarantee that this will work, validation is still necessary
                pattern: 'd{4}-d{2}-d{2}',
                max: maxDate?.format('YYYY-MM-DD') ?? undefined,
                min: minDate?.format('YYYY-MM-DD') ?? undefined
            }}
            type="date"
            onChange={(evt) => (onChange ? onChange(evt.target.value) : undefined)}
            {...rest}
        />
    );
}
