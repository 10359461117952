import AlertDialog from 'components/dialog/AlertDialog';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';
import { useCallback, useEffect } from 'react';
import { getTranslatedError } from 'utils/translation';
import { dateToIsoString } from 'utils/datetime';
import { useDialog } from 'ctx/DialogCtx';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'hooks/useAuth';
import { HourRegistration } from '../hour';
import useDimensionInputRules from '../hour/hooks/useDimensionInputRules';
import {
    getDimensionDefaultValues,
    DefaultValuesForCreate
} from '../hour/hooks/useHourRegistrationForm';
import { ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION } from '../util';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import { createCustomTimeTransaction } from '../util/util';

export default function QuickAddButton() {
    const { selectedDate, fromDate, toDate } = useSelectedTimePeriod();

    if (!selectedDate) {
        throw new Error('Cannot display details for no date in particular');
    }

    const { t } = useTranslation();
    const { showDialog, clearDialogs } = useDialog();
    useEffect(() => clearDialogs, [clearDialogs]); // Clears dialogs on unmount

    const dateInIsoFormat = dateToIsoString(selectedDate);

    const { employeeRegistrationInformation } =
        useEmployeeRegistrationInformation(dateInIsoFormat, true) || {};
    const { dimensionInputRules } = useDimensionInputRules(dateInIsoFormat);
    const dimensionDefaultValues = getDimensionDefaultValues(dimensionInputRules || []);

    const { dispatch, state } = useTimeTransactions();
    const isSubmitting = state.isLoading;
    const { onAuthError } = useAuth();

    const handleQuickAddClick = useCallback(async () => {
        if (isSubmitting) {
            return;
        }
        const timeInRaw = employeeRegistrationInformation?.defaultWorkingHours?.startTime ?? '';
        const [hourIn, minuteIn] = timeInRaw.split(':');
        const timeIn = `${hourIn}:${minuteIn}`;
        const timeOutRaw = employeeRegistrationInformation?.defaultWorkingHours?.endTime ?? '';
        const [hourOut, minuteOut] = timeOutRaw.split(':');
        const timeOut = `${hourOut}:${minuteOut}`;

        const prefilledValues: DefaultValuesForCreate = {
            dateIn: dateInIsoFormat,
            dateOut: dateInIsoFormat,
            timeIn,
            timeOut,
            text: '',
            ...dimensionDefaultValues
        };

        const errorMessage = await createCustomTimeTransaction(
            prefilledValues,
            fromDate,
            toDate,
            dispatch,
            onAuthError
        );

        if (errorMessage) {
            showDialog((onClose) =>
                errorMessage ===
                ERROR_RESPONSE_MESSAGE_TIME_REGISTRATION_IS_OVERLAPPING_ANOTHER_REGISTRATION ? (
                    <AlertDialog
                        open
                        severity="error"
                        title={getTranslatedError(errorMessage, '')}
                        onConfirm={onClose}
                        isRunningConfirmOperation={false}
                    />
                ) : (
                    <DialogWithCloseBtn onClose={onClose} open>
                        <HourRegistration
                            isCheckingIn={false}
                            date={dateInIsoFormat}
                            onSuccess={onClose}
                            onCancel={onClose}
                            initErrorMessage={errorMessage || undefined}
                            prefilledDefaultValues={prefilledValues}
                        />
                    </DialogWithCloseBtn>
                )
            );
        }
    }, [
        dateInIsoFormat,
        dimensionDefaultValues,
        dispatch,
        employeeRegistrationInformation?.defaultWorkingHours?.endTime,
        employeeRegistrationInformation?.defaultWorkingHours?.startTime,
        fromDate,
        isSubmitting,
        onAuthError,
        showDialog,
        toDate
    ]);

    return (
        <Button size="small" color="hours" onClick={handleQuickAddClick} disabled={isSubmitting}>
            {t('actions.quickAdd')}
        </Button>
    );
}
