import { useMemo } from 'react';
import { UseBrowserSupportResult } from './useBrowserSupport.types';

function isSupportingInputTime() {
    const input = document.createElement('input');
    input.setAttribute('type', 'time');
    const notADateValue = 'not-a-date';
    input.setAttribute('value', notADateValue);
    return input.value !== notADateValue;
}

export default function useBrowserSupport(): UseBrowserSupportResult {
    return useMemo(
        () => ({
            inputTime: isSupportingInputTime()
        }),
        []
    );
}
