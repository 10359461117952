import { AvatarProps, Avatar } from '@mui/material';
import AbsenceRequestIcon from 'components/icon/AbsenceRequestIcon';

export default function AbsenceRequestAvatar({ sx }: AvatarProps) {
    return (
        <Avatar
            sx={{
                // p: 0,
                borderColor: 'absence.main',
                borderRadius: '50%',
                borderWidth: 2,
                borderStyle: 'solid',
                backgroundColor: 'white',
                ...sx
            }}
        >
            <AbsenceRequestIcon color="primary" />
        </Avatar>
    );
}
