import { useMemo } from 'react';
import useEmployeeRegistrationInformation from '../useEmployeeRegistrationInformation';
import { UseFeatureAccessResult } from './useFeatureAccess.types';

export default function useFeatureAccess(): UseFeatureAccessResult {
    const { isLoading, employeeRegistrationInformation } = useEmployeeRegistrationInformation('');

    const {
        hasAbsenceRegistration,
        hasSimployerAbsence,
        hasCapitechTid,
        hasCapitechPlan,
        hasCapitechFlowPlan
    } = employeeRegistrationInformation ?? {};

    const access = useMemo<UseFeatureAccessResult['access']>(
        () => ({
            absence: hasAbsenceRegistration ?? null,
            simployerAbsence: hasSimployerAbsence ?? null,
            time: hasCapitechTid ?? null,
            plan: (hasCapitechFlowPlan || hasCapitechPlan) ?? null
        }),
        [
            hasAbsenceRegistration,
            hasSimployerAbsence,
            hasCapitechTid,
            hasCapitechFlowPlan,
            hasCapitechPlan
        ]
    );

    return {
        isLoading,
        isLoadingFirstTime: !employeeRegistrationInformation,
        access
    };
}
