import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { AllKnownEmployeeSettings } from './useEmployeeSettings.types';

export interface EmployeeSettingDefinition {
    key: AllKnownEmployeeSettings;
    label: string; // t('language')
    options: Array<{
        label: string; // t('norwegian')
        value: string; // 'nb-NO'
    }>;
}

export default function useEmployeeSettingDefinitions() {
    const { t } = useTranslation();

    const employeeSettingDefinitions: EmployeeSettingDefinition[] = useMemo(
        () => [
            {
                key: 'language',
                label: t('profile.settings.language'),
                options: [
                    {
                        label: t('profile.settings.languageOptions.norwegian'),
                        value: 'nb-NO'
                    },
                    {
                        label: t('profile.settings.languageOptions.english'),
                        value: 'en-EN'
                    }
                ]
            },
            {
                key: 'selectCurrentDateOnStartup',
                label: t('profile.settings.selectCurrentDateOnStartup'),
                options: [
                    {
                        label: t('profile.settings.selectCurrentDateOnStartupOptions.true'),
                        value: 'true'
                    },
                    {
                        label: t('profile.settings.selectCurrentDateOnStartupOptions.false'),
                        value: 'false'
                    }
                ]
            },
            {
                key: 'useNativeTimeInput',
                label: t('profile.settings.useNativeTimeInput'),
                options: [
                    {
                        label: t('yes'),
                        value: 'true'
                    },
                    {
                        label: t('no'),
                        value: 'false'
                    }
                ]
            }
        ],
        [t]
    );

    return employeeSettingDefinitions;
}
