import { Stack } from '@mui/material';
import useAppSettings from 'hooks/useAppSettings';
import { ProfileDetails } from '../../details';
import { ProfileSettings } from '../../settings';
import { PasswordChangeCard } from '../../password';

export default function ProfileMain() {
    const { showChangePassword } = useAppSettings();
    return (
        <Stack spacing={4} mt={2}>
            <ProfileDetails />
            <ProfileSettings />
            {showChangePassword && <PasswordChangeCard />}
        </Stack>
    );
}
