import { Box, Paper, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import DialogMessage from '../models/DialogMessage';
import DialogMessageReadConfirm from './DialogMessageReadConfirm';

type DialogMessagesProps = {
    messages: DialogMessage[];
    onMessageChanged: () => void;
};

export default function DialogMessages({ messages, onMessageChanged }: DialogMessagesProps) {
    const { t } = useTranslation();

    function getFromNowTime(timestamp: string) {
        const date = dayjs(timestamp);
        if (date.isBefore(dayjs().subtract(1, 'day'))) {
            return date.format('ddd DD.MM.YYYY HH:mm');
        }
        return date.fromNow();
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                overflow: 'auto',
                my: 2,
                mx: { xs: 0, md: 1 },
                gap: 2
            }}
        >
            {messages.map((message) => (
                <Paper
                    key={message.id}
                    sx={(theme) => ({
                        p: 1,
                        backgroundColor: message.isIncoming
                            ? theme.palette.background.paper
                            : 'messageOutgoing.main',
                        color: message.isIncoming ? 'inherit' : 'messageOutgoing.contrastText',
                        width: '80%',
                        whiteSpace: 'pre-wrap',
                        borderRadius: 1.5,
                        alignSelf: message.isIncoming ? 'flex-start' : 'flex-end',
                        wordBreak: 'break-word'
                    })}
                    elevation={0}
                >
                    <Typography fontFamily="GT-Walsheim-Bold">{message.senderFullName}</Typography>

                    <Box pb={1}>
                        {message.body}
                        {message.body === '' && (
                            <Typography fontStyle="italic">{t('messages.emptyBody')}</Typography>
                        )}
                    </Box>

                    {message.isIncoming && message.isUnAcknowledged ? (
                        <Box pb={1} pt={1}>
                            <DialogMessageReadConfirm
                                onSuccess={onMessageChanged}
                                messageId={message.id}
                            />
                        </Box>
                    ) : null}

                    <Typography textAlign="right">{getFromNowTime(message.createdOn)}</Typography>
                </Paper>
            ))}
        </Box>
    );
}
