import { AbsenceRequestsProvider } from '../providers';
import AbsenceOverview from './AbsenceOverview';

export default function AbsenceOverviewWrapper() {
    return (
        <AbsenceRequestsProvider>
            <AbsenceOverview />
        </AbsenceRequestsProvider>
    );
}
