import { Container, Box, Grid } from '@mui/material';
import DialogProvider from 'ctx/DialogCtx';
import DayDetails from './components/DayDetails';
import DailySummaryTable from './components/DailySummaryTable';
import { CapitechCalendar } from './components/CapitechCalendar';
import MonthlySummary from './components/MonthlySummary';
import TimePeriodSelectedAlerts from './components/TimePeriodSelectedAlerts';
import CapitechDataVisibilitySwitchesRow from './components/CapitechDataVisibilitySwitchesRow';

export type DashboardViewProps = {
    currentMonth: Date;
    selectedDay: Date | null;
    handleSelectDay: (day: Date | null) => void;
    handleChangeMonth: (dayInMonth: Date) => void;
};

export default function DashboardView({
    currentMonth,
    selectedDay,
    handleSelectDay,
    handleChangeMonth
}: DashboardViewProps) {
    return (
        <Box
            sx={{
                flexGrow: 1,
                overflow: 'auto'
            }}
            component="main"
        >
            <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={7} lg={8}>
                        <CapitechCalendar
                            selectedDate={selectedDay}
                            onDateChange={handleSelectDay}
                            onMonthChange={handleChangeMonth}
                        />
                        <CapitechDataVisibilitySwitchesRow
                            sx={{ mt: 2, justifyContent: 'center' }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={5} lg={4}>
                        <TimePeriodSelectedAlerts mb={2} />
                        {selectedDay ? (
                            <>
                                <DialogProvider>
                                    <DayDetails />
                                </DialogProvider>
                                <DailySummaryTable />
                            </>
                        ) : (
                            <MonthlySummary currentMonth={currentMonth} />
                        )}
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
