import useReadableDate from 'hooks/useReadableDate';
import AbsenceRequest from 'models/AbsenceRequest';
import { DimensionName, allDimensionNamesSorted } from 'models/TimeRegistrationModels';

type DimensionDescriptionPropertyName = `${DimensionName}Description`;

const sortedDimensionProps = allDimensionNamesSorted.map<DimensionDescriptionPropertyName>(
    (dimensionName) => `${dimensionName}Description`
);

export function joinAbsenceRequestDescriptionsIntoString(
    absenceRequest: AbsenceRequest,
    omitAbsenceCodeDescription = false
) {
    const possibleDimensionDescriptionsSorted = sortedDimensionProps.map((dimensionPropertyName) =>
        dimensionPropertyName in absenceRequest
            ? absenceRequest[dimensionPropertyName as keyof AbsenceRequest]
            : undefined
    );

    const dimensionDescriptionsSorted = possibleDimensionDescriptionsSorted.filter(
        (possibleDimensionDescription): possibleDimensionDescription is string | number =>
            typeof possibleDimensionDescription !== 'undefined' &&
            possibleDimensionDescription !== null &&
            possibleDimensionDescription !== ''
    );

    if (omitAbsenceCodeDescription) {
        return dimensionDescriptionsSorted.join(' | ');
    }

    const allDescriptionsSorted = [
        absenceRequest.absenceCodeDescription,
        ...dimensionDescriptionsSorted
    ];

    return allDescriptionsSorted.join(' | ');
}

export function useSmartAbsenceTimespanString(absence: AbsenceRequest) {
    const readableFromDate = useReadableDate(absence.fromDate);
    const readableToDate = useReadableDate(absence.toDate);

    if (absence.fromDate === absence.toDate) {
        if (absence.fromTime !== absence.toTime) {
            return `${readableFromDate}, ${absence.fromTime} - ${absence.toTime}`;
        }
        return readableFromDate;
    }

    return `${readableFromDate} - ${readableToDate}`;
}
