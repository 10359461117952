import { useMemo } from 'react';
import { DimensionValuesByDimension } from 'utils/dimension';
import useDimensionDescriptionStore from './useDimensionDescriptionStore';
import {
    DimensionOptionsByDimension,
    getDisplayableOptionsFromStore
} from './useDimensionOptionsSet.helpers';
import { DimensionInputRules } from '../types/DimensionInput';

/**
 *
 * @param dimensionInputRules Rules which define which inputs are available
 * @param currentDimensionValues Updated set of dimension values. Ensures that the correct option sets are provided.
 * @param initDimensionValues Initial set of dimension values; default values if creating something, current values if editing something. Non-optional to ensure option to initial values matching a disabled option gets included.
 * @returns
 */
export default function useDimensionOptionsSet(
    dimensionInputRules: DimensionInputRules | null,
    currentDimensionValues: DimensionValuesByDimension,
    initDimensionValues: DimensionValuesByDimension
) {
    const { descriptionStore, isLoading, isDescriptionsInitialized, dimensionNamesLoading } =
        useDimensionDescriptionStore(dimensionInputRules, currentDimensionValues);

    // Options are all of the ones that have been downloaded
    const dimensionOptionsByDimension = useMemo<DimensionOptionsByDimension>(
        () => getDisplayableOptionsFromStore(descriptionStore, initDimensionValues),
        [descriptionStore, initDimensionValues]
    );

    return {
        dimensionOptionsSet: dimensionOptionsByDimension,
        isLoading,
        isDescriptionsInitialized,
        dimensionNamesLoading
    };
}
