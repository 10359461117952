import { Card, CardContent, Typography } from '@mui/material';
import { useFetchArray } from 'hooks/useFetch';
import AccumulatedTimeResult from 'models/AccumulatedTime';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { dateToIsoString, dateToString } from 'utils/datetime';
import useSizeMaintainer from 'hooks/useSizeMaintainer';
import { FlexiBalance } from 'models/FlexiBalance';
import { useEmployeeRegistrationInformation } from 'features/misc/employeeSettings';
import { useTimeTransactions } from '../ctx/TimeTransactionsProvider';
import { TimeTransactionsDispatchType } from '../ctx/TimeTransactionReducer.types';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import AccumulatedTimeSummaryTableView from './AccumulatedTimeSummaryTableView';

export default function DailySummaryTable() {
    const { selectedDate } = useSelectedTimePeriod();

    if (!selectedDate) {
        throw new Error('Selected date is not set in DailySummaryTable');
    }

    const selectedDateAsIsoString = dateToIsoString(selectedDate);

    const { state, dispatch } = useTimeTransactions();
    const { isLoading: isLoadingTimeTransactions, shouldRefreshSummary } = state;

    const { employeeRegistrationInformation, isLoading: isLoadingEmpRegInfo } =
        useEmployeeRegistrationInformation(selectedDateAsIsoString);
    const hasFlexitime = employeeRegistrationInformation?.hasFlexitime;

    const [
        accumulatedTime,
        {
            isLoading: isLoadingAccuTime,
            errorMessage: errorMessageAccuTime,
            sendRequest: fetchAccuTime
        }
    ] = useFetchArray<AccumulatedTimeResult>('getAccumulatedTime', {
        reqData: {
            fromDate: selectedDateAsIsoString,
            toDate: selectedDateAsIsoString
        }
    });

    const [
        flexiTimeBalances,
        {
            isLoading: isLoadingFlexiBalances,
            errorMessage: errorMessageFlexiBalances,
            sendRequest: fetchFlexitimeBalances
        }
    ] = useFetchArray<FlexiBalance>('getFlexiTimeBalanceForDay', {
        reqData: {
            date: selectedDateAsIsoString
        }
    });

    const { t } = useTranslation();

    useEffect(() => {
        if ((isLoadingTimeTransactions && !isLoadingAccuTime) || shouldRefreshSummary) {
            fetchAccuTime();
            dispatch({
                type: TimeTransactionsDispatchType.RefreshSummary,
                shouldRefreshSummary: false
            });
        }
    }, [
        dispatch,
        fetchAccuTime,
        isLoadingAccuTime,
        isLoadingTimeTransactions,
        shouldRefreshSummary
    ]);

    useEffect(() => {
        if (isLoadingTimeTransactions && !isLoadingFlexiBalances && hasFlexitime) {
            fetchFlexitimeBalances();
        }
    }, [fetchFlexitimeBalances, hasFlexitime, isLoadingFlexiBalances, isLoadingTimeTransactions]);

    const flexiBalanceLines = flexiTimeBalances.map((flexiBalance) => ({
        timeCategoryId: flexiBalance.accountId,
        timeCategoryDescription: flexiBalance.accountDescription,
        amount: flexiBalance.totalBalanceInHours
    }));

    const accuCardRef = useRef(null);
    const sizeAccuTimeCardIfLoading = useSizeMaintainer(
        accuCardRef,
        isLoadingTimeTransactions,
        true
    );

    return (
        <Card sx={{ ...sizeAccuTimeCardIfLoading, mb: 2 }} ref={accuCardRef}>
            <CardContent sx={{ px: 1 }}>
                <Typography gutterBottom variant="h5" component="div" px={1}>
                    {t('dashboard.yourHoursForSomething', {
                        something: dateToString(selectedDate)
                    })}
                </Typography>
                <AccumulatedTimeSummaryTableView
                    errorMessage={errorMessageAccuTime}
                    accumulatedTimeLines={accumulatedTime}
                    isLoading={isLoadingAccuTime || isLoadingEmpRegInfo}
                    label={t('dashboard.yourHoursForSomething')}
                />
                {hasFlexitime && (
                    <AccumulatedTimeSummaryTableView
                        sx={{ mt: 2 }}
                        errorMessage={errorMessageFlexiBalances}
                        accumulatedTimeLines={flexiBalanceLines}
                        isLoading={isLoadingFlexiBalances || isLoadingEmpRegInfo}
                        label={t('balances.flexitime.summaryTable.label')}
                        typeLabel={t('balances.tabLabels.flexiTime')}
                    />
                )}
            </CardContent>
        </Card>
    );
}
