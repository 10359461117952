import { useDutiesOnDateInSetDateRange } from 'features/plan';
import { useCallback } from 'react';
import PlannedDutyDetails from './PlannedDutyDetails';
import { useSelectedTimePeriod } from '../ctx/SelectedTimePeriodCtx';
import AvailableDutyDetails from './AvailableDutyDetails';
import { useCapitechDataVisibility } from '../providers';
import RequestedDutyDetails from './RequestedDutyDetails';
import DutyWishDetails from './DutyWishDetails';

export default function DutyDetails() {
    const { selectedDate } = useSelectedTimePeriod();

    if (!selectedDate) {
        throw new Error('Cannot display details for no date in particular');
    }
    const { planned, available, requested, wish, wishPeriods } =
        useDutiesOnDateInSetDateRange(selectedDate);
    const availableDuties = available.items.filter(
        (duty) =>
            !requested.items.find(
                (r) =>
                    r.dutyId === duty.dutyId &&
                    r.startDate === duty.startDate &&
                    r.departmentId === duty.departmentId &&
                    r.dutyPlanId === duty.dutyPlanId
            )
    );
    const capitechDataVisibility = useCapitechDataVisibility();

    const refreshDuties = useCallback(() => {
        planned.refresh();
        available.refresh();
        requested.refresh();
        wish.refresh();
        wishPeriods.refresh();
    }, [planned, available, requested, wish, wishPeriods]);

    return (
        <>
            {capitechDataVisibility.isVisibleData('dutyRequested') &&
                requested.items.map((duty) => (
                    <RequestedDutyDetails
                        requestedDuty={duty}
                        relatedAvailableDuty={available.items.find((a) => a.dutyId === duty.dutyId)}
                        key={duty.dutyId}
                        onDelete={refreshDuties}
                    />
                ))}
            {capitechDataVisibility.isVisibleData('dutyWish') &&
                wish.items.map((w, i) => (
                    <DutyWishDetails
                        // I don't like this any more than you do eslint, but there is no unique data in the wish object
                        // eslint-disable-next-line react/no-array-index-key
                        key={w.createdTime + i}
                        dutyWish={w}
                        onDelete={refreshDuties}
                    />
                ))}
            {capitechDataVisibility.isVisibleData('dutyWishPeriod') &&
                wishPeriods.items.map((wp, i) => (
                    <DutyWishDetails
                        // eslint-disable-next-line react/no-array-index-key
                        key={wp.requestTime + i}
                        dutyWish={wp}
                        onDelete={refreshDuties}
                    />
                ))}
            {capitechDataVisibility.isVisibleData('dutyAvailable') &&
                availableDuties.map((duty) => (
                    <AvailableDutyDetails
                        duty={duty}
                        key={duty.dutyId}
                        onRequestDutySuccess={refreshDuties}
                    />
                ))}
            {capitechDataVisibility.isVisibleData('dutyPlanned') &&
                planned.items.map((duty) => <PlannedDutyDetails duty={duty} key={duty.dutyId} />)}
        </>
    );
}
