import { Grid, Typography } from '@mui/material';
import ActionButton from 'components/button/ActionButton';
import DialogWithCloseBtn from 'components/dialog/DialogWithCloseBtn';
import AbsenceRequestIcon from 'components/icon/AbsenceRequestIcon';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDialog } from 'ctx/DialogCtx';
import { dateToIsoString } from 'utils/datetime';
import AbsenceRequestRegistration from '../AbsenceRequestRegistration';
import { AbsenceRequestsContext } from '../providers/absenceRequests/AbsenceRequestsProvider';

export default function AbsencePageHeader() {
    const { t } = useTranslation();
    const { showDialog } = useDialog();
    const dateInIsoFormat = dateToIsoString(new Date());
    const { refreshAbsenceRequests } = useContext(AbsenceRequestsContext);

    const handleNewAbsenceClick = useCallback(() => {
        showDialog((onClose) => (
            <DialogWithCloseBtn onClose={onClose} open>
                <AbsenceRequestRegistration
                    sx={{ maxWidth: '400px' }}
                    date={dateInIsoFormat}
                    onSuccess={() => {
                        refreshAbsenceRequests();
                        onClose();
                    }}
                    onCancel={onClose}
                />
            </DialogWithCloseBtn>
        ));
    }, [dateInIsoFormat, refreshAbsenceRequests, showDialog]);

    return (
        <Grid container sx={{ mt: 4, mb: 2 }}>
            <Grid item xs>
                <Typography
                    component="h1"
                    variant="h5"
                    gutterBottom
                    sx={{
                        textTransform: 'uppercase'
                    }}
                >
                    {t('absenceRequestOverview.pageTitle')}
                </Typography>
            </Grid>
            <Grid item>
                <ActionButton onClick={handleNewAbsenceClick} startIcon={<AbsenceRequestIcon />}>
                    {t('dayDetails.button.requestAbsence')}
                </ActionButton>
            </Grid>
        </Grid>
    );
}
