import { useContext } from 'react';
import { HolidaysContext } from '../../ctx/HolidaysProvider/HolidaysProvider';
import { UseHolidaysReturn } from './useHolidays.types';

export default function useHolidays(): UseHolidaysReturn {
    const ctx = useContext(HolidaysContext);

    if (!ctx) {
        throw new Error('useHolidays must be used within a HolidaysProvider');
    }

    return ctx;
}
