import ConfirmDialog from 'components/dialog/ConfirmDialog';
import { useFetch } from 'hooks/useFetch';
import AbsenceRequest from 'models/AbsenceRequest';
import { useTranslation } from 'react-i18next';

type AbsenceRequestDeleteDialogProps = {
    absenceRequest: AbsenceRequest;
    onSuccess: VoidFunction;
    onCancel: VoidFunction;
};

type PostDeleteAbsenceRequestPayload = [];
type PostDeleteAbsenceRequestInput = {
    absenceRequestId: number;
};

export default function AbsenceRequestDeleteDialog({
    absenceRequest,
    onSuccess,
    onCancel
}: AbsenceRequestDeleteDialogProps) {
    const [, { errorMessage, isLoading: isDeleting, sendRequest: deleteAbsenceRequest }] = useFetch<
        PostDeleteAbsenceRequestPayload,
        PostDeleteAbsenceRequestInput
    >('deleteAbsenceRequest', {
        reqData: {
            absenceRequestId: absenceRequest.id
        },
        manuallyTriggerRequest: true,
        onSuccess: () => onSuccess()
    });

    const { t } = useTranslation();

    return (
        <ConfirmDialog
            open
            severity="warning"
            title={t('actions.confirmDeletion')}
            confirmText={t('actions.delete')}
            isRunningConfirmOperation={isDeleting}
            onCancel={onCancel}
            onConfirm={deleteAbsenceRequest}
            errorMessage={errorMessage}
        />
    );
}
