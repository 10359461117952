import DateEvent, { DateEventType } from './DateEvent';
import DateEventGroupWrapper, { DateEventGroupType } from './DateEventGroupWrapper';

type DateEventSetSliceProps = {
    dateEventSetSlice: DateEventGroupType | DateEventType;
};

/**
 * Part of a date event set can be a group of events, or a single event.
 * A group can be output in a special wrapper, or just as a list of events.
 * The special wrapper is typically intended for display in mobile view as a dot.
 * A group can only be displayed as a dot if some of the events indicate som kind of
 * fill variant. If all events in a group have fill variant 'none', the group is
 * displayed as a list of events.
 *
 * @param param0
 * @returns
 */
export default function DateEventSetSlice({ dateEventSetSlice }: DateEventSetSliceProps) {
    const isDateGroup = 'items' in dateEventSetSlice;

    if (isDateGroup) {
        const dateGroup = dateEventSetSlice as DateEventGroupType;

        const isPresentableDateGroup = isDateGroup
            ? dateEventSetSlice.items.some((eventItem) => eventItem.fillVariant !== 'none')
            : false;

        return isPresentableDateGroup ? (
            <DateEventGroupWrapper groupData={dateGroup}>
                {dateGroup.items.map((dateEvent, index) => (
                    <DateEvent
                        // eslint-disable-next-line react/no-array-index-key
                        key={`event-${index}-in-day-group`}
                        dateEvent={dateEvent}
                    />
                ))}
            </DateEventGroupWrapper>
        ) : (
            <>
                {dateGroup.items.map((dateEvent, index) => (
                    <DateEvent
                        // eslint-disable-next-line react/no-array-index-key
                        key={`event-${index}-in-day`}
                        dateEvent={dateEvent}
                    />
                ))}
            </>
        );
    }

    return <DateEvent dateEvent={dateEventSetSlice} />;
}
