import { Calendar } from 'components/Calendar';
import { CalendarProps } from 'components/Calendar/Calendar';
import useDateEventsFromCapitechTimePeriod from './hooks/useDateEventsFromCapitechTimePeriod';

type CapitechCalendarProps = Omit<CalendarProps, 'dateEventSets'>;

export default function CapitechCalendar({
    onDateChange,
    onMonthChange,
    selectedDate
}: CapitechCalendarProps) {
    const { dateEventSets, isLoading } = useDateEventsFromCapitechTimePeriod();

    return (
        <Calendar
            disableDateSelection={isLoading}
            selectedDate={selectedDate}
            dateEventSets={dateEventSets}
            onDateChange={onDateChange}
            onMonthChange={(newDateInMonth) => {
                if (newDateInMonth) {
                    onDateChange(newDateInMonth);
                    onMonthChange(newDateInMonth);
                }
            }}
        />
    );
}
