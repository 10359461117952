import { useContext, useMemo } from 'react';
import { AbsenceRequestsContext } from '../../AbsenceRequestsProvider';
import { UseAbsenceRequestsAffectingDateResult } from './useAbsenceRequestsAffectingDate.types';

export default function useAbsenceRequestsAffectingDate(
    dateInIsoFormat: string
): UseAbsenceRequestsAffectingDateResult {
    const ctx = useContext(AbsenceRequestsContext);
    if (!ctx) {
        throw new Error('useAbsenceRequests must be used within an AbsenceRequestsProvider');
    }

    const { absenceRequests, isLoading, errorMessage, refreshAbsenceRequests } = ctx;

    const selectedAbsenceRequests = useMemo(
        () =>
            absenceRequests.filter(
                (absenceRequest) =>
                    absenceRequest.fromDate <= dateInIsoFormat &&
                    absenceRequest.toDate >= dateInIsoFormat &&
                    absenceRequest.absenceRequestStatus === 'Created'
            ),
        [dateInIsoFormat, absenceRequests]
    );

    return {
        absenceRequests: selectedAbsenceRequests,
        isLoading,
        errorMessage,
        refreshAbsenceRequests
    };
}
