import { useCallback } from 'react';
import SnackbarBriefAlert from 'components/SnackbarBriefAlert';
import Switch from 'components/Input/Switch';
import { AllKnownEmployeeSettings } from '../../hooks/useEmployeeSettings.types';
import { useEmployeeSettingValuePost } from '../../hooks';

type SettingSwitchProps = {
    name: AllKnownEmployeeSettings;
    initValue: string;
};
export default function SettingSwitch({ name, initValue }: SettingSwitchProps) {
    const { currentValue, translatedErrorMessage, isSubmitting, postValue } =
        useEmployeeSettingValuePost(name, initValue);

    const activeValue = 'true';
    const isActive = currentValue === activeValue;

    const onChangeSwitch = useCallback(() => {
        if (isSubmitting) return;
        postValue(isActive ? 'false' : 'true');
    }, [postValue, isActive, isSubmitting]);

    return (
        <>
            <SnackbarBriefAlert severity="error" message={translatedErrorMessage} />
            <Switch
                value={currentValue}
                activeValue="true"
                onChange={onChangeSwitch}
                isBusy={isSubmitting}
            />
        </>
    );
}
