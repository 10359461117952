import { ListItem, ListItemButton, ListItemText } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { useTranslation } from 'react-i18next';

export default function LogOutButton() {
    const { logout } = useAuth();
    const { t } = useTranslation();

    return (
        <ListItem disableGutters disablePadding>
            <ListItemButton onClick={logout}>
                <ListItemText primary={t('navbar.logOut')} />
            </ListItemButton>
        </ListItem>
    );
}
