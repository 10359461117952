import { AuthContext } from 'ctx/AuthProvider';
import { useContext } from 'react';

export default function useAuth() {
    const ctx = useContext(AuthContext);

    if (!ctx) {
        throw new Error('useAuth only available inside AuthContext');
    }

    return ctx;
}
