import { useEffect, useState } from 'react';
import useApiPost from 'hooks/useApiPost';
import { getTranslatedOrFallback } from 'utils/translation';
import { AllKnownEmployeeSettings } from './useEmployeeSettings.types';
import useEmployeeSettingSyncer from './useEmployeeSettingSyncer';

type PostUpsertMyCapitechSettingInput = {
    key: string;
    value: string;
};
type PostUpsertMyCapitechSettingPayload = Array<never>;

export default function useEmployeeSettingValuePost(
    settingName: AllKnownEmployeeSettings,
    initValue: string,
    onSuccess?: (value: string) => void
) {
    const [lastStoredValue, setLastStoredValue] = useState(initValue);
    const [currentValue, setCurrentValue] = useState(initValue);
    const { syncEmployeeSetting } = useEmployeeSettingSyncer();

    const [{ isSubmitting, errorMessage }, request] = useApiPost<
        PostUpsertMyCapitechSettingPayload,
        PostUpsertMyCapitechSettingInput
    >('upsertMyCapitechSetting', {
        onSuccess: (data, input) => {
            const typedInput = input as PostUpsertMyCapitechSettingInput; // Icky, but it'll do until types are improved

            const inputValue = typedInput.value;
            setLastStoredValue(inputValue);

            // By doing this just once we also avoid the risk of an infinite loop if the server returns without the expected updated value
            const inputKey = typedInput.key as AllKnownEmployeeSettings;
            syncEmployeeSetting(inputKey, inputValue);

            if (onSuccess) {
                onSuccess(inputValue);
            }
        },
        popContentArray: true
    });

    // revert current value to last stored value if error occurs
    useEffect(() => {
        if (errorMessage && !isSubmitting && currentValue !== lastStoredValue) {
            setCurrentValue(lastStoredValue);
        }
    }, [isSubmitting, lastStoredValue, errorMessage, currentValue]);

    const postValue = (value: string) => {
        request({
            key: settingName,
            value
        });
        setCurrentValue(value);
    };

    const translatedErrorMessage = errorMessage ? getTranslatedOrFallback(errorMessage) : null;
    return { postValue, isSubmitting, translatedErrorMessage, currentValue };
}
